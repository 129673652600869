import React from 'react';
import { Button, Dropdown, Pagination, Popover, Tag } from 'antd';
import { PartnerStatus } from '../../pages/Partners/PartnerStatus';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../svgs/MoreActionIcon';
import { UserI } from '../../interfaces/user';
import Divider from '../Divider';
import { AccessControlI } from '../../interfaces';
import { RoleI } from '../../interfaces/role';
import Text from '../Text';
import SizeBox from '../SizeBox';
import { IconName } from '../Icon/iconName';
import Icon from '../Icon';
import { Avatar } from '../User/Avatar';
import { formatIntlPhoneNumber } from '../PhoneNumberInput/PhoneNumberInput';

interface Props {
  access: AccessControlI;
  data: UserI[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const UsersCardList: React.FC<Props> = ({ access, data, pagination, onPaginationChange }) => {
  const navitate = useNavigate();

  const renderField = (props: { icon: IconName; value: string; className?: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-600 text-xs tw-flex-none tw-min-w-4 tw-min-h-4" size={12} />
        <Text variant="labelSm" className={`flex-1 !tw-font-normal ${props.className}`}>
          {props.value}
        </Text>
      </div>
    );
  };

  const handleCardClick = (item: UserI) => {
    if (access.isCanRead) navitate(`/users/${item.id}`);
  };

  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="card-list">
        {data.map((item) => {
          return (
            <button key={item.id} className="card-item" onClick={() => handleCardClick(item)}>
              <a
                className="tw-flex tw-w-full tw-justify-between tw-p-0"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Text variant="inputLabelSm" className="tw-text-primary-800">
                  {item.partner?.displayName}
                </Text>
                <Dropdown
                  menu={{
                    items: [
                      access.isCanRead
                        ? {
                            key: '1',
                            label: (
                              <Link to={`/users/${item.id}`}>
                                <Button className="btn-table-action-primary" type="link">
                                  View
                                </Button>
                              </Link>
                            ),
                          }
                        : null,
                      access.isCanUpdate
                        ? {
                            key: '2',
                            label: (
                              <Link to={`/users/${item.id}/edit`}>
                                <Button className="btn-table-action-primary" type="link">
                                  Edit Profile
                                </Button>
                              </Link>
                            ),
                          }
                        : null,
                    ],
                  }}
                >
                  <div>
                    <MoreActionIcon />
                  </div>
                </Dropdown>
              </a>
              <div className="tw-flex tw-justify-between tw-flex-col tw-w-full tw-h-full">
                <div className="tw-flex tw-flex-col tw-gap-3">
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <Avatar id={item?.id ?? 0} className="!tw-w-10 !tw-h-10 tw-rounded-[50%]" />
                    <Text variant="bodyMd" className="tw-truncate tw-font-bold">
                      {item.username}
                    </Text>
                  </div>
                  {item.type !== 'partnerAdmin' && item.roles.length > 0 ? (
                    <div className="tw-flex tw-items-center">
                      <Tag style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {item.roles[0].name}
                      </Tag>
                      {item.roles.length > 1 && (
                        <span className="more-item-number">
                          <Popover
                            content={item.roles.map((role: RoleI) => (
                              <Tag key={role.id} className="tw-truncate tw-max-w-[150px]">
                                {role?.name}
                              </Tag>
                            ))}
                            trigger="click"
                          >
                            <button
                              className="tw-cursor-pointer tw-border-none tw-bg-white tw-rounded-[17px] tw-py-[2px] tw-px-3 hover:tw-bg-grey-400"
                              onClick={(e) => e.stopPropagation()}
                            >{`+${item.roles.length - 1}`}</button>
                          </Popover>
                        </span>
                      )}
                    </div>
                  ) : (
                    <Tag className="tw-w-fit">{item.type === 'partnerAdmin' ? 'Partner Administrator' : 'No Role'}</Tag>
                  )}
                  {renderField({ icon: 'icon-mail', value: item.email ?? '', className: 'truncate-2-lines' })}
                  {renderField({ icon: 'icon-phone', value: formatIntlPhoneNumber(item.contactNumber) })}
                  <SizeBox height={12} />
                </div>
                <div>
                  <Divider />
                  <div className="tw-pt-3">
                    <PartnerStatus status={item.status} />
                  </div>
                </div>
              </div>
            </button>
          );
        })}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        pageSizeOptions={[5, 10, 20, 50]}
        showSizeChanger
      />
    </section>
  );
};

export default UsersCardList;
