import { Button, Dropdown } from 'antd';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../../constants';
import { handleFilterParams } from '../../../utils/global';
import { HardwareSearchParamsI } from '../../../interfaces/hardware';
import HardwaresTable from '../../../components/tables/Hardwares';
import { CurrentUserI } from '../../../interfaces/auth';
import { handleAccessControl } from '../../../utils/role';
import Text from '../../../components/Text';
import TrackingFilterForm from '../../../components/forms/hardware/TrackingFilter';
import { getPlatformHardwareList } from '../../../apis/platforms/hardware';
import HardwareCardList from '../../../components/card-list/platforms/Hardware';
import DisplayLastUpdateApp from '../../../components/DisplayLastUpdate';
import SizeBox from '../../../components/SizeBox';
import EmptyData from '../../../components/EmptyData';

const PlatformHardwarePage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'platform/hardware');
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const searchDevId = urlSearch.get('search[devId]');
    const searchSerialCode = urlSearch.get('search[serialCode]');
    const type = urlSearch.get('filter[type]')?.split(',') ?? [];
    const status = urlSearch.get('filter[status]')?.split(',') ?? [];
    const condition = urlSearch.get('filter[condition]')?.split(',') ?? [];
    const siteIds = urlSearch.get('filter[siteIds]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search: { serialCode: searchSerialCode, devId: searchDevId },
      filter: { status, type, condition, siteIds },
    } as HardwareSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { hardwares = [], total = 0 } = {},
    isLoading,
    refetch: refetchData,
  } = useQuery(['platform-hardware', 'list', queryParams], () => getPlatformHardwareList(queryParams), {
    select({ data: { hardwares, total } }) {
      return { hardwares, total };
    },
  });

  const currentPage = queryParams?.page ?? 1;
  const pagination: any = {
    total,
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: Partial<HardwareSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const handleFilterChange = (values: HardwareSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/platform/hardware/${record?.serialCode}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <div className="page-platform-hardwares-container tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
        <Text component="h3" variant="h3">
          Hardware
        </Text>
      </div>
      <TrackingFilterForm queryParams={queryParams} onChange={handleFilterChange} refetchData={refetchData} />
      {!isLoading && hardwares.length === 0 && (
        <EmptyData
          title="No Hardware"
          message="Hardware within all partner entities across platform will be displayed here."
        />
      )}
      {!isLoading && hardwares.length !== 0 && (
        <>
          <DisplayLastUpdateApp />
          <SizeBox height={8} />
          {!queryParams.viewMode ? (
            <HardwareCardList
              data={hardwares}
              pagination={pagination}
              onPaginationChange={handleFilter}
              access={access}
            />
          ) : (
            <HardwaresTable
              rowKey="id"
              loading={isLoading}
              pagination={pagination}
              dataSource={hardwares}
              withAction={withAction}
              onChange2={handleFilter}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PlatformHardwarePage;
