import React, { useEffect, useState } from 'react';
import { HardwareGroupI } from '../../interfaces/hardware';
import { PAGE_SIZE } from '../../constants';
import HardwareGroupItemCard from '../shared/Hardware/GroupItemCard';
import { Pagination, message } from 'antd';
import DetailFilterForm from '../forms/DetailFilterForm';
import PrimaryButton from '../buttons/PrimaryButton';
import { useMutation, useQueryClient } from 'react-query';
import { updateHardwareGroup } from '../../apis/hardwares';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  data: HardwareGroupI;
  isDisplayDelete?: boolean;
  isDisplayFilter?: boolean;
};

const GroupRemoveHardwareDrawer: React.FC<Props> = ({ data, isDisplayDelete = true, isDisplayFilter = true }) => {
  const queryClient = useQueryClient();
  const [items, setItems] = useState<HardwareGroupI['hardwares']>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;

  const handlePaginationChange = (page: number, per_page: number) => {
    setCurrentPage(page);
    setPageSize(per_page);
  };

  const { mutateAsync: updateMutate } = useMutation(updateHardwareGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      queryClient.invalidateQueries({
        queryKey: [`tracking/groups/${data?.id}`, undefined],
      });
      message.info('Delete successfully!');
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const handleFilter = (values: HardwareGroupI) => {
    console.log('values', values);
  };

  const handleDelete = (id: HardwareGroupI['id']) => {
    setItems((prevItems) => {
      const index = prevItems?.findIndex((item) => item.id === id);
      if (index === undefined || index === -1) {
        return prevItems;
      }

      const updatedItems = [...(prevItems || [])];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  const onSaveClick = () => {
    const newData: any = { ...data };
    const newItems = items?.map((item) => {
      return { serialCode: item.serialCode };
    });
    newData.hardwareList = newItems;
    delete newData.attachments;
    updateMutate({ code: data?.code, data: newData });
  };
  useEffect(() => {
    if (data) {
      setItems(data.hardwares);
    }
  }, [data]);

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-h-full">
      <div>
        <Text variant="labelSm" className="tw-mb-4 tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-all_inbox" size={20} />
          {items?.length ?? 0} Hardware
        </Text>
        {isDisplayFilter && <DetailFilterForm handleFilter={handleFilter} />}
        <div className="wrapper tw-flex tw-gap-4 tw-flex-wrap">
          {items?.slice(start, end)?.map((item: any) => {
            return (
              <HardwareGroupItemCard
                item={item}
                key={item.serialCode}
                canDelete={true}
                handleDelete={handleDelete}
                isDisplayDelete={isDisplayDelete}
              />
            );
          })}
        </div>
        <div className="pagination-container tw-mt-6">
          <Pagination
            total={data?.hardwares?.length ?? 0}
            pageSize={pageSize}
            showTotal={(total) => `${total} Items`}
            current={currentPage}
            onChange={handlePaginationChange}
            showSizeChanger
            pageSizeOptions={[5, 10, 20, 50]}
          />
        </div>
      </div>
      {isDisplayDelete && (
        <div className="tw-w-full">
          <PrimaryButton label="Save" onClick={onSaveClick} className="tw-w-full" />
        </div>
      )}
    </div>
  );
};

export default GroupRemoveHardwareDrawer;
