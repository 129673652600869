import React, { ReactNode } from 'react';
import './ActionSection.scss';

type Props = {
  label: string | ReactNode;
  content: ReactNode;
  className?: string;
};

const ActionSection: React.FC<Props> = ({ label, content, className }) => {
  return (
    <div className={`role-actions-section ${className}`}>
      <div className="label-side">{label}</div>
      <div className="actions-side">{content}</div>
    </div>
  );
};

export default ActionSection;
