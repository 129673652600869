import axiosInstance from '../axiosInstance';
import { getAuthenToken } from '../utils/authentication';

const baseUri = process.env.REACT_APP_API_URI ?? '';

export const api = {
  get: <T>(url: string, params?: Record<string, unknown>) =>
    axiosInstance.get<T>(`${baseUri}/${url}`, {
      headers: {
        Authorization: `Bearer ${getAuthenToken()?.authToken}`,
        'Content-Type': 'application/json',
      },
      ...params,
    }),
  post: <T>(url: string, data: any) =>
    axiosInstance.post<T>(`${baseUri}/${url}`, data, {
      headers: {
        Authorization: `Bearer ${getAuthenToken()?.authToken}`,
        'Content-Type': 'application/json',
      },
    }),
  patch: <T>(url: string, data: any) =>
    axiosInstance.patch<T>(`${baseUri}/${url}`, data, {
      headers: {
        Authorization: `Bearer ${getAuthenToken()?.authToken}`,
        'Content-Type': 'application/json',
      },
    }),
  delete: <T>(url: string) =>
    axiosInstance.delete<T>(`${baseUri}/${url}`, {
      headers: {
        Authorization: `Bearer ${getAuthenToken()?.authToken}`,
        'Content-Type': 'application/json',
      },
    }),
  postFormData: <T>(url: string, data: any) =>
    axiosInstance.post<T>(`${baseUri}/${url}`, data, {
      headers: {
        Authorization: `Bearer ${getAuthenToken()?.authToken}`,
        'Content-Type': 'multipart/form-data',
      },
    }),
  postBlob: <T>(url: string, data: any) =>
    axiosInstance.post<T>(`${baseUri}/${url}`, data, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getAuthenToken()?.authToken}`,
        'Content-Type': 'application/json',
      },
    }),
  getImage: <T>(url: string) =>
    axiosInstance.get<T>(`${baseUri}/${url}`, {
      headers: {
        Authorization: `Bearer ${getAuthenToken()?.authToken}`,
        'Content-Type': 'image/png',
      },
      responseType: 'arraybuffer',
    }),
};
