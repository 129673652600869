import React from 'react';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import Text from '../Text';
import SuccessSubmittedItem from '../shared/SuccessSubmittedItem';

type Props = {
  data?: any;
};

const TransferSuccessModal: React.FC<Props> = ({ data }) => {
  return (
    <div className="tw-text-center tw-py-4 tw-px-4">
      <CheckCircleIcon width={48} height={48} color="#0A7A08" />
      <Text variant="h3" className="tw-mt-2 tw-mb-3">
        You have successfully submitted!
      </Text>
      <div className="notice tw-mb-8 tw-text-grey-700">
        <Text variant="bodyMd" className="!tw-block">
          Few actions are available for this entry.
        </Text>
        <Text variant="bodyMd" className="!tw-block">
          You can choose the options below
        </Text>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3">
        <SuccessSubmittedItem
          url={`/transfers/${data?.code}`}
          title="View the Ownership Transfer Report"
          description={['View the report of Ownership Transfe']}
        />
      </div>
    </div>
  );
};

export default TransferSuccessModal;
