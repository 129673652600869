import React from 'react';
import { CurrentUserI } from '../../interfaces/auth';
import { useQueryClient } from 'react-query';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../../constants';
import HeaderAccountInfoDesktop from './HeaderAccountInfoDesktop';
import { Button, Layout } from 'antd';
import Icon from '../Icon';
const { Header } = Layout;

type Props = {
  currentUser: CurrentUserI;
  onMenuDrawerShowClick: () => void;
};

const HeaderLayout: React.FC<Props> = ({ currentUser, onMenuDrawerShowClick }) => {
  const queryClient = useQueryClient();

  const handleLogout = () => {
    Cookies.remove(TOKEN_NAME);
    queryClient.invalidateQueries();
    window.location.href = '/login';
  };

  return (
    <Header className="site-header">
      <div className="fake-element">
        <Button onClick={onMenuDrawerShowClick}>
          <Icon name="icon-menu" />
        </Button>
      </div>
      <HeaderAccountInfoDesktop currentUser={currentUser} handleLogout={handleLogout} />
    </Header>
  );
};

export default HeaderLayout;
