import React from 'react';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import Text from '../Text';
import SuccessSubmittedItem from '../shared/SuccessSubmittedItem';

type Props = {
  data?: any;
  configurationCode: string;
};

const ConfigurationSuccessModal: React.FC<Props> = ({ data, configurationCode }) => {
  return (
    <div className="tw-text-center tw-py-4 tw-px-4">
      <CheckCircleIcon width={48} height={48} color="#0A7A08" />
      <Text variant="h3" className="tw-mt-2 tw-mb-3">
        You have successfully submitted!
      </Text>
      <div className="notice tw-mb-8 tw-text-grey-700">
        <Text variant="bodyMd" className="!tw-block">
          Few actions are available for this entry.
        </Text>
        <Text variant="bodyMd" className="!tw-block">
          You can choose the options below
        </Text>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3">
        <SuccessSubmittedItem
          url={`/configurations/reports/${data?.code}`}
          title="View the Config Application Report"
          description={['View the report of Configuration you have just created']}
        />
        <SuccessSubmittedItem
          url={`/configurations/${configurationCode}`}
          title="View the Hardware Configuration"
          description={['View the profile of Hardware Configuration']}
        />
      </div>
    </div>
  );
};

export default ConfigurationSuccessModal;
