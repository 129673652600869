import React, { ReactNode } from 'react';
import { Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AccessControlI } from '../../../interfaces';
import { ProductI } from '../../../interfaces/product';
import Icon from '../../Icon';
import Text from '../../Text';
import { IconName } from '../../Icon/iconName';
import { getProductCategory } from '../../../utils/global';

interface Props {
  access: AccessControlI;
  data: ProductI[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const PlatformProductsCardList: React.FC<Props> = ({ access, data, pagination, onPaginationChange }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: ProductI) => {
    if (access.isCanRead) navitate(`/platform/products/${item.sku}`);
  };

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="card-list card-list-products-container">
        {data.map((item) => {
          return (
            <button key={item.id} className="card-item" onClick={() => handleCardClick(item)}>
              {item?.notes && <Icon name="icon-text_snippet" className="btn-more-action" size={16} />}
              <div className="tw-flex tw-flex-col tw-w-full">
                <Text variant="inputLabelSm">{item.brandName}</Text>
                <Text variant="bodyLgB" className="tw-text-primary-800 tw-mt-2 tw-mb-3">
                  {item.name}
                </Text>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  {renderField({ icon: 'icon-person', value: item?.partner?.legalName })}
                  {renderField({ icon: 'icon-invert_colors', value: `${item.abv ?? 0}% (ABV)` })}
                  {renderField({ icon: 'icon-receipt', value: item.sku ?? '' })}
                  {renderField({
                    icon: 'icon-wine_bar',
                    value: <span className="text-capitalize">{getProductCategory(item?.category ?? undefined)}</span>,
                  })}
                </div>
              </div>
            </button>
          );
        })}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        pageSizeOptions={[5, 10, 20, 50]}
        showSizeChanger
      />
    </section>
  );
};

export default PlatformProductsCardList;
