import { PartnerStatusI } from '../../interfaces/partner';
import React from 'react';
import './PartnerStatus.scss';
import { SiteStatusI } from '../../interfaces/site';
import { UserStatusI } from '../../interfaces/user';
import Text from '../../components/Text';

interface Props {
  status: PartnerStatusI | SiteStatusI | UserStatusI | string;
}

const replacedLabel = (label: string) => {
  if (label.toLowerCase() === 'request') {
    return 'Request in Review';
  }
  if (label.toLowerCase() === 'nonactive') {
    return 'Non Active';
  }
  return label;
};

export const PartnerStatus: React.FC<Props> = ({ status }) => {
  return (
    <Text className={`partner-status ${status}`} variant="labelSm">
      {replacedLabel(status)}
    </Text>
  );
};
