import { Dropdown, MenuProps, Space, Tabs, TabsProps } from 'antd';
import React, { useMemo } from 'react';
import { Link, useLocation, useOutletContext } from 'react-router-dom';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import Icon from '../../components/Icon';
import Text from '../../components/Text';
import { PAGE_SIZE } from '../../constants';
import { useQuery } from 'react-query';
import { ProductGroupSearchParamsI, ProductSearchParamsI } from '../../interfaces/product';
import { getProductGroupsList, getProductsList } from '../../apis/products';
import ProductsTabPage from './ProductsTab';
import ProductGroupsTabPage from './GroupsTab';

const createTabItem = (key: string, label: string, children: React.ReactNode) => ({
  key,
  label: (
    <Text variant="labelLg" className="tab-title !tw-font-normal">
      <span>{label}</span>
    </Text>
  ),
  children,
});

const TrackingPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const productAccess = handleAccessControl(currentUser, 'products');
  const groupAccess = handleAccessControl(currentUser, 'product-groups');
  const location = useLocation();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const productQueryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const searchName = urlSearch.get('search[name]');
    const searchBrand = urlSearch.get('search[brand]');
    const searchSKU = urlSearch.get('search[sku]');
    const filterProductionOrigin = urlSearch.get('filter[productionOrigin]');
    const filterCategoryId = urlSearch.get('filter[categoryId]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      filter: { productionOrigin: filterProductionOrigin, categoryId: filterCategoryId },
      search: { name: searchName, brand: searchBrand, sku: searchSKU },
    } as ProductSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { products = [], total: productTotal = 0 } = {},
    isLoading,
    refetch: productRefetchData,
  } = useQuery(['products', 'list', productQueryParams], () => getProductsList(productQueryParams));

  const groupQueryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const searchTitle = urlSearch.get('search');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search: searchTitle,
    } as ProductGroupSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { productGroups = [], total: groupTotal = 0 } = {},
    isLoading: isGroupLoading,
    refetch: groupRefetchData,
  } = useQuery(['product-groups', 'list', groupQueryParams], () => getProductGroupsList(groupQueryParams));

  const tabItems: TabsProps['items'] = [
    ...(productAccess.isCanRead
      ? [
          createTabItem(
            '1',
            `All Products (${productTotal ?? 0})`,
            <ProductsTabPage
              queryParams={productQueryParams}
              products={products}
              total={productTotal}
              isLoading={isLoading}
              refetchData={productRefetchData}
            />,
          ),
        ]
      : []),
    ...(groupAccess.isCanRead
      ? [
          createTabItem(
            '2',
            `Product Group (${groupTotal ?? 0})`,
            <ProductGroupsTabPage
              queryParams={groupQueryParams}
              productGroups={productGroups}
              total={groupTotal}
              isLoading={isGroupLoading}
              refetchData={groupRefetchData}
            />,
          ),
        ]
      : []),
  ];

  const actionItems: MenuProps['items'] = [
    productAccess.isCanCreate
      ? {
          label: (
            <Link to="/products/new">
              <Icon name="icon-add_box" /> <span>Create Product</span>
            </Link>
          ),
          key: '0',
        }
      : null,
    groupAccess.isCanCreate
      ? {
          label: (
            <Link to="/product-groups/new">
              <Icon name="icon-group_work" /> <span>Create Product Grouping</span>
            </Link>
          ),
          key: '1',
        }
      : null,
  ];

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
        <Text component="h3" variant="h3">
          Product List
        </Text>
        <Dropdown menu={{ items: actionItems }} trigger={['click']} overlayClassName="create-allocation-dropdown">
          <Space>
            <PrimaryButton label="Add Action" icon={<PlusIcon />} />
          </Space>
        </Dropdown>
      </div>
      <Tabs defaultActiveKey="1" items={tabItems} rootClassName="app-tabs" className="tw-mt-2" />
    </div>
  );
};

export default TrackingPage;
