import React, { ReactNode } from 'react';
import Text from '../Text';
import BackLink from '../buttons/BackLink';
import DropdownApp from '../Dropdown';
import Icon from '../Icon';
import { MenuProps } from 'antd';
import { AccessControlI } from '../../interfaces';

type Props = {
  label: ReactNode;
  setIsVisibleModalUpload?: React.Dispatch<React.SetStateAction<boolean>>;
  access: AccessControlI;
  isDisplayManageButton?: boolean;
};
const DetailsTopSection: React.FC<Props> = ({
  label,
  setIsVisibleModalUpload,
  access,
  isDisplayManageButton = true,
}) => {
  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'upload-attachments') setIsVisibleModalUpload!(true);
  };

  const items: MenuProps['items'] = [
    access?.isCanUpdate
      ? {
          key: 'upload-attachments',
          label: (
            <>
              <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
                <Icon name="icon-upload_file" size={16} />
                <span>Upload Attachments</span>
              </Text>
            </>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <section className="tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-flex tw-flex-col tw-flex-1 tw-mb-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <BackLink />
        {isDisplayManageButton && !!items.some((item) => !!item) && (
          <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
        )}
      </div>
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-auto">
          <Text variant="h3">{label}</Text>
        </div>
      </div>
    </section>
  );
};

export default DetailsTopSection;
