import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Text from '../Text';
import Icon from '../Icon';
import { Drawer, MenuProps } from 'antd';
import DropdownApp from '../Dropdown';
import BackLink from '../buttons/BackLink';
import { ProductGroupI } from '../../interfaces/product';
import GroupRemoveProductDrawer from './GroupRemoveProductDrawer';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';

type Props = {
  data: ProductGroupI;
};

const ProductGroupTopInfo: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const [isRemoveGroupVisible, setIsRemoveGroupVisible] = useState<boolean>(false);
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'product-groups');

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'edit') navigate(`/products/groups/${data.code}/edit`);
    if (e.key === 'remove-products') setIsRemoveGroupVisible(true);
    if (e.key === 'add-products') navigate(`/product-groups/${data.code}/edit`);
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-edit" size={16} />
          <span>Edit Product Group Details</span>
        </Text>
      ),
    },
    {
      key: 'add-products',
      label: (
        <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-add_circle_outline" size={16} />
          <span>Add More Products</span>
        </Text>
      ),
    },
    {
      key: 'remove-products',
      label: (
        <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-remove_circle_outline" size={16} />
          <span>Remove Products from Group</span>
        </Text>
      ),
    },
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <section className="tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-flex tw-flex-col tw-flex-1 tw-mb-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <BackLink />
        {access.isCanUpdate && (
          <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
        )}
      </div>
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-auto">
          <div className="tw-flex tw-flex-col tw-gap-2">
            <Text variant="h3" className="tw-mb-2 tw-truncate xs:tw-max-w-[195px] tw-text-grey-800">
              {data?.title}
            </Text>
          </div>
        </div>
      </div>
      <Drawer
        title={'Remove Products from Group'}
        onClose={() => setIsRemoveGroupVisible(false)}
        open={isRemoveGroupVisible}
        rootClassName="hardware-scan-qrcode-drawer"
        width={884}
      >
        <GroupRemoveProductDrawer data={data} />
      </Drawer>
    </section>
  );
};

export default ProductGroupTopInfo;
