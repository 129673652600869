import React, { Fragment, useState } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import { Modal, Tabs, TabsProps } from 'antd';
import { HardwareScannedStateI } from '../../interfaces/hardware';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import ConfirmButton from '../buttons/ConfirmButton';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import SharedScanQRCode from '../shared/Hardware/ScanQRCodeScreen';
import SharedManualScreen from '../shared/Hardware/ManualScreen';
import ConfigurationScanTabsContent from './ScanTabsContent';
import SecondaryButton from '../buttons/SecondaryButton';
import StartInstructionImage from '../../images/SmartPourIns.png';
import SizeBox from '../SizeBox';
import { Notice } from '../Notice';
import WarningIcon from '../../svgs/WarningIcon';
import Text from '../Text';
import Icon from '../Icon';

const DiscardConfirmContent = () => {
  return (
    <div>
      <h2>Are you sure you want to close this Scanning session?</h2>
      <p>All your currently scanned Hardware will be lost.</p>
    </div>
  );
};

const RenderDescriptionWarningNotice = () => {
  return (
    <Fragment>
      <Text variant="bodyMd" className="tw-inline-block">
        Please ensure that the SmartPour(s) you are about to apply a configuration to are &nbsp;
        <span className="tw-text-success-500 tw-font-bold tw-inline-flex tw-gap-1">
          <Icon name="icon-wifi" size={16} /> Online
        </span>
      </Text>
      <Text variant="bodyMd">For a SmartPour to be Online:</Text>
      <ul className="tw-m-0 tw-pl-6">
        <li>
          Turn on through the <span className="tw-font-bold">Power button</span> on its interface
        </li>
        <li>
          Pair the SmartPour with the CircularONE app via an{' '}
          <span className="tw-font-bold">active Wifi connection.</span> If not paired, please go through the{' '}
          <span className="tw-font-bold">SmartPour Pairing workflow</span> (only available in Mobile App) first.
        </li>
      </ul>
    </Fragment>
  );
};

type Props = {
  setCurrentStep: (step: number) => void;
  setScannedData: (_: any) => void;
  handleVerify?: (_: any) => void;
  scannedData: HardwareScannedStateI[];
  stopDecodingScanner: boolean;
  finalScannedData?: HardwareScannedStateI[];
  setFinalScannedData?: (_: any) => void;
  setStopDecodingScanner: (_: any) => void;
  setBackTo?: (_: string) => void;
  pageName?: string;
};

const ConfigurationScanStep: React.FC<Props> = ({
  scannedData,
  setCurrentStep,
  setScannedData,
  handleVerify,
  stopDecodingScanner,
  setStopDecodingScanner,
  finalScannedData = [],
  setFinalScannedData,
  pageName,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [isStartScanning, setIsStartScanning] = useState<boolean>(false);

  const validateScanResult = (value: string) => {
    let status = 'success';
    const validateData = [...finalScannedData, ...scannedData];

    if (validateData.length > 0) {
      if (validateData.find((data: HardwareScannedStateI) => data.serialCode === value)) {
        status = 'error';
      }
    }

    setTimeout(() => {
      if (status === 'success') {
        const successModal = modal.success({
          type: 'success',
          title: 'Successfully Scanned!',
          content: 'Code detected and added to scanned list!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <CheckCircleIcon width={16} height={16} color="#5FB670" />,
          width: 345,
        });
        const newData = {
          serialCode: value,
        } as HardwareScannedStateI;
        setScannedData([...scannedData, newData]);
        setTimeout(() => {
          successModal.destroy();
        }, 1000);
      }

      if (status === 'error') {
        const errorModal = modal.error({
          type: 'error',
          title: 'Failed Scanning!',
          content: 'We detected a duplicated scan. Please check again!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <ErrorModalIcon color="#B13939" />,
          width: 345,
        });

        setTimeout(() => {
          errorModal.destroy();
        }, 1000);
      }
    }, 300);
  };

  const handleScanOrManualSubmit = (result: any) => {
    validateScanResult(result.text);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <SharedScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          scanTabsContent={<ConfigurationScanTabsContent scannedData={scannedData} />}
          pageName={pageName}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <SharedManualScreen
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanTabsContent={<ConfigurationScanTabsContent scannedData={scannedData} />}
        />
      ),
    },
  ];

  const handleFinishScanning = () => {
    handleVerify?.({});
    setCurrentStep(2);
    setStopDecodingScanner(true);
  };

  const handleDiscard = () => {
    setScannedData([]);
    setFinalScannedData?.([]);
    setCurrentStep(1);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  return (
    <div className="component-hardware-allocation-scan-step">
      <div className="wrapper">
        {isStartScanning ? (
          <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />
        ) : (
          <div className="tw-max-w-[520px] tw-my-0 tw-mx-auto">
            <img src={StartInstructionImage} alt="Configuration Instruction Image" className="tw-w-full" />
            <SizeBox height={24} />
            <Notice
              message={<RenderDescriptionWarningNotice />}
              type="warning"
              icon={<WarningIcon />}
              closable={false}
            />
          </div>
        )}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Previous" onClick={() => setCurrentStep(2)} />
          {!isStartScanning && (
            <>
              <PrimaryButton label="Start Scanning" onClick={() => setIsStartScanning(true)} />
            </>
          )}
          {isStartScanning && (
            <>
              <ConfirmButton label="Discard" onOk={handleDiscard} content={<DiscardConfirmContent />} type="error" />
              <PrimaryButton label="Finish Scanning" onClick={handleFinishScanning} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationScanStep;
