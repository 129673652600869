import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const EcoTOTEIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 24}px`}
      viewBox="0 0 25 24"
      width={`${width ?? 25}px`}
      fill={color ?? 'currentColor'}
    >
      <g id="Hardwares type">
        <g id="Subtract">
          <path
            d="M15.46 4.76306H13.75L13.7337 4.75C13.7334 4.76116 13.7333 4.77233 13.7331 4.78351H6.95205C6.7024 4.78351 6.5 4.98498 6.5 5.23351V7.99491C6.5 8.11916 6.6012 8.21991 6.72603 8.21991H7.36303V15.9517H9.08905V8.21991H10.5891C10.7139 8.21991 10.8151 8.32063 10.8151 8.44491V15.7267C10.8151 15.851 10.7139 15.9517 10.5891 15.9517H6.72603C6.6012 15.9517 6.5 16.0525 6.5 16.1767V18.9381C6.5 19.1866 6.7024 19.3881 6.95205 19.3881H18.1301C18.3798 19.3881 18.5822 19.1866 18.5822 18.9381V5.23351C18.5822 4.98498 18.3798 4.78351 18.1301 4.78351H15.4594C15.4595 4.77668 15.4597 4.76986 15.46 4.76306ZM17.0617 8.21991V9.11991H12.5411V8.21991H17.0617ZM14.3493 10.9199V11.8199H12.5411V10.9199H14.3493ZM17.0617 13.6199V14.5199H12.5411V13.6199H17.0617ZM14.3493 16.3199V17.2199H12.5411V16.3199H14.3493Z"
            fill={color ?? 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
};
export default EcoTOTEIcon;
