import { Badge, Dropdown, Form, Input, MenuProps } from 'antd';
import React, { useMemo } from 'react';
import IconButton from '../../buttons/IconButton';
import ViewColumn from '../../../svgs/ViewColumn';
import { HardwareSearchParamsI } from '../../../interfaces/hardware';
import SearchInputIcon from '../../../svgs/SearchInputIcon';
import FilterIcon from '../../../svgs/FilterIcon';
import HardwareFilterContent from './FilterContent';
import DropdownApp from '../../Dropdown';
import Icon from '../../Icon';
import { renderSortList } from '../../../utils/sort';
import RefreshButton from '../../buttons/RefreshButton';

type Props = {
  queryParams: HardwareSearchParamsI & { viewMode?: string };
  onChange(newParams: HardwareSearchParamsI & { viewMode?: string }): void;
  pageName?: string;
  refetchData: () => void;
};

const DepleteFilterForm: React.FC<Props> = ({ queryParams, onChange, pageName, refetchData }) => {
  const [form] = Form.useForm();

  const handleOnChange = (params: Partial<HardwareSearchParamsI & { viewMode?: string }>) => {
    onChange({ ...queryParams, ...params });
  };

  const count = useMemo(() => {
    if (!queryParams.filter) return 0;
    let count = 0;
    Object.values(queryParams.filter).forEach((val) => {
      count += val?.length || 0;
    });
    return count;
  }, [queryParams]);

  const handleDropdownItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleOnChange({
      orderBy: orderBy as HardwareSearchParamsI['orderBy'],
      orderType: type as HardwareSearchParamsI['orderType'],
    });
  };

  const items: MenuProps['items'] = renderSortList(queryParams, 'serialCode');

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="filter-form-container">
      <div className="form-wrap">
        <Form
          initialValues={{ serialCode: queryParams.search?.serialCode }}
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={({ serialCode }) => handleOnChange({ search: { serialCode } })}
          scrollToFirstError
          className="tw-w-full"
        >
          <div className="form-container">
            <div className="form-content tw-flex-auto">
              <Form.Item name="reportId" className="search-box tw-w-full">
                <Input placeholder="Report No." prefix={<SearchInputIcon />} disabled />
              </Form.Item>
            </div>
            <div className="tw-flex tw-gap-3 xs:tw-gap-1 xs:tw-w-full xs:tw-justify-end">
              <DropdownApp
                icon={<Icon name="icon-sort" size={22} />}
                menu={menuDropdownProps}
                size="small"
                placement="bottomRight"
                type="secondary"
                disabled={true}
              />
              <Dropdown
                className="filter-dropdown-button"
                dropdownRender={() => (
                  <HardwareFilterContent
                    queryParams={queryParams}
                    onChange={({ filter, search }) => handleOnChange({ filter, search })}
                    pageName={pageName}
                  />
                )}
                trigger={['click']}
                overlayClassName="filter-drowdown"
              >
                <Badge count={count}>
                  <IconButton icon={<FilterIcon height={20} />} />
                </Badge>
              </Dropdown>
              <IconButton
                className={
                  queryParams.viewMode === 'list'
                    ? 'tw-bg-primary-800 tw-text-white hover:!tw-bg-primary-800 hover:!tw-text-white'
                    : 'tw-bg-white hover:!tw-bg-primary-800 hover:!tw-text-white'
                }
                icon={<ViewColumn />}
                onClick={() => handleOnChange({ viewMode: queryParams.viewMode ? undefined : 'list' })}
              />
              <RefreshButton doRefetch={refetchData} />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DepleteFilterForm;
