import React, { useEffect, useState } from 'react';
import { getHardwareModel } from '../apis/hardwares';
import Text from './Text';

interface Props {
  productId?: string;
}

export const HardwareModel: React.FC<Props> = ({ productId }) => {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    const fetchProductModel = async (productId: string) => {
      try {
        const response = await getHardwareModel(productId);
        setData(response);
      } catch (error: any) {
        console.error('Error fetching image:', error?.message);
      }
    };
    if (productId) fetchProductModel(productId);
  }, [productId]);

  return <Text variant="bodyLgB">{data ? data.name : 'Not Applicable'}</Text>;
};
