import React from 'react';
import { Button, message } from 'antd';
import { ReactSVG } from 'react-svg';
import TrashSVG from '../../svgs/trash.svg';
import DownloadSVG from '../../svgs/download.svg';
import DangerButton from '../buttons/DangerButton';
import { useMutation, useQueryClient } from 'react-query';
import { deleteAttachment } from '../../apis/attachments';
import { AttachmentI } from '../../interfaces/attachments';
import { saveAs } from 'file-saver';
import SiteFilterFormComponent from './FilterForm';
import AttachmentsTable from '../tables/Attachments';

type Props = {
  attachments?: any[];
  siteId?: number;
};

const SiteAttachments: React.FC<Props> = ({ attachments, siteId }) => {
  const queryClient = useQueryClient();

  const handleFilterSubmit = (values: { search: string }) => {
    console.log('values', values);
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteAttachment, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['sites', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`sites/${siteId}`, undefined],
      });
    },
  });

  const handleDelete = (record: AttachmentI) => {
    mutationDelete(Number(record.id));
  };

  const handleDownload = (record: AttachmentI) => {
    saveAs(record.url, record.fileName || 'site-attachment-file');
  };

  const withAction = (record: AttachmentI) => {
    return (
      <div className="table-action-icon" style={{ display: 'flex' }}>
        <Button className="btn-table-action-primary has-icon" type="link" onClick={() => handleDownload(record)}>
          <ReactSVG src={DownloadSVG} />
        </Button>
        <DangerButton onOk={() => handleDelete(record)} icon={<ReactSVG src={TrashSVG} />} />
      </div>
    );
  };
  return (
    <div className="partner-history-container">
      <div className="page-content-header">
        <h2 className="title-page">Attachments</h2>
      </div>
      <div className="content-wrap">
        <SiteFilterFormComponent handleFilter={handleFilterSubmit} />
        <AttachmentsTable rowKey="id" pagination={false} dataSource={attachments} withAction={withAction} />
      </div>
    </div>
  );
};

export default SiteAttachments;
