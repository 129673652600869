import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { ForgetPasswordParamsI } from '../../interfaces/auth';
import ForgotPasswordForm from '../../components/forms/ForgotPassword';
import { doForgotPassword } from '../../apis/auth';

const ForgotPasswordPage: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { mutate: createMutate } = useMutation(doForgotPassword, {
    onSuccess: async () => {
      setIsSuccess(true);
    },
    onError: () => {
      setIsSuccess(true);
    },
  });

  const handleSubmit = async (values: ForgetPasswordParamsI) => {
    createMutate(values);
  };

  return (
    <div className="auth-container">
      <ForgotPasswordForm handleSubmit={handleSubmit} isSuccess={isSuccess} />
    </div>
  );
};

export default ForgotPasswordPage;
