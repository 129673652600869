import React, { useEffect, useState } from 'react';
import { HardwareProgressStepListI } from '../../../interfaces';
import {
  HardwareItemI,
  HardwareScannedStateI,
  HardwareTypeI,
  HardwareVerifyCollectionI,
  HardwareVerifyTypeI,
} from '../../../interfaces/hardware';
import SharedHardwareProgressBar from './ProgressBar';
import { doVerifyHardware } from '../../../apis/hardwares';
import useNavigateAway from '../../../hooks/useNavigateAway';
import HardwareVerifyFilterForm from '../../forms/hardware/VerifyFilter';
import DepletionVerifyStep from '../../HardwareDepletion/VerifyStep';
import { handleRemoveVerifiedData } from '../../../utils/hardware';
import DepletionReviewStep from '../../HardwareDepletion/ReviewStep';
import DepletionScanStep from '../../HardwareDepletion/ScanStep';
import HardwareW2WGroupingDepletePage from './W2WGroupingDeplete';
import { useNavigate } from 'react-router-dom';
import { SiteI } from '../../../interfaces/site';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'Scan',
  },
  {
    step: 2,
    label: 'Verify',
  },
  {
    step: 3,
    label: 'Review',
  },
];

type Props = {
  successData: any;
  backTo?: string;
  site?: SiteI;
};

const HardwareW2WDepletePage: React.FC<Props> = ({ successData, site, backTo = '/' }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(2);
  const [registerData, setRegisterData] = useState<HardwareScannedStateI[]>([]);
  const [hardwareVerifiedData, setHardwareVerifiedData] = useState<HardwareVerifyCollectionI>();
  const [filtered, setFiltered] = useState<HardwareVerifyCollectionI>();
  const [finalScannedData, setFinalScannedData] = useState<HardwareScannedStateI[]>([]);
  const [chosenType, setChosenType] = useState<HardwareTypeI>();
  const [reviewedData, setReviewedData] = useState();
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);

  const handleVerify = async (hardwareList: HardwareScannedStateI[]) => {
    try {
      const finalData = [...finalScannedData, ...hardwareList];
      const params = {
        hardwareList: finalData,
        verifyType: 'depletion',
        siteId: site?.id,
      } as {
        hardwareList: HardwareScannedStateI[];
        verifyType: HardwareVerifyTypeI;
        siteId: number;
      };
      setFinalScannedData(finalData);
      setRegisterData([]);
      const data = await doVerifyHardware(params);
      setHardwareVerifiedData(data);
      setCurrentStep(2);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyScanned = async () => {
    try {
      const finalData = [...finalScannedData, ...registerData];
      const params = {
        hardwareList: finalData,
        verifyType: 'depletion',
        siteId: site?.id,
      } as {
        hardwareList: HardwareScannedStateI[];
        verifyType: HardwareVerifyTypeI;
        siteId: number;
      };
      setFinalScannedData(finalData);
      setRegisterData([]);
      const data = await doVerifyHardware(params);
      setHardwareVerifiedData(data);
      setCurrentStep(2);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyFilter = (values: { serialCode: HardwareScannedStateI['serialCode'] }) => {
    if (values?.serialCode) {
      const hardwareList = hardwareVerifiedData?.hardwareList?.filter(
        (item: HardwareScannedStateI) => item.serialCode === values.serialCode,
      );
      const newData: any = { ...hardwareVerifiedData };
      newData.hardwareList = hardwareList;
      setFiltered(newData);
    } else {
      setFiltered(undefined);
    }
  };

  const handleDeleteVerifiedData = (item: HardwareItemI) => {
    const result = handleRemoveVerifiedData(hardwareVerifiedData, item.serialCode);
    setHardwareVerifiedData(result);
    setFinalScannedData((prevItems) => {
      const index = finalScannedData.findIndex((data) => data.serialCode === item.serialCode);
      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }
      return prevItems;
    });
  };

  useNavigateAway({
    when: finalScannedData.length > 0 || registerData.length > 0,
    handleProceed() {
      setRegisterData([]);
      setHardwareVerifiedData(undefined);
      setFinalScannedData([]);
    },
  });

  useEffect(() => {
    if (successData) {
      const data = successData.hardwareList.map((item: HardwareItemI) => {
        if (item?.currentData?.type === 'ecotote') {
          return {
            serialCode: item.serialCode,
          };
        }
      });
      handleVerify(data?.filter((item: HardwareItemI) => item !== undefined));
    }
  }, [successData]);

  const handleScanmoreButton = () => {
    setCurrentStep(1);
    setStopDecodingScanner(false);
  };

  const backFunction = () => {
    if (currentStep === 3) setCurrentStep(2);
    if (currentStep === 2) setCurrentStep(1);
    if (currentStep === 1) navigate(backTo);
  };

  return (
    <div id="pages-hardwares-registrations-new-container">
      {currentStep !== 4 && (
        <SharedHardwareProgressBar
          currentStep={currentStep}
          title="Mark ecoTOTEs as Depleted"
          stepList={progressStepList}
          backFunction={backFunction}
        />
      )}
      {currentStep === 2 && (
        <HardwareVerifyFilterForm
          type="ecoTOTEs"
          handleVerifyFilter={handleVerifyFilter}
          numberOfHardwareScanned={hardwareVerifiedData?.numberOfHardwareScanned}
          numberOfInvalid={hardwareVerifiedData?.numberOfInvalid}
        />
      )}
      {currentStep === 1 && (
        <DepletionScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setRegisterData}
          scannedData={registerData}
          handleVerify={handleVerifyScanned}
          setStopDecodingScanner={setStopDecodingScanner}
          stopDecodingScanner={stopDecodingScanner}
          finalScannedData={finalScannedData}
          setFinalScannedData={setFinalScannedData}
        />
      )}
      {currentStep === 2 && (
        <DepletionVerifyStep
          setCurrentStep={setCurrentStep}
          verifiedData={filtered ?? hardwareVerifiedData}
          handleDeleteVerifiedData={handleDeleteVerifiedData}
          handleScanmoreButton={handleScanmoreButton}
        />
      )}
      {currentStep === 3 && (
        <DepletionReviewStep
          setScannedData={setRegisterData}
          setCurrentStep={setCurrentStep}
          verifiedData={hardwareVerifiedData}
          setChosenType={setChosenType}
          setSuccessData={setReviewedData}
          successData={reviewedData}
          setFinalScannedData={setFinalScannedData}
          site={site}
          depleteStep={4}
        />
      )}
      {currentStep === 4 && (
        <HardwareW2WGroupingDepletePage
          site={site}
          siteId={site?.id}
          registerType={chosenType}
          successData={reviewedData}
        />
      )}
    </div>
  );
};

export default HardwareW2WDepletePage;
