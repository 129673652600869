import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Input, Spin } from 'antd';
import { SiteI } from '../../interfaces/site';
import { getHardwareSitesList } from '../../apis/hardwares';
import Text from '../Text';
import { getCountryFromCode } from '../../utils/global';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import SearchInputIcon from '../../svgs/SearchInputIcon';

type Props = {
  siteId?: number;
  nextStep?: number;
  allocationType?: string;
  setSiteId?: (id: number) => void;
  setSite?: (_: SiteI) => void;
  setCurrentStep: (step: number) => void;
};

const ConfigurationChooseSiteStep: React.FC<Props> = ({
  siteId,
  nextStep,
  allocationType,
  setSiteId,
  setSite,
  setCurrentStep,
}) => {
  const [filteredData, setFilteredData] = useState<SiteI[]>([]);
  const { data: { sites = [] } = {}, isLoading } = useQuery(['sites', 'list', {}], () => getHardwareSitesList({}), {
    select({ data: { sites } }) {
      return { sites };
    },
  });

  const onFilterChange = (event: any) => {
    const { value } = event.target;
    if (value) {
      const data = sites.filter(
        (site: SiteI) =>
          site.displayName.toLowerCase().includes(value.toLowerCase()) ||
          site.addressLine1.toLowerCase().includes(value.toLowerCase()) ||
          site.postalCode.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredData(data);
    } else {
      setFilteredData(sites);
    }
  };

  const onSiteClick = (site: SiteI) => {
    setSiteId?.(site.id);
    setSite?.(site);
  };

  useEffect(() => {
    if (sites.length) {
      setFilteredData(sites);
    }
  }, [sites]);

  return (
    <Spin spinning={isLoading}>
      <div className="wrapper tw-w-[500px] tw-my-0 tw-mx-[auto] tw-h-[73vh]">
        <Text variant="bodyLg" className="tw-w-full tw-text-center tw-block tw-mt-4 tw-mb-3">
          {`Choose a site ${allocationType && allocationType === 'send' ? '(Send From)' : ''}`}
        </Text>
        <Input placeholder="Search for a site or address" prefix={<SearchInputIcon />} onChange={onFilterChange} />
        <div className=" tw-mt-6 tw-mb-[100px] tw-h-[57vh] tw-overflow-auto">
          {filteredData.map((site: SiteI) => (
            <button
              key={site.id}
              onClick={() => onSiteClick(site)}
              className={`${siteId === site.id ? 'active !tw-bg-primary-50' : 'non-active'} tw-bg-white tw-rounded-lg tw-border-none tw-w-full tw-mb-3 tw-flex tw-flex-col tw-py-4 tw-px-4 tw-gap-1 hover:tw-bg-primary-50`}
            >
              <Text variant="bodyMdB">{site.displayName}</Text>
              <Text variant="bodySm" className=" tw-text-grey-700">
                {site.addressLine1}, {getCountryFromCode(site.country)}, {site.postalCode}
              </Text>
            </button>
          ))}
        </div>
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Previous" onClick={() => setCurrentStep(nextStep ?? 1)} />
          <PrimaryButton label="Next" onClick={() => setCurrentStep(nextStep ?? 3)} disabled={!siteId} />
        </div>
      </div>
    </Spin>
  );
};

export default ConfigurationChooseSiteStep;
