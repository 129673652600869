import { BaseResponse } from '../interfaces';
import { AttachmentI } from '../interfaces/attachments';
import { HardwareSearchParamsI } from '../interfaces/hardware';
import { HardwareDepleteParamsI, HardwareDepletionCollectionI } from '../interfaces/hardwareDepletion';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetHardwareDepletion = (uuid: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`depletion-histories/${uuid}`);
  const data = response?.data;
  return { data, error, isLoading };
};

export const getDepletionHistoriesList = async (params?: HardwareSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<HardwareDepletionCollectionI>>(
    `depletion-histories?${handleFilterParams(params)}`,
  );
  return response;
};

export const doHardwareDepletion = async (values: HardwareDepleteParamsI) => {
  const { data: response }: any = await api.post('hardwares/depletion', values);
  return response.data;
};

export const updateDepletionHistories = async ({
  id,
  values,
}: {
  id: string;
  values: Partial<{ attachments: AttachmentI }>;
}) => {
  const { data: response }: any = await api.patch(`depletion-histories/${id}`, values);
  return response.data;
};
