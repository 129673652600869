import { useMutation, useQuery } from 'react-query';
import { impersonatePartner, impersonationSignOut } from '../../apis/partners';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../../constants';
import { message } from 'antd';
import { getAuthenToken } from '../../utils/authentication';

export default function useImpersonation() {
  const { data: isImpersonating = false } = useQuery(['impersonation'], () => {
    const authData = getAuthenToken();
    return authData?.impersonating === true;
  });

  const { mutate: impersonate, isLoading: isRequesting } = useMutation({
    mutationFn: impersonatePartner,
    onSuccess(data) {
      const authData = JSON.stringify({ ...data, impersonating: true });
      Cookies.set(TOKEN_NAME, authData);
      window.location.replace(window.origin);
    },
    onError() {
      message.error('Impersonation failed. Please try again.');
    },
  });

  const { mutate: signOut, isLoading: isSigningOut } = useMutation({
    mutationFn: impersonationSignOut,
    onSuccess(data) {
      const authData = JSON.stringify({ ...data, impersonating: false });
      Cookies.set(TOKEN_NAME, authData);

      window.location.replace(window.origin);
    },
    onError() {
      message.error('Cannot signout. Please try again.');
    },
  });

  const isLoading = isRequesting || isSigningOut;

  return {
    impersonate,
    signOut,
    isLoading,
    isImpersonating,
  };
}
