import React, { ReactNode } from 'react';
import { Divider, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AccessControlI } from '../../interfaces';
import Icon from '../Icon';
import Text from '../Text';
import { IconName } from '../Icon/iconName';
import { DateFormat } from '../../utils/global';
import { ConfigurationI } from '../../interfaces/configuration';
import './HardwareCardItem.scss';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';

interface Props {
  access: AccessControlI;
  data: ConfigurationI[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const PresetsCardList: React.FC<Props> = ({ access, data, pagination, onPaginationChange }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: ConfigurationI) => {
    if (access.isCanRead) navitate(`/configurations/${item?.code}`);
  };

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <section className="tw-flex tw-flex-col">
      <div className="card-list">
        {data.map((item) => {
          return (
            <div className="card-item hardware-card-item" key={item.id}>
              <button className="item" onClick={() => handleCardClick(item)}>
                {item?.remarks && <Icon name="icon-text_snippet" className="btn-more-action" size={16} />}
                <Text variant="inputLabelSm" className="tw-text-grey-700">
                  Configuration Preset
                </Text>
                <div className="tw-flex tw-flex-col tw-w-full tw-gap-4">
                  <Text variant="bodyLgB" className="tw-text-primary-800 tw-mt-2">
                    {item.name}
                  </Text>
                  <div className="tw-flex tw-flex-col tw-w-full tw-gap-2">
                    {renderField({ icon: 'icon-build_circle', value: item?.code })}
                    <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                      {handleDisplayIconByTypeFromApi(item?.hardwareType, {
                        width: 16,
                        height: 16,
                      })}
                      <Text variant="inputLabelSm" className="flex-1">
                        {getTypeFromApi(item)}
                      </Text>
                    </div>
                  </div>
                  <Divider className="tw-m-0" />
                  {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
                </div>
              </button>
            </div>
          );
        })}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        pageSizeOptions={[5, 10, 20, 50]}
        showSizeChanger
      />
    </section>
  );
};

export default PresetsCardList;
