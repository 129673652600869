import { Form, Input } from 'antd';
import React, { ReactNode, useState } from 'react';
import './ManualScreen.scss';
import PrimaryButton from '../../buttons/PrimaryButton';
import Text from '../../Text';

type Props = {
  handleScanOrManualSubmit: (_: { text: string }) => void;
  scanTabsContent?: ReactNode;
  formLabel?: string;
  placeHolder?: string;
  title?: string;
  isDisplayTitle?: boolean;
  errorMessage?: string;
  validateRegex?: RegExp;
};

const SharedManualScreen: React.FC<Props> = ({
  handleScanOrManualSubmit,
  scanTabsContent,
  formLabel,
  placeHolder,
  title,
  isDisplayTitle = true,
  errorMessage,
  validateRegex,
}) => {
  const [form] = Form.useForm();
  const [warningMessage, setWarningMessage] = useState<string>();

  const handleValidation = (_: any, value: any) => {
    if (validateRegex && errorMessage) {
      if (!value || value === '') {
        setWarningMessage('This field is required');
        return Promise.reject(new Error('This field is required'));
      }

      if (!validateRegex?.test(value)) {
        setWarningMessage(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }
    }
    setWarningMessage(undefined);
    return Promise.resolve();
  };

  const onFinish = (values: { text: string }) => {
    handleScanOrManualSubmit(values);
    form.resetFields();
  };

  return (
    <div className="shared-hardware-manual-step">
      {isDisplayTitle && (
        <Text variant="h2" className="tw-w-full tw-text-center tw-mt-4">
          {title ?? 'Manual Input'}
        </Text>
      )}
      {scanTabsContent}
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        scrollToFirstError
        onFinish={onFinish}
        layout="vertical"
        className="tw-w-[500px] tw-mx-auto tw-my-0"
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item
              label={formLabel ?? 'Enter Barcode Number'}
              name="text"
              rules={[{ required: true, message: 'This field is required', validator: handleValidation }]}
              help={warningMessage || ''}
              validateStatus={warningMessage ? 'error' : undefined}
            >
              <Input placeholder={placeHolder ?? 'Type Barcode'} />
            </Form.Item>
          </div>
        </div>
        <PrimaryButton label="Scan" htmlType="submit" className="tw-mt-[36px]" />
      </Form>
    </div>
  );
};

export default SharedManualScreen;
