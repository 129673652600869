import React, { useState } from 'react';
import Text from '../Text';
import { Form, Input } from 'antd';
import PrimaryButton from '../buttons/PrimaryButton';
import { doVerifyOwnerTransfer } from '../../apis/transfers';
import { useAppNotification } from '../../hooks/useAppNotification';
import { PartnerI } from '../../interfaces/partner';
import { Avatar } from '../User/Avatar';
import { CommonStatus } from '../CommonStatus';
import SecondaryButton from '../buttons/SecondaryButton';
import Icon from '../Icon';

const errorMessage = 'Reference Code doesn’t exist. Please check and type again';

type Props = {
  partner?: PartnerI;
  setCurrentStep: (_: number) => void;
  setPartner: (_: PartnerI | undefined) => void;
};

const TransferToScreen: React.FC<Props> = ({ partner, setPartner, setCurrentStep }) => {
  const { openNotification } = useAppNotification();
  const [form] = Form.useForm();
  const [warningMessage, setWarningMessage] = useState('');

  const onFinish = async (values: { referenceCode: string }) => {
    try {
      const data = await doVerifyOwnerTransfer(values.referenceCode);
      setPartner(data);
    } catch (error) {
      openNotification({
        type: 'error',
        title: 'Partner does not exist!',
        content:
          'We cannot recognise this Reference Code. Please check with your Partner again for correct Reference Code and make sure their Partner’s status is Active.',
      });
      setWarningMessage(errorMessage);
      return Promise.resolve();
    }
  };

  const doReEnter = () => {
    setPartner(undefined);
    form.resetFields();
  };

  return (
    <>
      <div className="tw-max-w-[500px] tw-my-0 tw-mx-auto tw-p-6">
        {partner ? (
          <div className="tw-text-center">
            <Text variant="bodyLg" className="tw-text-grey-700 tw-mb-6">
              1 Partner Scanned:
            </Text>
            <div className="tw-max-w-[230px] tw-rounded-lg tw-bg-white tw-p-3 tw-flex tw-flex-col tw-my-0 tw-mx-auto">
              <div className="tw-w-full tw-aspect-square tw-rounded-lg tw-grid tw-place-items-center tw-relative tw-bg-grey-500">
                <Avatar type="partner" id={partner.id} className="tw-bg-grey-400 tw-w-full" />
              </div>
              <Text variant="h4" className="tw-text-primary-800 tw-my-3 tw-flex tw-justify-start">
                {partner.displayName}
              </Text>
              <CommonStatus status="active" />
            </div>
          </div>
        ) : (
          <>
            <Text variant="bodyLg" className="tw-text-center tw-mb-6">
              Reference Code of Partner that you’re transferring the ownership of the hardware to
            </Text>
            <Form
              form={form}
              requiredMark={false}
              colon={false}
              scrollToFirstError
              onFinish={onFinish}
              layout="vertical"
              className="tw-p-6 tw-bg-white tw-shadow-primary tw-rounded-lg"
            >
              <div className="form-container">
                <div className="form-content">
                  <Form.Item
                    label={'Enter Partner’s Reference Code'}
                    name="referenceCode"
                    rules={[{ required: true, message: 'This field is required' }]}
                    help={warningMessage || ''}
                    validateStatus={warningMessage ? 'error' : undefined}
                  >
                    <Input placeholder={'Type Partner’s Reference Code'} />
                  </Form.Item>
                </div>
              </div>
              <PrimaryButton label="Enter" htmlType="submit" className="tw-w-full tw-mt-6" />
            </Form>
          </>
        )}
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton
            label={'Re-enter'}
            onClick={doReEnter}
            disabled={!partner}
            icon={<Icon name="icon-refresh" size={24} />}
          />
          <PrimaryButton label={'Continue'} onClick={() => setCurrentStep(2)} disabled={!partner} />
        </div>
      </div>
    </>
  );
};

export default TransferToScreen;
