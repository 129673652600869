import { Form, message, Spin } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';
import { updateHardwareGroup, useGetHardwareGroup } from '../../apis/hardwares';
import HardwareGroupForm from '../../components/Tracking/GroupForm';
import { HardwareGroupParamsI } from '../../interfaces/hardware';

const TrackingGroupUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { code } = useParams<{ code: string }>();
  const { data, isLoading } = useGetHardwareGroup(code);
  const [isCodeDuplicate, setIsCodeDuplicate] = useState<boolean>(false);

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(updateHardwareGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['tracking', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`tracking/groups/${code}`, undefined],
      });
      message.info('Update successfully!');
      navigate(`/tracking`, { replace: true });
    },
    onError: async (error) => {
      if (error === 'code exists') {
        setIsCodeDuplicate(true);
      } else {
        message.error('Update failed! Please try again');
      }
    },
  });

  const handleSubmit = async (values: HardwareGroupParamsI) => {
    updateMutate({ code, data: values });
  };

  return (
    <Spin spinning={isLoading || isPageLoading}>
      <CreateUpdateTopSection label="Edit Grouping" labelButton="Update" form={form} />
      <HardwareGroupForm
        form={form}
        handleSubmit={handleSubmit}
        group={data}
        groupId={code}
        isCodeDuplicate={isCodeDuplicate}
      />
    </Spin>
  );
};

export default TrackingGroupUpdatePage;
