import { Form, MenuProps, Modal, message } from 'antd';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Icon from '../Icon';
import Text from '../Text';
import DropdownApp from '../Dropdown';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import { useMutation, useQueryClient } from 'react-query';
import { AttachmentCategories } from '../../constants';
import BackLink from '../buttons/BackLink';
import AddAttachmentForm from '../forms/AddAttachmentForm';
import { ConfigReportsI } from '../../interfaces/configuration';
import { updateConfigurationReport } from '../../apis/configurations';

type Props = {
  data?: ConfigReportsI;
};

const ConfigReportTopSection: React.FC<Props> = ({ data }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'configurations');
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState<boolean>(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'update-attachments') setIsVisibleModalUpload(true);
  };

  function handleCancelUploadAttachment() {
    setIsVisibleModalUpload(false);
    form.resetFields(['categoryName', 'file']);
  }

  const { mutate: uploadAttachment } = useMutation(updateConfigurationReport, {
    onSuccess: async () => {
      message.info('Uploaded attachment!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Adding attachment failed! Please try again');
    },
  });

  const items: MenuProps['items'] = [
    access?.isCanUpdate
      ? {
          key: 'update-attachments',
          label: (
            <>
              <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
                <Icon name="icon-upload_file" size={16} />
                <span>Upload Attachments</span>
              </Text>
            </>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="tw-mb-6">
      <div className="content-wrap">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <BackLink />
          <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
        </div>
        <div className="wrapper tw-flex tw-items-center tw-flex-row">
          <div className="name-section">
            <Text variant="h2">Apply Configuration Report - {data?.code}</Text>
          </div>
        </div>
      </div>
      <Modal
        open={isVisibleModalUpload}
        onOk={handleCancelUploadAttachment}
        onCancel={handleCancelUploadAttachment}
        footer={null}
        width={560}
      >
        <AddAttachmentForm
          form={form}
          handleCancel={handleCancelUploadAttachment}
          multiple
          categories={AttachmentCategories}
          update={uploadAttachment}
        />
      </Modal>
    </div>
  );
};

export default ConfigReportTopSection;
