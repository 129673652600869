import React from 'react';
import { Button, Divider, Dropdown, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../svgs/MoreActionIcon';
import './HardwareCardItem.scss';
import { DateFormat } from '../../utils/global';
import { AccessControlI } from '../../interfaces';
import Text from '../Text';
import { IconName } from '../Icon/iconName';
import Icon from '../Icon';
import { HardwareGroupI } from '../../interfaces/hardware';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';

type Props = {
  item: HardwareGroupI;
  access: AccessControlI;
};
const HardwareGroupCardItem: React.FC<Props> = ({ item, access }) => {
  const navitate = useNavigate();

  const items = [
    access.isCanRead
      ? {
          key: '1',
          label: (
            <Link to={`/tracking/groups/${item.code}`}>
              <Button className="btn-table-action-primary" type="link">
                View
              </Button>
            </Link>
          ),
        }
      : null,
    access.isCanUpdate
      ? {
          key: '2',
          label: (
            <Link to={`/tracking/groups/${item.code}/edit`}>
              <Button className="btn-table-action-primary" type="link">
                Update
              </Button>
            </Link>
          ),
        }
      : null,
  ];

  const handleCardClick = (item: HardwareGroupI) => {
    navitate(`/tracking/groups/${item.code}`);
  };

  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button className="hardware-card-item card-item" onClick={() => handleCardClick(item)}>
      <a className="btn-more-action" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
        <Dropdown menu={{ items }}>
          <Space>
            <MoreActionIcon />
          </Space>
        </Dropdown>
      </a>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <Text variant="inputLabelSm" className="tw-text-grey-700 tw-flex tw-items-center tw-gap-3">
          <span>Hardware Group</span>{' '}
          {item?.remarks && <Icon name="icon-text_snippet" className="tw-text-grey-900" size={16} />}
        </Text>
        <Text variant="bodyLgB" className="tw-text-primary-800">
          {item.title}
        </Text>
        <div className="tw-flex tw-flex-col tw-gap-2">
          {renderField({ icon: 'icon-receipt', value: `${item?.code}` })}
          {renderField({
            icon: 'icon-all_inbox',
            value: `${item?.hardwares ? item?.hardwares?.length : item._count?.hardwares} Hardware`,
          })}
          <span className="tw-flex tw-flex-row tw-items-center tw-gap-1">
            {handleDisplayIconByTypeFromApi(item?.hardwareType, { color: '#898989', width: 16, height: 16 })}
            <Text variant="inputLabelSm" className="flex-1">
              {getTypeFromApi(item)}
            </Text>
          </span>
        </div>
        <Divider className="tw-m-0" />
        {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
      </div>
    </button>
  );
};

export default HardwareGroupCardItem;
