import { Checkbox, Form } from 'antd';
import React from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import { HardwareGroupSearchParamsI } from '../../../interfaces/hardware';
import Text from '../../Text';
import SecondaryButton from '../../buttons/SecondaryButton';
import { convertToHardwareType } from '../../../utils/hardware';

type FormParams = {
  hardwareType: string[];
};

type Props = {
  queryParams: HardwareGroupSearchParamsI;
  onChange(newParams: Partial<HardwareGroupSearchParamsI>): void;
};

const HardwareGroupFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: { hardwareType: values?.hardwareType },
    });
  };

  const typeOptions = ['ecoplant', 'ecotote', 'smartpour', 'smartkiosk'];

  return (
    <div className="component-hardware-filter-content-container partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        initialValues={{
          hardwareType: queryParams.filter?.hardwareType,
        }}
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Text variant="h4" className="tw-mb-3">
              Type
            </Text>
            <Form.Item name="hardwareType" valuePropName="checked">
              <Checkbox.Group style={{ gap: 8 }}>
                {typeOptions.map((type: string) => (
                  <Checkbox key={type} value={type}>
                    {convertToHardwareType(type)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default HardwareGroupFilterContent;
