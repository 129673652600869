import { Form, Select } from 'antd';
import React, { useMemo } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import countryList from 'react-select-country-list';
import SecondaryButton from '../../buttons/SecondaryButton';
import Text from '../../Text';
import { ConfigReportSearchParamsI } from '../../../interfaces/configuration';

type FormParams = {
  name: string;
};

type Props = {
  queryParams: ConfigReportSearchParamsI;
};

const ReportFilterContent: React.FC<Props> = ({ queryParams }) => {
  console.log('queryParams', queryParams);
  const [form] = Form.useForm();
  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleOnFinish = (values: FormParams) => {
    console.log('values', values);
  };

  const handleResetFilter = () => {
    form.resetFields();
  };

  return (
    <div className="partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Text variant="h4" className="tw-mb-3">
              Product Origin
            </Text>
            <Form.Item name="productionOrigin">
              <Select
                placeholder="Select a country"
                showSearch
                optionFilterProp="label"
                options={countryOptions}
                allowClear
              />
            </Form.Item>
            <Text variant="h4" className="tw-mb-3">
              Beverage Type
            </Text>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ReportFilterContent;
