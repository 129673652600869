import { Checkbox, Form } from 'antd';
import React, { useMemo } from 'react';
import { ModuleI } from '../../../interfaces/module';
import { CheckboxOptionI } from '../../../interfaces';
import ActionSection from '../../Role/ActionSection';
import { handleGetPermissionFromModule } from '../../../utils/role';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const ProductCollapse: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  const productCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Product'),
    [modules],
  );
  const productGroupOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Product_grouping'),
    [modules],
  );

  return (
    <>
      <ActionSection
        label="Product"
        content={
          <Form.Item name="colProduct">
            <Checkbox.Group options={productCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colProduct')} />
          </Form.Item>
        }
      />
      <ActionSection
        label="Product Grouping"
        content={
          <Form.Item name="colProduct_grouping">
            <Checkbox.Group
              options={productGroupOptions}
              onChange={(e) => onCheckboxChange(e, 'colProduct_grouping')}
            />
          </Form.Item>
        }
      />
    </>
  );
};

export default ProductCollapse;
