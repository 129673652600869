import { Form, Spin, message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import RoleForm from '../../components/forms/role/Form';
import { RoleParamsI } from '../../interfaces/role';
import { createRole } from '../../apis/roles';
import { CurrentUserI } from '../../interfaces/auth';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';

const RoleNewPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate: createMutate, isLoading: isPageLoading } = useMutation(createRole, {
    onSuccess: async () => {
      message.info('Create role successfully!');
      queryClient.invalidateQueries({
        queryKey: ['roles', 'list'],
      });
      navigate(`/roles`, { replace: true });
    },
    onError: () => {
      message.error('Create role failed! Please try again');
    },
  });

  const handleSubmit = async (values: RoleParamsI) => {
    createMutate(values);
  };

  return (
    <Spin spinning={isPageLoading}>
      <CreateUpdateTopSection label="Add Role" labelButton="Create" form={form} />
      <RoleForm form={form} handleSubmit={handleSubmit} currentUser={currentUser} />
    </Spin>
  );
};

export default RoleNewPage;
