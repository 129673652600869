import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat, getCountryFromCode } from '../../utils/global';
import { ProductCategoryI } from '../../interfaces/productCategory';
import { PartnerI } from '../../interfaces/partner';
import { ProductI } from '../../interfaces/product';
import Icon from '../Icon';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: ProductI[];
};

const ProductsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Name',
      width: 200,
      ellipsis: true,
      render: (record: ProductI) => {
        return (
          <span className="tw-flex tw-items-center tw-gap-x-2">
            <span>{`${record?.name}`}</span>
            {record?.notes !== null && <Icon name="icon-text_snippet" size={16} />}
          </span>
        );
      },
    },
    {
      title: 'Brand Owner',
      dataIndex: 'partner',
      width: 150,
      ellipsis: true,
      render: (partner: PartnerI) => {
        return <span>{partner?.legalName}</span>;
      },
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      width: 150,
      ellipsis: true,
      render: (brandName: string) => {
        return <span>{brandName}</span>;
      },
    },
    {
      title: 'Origin',
      dataIndex: 'productionOrigin',
      width: 150,
      ellipsis: true,
      render: (productionOrigin: string) => {
        return <span className="text-capitalize">{getCountryFromCode(productionOrigin)}</span>;
      },
    },
    {
      title: 'ABV',
      dataIndex: 'abv',
      width: 150,
      ellipsis: true,
      render: (abv: number) => {
        return <span className="text-uppercase">{`${abv ?? 0}%`}</span>;
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: 150,
      ellipsis: true,
      render: (sku: string) => {
        return <span className="text-uppercase">{sku}</span>;
      },
    },
    {
      title: 'beverage type',
      dataIndex: 'category',
      width: 150,
      ellipsis: true,
      render: (category: ProductCategoryI) => {
        return <span className="text-uppercase">{category?.type}</span>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default ProductsTable;
