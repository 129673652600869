import { Form, FormInstance, Input, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import UploadAttachmentButton from '../../buttons/UploadAttachmentButton';
import { convertToApiFile, hasDuplicateBottleCodeValues } from '../../../utils/global';
import { AttachmentI } from '../../../interfaces/attachments';
import countryList from 'react-select-country-list';
import { useQuery } from 'react-query';
import { getProductCategoriesList, getProductCategory } from '../../../apis/productCategories';
import { ProductCategoryI } from '../../../interfaces/productCategory';
import IconButton from '../../buttons/IconButton';
import DeleteCircleIcon from '../../../svgs/DeleteCircleIcon';
import { ProductI } from '../../../interfaces/product';
import './Form.scss';
import SecondaryButton from '../../buttons/SecondaryButton';
import AddMoreIcon from '../../../svgs/AddMoreIcon';
import { BottleCodeRegex, DecimalRegex, ProductAttachmentCategories } from '../../../constants';

type Props = {
  form: FormInstance;
  productId?: string;
  product?: any;
  handleSubmit(_: any): void;
};

const ProductForm: React.FC<Props> = ({ product, productId, form, handleSubmit }) => {
  const [files, setFiles] = useState<any[]>([]);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [categoryChildren, setCategoryChildren] = useState<ProductCategoryI[]>([]);
  const [categoryThirdChildren, setCategoryThirdChildren] = useState<ProductCategoryI[]>([]);
  const [parentId, setParentId] = useState<number>();
  const [secondCategoryId, setSecondCategoryId] = useState<number>();
  const [thirdCategoryId, setThirdCategoryId] = useState<number>();
  const [isCategoryLoading, setIsCategoryLoading] = useState<boolean>(false);

  const { data: categoriesData, isLoading } = useQuery(['categories', 'list', {}], () => getProductCategoriesList());
  const onParentCategoryChange = async (value: number) => {
    if (value) {
      const result: any = await getProductCategory(value);
      if (result?.children) {
        setCategoryChildren(result?.children);
      } else {
        setCategoryChildren([]);
      }
      form.resetFields(['secondCategoryId', 'thirdCategoryId']);
      setCategoryThirdChildren([]);
    }
  };

  const onChildrenChange = (value: number) => {
    const secondChildren: ProductCategoryI | undefined = categoryChildren.find(
      (child: ProductCategoryI) => child.id === value,
    );
    if (secondChildren?.children) {
      setCategoryThirdChildren(secondChildren?.children);
    } else {
      setCategoryThirdChildren([]);
    }
    form.resetFields(['thirdCategoryId']);
  };

  const handleOnFinish = (values: Partial<ProductI>) => {
    const newValues: any = values;
    if (files && Object.keys(files).length > 0) {
      const newFiles = files.map((file: any) => {
        if (file?.data) {
          return convertToApiFile(file);
        }
      });
      newValues.attachments = newFiles.filter((file: any) => file !== undefined);
    }
    newValues.categoryId = values?.thirdCategoryId ?? values?.secondCategoryId ?? values?.categoryId;
    const newTagging = values?.tagging?.reduce((acc: any, { key = '', value = '' }) => {
      acc[key] = value;
      return acc;
    }, {});
    newValues.tagging = newTagging;
    newValues.bottleCodes = values?.bottleCodes?.map((code: any) => code.value);
    delete newValues.thirdCategoryId;
    delete newValues.secondCategoryId;
    handleSubmit(newValues);
  };

  const handleGetSecondCategory = async (secondId: number) => {
    const result: any = await getProductCategory(secondId);
    if (result?.children) {
      setCategoryChildren(result?.children);
    } else {
      setCategoryChildren([]);
    }
  };

  const handleGetParrentCategory = async (category: ProductCategoryI) => {
    setIsCategoryLoading(true);
    const result: any = await getProductCategory(Number(category?.parentId));
    if (!result) return undefined;
    if (result?.parentId === null) {
      setParentId(result?.id);
      setCategoryChildren(result?.children);
      setSecondCategoryId(category?.id);
      setIsCategoryLoading(false);
    } else if (result?.parent && result?.parent?.parentId === null) {
      setParentId(result?.parent?.id);
      setSecondCategoryId(result?.id);
      setCategoryThirdChildren(result?.children);
      setThirdCategoryId(category?.id);
      handleGetSecondCategory(result?.parent?.id);
      setIsCategoryLoading(false);
    } else {
      handleGetParrentCategory(result);
      setIsCategoryLoading(false);
    }
  };

  useEffect(() => {
    if (product && product.sku === productId) {
      let newAttachments;
      if (product?.attachments && Object.values(product.attachments).length > 0) {
        newAttachments = product?.attachments.map((attachment: AttachmentI) => {
          return {
            categoryName: attachment.categoryName,
            id: attachment.id,
            name: attachment.fileName,
            type: attachment.fileType,
            url: attachment.url,
          };
        });
      }

      const taggingFieldValue = Object.keys(product?.tagging)?.map((item: any) => {
        return {
          key: item,
          value: product?.tagging[item],
        };
      });

      if (product.category) {
        if (product?.category?.parentId === null) {
          setParentId(product?.category?.id);
        } else {
          handleGetParrentCategory(product?.category);
        }
      }

      const newBottleCodes = product?.bottleCodes.map((code: string) => {
        return {
          value: code,
        };
      });

      form.setFieldsValue({
        ...product,
        tagging: taggingFieldValue,
        bottleCodes: newBottleCodes ?? [],
      });
      setFiles(newAttachments ?? []);
    }
  }, [product]);

  useEffect(() => {
    if (parentId || secondCategoryId || thirdCategoryId) {
      form.setFieldsValue({
        categoryId: parentId,
        secondCategoryId: secondCategoryId ?? null,
        thirdCategoryId: thirdCategoryId ?? null,
      });
    }
  }, [parentId, secondCategoryId, thirdCategoryId]);

  return (
    <div className="product-form-container">
      <Form
        form={form}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{
          tagging: [{ key: '', value: '' }],
          bottleCodes: [{ value: '' }],
        }}
        layout="vertical"
      >
        <div className="form-container">
          <div className="form-content">
            <div className="content-wrap">
              <h2 className="form-title">Product Information</h2>
              <div className="flex-2-column">
                <Form.Item
                  label="Brand"
                  name="brandName"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Input placeholder="Type product brand name" />
                </Form.Item>
                <Form.Item
                  label="Product Name"
                  name="name"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Input placeholder="Type product name" />
                </Form.Item>
              </div>
              <div className="flex-2-column">
                <Form.Item
                  label="Product Origin"
                  name="productionOrigin"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Select placeholder="Select a country" showSearch optionFilterProp="label" options={countryOptions} />
                </Form.Item>
                <Form.Item
                  required
                  label="ABV"
                  name="abv"
                  rules={[
                    {
                      validator(_: any, value) {
                        if (!DecimalRegex.test(value)) {
                          return Promise.reject(
                            new Error('Number must have exactly one decimal point and be between 0.0 and 100.0.'),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="0.0" suffix="%" />
                </Form.Item>
              </div>
              <div className="flex-3-column">
                <Form.Item label="SKU" name="sku" rules={[{ required: true, message: 'This field is required' }]}>
                  <Input placeholder="Type SKU number" />
                </Form.Item>
              </div>
              <div className="flex-3-column">
                <Form.Item
                  label="Beverage Type"
                  name="categoryId"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Select
                    placeholder="Select an option"
                    style={{ width: '100%' }}
                    onChange={onParentCategoryChange}
                    loading={isLoading || isCategoryLoading}
                  >
                    {categoriesData?.categories?.map((category: ProductCategoryI) => (
                      <Select.Option key={category.id} value={category.id}>
                        <span className="text-capitalize">{category.name}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {categoryChildren?.length > 0 && (
                  <Form.Item
                    label="Beverage Classification"
                    name="secondCategoryId"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Select
                      placeholder="Choose an option"
                      style={{ width: '100%' }}
                      onChange={onChildrenChange}
                      loading={isCategoryLoading}
                    >
                      {categoryChildren?.map((category: ProductCategoryI) => (
                        <Select.Option key={category.id} value={category.id}>
                          <span className="text-capitalize">{category.name}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {categoryThirdChildren.length > 0 && (
                  <Form.Item
                    label="Beverage Sub-Classification"
                    name="thirdCategoryId"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Select placeholder="Choose an option" style={{ width: '100%' }} loading={isCategoryLoading}>
                      {categoryThirdChildren.map((category: ProductCategoryI) => (
                        <Select.Option key={category.id} value={category.id}>
                          <span className="text-capitalize">{category.name}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </div>
              <div className="pair-tagging">
                <p>Key Value Pair Tagging (Optional)</p>
                <span>Maximum 50 characters</span>
                <div className="tagging-form-field">
                  <Form.List name="tagging">
                    {(fields, { add, remove }) => (
                      <>
                        <p className="tagging-label">
                          <span>Key Name</span> <span>Value</span>
                        </p>
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key} className="row-2-column">
                            <div className="field-item">
                              <Form.Item {...restField} name={[name, 'key']}>
                                <Input placeholder="Type here" />
                              </Form.Item>
                              <Form.Item {...restField} name={[name, 'value']}>
                                <Input placeholder="Type here" />
                              </Form.Item>
                            </div>
                            <div className="delete-tagging-button">
                              {key > 0 && <IconButton onClick={() => remove(name)} icon={<DeleteCircleIcon />} />}
                            </div>
                          </div>
                        ))}
                        <Form.Item>
                          <SecondaryButton
                            className="add-more-btn"
                            onClick={() => add()}
                            label="Add More"
                            icon={<AddMoreIcon />}
                          />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
              <Form.Item label="Remarks (Optional)" name="notes">
                <Input.TextArea placeholder="Type here" />
              </Form.Item>
              <div className="attachment-list-section">
                <Form.Item name="uploadFile" label="Media Attachments (Optional)" style={{ width: '100%' }}>
                  <UploadAttachmentButton
                    categories={ProductAttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                    multiple={true}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="content-wrap" style={{ marginTop: 24 }}>
              <h2 className="form-title">Related Bottle Codes</h2>
              <Form.List name="bottleCodes">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key}>
                        <div className="flex-3-column">
                          <Form.Item
                            {...restField}
                            label="Bottle Code"
                            name={[name, 'value']}
                            rules={[
                              { required: key === 0, message: 'This field is required' },
                              {
                                validator(_: any, value) {
                                  const fieldValue = form.getFieldValue('bottleCodes');

                                  if (!value) {
                                    return Promise.reject(new Error(''));
                                  }

                                  if (fieldValue && hasDuplicateBottleCodeValues(fieldValue)) {
                                    return Promise.reject(new Error('Duplicate BottleCodes Found!'));
                                  }

                                  if (!BottleCodeRegex.test(value)) {
                                    return Promise.reject(
                                      new Error('Each BottleCodes Only Allow 6 alphanumeric characters!'),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Type bottle code" />
                          </Form.Item>
                          <div className="delete-tagging-button">
                            {key > 0 && <IconButton onClick={() => remove(name)} icon={<DeleteCircleIcon />} />}
                          </div>
                        </div>
                      </div>
                    ))}
                    {fields.length < 4 && (
                      <Form.Item>
                        <SecondaryButton
                          className="add-more-btn"
                          onClick={() => add()}
                          label="Add More"
                          icon={<AddMoreIcon />}
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProductForm;
