import { Checkbox, Form, Input } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../buttons/PrimaryButton';
import './LoginForm.scss';
import LoginImage from '../../svgs/logo.svg';

type Props = {
  handleSubmit?: (_: any) => void;
};

const LoginForm: React.FC<Props> = ({ handleSubmit }) => {
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const onVerifyCheckbox = (event: any) => {
    if (event.target.checked) {
      setDisableSubmit(false);
    } else setDisableSubmit(true);
  };

  return (
    <div className="login-form-container">
      <div className="container">
        <div className="form-container">
          <div className="logo-section">
            <Link to={'/'}>
              <img src={LoginImage} alt="Eco Spirits" />
            </Link>
          </div>
          <Form scrollToFirstError layout="vertical" requiredMark={false} colon={false} onFinish={handleSubmit}>
            <div className="FormContent">
              <Form.Item
                label="Email or Username"
                name="emailUsername"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input.Password size="large" placeholder="Password" />
              </Form.Item>
              <div className="verify-section">
                <Checkbox onChange={onVerifyCheckbox}>
                  <p>
                    I agree to{' '}
                    <a href="https://ecospirits.global/end-user-license-agreement/" target="_blank" rel="noreferrer">
                      User Service Agreement
                    </a>{' '}
                    &{' '}
                    <a href="https://ecospirits.global/privacy-policy/" rel="noreferrer" target="_blank">
                      Privacy Policy.
                    </a>
                  </p>
                </Checkbox>
              </div>
              <div className="forgot-password-section">
                <Link to={`/forgot-password`}>Forgot Password</Link>
              </div>
            </div>
            <div className="FormFooter">
              <PrimaryButton htmlType="submit" label="Sign In" style={{ width: '100%' }} disabled={disableSubmit} />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
