import { MenuProps } from 'antd';
import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Icon from '../Icon';
import Text from '../Text';
import DropdownApp from '../Dropdown';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import BackLink from '../buttons/BackLink';
import { ConfigurationI } from '../../interfaces/configuration';

type Props = {
  data?: ConfigurationI;
};

const ConfigurationTopSection: React.FC<Props> = ({ data }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'configurations');
  const navigate = useNavigate();

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'apply-config') navigate(`/configurations/apply?code=${data?.code}`);
  };

  const items: MenuProps['items'] = [
    access?.isCanCreate
      ? {
          key: 'apply-config',
          label: (
            <>
              <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
                <Icon name="icon-assignment_turned_in" size={16} />
                <span>Apply Configuration to Hardware</span>
              </Text>
            </>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="tw-mb-6">
      <div className="content-wrap">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <BackLink />
          <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
        </div>
        <div className="wrapper tw-flex tw-items-center tw-flex-row">
          <div className="name-section">
            <Text variant="h2">SmartPour Configuration Preset - {data?.name}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationTopSection;
