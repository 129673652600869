import { ProductCategoryI } from './../interfaces/productCategory';
import { BaseResponse } from '../interfaces';
import { ProductCategoriesCollectionI } from '../interfaces/productCategory';
import { api } from './api';

export const getProductCategory = async (id: number) => {
  const {
    data: { data = {} },
  } = await api.get<{ data: { data: ProductCategoryI } }>(`categories/${id}`);
  return data;
};

export const getProductCategoriesList = async () => {
  const { data: response } = await api.get<BaseResponse<ProductCategoriesCollectionI>>(`categories`);
  return response?.data;
};
