export const displayUserHistoryType: Record<string, string> = {
  verified: 'Verified',
  loggedIn: 'Logged In',
  loggedOut: 'Logged Out',
  impersonateLoggedOut: 'Logged Out (Impersonate)',
  impersonateLoggedIn: 'Logged In (Impersonate)',
  created: 'Created',
  update: 'Updated',
  addRole: 'Role (Assigned)',
  removeRole: 'Role (Unassigned)',
  addSite: 'Site (Assigned)',
  removeSite: 'Site (Unassigned)',
  updateStatus: 'Update Status',
};
