import React from 'react';
import IconButton from './IconButton';
import RefreshIcon from '../../svgs/RefreshIcon';

type Props = {
  className?: string;
  doRefetch?: () => void;
};

const RefreshButton: React.FC<Props> = ({ className, doRefetch }) => {
  return (
    <IconButton
      className={`tw-bg-white tw-text-primary-500 hover:!tw-bg-primary-800 hover:!tw-text-white ${className} tw-w-[40px]`}
      icon={<RefreshIcon size={16} />}
      onClick={() => doRefetch?.()}
    />
  );
};

export default RefreshButton;
