import React, { useState } from 'react';
import {
  HardwareRegisterParamsI,
  HardwareScannedStateI,
  HardwareTypeI,
  HardwareVerifyCollectionI,
} from '../../interfaces/hardware';
import './RegisterChooseTypeStep.scss';
import SecondaryButton from '../buttons/SecondaryButton';
import Divider from '../Divider';
import { Form, Input, Spin, message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { convertToApiFile } from '../../utils/global';
import UploadAttachmentButton from '../buttons/UploadAttachmentButton';
import ConfirmButton from '../buttons/ConfirmButton';
import { doRegisterHardware } from '../../apis/hardwares';
import HardwareRegisterSuccessModal from './RegisterSuccessModal';
import './RegisterReviewStep.scss';
import { CurrentUserI } from '../../interfaces/auth';
import { useOutletContext } from 'react-router-dom';
import { handleDisplayHardwareType } from '../../utils/hardware';
import { AttachmentCategories } from '../../constants';
import NonClosableModal from '../NonClosableModal';
import { SiteI } from '../../interfaces/site';

const ConfirmContent = () => {
  return (
    <div>
      <h2>Proceed with registration?</h2>
      <p>All the scanned Hardware will be logged into your Hardware Tracking List.</p>
    </div>
  );
};

type Props = {
  registerType?: HardwareTypeI;
  setCurrentStep: (step: number) => void;
  registerVerifiedData?: HardwareVerifyCollectionI;
  setRegisterData: (_: any) => void;
  setSuccessData: (_: any) => void;
  successData: any;
  setFinalScannedData: (_: HardwareScannedStateI[]) => void;
  site?: SiteI;
};

const HardwareRegisterReviewStep: React.FC<Props> = ({
  setRegisterData,
  registerVerifiedData,
  registerType,
  setCurrentStep,
  setSuccessData,
  successData,
  setFinalScannedData,
  site,
}) => {
  const currentUser: CurrentUserI = useOutletContext();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [files, setFiles] = useState<any[]>([]);
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);

  const handleModalCancel = () => {
    setIsSuccessModal(false);
    form.resetFields();
  };

  const { mutate: createMutate, isLoading: isCreateLoading } = useMutation(doRegisterHardware, {
    onSuccess: async (data) => {
      setSuccessData(data);
      queryClient.invalidateQueries({
        queryKey: ['hardwares', 'list'],
      });
      setIsSuccessModal(true);
    },
    onError: () => {
      message.error('Register failed! Please try again');
    },
  });

  const handleSubmit = (values: HardwareRegisterParamsI) => {
    const newValues: any = values;
    const hardwareList = registerVerifiedData?.hardwareList?.map((item: any) => {
      delete item.isInDB;
      delete item.error;
      delete item.currentData;
      return item;
    });

    newValues.hardwareList = hardwareList;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    newValues.siteId = site?.id;
    setRegisterData([]);
    setFinalScannedData([]);
    createMutate(newValues);
  };

  return (
    <Spin spinning={!registerVerifiedData || isCreateLoading}>
      <div className="component-hardware-register-review-step">
        <Form
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
        >
          <div className="content-wrap details-container">
            <h2 className="details-title">Registration Details</h2>
            <div className="details-content-wrap">
              <div className="item">
                <div className="label">Owner</div>
                <div className="value">{currentUser.username}</div>
              </div>
              <div className="item">
                <div className="label">Allocatee</div>
                <div className="value">{currentUser.username}</div>
              </div>
              <Divider />
              <div className="item">
                <div className="label">Number of Hardware</div>
                <div className="value">{registerVerifiedData?.numberOfValid}</div>
              </div>
              <div className="item">
                <div className="label">Hardware Type</div>
                <div className="value">{handleDisplayHardwareType(registerType)}</div>
              </div>
              <Divider />
              <div className="form-container">
                <div className="form-content">
                  <div className="item">
                    <div className="label">Site</div>
                    <div className="value">{site?.displayName}</div>
                  </div>
                  <Form.Item label="Remarks" name="remarks">
                    <Input.TextArea placeholder="Type remarks" rows={5} />
                  </Form.Item>
                  <p className="label">Media Attachments</p>
                  <UploadAttachmentButton
                    categories={AttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div className="hardware-footer-section">
          <div>
            <SecondaryButton label="Previous Step" onClick={() => setCurrentStep(4)} />
            <ConfirmButton
              type="info"
              label="Finish Scanning"
              onOk={() => form.submit()}
              content={<ConfirmContent />}
              closable={false}
            />
          </div>
        </div>
      </div>
      <NonClosableModal isVisible={isSuccessModal} handleCancel={handleModalCancel}>
        <HardwareRegisterSuccessModal data={successData} setCurrentStep={setCurrentStep} />
      </NonClosableModal>
    </Spin>
  );
};

export default HardwareRegisterReviewStep;
