import { BaseResponse } from '../interfaces';
import { RoleCollectionI, RoleI, RoleSearchParams } from '../interfaces/role';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetRole = (id: number) => {
  const { data: response, error, isLoading } = useFetch<any>(`roles/${id}`);
  const data: RoleI = response?.data;
  return { data, error, isLoading };
};

export const getRolesList = async (params?: RoleSearchParams) => {
  const { data: response } = await api.get<BaseResponse<RoleCollectionI>>(`roles?${handleFilterParams(params)}`);
  return response?.data;
};

export const createRole = async (values: Partial<RoleI>) => {
  const { data: response }: any = await api.post('roles', values);
  return response.data;
};

export const updateRole = async ({ id, ...values }: { id: string; values: RoleI }) => {
  const { data: response }: any = await api.patch(`roles/${id}`, values);
  return response.data;
};

export const deleteRole = async (id: number) => {
  const { data: response }: any = await api.delete(`roles/${id}`);
  return response.data;
};

export const updateRoleStatus = async (params: { id: number; status: string }) => {
  const { data: response }: any = await api.patch(`roles/${params.id}/status`, { status: params.status });
  return response.data;
};
