import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const AllInboxIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg width={width ?? 12} height={height ?? 12} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="all_inbox" clipPath="url(#clip0_12463_24441)">
        <path
          id="Vector"
          d="M17.0698 3.20312H4.93646C3.98312 3.20312 3.20312 3.98312 3.20312 4.93646V11.0031C3.20312 11.9565 3.98312 12.7365 4.93646 12.7365H17.0698C18.0231 12.7365 18.8031 11.9565 18.8031 11.0031V4.93646C18.8031 3.98312 18.0231 3.20312 17.0698 3.20312ZM4.93646 9.26979H7.64913C7.83113 9.94579 8.22979 10.5438 8.74979 11.0031H4.93646V9.26979ZM17.0698 11.0031H13.2565C13.7765 10.5438 14.1751 9.94579 14.3571 9.26979H17.0698V11.0031ZM17.0698 7.53646H12.7365V8.40312C12.7365 9.33046 11.9305 10.1365 11.0031 10.1365C10.0758 10.1365 9.26979 9.33046 9.26979 8.40312V7.53646H4.93646V4.93646H17.0698V7.53646ZM15.3365 13.6031H12.7365V14.4698C12.7365 14.8771 12.5718 15.2498 12.3205 15.5531C11.9998 15.9431 11.5231 16.2031 11.0031 16.2031C10.4831 16.2031 10.0065 15.9431 9.68579 15.5531C9.43446 15.2498 9.26979 14.8771 9.26979 14.4698V13.6031H3.20312V17.0698C3.20312 18.0231 3.98312 18.8031 4.93646 18.8031H17.0698C18.0231 18.8031 18.8031 18.0231 18.8031 17.0698V13.6031H15.3365ZM4.93646 15.3365H7.64913C7.66646 15.4145 7.70113 15.4838 7.72713 15.5531C7.93513 16.1425 8.29046 16.6625 8.74979 17.0698H4.93646V15.3365ZM17.0698 17.0698H13.2565C13.7245 16.6625 14.0798 16.1425 14.2791 15.5531C14.3051 15.4838 14.3398 15.4145 14.3571 15.3365H17.0698V17.0698Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_12463_24441">
          <rect width="20.8" height="20.8" fill="white" transform="translate(0.601562 0.601562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AllInboxIcon;
