import { Button, Modal } from 'antd';
import React, { ReactNode } from 'react';
import './ConfirmButton.scss';

type Props = {
  type: 'info' | 'error';
  label?: ReactNode;
  content?: string | ReactNode;
  style?: any;
  disabled?: boolean;
  closable?: boolean;
  icon?: ReactNode;
  className?: string;
  width?: number;
  buttonDisplayMode?: 'verical' | 'horizontal';
  okText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onOk?: () => void;
};

const ConfirmButton: React.FC<Props> = ({
  type,
  label,
  style,
  content,
  disabled,
  closable,
  icon,
  className,
  width,
  buttonDisplayMode = 'verical',
  okText,
  cancelText,
  onOk,
  onCancel,
}) => {
  const { confirm } = Modal;
  function showConfirm() {
    confirm({
      content: content,
      onOk() {
        onOk?.();
      },
      onCancel() {
        onCancel?.();
      },
      okText: okText ?? 'Confirm',
      cancelText: cancelText ?? 'Back',
      wrapClassName: `confirm-content-wrapper ${type} ${buttonDisplayMode}`,
      closable: closable ?? true,
      keyboard: closable ?? true,
      width: width ?? 412,
    });
  }
  return (
    <Button
      type="default"
      className={`btn-confirm ${type} ${className}`}
      htmlType="button"
      style={style}
      onClick={showConfirm}
      disabled={disabled}
    >
      {icon}
      {label ?? label}
    </Button>
  );
};

export default ConfirmButton;
