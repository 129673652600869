import { Image, MenuProps, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import DefaultSVG from '../../svgs/supervised_user_circle.svg';
import './Name.scss';
import { UserI } from '../../interfaces/user';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Icon from '../Icon';
import Text from '../Text';
import DropdownApp from '../Dropdown';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import { useMutation, useQueryClient } from 'react-query';
import { updateUserStatus } from '../../apis/users';
import AppUpdateStatusForm from '../forms/AppUpdateStatus';
import { UserStatusList } from '../../constants';
import { CommonStatus } from '../CommonStatus';
import BackLink from '../buttons/BackLink';
import { AttachmentI } from '../../interfaces/attachments';
import EmailRetriggerButton from '../shared/EmailRetriggerButton';

type Props = {
  user?: Partial<UserI>;
};

const UserName: React.FC<Props> = ({ user }) => {
  const [logoUrl, setLogoUrl] = useState<string>();
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'users');
  const navigate = useNavigate();
  const [isUpdateStatusVisible, setIsUpdateStatusVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleCancel = () => {
    setIsUpdateStatusVisible(false);
  };

  const { mutate: updateStatus } = useMutation(updateUserStatus, {
    onSuccess: async () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const onUpdateStatusSubmit = async (values: { status: string }) => {
    updateStatus({ id: Number(user?.id), status: values.status });
    setIsUpdateStatusVisible(false);
  };

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'update-status') setIsUpdateStatusVisible(true);
    if (e.key === 'edit') navigate(`/users/${user?.id}/edit`);
  };

  const items: MenuProps['items'] = [
    access.isCanUpdate
      ? {
          key: 'edit',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-edit" size={16} />
              <span>Edit User</span>
            </Text>
          ),
        }
      : null,
    access.isCanUpdateStatus
      ? {
          key: 'update-status',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-radio_button_checked" size={16} />
              <span>Update Status</span>
            </Text>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  useEffect(() => {
    if (user) {
      const logo = user.attachments?.filter((item: AttachmentI) => item.categoryName === 'logo');
      setLogoUrl(logo?.[0]?.url ?? DefaultSVG);
    }
  }, [user]);

  return (
    <div className="partner-name-container">
      <div className="content-wrap">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <BackLink />
          <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
        </div>
        <div className="wrapper tw-flex tw-items-center tw-flex-row">
          <div className="logo-section">
            <Image
              src={logoUrl}
              alt="Logo"
              preview={false}
              fallback={DefaultSVG}
              className="tw-rounded-[50%]"
              loading="lazy"
            />
          </div>
          <div className="name-section">
            <div className="name">{user?.username}</div>
            <div className="tw-flex tw-gap-3">
              <div className="status">{<CommonStatus status={user?.status as UserI['status']} />}</div>
              {user?.status === 'pending' && <EmailRetriggerButton email={user?.email ?? ''} />}
            </div>
          </div>
        </div>
      </div>
      <Modal open={isUpdateStatusVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={412}>
        <AppUpdateStatusForm
          data={user}
          onCancel={handleCancel}
          handleSubmit={onUpdateStatusSubmit}
          statusList={UserStatusList}
        />
      </Modal>
    </div>
  );
};

export default UserName;
