import React from 'react';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import SuccessSubmittedItem from '../shared/SuccessSubmittedItem';
import Text from '../Text';

type Props = {
  data?: any;
  setCurrentStep: (step: number) => void;
};

const HardwareRegisterSuccessModal: React.FC<Props> = ({ data, setCurrentStep }) => {
  return (
    <div className="tw-text-center tw-py-4 tw-px-4">
      <CheckCircleIcon width={48} height={48} color="#0A7A08" />
      <Text variant="h3" className="tw-mt-2 tw-mb-3">
        You have successfully submitted!
      </Text>
      <div className="notice tw-mb-8 tw-text-grey-700">
        <Text variant="bodyMd" className="!tw-block">
          Few actions are available for this entry.
        </Text>
        <Text variant="bodyMd" className="!tw-block">
          You can choose the options below
        </Text>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3">
        <SuccessSubmittedItem
          url={`/hardware-registrations/${data?.uuid}`}
          title="View the registration report"
          description={['View the report of your registered Hardware']}
        />
        <SuccessSubmittedItem
          onClick={() => setCurrentStep(6)}
          title="Hardware Grouping"
          description={[
            'Create a group of currently scanned Hardware.',
            'Only similar hardware can be grouped together.',
          ]}
        />
      </div>
    </div>
  );
};

export default HardwareRegisterSuccessModal;
