import React from 'react';
import E403Image from '../images/page-403.png';
import { Link } from 'react-router-dom';
import Text from '../components/Text';

const E403Page: React.FC = () => {
  return (
    <div className="page-404-403-container tw-bg-grey-50">
      <div className="wraper">
        <img src={E403Image} alt="error 404" />
        <h2>Forbidden!</h2>
        <p>Sorry, you don’t have permission to access this page</p>
        <Link
          to="/partner-profile"
          className="tw-mt-6 tw-p-[11px] tw-inline-block tw-border tw-border-solid tw-border-primary-500 tw-rounded-lg tw-bg-white"
        >
          <Text variant="bodyLg" className="tw-inline-flex">
            Go back to Partner Profile
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default E403Page;
