import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { SiteI } from '../../interfaces/site';
import { HardwareI } from '../../interfaces/hardware';
import { DateFormat } from '../../utils/global';
import { getTrackingTypeFromApi } from '../../utils/hardware';
import { CommonStatus } from '../CommonStatus';
import { PartnerI } from '../../interfaces/partner';
import Icon from '../Icon';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: HardwareI[];
};

const HardwaresTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Hardware',
      width: 150,
      ellipsis: true,
      render: (hardware: HardwareI) => {
        return (
          <span className="tw-flex tw-items-center tw-gap-x-2">
            <span>{`${hardware?.serialCode ?? hardware?.id}`}</span>{' '}
            {hardware.remarks !== null && <Icon name="icon-text_snippet" size={16} />}{' '}
          </span>
        );
      },
    },
    {
      title: 'Type',
      width: 150,
      ellipsis: true,
      render: (record: HardwareI) => {
        return <span>{getTrackingTypeFromApi(record)}</span>;
      },
    },
    {
      title: 'Status',
      width: 180,
      render: (item: HardwareI) => {
        return (
          <p className="status-section tw-flex tw-gap-1">
            {item?.status && <CommonStatus status={item?.status} />}
            {item?.condition && <CommonStatus status={item?.condition} />}
          </p>
        );
      },
    },
    {
      title: 'Hardware Owner',
      dataIndex: 'owner',
      width: 180,
      ellipsis: true,
      render: (owner: PartnerI) => {
        return <span>{owner?.legalName}</span>;
      },
    },
    {
      title: 'Site',
      dataIndex: 'site',
      width: 120,
      ellipsis: true,
      render: (site: SiteI) => {
        return <span>{site?.legalName}</span>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default HardwaresTable;
