import React, { useMemo } from 'react';
import { getHardwareHistory } from '../../apis/hardwares';
import { useQuery } from 'react-query';
import { HardwareHistoryI, HardwareHistorySearchParams } from '../../interfaces/hardwareHistory';
import TrackingHistoryItem from './HistoryItem';
import { Pagination, Spin } from 'antd';
import { PAGE_SIZE } from '../../constants';
import { handleFilterParams } from '../../utils/global';
import { useNavigate } from 'react-router-dom';
import TrackingHistoryFilter from './HistoryFilter';
import DisplayLastUpdateApp from '../DisplayLastUpdate';
import SizeBox from '../SizeBox';
import EmptyData from '../EmptyData';

type Props = {
  id?: string;
  hardwareType: string;
};

const TrackingHistory: React.FC<Props> = ({ id, hardwareType }) => {
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const categoryFilter = urlSearch.get('filter[category]');
    const userFilter = urlSearch.get('filter[user]');
    const partnershipFilter = urlSearch.get('filter[partnership]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search: search,
      filter: { user: userFilter, category: categoryFilter, partnership: partnershipFilter },
    } as HardwareHistorySearchParams & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { hardwareHistories = [], total = 0 } = {},
    isLoading,
    refetch: refetchData,
  } = useQuery(
    ['hardware-histories', `${id}`, queryParams],
    () => getHardwareHistory({ id: id ?? '', params: queryParams }),
    {
      select({ data: { hardwareHistories, total } }) {
        return { hardwareHistories, total };
      },
    },
  );

  const currentPage = queryParams?.page ?? 1;

  const setQueryParams = (params: Partial<HardwareHistorySearchParams>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: HardwareHistorySearchParams) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  return (
    <Spin spinning={isLoading} className="partner-history-container">
      <TrackingHistoryFilter
        queryParams={queryParams}
        onChange={handleFilterChange}
        hardwareType={hardwareType}
        refetchData={refetchData}
      />
      <DisplayLastUpdateApp />
      <SizeBox height={8} />
      {!isLoading && hardwareHistories.length === 0 && <EmptyData title="No Data" />}
      {hardwareHistories.map((item: HardwareHistoryI) => {
        if (!['vol', 'unmating', 'update'].includes(item.eventName)) {
          return <TrackingHistoryItem item={item} key={item?.id} hardwareType={hardwareType} />;
        }
      })}
      <div className="pagination-container tw-mt-[44px]">
        <Pagination
          total={total}
          pageSize={queryParams.limit ?? PAGE_SIZE}
          showTotal={(total) => `${total} Items`}
          current={currentPage}
          onChange={(page, pageSize) => handleFilter({ page, per_page: pageSize })}
          showSizeChanger
        />
      </div>
    </Spin>
  );
};

export default TrackingHistory;
