import React from 'react';
import { HardwareVerifyCollectionI } from '../../../interfaces/hardware';
import SecondaryButton from '../../buttons/SecondaryButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import HardwareDetailsCard from './HardwareDetailsCard';

type Props = {
  registerVerifiedData?: HardwareVerifyCollectionI;
  nextStep?: number;
  setCurrentStep: (step: number) => void;
  handleDeleteVerifiedData?: (_: any) => void;
  handleScanmoreButton: () => void;
};

const W2WHardwareGroupVerifyStep: React.FC<Props> = ({
  registerVerifiedData,
  nextStep,
  setCurrentStep,
  handleDeleteVerifiedData,
  handleScanmoreButton,
}) => {
  return (
    <div className="tw-py-4 tw-px-4">
      <div className="tw-flex tw-gap-4 tw-flex-wrap">
        {registerVerifiedData?.hardwareList?.map((item: any) => {
          return <HardwareDetailsCard item={item} key={item.serialCode} handleDelete={handleDeleteVerifiedData} />;
        })}
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Scan more" onClick={handleScanmoreButton} />
          <PrimaryButton
            label="Next"
            onClick={() => setCurrentStep(nextStep ?? 5)}
            disabled={registerVerifiedData?.hardwareList?.length === 0}
          />
        </div>
      </div>
    </div>
  );
};

export default W2WHardwareGroupVerifyStep;
