import React, { useState } from 'react';
import {
  HardwareRegisterParamsI,
  HardwareScannedStateI,
  HardwareTypeI,
  HardwareVerifyCollectionI,
} from '../../interfaces/hardware';
import SecondaryButton from '../buttons/SecondaryButton';
import Divider from '../Divider';
import { Form, Input, Spin, message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { convertToApiFile } from '../../utils/global';
import UploadAttachmentButton from '../buttons/UploadAttachmentButton';
import ConfirmButton from '../buttons/ConfirmButton';
import './ReviewStep.scss';
import DepletionSuccessModal from './SuccessModal';
import { doHardwareDepletion } from '../../apis/hardwareDepletions';
import { handleGetUniqType } from '../../utils/hardware';
import { AttachmentCategories } from '../../constants';
import NonClosableModal from '../NonClosableModal';
import { SiteI } from '../../interfaces/site';
import Icon from '../Icon';
import Text from '../Text';

const ConfirmContent = () => {
  return (
    <div>
      <h2>Mark the valid hardware as empty?</h2>
      <p className="danger-text">
        You will not be able to revert this action once you have marked the hardwares empty.
      </p>
      <p className="danger-text">Please double check if all the ecoTOTE(s) are empty.</p>
    </div>
  );
};

type Props = {
  verifiedData?: HardwareVerifyCollectionI;
  successData: any;
  site?: SiteI;
  previousStep?: number;
  depleteStep?: number;
  setCurrentStep: (step: number) => void;
  setScannedData: (_: any) => void;
  setSuccessData: (_: any) => void;
  setChosenType: (type: HardwareTypeI) => void;
  setFinalScannedData: (_: HardwareScannedStateI[]) => void;
};

const DepletionReviewStep: React.FC<Props> = ({
  successData,
  verifiedData,
  site,
  previousStep,
  depleteStep,
  setChosenType,
  setCurrentStep,
  setScannedData,
  setSuccessData,
  setFinalScannedData,
}) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleModalCancel = () => {
    setIsSuccessModal(false);
    form.resetFields();
  };

  const { mutate: doCreateMutate, isLoading: isCreateLoading } = useMutation(doHardwareDepletion, {
    onSuccess: async (data) => {
      setSuccessData(data);
      queryClient.invalidateQueries();
      setIsSuccessModal(true);
      setScannedData([]);
      setFinalScannedData([]);
    },
    onError: () => {
      message.error('Your action was failed! Please try again');
    },
  });

  const handleSubmit = (values: HardwareRegisterParamsI) => {
    const newValues: any = values;
    const hardwareList = verifiedData?.hardwareList?.map((item: any) => {
      delete item.isInDB;
      delete item.error;
      delete item.currentData;
      delete item.type;
      return item;
    });

    newValues.hardwareList = hardwareList;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    newValues.siteId = site?.id;
    doCreateMutate(newValues);
  };

  return (
    <Spin spinning={!verifiedData || isCreateLoading}>
      <div className="component-hardware-depletion-review-step">
        <Form
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
        >
          <div className="content-wrap details-container" style={{ marginTop: 16 }}>
            <h2 className="details-title">Mark as Empty</h2>
            <div className="details-content-wrap">
              <div className="item">
                <div className="label">Number of ecoTOTEs</div>
                <div className="value">{verifiedData?.numberOfValid}</div>
              </div>
              <div className="item">
                <div className="label">Hardware Type</div>
                <div className="value tw-flex tw-flex-col">
                  {handleGetUniqType(verifiedData?.hardwareList ?? []).map((item: { type: string; count: number }) => (
                    <>
                      {item.type.includes('ecoTOTE') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                      {item.type.includes('ecoPLANT') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                      {item.type.includes('SmartPour') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <Divider />
              <div className="item">
                <div className="label">Site</div>
                <div className="value">{site?.displayName}</div>
              </div>
              <div className="form-container">
                <div className="form-content">
                  <Form.Item label="Remarks" name="remarks">
                    <Input.TextArea placeholder="Type remarks" rows={5} />
                  </Form.Item>
                  <p className="label">Media Attachments</p>
                  <UploadAttachmentButton
                    categories={AttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div className="hardware-footer-section">
          <div>
            <SecondaryButton label="Previous Step" onClick={() => setCurrentStep(previousStep ?? 2)} />
            <ConfirmButton
              type="info"
              label="Finish Scanning"
              onOk={() => form.submit()}
              content={<ConfirmContent />}
              closable={false}
            />
          </div>
        </div>
      </div>
      <NonClosableModal isVisible={isSuccessModal} handleCancel={handleModalCancel}>
        <DepletionSuccessModal
          data={successData}
          setChosenType={setChosenType}
          setCurrentStep={setCurrentStep}
          depleteStep={depleteStep}
        />
      </NonClosableModal>
    </Spin>
  );
};

export default DepletionReviewStep;
