import React, { useEffect, useState } from 'react';

type Props = {
  isStartCountdown: boolean;
  totalOfTime: number;
  setIsStartCountdown: (value: boolean) => void;
};

const Countdown: React.FC<Props> = ({ isStartCountdown, totalOfTime, setIsStartCountdown }) => {
  const [seconds, setSeconds] = useState<number>(totalOfTime);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    if (isStartCountdown) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isStartCountdown]);

  useEffect(() => {
    if (seconds === 0) {
      setIsStartCountdown(false);
      setSeconds(totalOfTime);
    }
  }, [seconds]);
  return <span>{formatTime(seconds)}</span>;
};

export default Countdown;
