import { Checkbox, Form } from 'antd';
import React, { useMemo } from 'react';
import ActionSection from './ActionSection';
import { ModuleI } from '../../interfaces/module';
import { RoleI } from '../../interfaces/role';
import { CheckboxOptionI } from '../../interfaces';
import { getModulePermissions } from '../../utils/global';

type Props = {
  modules?: ModuleI[];
  role?: RoleI;
};

const RoleNotificationCollapseDetail: React.FC<Props> = ({ modules, role }) => {
  const options: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Email')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  return (
    <Form.Item name="colEmail">
      <ActionSection label="Email" content={<Checkbox.Group options={options} value={role?.colEmail} />} />
    </Form.Item>
  );
};

export default RoleNotificationCollapseDetail;
