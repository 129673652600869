import React from 'react';
import { Pagination } from 'antd';
import { AccessControlI } from '../../interfaces';
import HardwareGroupCardItem from './HardwareGroupCardItem';

interface Props {
  access: AccessControlI;
  data: any[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const TrackingGroupsCardList: React.FC<Props> = ({ access, data, pagination, onPaginationChange }) => {
  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="card-list">
        {data.map((item) => (
          <HardwareGroupCardItem access={access} key={item.id} item={item} />
        ))}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        pageSizeOptions={[5, 10, 20, 50]}
        showSizeChanger
      />
    </section>
  );
};

export default TrackingGroupsCardList;
