import { Form, message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { updateProduct, useGetProduct } from '../../apis/products';
import ProductForm from '../../components/forms/product/Form';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';

const ProductUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { sku } = useParams<{ sku: string }>();
  const { data, isLoading } = useGetProduct(sku);

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(updateProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['products', 'list'],
      });
      message.info('Update product successfully!');
      navigate(`/products`);
    },
    onError: () => {
      message.error('Update product failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ sku: sku ?? '', values });
  };

  return (
    <Spin spinning={isLoading || isPageLoading}>
      <CreateUpdateTopSection label="Edit Product" labelButton="Update" form={form} />
      <ProductForm form={form} handleSubmit={handleSubmit} product={data} productId={sku} />
    </Spin>
  );
};

export default ProductUpdatePage;
