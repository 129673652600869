import { TransferCollectionI, TransferSearchParamsI, TransferVerifyOwnerResponseI } from './../interfaces/transfer';
import { BaseResponse } from '../interfaces';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';
import { HardwareVerifyCollectionI } from '../interfaces/hardware';

export const useGetTransfer = (code?: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`transfers/${code}`);
  const data = response?.data;
  return { data, error, isLoading };
};

export const getTransfersList = async (params?: TransferSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<TransferCollectionI>>(
    `transfers?${handleFilterParams(params)}`,
  );
  return response;
};

export const doTransfer = async (values: any) => {
  const { data: response }: any = await api.post('transfers', values);
  return response.data;
};

export const doVerifyTransfer = async (params: { hardwareList: { serialCode: string }[] }) => {
  const { data: response }: any = await api.post<BaseResponse<HardwareVerifyCollectionI>>(`transfers/verify`, params);
  return response.data;
};

export const doVerifyOwnerTransfer = async (referenceCode: string) => {
  const { data: response }: any = await api.post<BaseResponse<TransferVerifyOwnerResponseI>>(`transfers/verify-owner`, {
    referenceCode,
  });
  return response.data;
};
