import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { EmailListUserI } from '../../interfaces/emailList';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: EmailListUserI[];
};

const EmailListEmails: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Username',
      dataIndex: 'username',
      width: 150,
      ellipsis: true,
      render: (username: string) => {
        return <span>{username}</span>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
      ellipsis: true,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      title: 'Action',
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default EmailListEmails;
