import { Checkbox, Form } from 'antd';
import React, { useEffect } from 'react';
import { HardwareHistorySearchParams } from '../../interfaces/hardwareHistory';
import Text from '../Text';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { displayAllHardwareHistoryType, getHistoryTypeByType } from '../../utils/hardware';
import Divider from '../Divider';
import SizeBox from '../SizeBox';
import SelectAllCheckbox from '../SelectAllCheckbox';

type FormParams = {
  category: string;
  user: string;
  search: string;
  partnership: 'internal' | 'external';
};

type Props = {
  queryParams: HardwareHistorySearchParams;
  hardwareType: string;
  onChange(newParams: Partial<HardwareHistorySearchParams>): void;
};

const TrackingHistoryFilterContent: React.FC<Props> = ({ queryParams, hardwareType, onChange }) => {
  const [form] = Form.useForm();

  const categoryOptions = Object.keys(getHistoryTypeByType(hardwareType)).map((item) => {
    return { label: displayAllHardwareHistoryType[item], value: item };
  });

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: { category: values?.category, user: values?.user, partnership: values?.partnership },
      search: values?.search,
    });
  };

  const userOptions = ['system', 'user'];
  const partnershipOptions = ['external', 'internal'];

  useEffect(() => {
    if (queryParams.filter) {
      const category = queryParams.filter?.category ? queryParams.filter?.category.split(',') : [];
      const user = queryParams.filter?.user ? queryParams.filter?.user.split(',') : [];
      const partnership = queryParams.filter?.partnership ? queryParams.filter?.partnership.split(',') : [];
      form.setFieldsValue({
        category,
        user,
        partnership,
      });
    }
  }, [queryParams]);

  return (
    <div className="component-hardware-filter-content-container partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content tw-max-h-[500px] tw-overflow-y-auto">
            <Form.Item name="category">
              <SelectAllCheckbox form={form} plainOptions={categoryOptions} name="category" sectionTitle="Category">
                {categoryOptions.map((item: { label: string; value: string }) => (
                  <Checkbox key={item.value} value={item.value}>
                    {item.label}
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
            <SizeBox height={12} />
            <Divider />
            <SizeBox height={12} />
            <Form.Item name="user">
              <SelectAllCheckbox form={form} plainOptions={userOptions} name="user" sectionTitle="Created by">
                {userOptions.map((item: string) => (
                  <Checkbox key={item} value={item}>
                    <span className="text-capitalize">{item}</span>
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
            <SizeBox height={12} />
            <Divider />
            <SizeBox height={12} />
            <Form.Item name="partnership">
              <SelectAllCheckbox form={form} plainOptions={partnershipOptions} name="partnership">
                {partnershipOptions.map((item: string) => (
                  <Checkbox key={item} value={item}>
                    <span className="text-capitalize">{item}</span>
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
            <SizeBox height={12} />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TrackingHistoryFilterContent;
