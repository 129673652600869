import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const SendIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg width={width ?? 15} height={height ?? 11} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M11.333 4.3335C11.4463 4.3335 11.5597 4.34016 11.6663 4.3535V1.58016C11.6663 0.893496 11.1063 0.333496 10.4197 0.333496H1.57967C0.893008 0.333496 0.333008 0.893496 0.333008 1.58016V8.42016C0.333008 9.10683 0.893008 9.66683 1.57967 9.66683H8.06634C7.81301 9.16683 7.66634 8.60016 7.66634 8.00016C7.66634 5.9735 9.30634 4.3335 11.333 4.3335ZM5.93301 5.66683L1.66634 3.12683V1.66683H1.81967L5.93967 4.12016L10.1597 1.66683H10.333V3.10683L5.93301 5.66683Z"
            fill={color ?? 'currentColor'}
          />
          <path
            id="Vector_2"
            d="M11.6667 5.66699L10.7267 6.60699L11.78 7.66699H9V9.00033H11.78L10.7267 10.0603L11.6667 11.0003L14.3333 8.33366L11.6667 5.66699Z"
            fill={color ?? 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
};

export default SendIcon;
