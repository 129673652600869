import React, { ReactNode, createContext, useContext } from 'react';
import { NotificationArgsProps, notification } from 'antd';
import Icon from '../components/Icon';
import CheckCircleIcon from '../svgs/CheckCircleIcon';
import './useAppNotification.scss';
import Text from '../components/Text';

type NotificationPlacement = NotificationArgsProps['placement'];
type Props = {
  type: 'success' | 'info' | 'error' | 'warning';
  content: ReactNode;
  title?: ReactNode;
  placement?: NotificationPlacement;
  width?: number;
  className?: string;
  style?: Record<string, any>;
  icon?: ReactNode;
};

const NotificationContext = createContext<{
  openNotification: (props: Props) => void;
}>({ openNotification: () => {} });

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = ({ type, content, title, placement, width, className, style, icon }: Props) => {
    const getIcon = () => {
      if (icon) {
        return icon;
      } else if (type === 'success') {
        return <CheckCircleIcon width={16} height={16} color="#5FB670" />;
      } else if (type === 'error') {
        return <Icon name="icon-error" size={16} className="tw-text-error-500" />;
      } else if (type === 'warning') {
        return <Icon name="icon-warning" size={16} className="tw-text-warning-500" />;
      } else {
        return undefined;
      }
    };

    api.open({
      message: <Text variant="bodyMdB">{title}</Text>,
      description: content ? <Text variant="bodyMd">{content}</Text> : null,
      className: `app-notification-container ${className}`,
      style: {
        ...style,
        width: width ?? 554,
      },
      type: type,
      placement: placement ?? 'top',
      icon: getIcon(),
    });
  };

  return (
    <NotificationContext.Provider value={{ openNotification }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useAppNotification = () => useContext(NotificationContext);
