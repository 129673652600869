import { Checkbox, Form } from 'antd';
import React, { useMemo } from 'react';
import { ModuleI } from '../../../interfaces/module';
import { CheckboxOptionI } from '../../../interfaces';
import ActionSection from '../../Role/ActionSection';
import { handleGetPermissionFromModule } from '../../../utils/role';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const NotificationCollapse: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  const options: CheckboxOptionI[] = useMemo(() => handleGetPermissionFromModule(modules, 'Email'), [modules]);

  return (
    <ActionSection
      label="Email"
      content={
        <Form.Item name="colEmail">
          <Checkbox.Group options={options} onChange={(e) => onCheckboxChange(e, 'colEmail')} />
        </Form.Item>
      }
    />
  );
};

export default NotificationCollapse;
