import React, { useState } from 'react';
import './FaultyReportButton.scss';
import Icon from '../Icon';
import Text from '../Text';
import FaultyReportModal from '../FaultyReportModal';

type Props = {
  data?: any;
  hardwareType?: string;
  label?: string;
  setCondition?: (_: string) => void;
};

const FaultyReportButton: React.FC<Props> = ({ data, hardwareType = 'ecotote', setCondition }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  return (
    <div onKeyDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
      <button
        className="tw-flex tw-items-center tw-gap-x-1 tw-p-0 tw-border-none tw-bg-white"
        onClick={handleClick}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Icon name="icon-flag" size={12} className="tw-text-primary-800" />
        <Text variant="buttonSm" className="tw-text-primary-800">
          Report
        </Text>
      </button>
      <FaultyReportModal
        setCondition={setCondition}
        data={data}
        hardwareType={hardwareType}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />
    </div>
  );
};

export default FaultyReportButton;
