import React from 'react';
import StickIcon from '../../svgs/StickIcon';
import './ProgressBar.scss';

type Props = {
  currentStep: number;
  stepList: { step: number; label: string }[];
};

const SharedProgressBar: React.FC<Props> = ({ currentStep, stepList }) => {
  return (
    <div className="shared-progress-bar tw-border-none tw-mx-auto tw-mt-5 tw-p-0">
      <div className="status-bar">
        <ul>
          {stepList.map((item: { step: number; label: string }) => (
            <li
              key={item.step}
              className={`step ${currentStep === item.step ? 'active' : 'inactive'} ${currentStep > item.step ? 'completed' : ''} for${stepList.length}step`}
            >
              <span className="number">{currentStep > item.step ? <StickIcon /> : item.step}</span>
              <span className="text">{item.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SharedProgressBar;
