import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { Tag } from 'antd';
import { UserI } from '../../interfaces/user';
import { PartnerI, PartnerStatusI } from '../../interfaces/partner';
import { PartnerStatus } from '../../pages/Partners/PartnerStatus';
import { formatIntlPhoneNumber } from '../PhoneNumberInput/PhoneNumberInput';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: PartnerI[];
};

const PartnersTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'PARTNER NAME',
      width: 150,
      dataIndex: 'legalName',
      ellipsis: true,
      render: (legalName: string) => {
        return <span>{legalName}</span>;
      },
    },
    {
      title: 'market',
      dataIndex: 'market',
      width: 150,
      ellipsis: true,
      render: (market: string[]) => {
        return market?.map((m: string) => (
          <Tag key={m} className="tw-mt-[3px] tw-mb-[3px]">
            {m}
          </Tag>
        ));
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      ellipsis: true,
      render: (status: PartnerStatusI) => {
        return <PartnerStatus status={status} />;
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'contactNumber',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Admin’s Username',
      dataIndex: 'users',
      width: 150,
      ellipsis: true,
      render: (users: UserI[]) => {
        return users?.map((admin: UserI) => <span key={admin.id}>{admin.username}</span>);
      },
    },
    {
      title: 'Admin’s Email',
      dataIndex: 'users',
      width: 150,
      ellipsis: true,
      render: (users: UserI[]) => {
        return users?.map((admin: UserI) => <span key={admin.id}>{admin.email}</span>);
      },
    },
    {
      title: 'Admin’s Phone Number',
      dataIndex: 'users',
      width: 150,
      ellipsis: true,
      render: (users: UserI[]) => {
        return users?.map((admin: UserI) => <span key={admin.id}>{formatIntlPhoneNumber(admin.contactNumber)}</span>);
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default PartnersTable;
