import React from 'react';
type Props = {
  width?: number;
  height?: number;
};

const ViewColumn: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 20}px`}
      viewBox="0 0 24 24"
      width={`${width ?? 20}px`}
      fill="currentColor"
    >
      <g>
        <path d="M4 5V18H21V5H4ZM14 7V16H11V7H14ZM6 7H9V16H6V7ZM19 16H16V7H19V16Z" />
      </g>
      <defs>
        <clipPath id="clip0_11171_57129">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ViewColumn;
