import React from 'react';
type Props = {
  width?: number;
  height?: number;
};

const CheckIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 12 12"
      height={`${height ?? 12}px`}
      viewBox="0 0 12 12"
      width={`${width ?? 12}px`}
      fill="currentColor"
    >
      <g id="check" clipPath="url(#clip0_13424_153818)">
        <path
          id="Vector"
          d="M4.49813 8.08687L2.41312 6.00187L1.70312 6.70687L4.49813 9.50187L10.4981 3.50187L9.79313 2.79688L4.49813 8.08687Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_13424_153818">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CheckIcon;
