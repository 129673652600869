import { Button } from 'antd';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.scss';
import Icon from '../Icon';
import Text from '../Text';

type Props = {
  loading?: boolean;
  className?: string;
  label?: ReactNode;
  backFunction?: () => void;
};

const BackLink: React.FC<Props> = ({ loading, className, label, backFunction }) => {
  const navigate = useNavigate();

  return (
    <Button
      type="link"
      className={`btn-link btn-back-action ${className} tw-flex tw-text-primary-500 hover:!tw-text-primary-500 !tw-p-0 tw-gap-2`}
      loading={loading}
      onClick={() => (backFunction ? backFunction() : navigate(-1))}
    >
      <Icon name="icon-chevron_left" size={16} />
      <Text variant="buttonSm" component="span">
        {label ?? 'Back'}
      </Text>
    </Button>
  );
};

export default BackLink;
