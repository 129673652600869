import { Checkbox, Form } from 'antd';
import React, { useEffect } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import './FilterContent.scss';
import { SiteSearchParamsI, SiteStatusI } from '../../../interfaces/site';
import { PartnerStatus } from '../../../pages/Partners/PartnerStatus';
import SecondaryButton from '../../buttons/SecondaryButton';
import Text from '../../Text';
import SelectAllCheckbox from '../../SelectAllCheckbox';

type Props = {
  queryParams: SiteSearchParamsI;
  onChange(newParams: Partial<SiteSearchParamsI>): void;
};

const remarkOptions = [
  { label: 'AMER', value: 'AMER' },
  { label: 'APAC', value: 'APAC' },
  { label: 'EMEA', value: 'EMEA' },
];
const typeOptions = [
  { label: 'Consume', value: 'consume' },
  { label: 'Fill', value: 'filling' },
  { label: 'Storage', value: 'storage' },
];

const statusOptions: SiteStatusI[] = ['active', 'idle', 'inactive'];

const SiteFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();

  const handleResetFilter = () => {
    onChange({ filter: {} });
    form.resetFields();
  };

  useEffect(() => {
    if (queryParams) {
      form.setFieldsValue({
        market: queryParams.filter?.market,
        status: queryParams.filter?.status,
        type: queryParams.filter?.type,
      });
    }
  }, [queryParams]);

  return (
    <div className="partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={(filter) => onChange({ filter })}
        scrollToFirstError
        layout="vertical"
        className="tw-max-h-[580px] tw-overflow-auto tw-pr-4"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Form.Item name="market">
              <SelectAllCheckbox form={form} plainOptions={remarkOptions} name="market" sectionTitle="Market">
                {remarkOptions.map((item) => (
                  <Checkbox key={item.value} value={item.value}>
                    {item.label}
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
            <Form.Item name="type">
              <SelectAllCheckbox form={form} plainOptions={typeOptions} name="type" sectionTitle="Type">
                {typeOptions.map((item) => (
                  <Checkbox key={item.value} value={item.value}>
                    {item.label}
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
            <Form.Item name="status">
              <SelectAllCheckbox form={form} plainOptions={statusOptions} name="status" sectionTitle="Status">
                {statusOptions.map((status) => (
                  <Checkbox key={status} value={status}>
                    <PartnerStatus status={status} />
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SiteFilterContent;
