import React, { useState } from 'react';
import { HardwareGroupI } from '../../interfaces/hardware';
import { useNavigate } from 'react-router-dom';
import Text from '../Text';
import Icon from '../Icon';
import { Drawer, MenuProps } from 'antd';
import DropdownApp from '../Dropdown';
import BackLink from '../buttons/BackLink';
import GroupRemoveHardwareDrawer from './GroupRemoveHardwareDrawer';

type Props = {
  data: HardwareGroupI;
};

const TrackingGroupTopInfo: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const [isRemoveGroupVisible, setIsRemoveGroupVisible] = useState<boolean>(false);

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'edit') navigate(`/tracking/groups/${data.code}/edit`);
    if (e.key === 'remove-hardware') setIsRemoveGroupVisible(true);
    if (e.key === 'add-hardware') navigate(`/hardware-groups/${data.code}/edit`);
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-edit" size={16} />
          <span>Edit Hardware Group</span>
        </Text>
      ),
    },
    {
      key: 'add-hardware',
      label: (
        <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-add_circle_outline" size={16} />
          <span>Add More Hardware</span>
        </Text>
      ),
    },
    {
      key: 'remove-hardware',
      label: (
        <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-remove_circle_outline" size={16} />
          <span>Remove Hardware from Group</span>
        </Text>
      ),
    },
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <section className="tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-flex tw-flex-col tw-flex-1 tw-mb-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <BackLink />
        <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
      </div>
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-auto">
          <div className="tw-flex tw-flex-col tw-gap-2">
            <Text variant="h3" className="tw-mb-2 tw-truncate xs:tw-max-w-[195px] tw-text-grey-800">
              {data?.title}
            </Text>
          </div>
        </div>
      </div>
      <Drawer
        title={'Remove Hardware from Group'}
        onClose={() => setIsRemoveGroupVisible(false)}
        open={isRemoveGroupVisible}
        rootClassName="hardware-scan-qrcode-drawer"
        width={884}
      >
        <GroupRemoveHardwareDrawer data={data} />
      </Drawer>
    </section>
  );
};

export default TrackingGroupTopInfo;
