import React from 'react';

const CreateIcon: React.FC = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="circle-add">
        <path
          id="Icon"
          d="M7.41667 10H14.0833M10.75 6.66667V13.3333M18.25 10C18.25 14.1421 14.8921 17.5 10.75 17.5C6.60786 17.5 3.25 14.1421 3.25 10C3.25 5.85786 6.60786 2.5 10.75 2.5C14.8921 2.5 18.25 5.85786 18.25 10Z"
          stroke="#224A13"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CreateIcon;
