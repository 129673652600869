import React from 'react';
import { IconName } from './iconName';

interface Props {
  name: IconName;
  size?: number;
  className?: string;
}

const Icon: React.FC<Props> = ({ name, size = 24, className }) => {
  return (
    <svg viewBox="0 0 24 24" width={size} height={size} className={className}>
      <use href={`/icons.svg#${name}`}></use>
    </svg>
  );
};

export default Icon;
