import React, { useState } from 'react';
import { HardwareScannedStateI } from '../../interfaces/hardware';
import { Button, Drawer } from 'antd';
import EmptyData from '../EmptyData';
import NoticeIcon from '../../svgs/NoticeIcon';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  scannedData: HardwareScannedStateI[];
};

const ConfigurationScanTabsContent: React.FC<Props> = ({ scannedData }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="component-allocation-scan-tabs-content">
      <p className="notice-section">
        <NoticeIcon />
        <span>
          QR/Barcode can be found either on the underside of the SmartPour 1.1S, or underside of the cover inside the
          SmartPOUR 2.0S
        </span>
      </p>
      <div className="scanned-btn">
        <Button type="primary" onClick={showDrawer}>
          <Icon name="icon-all_inbox" size={24} />
          {scannedData?.length} Code(s) Scanned
          <Icon name="icon-expand_more" size={24} />
        </Button>
      </div>
      <Drawer
        title={<span>{`${scannedData.length} Code(s) Scanned`}</span>}
        onClose={onDrawerClose}
        open={open}
        rootClassName="hardware-scan-qrcode-drawer"
      >
        <div className="hardware-scanned-list-drawer">
          {scannedData?.length > 0 ? (
            scannedData.map((data: HardwareScannedStateI) => (
              <div key={data.serialCode} className="item">
                <Text variant="bodyLgB" className="tw-text-primary-900 !tw-block">{`${data.serialCode}`}</Text>
              </div>
            ))
          ) : (
            <EmptyData message="No hardware scanned yet" />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default ConfigurationScanTabsContent;
