import React, { Fragment, useState } from 'react';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import FillingScanQRCode from './ScanQRCode';
import { Modal, Tabs, TabsProps } from 'antd';
import { getHardwareDetails } from '../../apis/hardwares';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import { FillingStartScanningScreen } from './StartScanningScreen';
import { Link } from 'react-router-dom';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import FillingManual from './Manual';
import EcoPlantCardItem from './EcoPlantCardItem';
import { HardwareI } from '../../interfaces/hardware';
import './ScanStep.scss';
import { FillingParamsI } from '../../interfaces/hardwareFilling';
import { SiteI } from '../../interfaces/site';

const RenderDescriptionWarningNotice = () => {
  return (
    <Fragment>
      <p>
        Ensure that the ecoPLANT has been registered before scanning. If not, please proceed to register the ecoPLANT.
      </p>
      <Link to={`/hardware-registrations/new`}>
        <span>Register ecoPLANT</span>
        <RightArrowIcon width={16} height={16} />{' '}
      </Link>
    </Fragment>
  );
};

const ScanNoticeMessage = 'QR code can be found on the Filling machine inside the ecoPLANT';

type Props = {
  setCurrentStep: (_: number) => void;
  setScannedData: (_: FillingParamsI) => void;
  scannedData?: FillingParamsI;
  stopDecodingScanner: boolean;
  currentStep: number;
  pageName?: string;
  setStopDecodingScanner: (_: any) => void;
  setSite: (_: SiteI) => void;
};

const ScanEcoPlantStep: React.FC<Props> = ({
  scannedData,
  stopDecodingScanner,
  currentStep,
  pageName,
  setCurrentStep,
  setScannedData,
  setStopDecodingScanner,
  setSite,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [screen, setScreen] = useState<'start' | 'scan' | 'scanned'>('start');
  const [dataChecked, setDataChecked] = useState<HardwareI>();

  const validateScanResult = async (value: string) => {
    let status = 'success';
    let hardware: HardwareI;
    try {
      hardware = await getHardwareDetails(value);
      if (hardware?.type !== 'ecoplant') {
        status = 'not-ecoplant';
      }
    } catch (error: any) {
      status = 'not-found-error';
      const errorModal = modal.error({
        type: 'error',
        title: 'Failed Scanning!',
        content: 'We cannot recognise this hardware’s serial code.',
        className: 'context-modal-custom-container',
        closable: true,
        centered: true,
        footer: null,
        icon: <ErrorModalIcon color="#B13939" />,
        width: 345,
      });

      setTimeout(() => {
        errorModal.destroy();
      }, 1000);
    }

    setTimeout(() => {
      if (status === 'not-ecoplant') {
        const errorModal = modal.error({
          type: 'error',
          title: 'Hardware is not an ecoPLANT!',
          content: 'Only ecoPLANT hardware is available for this scanning step. Please try again!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <ErrorModalIcon color="#B13939" />,
          width: 345,
        });

        setTimeout(() => {
          errorModal.destroy();
        }, 1000);
      }

      if (status === 'success') {
        setTimeout(() => {
          setScreen('scanned');
          setDataChecked(hardware);
          setSite(hardware?.site);
          setScannedData({ ...scannedData, plantId: hardware?.id, plant: hardware });
        }, 300);
      }
    }, 600);
  };

  const handleScanOrManualSubmit = (result: any) => {
    validateScanResult(result.text);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <FillingScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanNoticeMessage={ScanNoticeMessage}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          pageName={pageName}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <FillingManual scanNoticeMessage={ScanNoticeMessage} handleScanOrManualSubmit={handleScanOrManualSubmit} />
      ),
    },
  ];

  const onDiscard = () => {
    if (dataChecked) {
      setScreen('scan');
      setDataChecked(undefined);
    } else {
      setScreen('start');
    }
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  const onStartScreenClick = () => {
    setStopDecodingScanner(false);
    setScreen('scan');
  };

  return (
    <div className="component-hardware-filling-scan-step">
      <div className="wrapper">
        {screen === 'start' && (
          <FillingStartScanningScreen
            warningDescription2={<RenderDescriptionWarningNotice />}
            setScreen={setScreen}
            setStopDecodingScanner={setStopDecodingScanner}
            content={ScanNoticeMessage}
            currentStep={currentStep}
          />
        )}
        {screen === 'scan' && <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />}
        {screen === 'scanned' && <EcoPlantCardItem item={dataChecked} />}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          {screen === 'start' && <PrimaryButton label={'Scan ecoPLANT'} onClick={onStartScreenClick} />}
          {screen !== 'start' && (
            <>
              <SecondaryButton
                label={dataChecked?.type === 'ecoplant' ? 'Rescan' : 'Previous Step'}
                onClick={onDiscard}
                disabled={dataChecked?.type !== 'ecoplant'}
              />
              <PrimaryButton
                label={'Continue'}
                onClick={() => setCurrentStep(2)}
                disabled={dataChecked?.type !== 'ecoplant'}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScanEcoPlantStep;
