export function getBase64(img: File, callback: (_: any) => void) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getBase64Promise(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const arrayBufferToBase64 = (buffer: any) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

export const getImageSrc = (imageData: ArrayBuffer | null): string => {
  if (!imageData) return '';

  const base64 = arrayBufferToBase64(imageData);
  if (base64.startsWith('PHN2Zy')) {
    return `data:image/svg+xml;base64,${base64}`;
  }
  return `data:image/jpeg;base64,${base64}`;
};

export const allAttachmentTypes = {
  'image/png': '.png',
  'image/jpeg': '.jpeg',
  'image/x-icon': '.ico',
  'image/svg+xml': '.svg',
  'application/pdf': '.pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'text/csv': '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/msword': '.doc',
};

export const logoType = {
  'image/png': '.png',
  'image/jpeg': '.jpeg',
  'image/x-icon': '.ico',
  'image/svg+xml': '.svg',
};

export const doTypesMatch = (obj1: any, obj2: any) => {
  const keys1 = Object.keys(obj1);
  for (let key2 of obj2) {
    if (keys1.includes(key2)) {
      return true;
    }
  }
  return false;
};

export const validateAttachmentCategory = (documents: any[], type: string) => {
  if (type === 'logo') {
    const types = documents?.map((item: any) => item.type);
    return doTypesMatch(logoType, types);
  }
  return true;
};
