import React from 'react';
import { HardwareVerifyCollectionI } from '../../interfaces/hardware';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import HardwareDetailsCard from '../shared/Hardware/HardwareDetailsCard';
import { SiteI } from '../../interfaces/site';
import { useAppNotification } from '../../hooks/useAppNotification';

type Props = {
  verifiedData?: HardwareVerifyCollectionI;
  site?: SiteI;
  nextStep?: number;
  setCurrentStep: (step: number) => void;
  handleDeleteVerifiedData?: (_: any) => void;
  handleScanmoreButton: () => void;
};

const DepletionVerifyStep: React.FC<Props> = ({
  verifiedData,
  site,
  nextStep,
  setCurrentStep,
  handleDeleteVerifiedData,
  handleScanmoreButton,
}) => {
  const { openNotification } = useAppNotification();

  const continueClick = () => {
    if (verifiedData?.hardwareList?.length === 0) {
      openNotification({
        type: 'error',
        title: 'No Valid Code Scanned!',
        content: 'Please scan more to make sure you have at least 1 valid scanned hardware before proceeding.',
      });
    } else if (verifiedData?.numberOfInvalid) {
      openNotification({
        type: 'error',
        title: 'Invalid Code(s) Not Removed Yet',
        content: 'Please check and remove all invalid scans before proceeding to next step',
      });
    } else {
      setCurrentStep(nextStep ?? 3);
    }
  };
  return (
    <div className="tw-py-4 tw-px-4">
      <div className="tw-flex tw-gap-4 tw-flex-wrap">
        {verifiedData?.hardwareList?.map((item: any) => {
          return (
            <HardwareDetailsCard
              item={item}
              key={item.serialCode}
              site={site}
              handleDelete={handleDeleteVerifiedData}
            />
          );
        })}
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Scan more" onClick={handleScanmoreButton} />
          <PrimaryButton label="Continue" onClick={continueClick} />
        </div>
      </div>
    </div>
  );
};

export default DepletionVerifyStep;
