import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useBlocker } from 'react-router-dom';

const Content = (
  <div>
    <h2>Are you sure you want to leave?</h2>
    <p>Once confirm, you will lose all your current progress or scanned hardware.</p>
  </div>
);

type Props = {
  when?: boolean;
  handleCancel?: () => void;
  handleProceed?: () => void;
};

const useNavigateAway = ({ when = false, handleCancel, handleProceed }: Props) => {
  const { confirm } = Modal;
  const [currentUrl, setCurrentUrl] = useState<string>();

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return when && currentLocation.pathname !== nextLocation.pathname;
  });

  const onHandleCancel = () => {
    blocker.reset?.();
    handleCancel?.();
  };

  const onHandleOk = () => {
    blocker.proceed?.();
    handleProceed?.();
  };

  function showConfirm() {
    confirm({
      content: Content,
      onOk() {
        onHandleOk();
        blocker.reset?.();
      },
      onCancel() {
        onHandleCancel();
      },
      okText: 'Confirm',
      cancelText: 'Back',
      wrapClassName: `confirm-content-wrapper error`,
      closable: true,
    });
  }

  useEffect(() => {
    const onBeforeUnload = (event: any) => {
      if (when) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave?';
        return 'Once confirm, you will lose all your current progress or scanned hardware.';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [when]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setCurrentUrl(blocker.location.pathname);
      showConfirm();
    } else if (blocker.state === 'unblocked') {
      onHandleCancel();
    }
  }, [blocker.state]);
  return { currentUrl: currentUrl ?? undefined };
};

export default useNavigateAway;
