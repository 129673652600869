import React, { useMemo } from 'react';
import { Checkbox } from 'antd';
import ActionSection from './ActionSection';
import { ModuleI } from '../../interfaces/module';
import { RoleI } from '../../interfaces/role';
import { CheckboxOptionI } from '../../interfaces';
import { getModulePermissions } from '../../utils/global';

type Props = {
  modules?: ModuleI[];
  role?: RoleI;
};

const RoleDashboardCollapseDetail: React.FC<Props> = ({ modules, role }) => {
  const dashboardCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Dashboard')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  return (
    <ActionSection
      label="Dashboard"
      content={<Checkbox.Group options={dashboardCheckboxOptions} value={role?.colDashboard} />}
    />
  );
};

export default RoleDashboardCollapseDetail;
