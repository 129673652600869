import { Form, FormInstance, Input, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { convertToApiFile, humanizeText } from '../../../utils/global';
import { AttachmentCategories, EightAlphanumericCharactersRegex, EmailRegex, Markets } from '../../../constants';
import countryList from 'react-select-country-list';
import './Form.scss';
import { PartnerI, PartnerParamsI } from '../../../interfaces/partner';
import { UserI } from '../../../interfaces/user';
import { AttachmentI } from '../../../interfaces/attachments';
import UploadAttachmentButton from '../../buttons/UploadAttachmentButton';
import randomstring from 'randomstring';
import { PhoneNumberInput } from '../../PhoneNumberInput/PhoneNumberInput';

type Props = {
  form: FormInstance;
  partnerId?: number;
  partner?: PartnerI;
  handleSubmit(_: any): void;
};

const PartnerForm: React.FC<Props> = ({ form, partner, partnerId, handleSubmit }) => {
  const [files, setFiles] = useState<any[]>([]);
  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleOnFinish = (values: PartnerParamsI) => {
    const newValues: PartnerParamsI = values;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }

    if (partnerId) {
      newValues.adminUsername = '';
      newValues.adminEmail = '';
      newValues.adminContactNumber = '';
    }
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (partner && partner.id === partnerId) {
      let newAttachments;
      if (partner?.attachments && Object.values(partner.attachments).length > 0) {
        newAttachments = partner?.attachments.map((attachment: AttachmentI) => {
          return {
            categoryName: attachment.categoryName,
            id: attachment.id,
            name: attachment.fileName,
            type: attachment.fileType,
            url: attachment.url,
          };
        });
      }
      const adminUser = partner.users?.filter((user: UserI) => user.type === 'partnerAdmin');
      form.setFieldsValue({
        ...partner,
        adminUsername: adminUser?.[0]?.username ?? partner.users?.[0].username,
        adminEmail: adminUser?.[0]?.email ?? partner.users?.[0].email,
        adminContactNumber: adminUser?.[0]?.contactNumber ?? partner.users?.[0].contactNumber,
      });
      setFiles(newAttachments ?? []);
    }
  }, [partner]);

  return (
    <div className="partner-form-container">
      <Form
        form={form}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        layout={'vertical'}
        initialValues={{ referenceCode: randomstring.generate({ length: 8 }) }}
      >
        <div className="form-container">
          <div className="form-content">
            <div className="content-wrap">
              <h2 className="form-title">Partner Information</h2>
              <Form.Item
                label="Reference Code"
                name="referenceCode"
                rules={[
                  {
                    validator(_: any, value) {
                      if (!EightAlphanumericCharactersRegex.test(value)) {
                        return Promise.reject(new Error('This field should contain exactly 8 characters.'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item
                label="Business Legal Name"
                name="legalName"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item
                label="Business Name"
                name="displayName"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item
                label="Business Contact Number"
                name="contactNumber"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <PhoneNumberInput />
              </Form.Item>
              <Form.Item
                label="Business Email Address"
                name="email"
                rules={[
                  { type: 'email', message: 'Wrong format. Please Type in a valid email' },
                  { required: true, message: 'This field is required' },
                ]}
              >
                <Input type="email" placeholder="Type here" autoComplete="off" />
              </Form.Item>
              <Form.Item label="Market" name="market" rules={[{ required: true, message: 'This field is required' }]}>
                <Select placeholder="Choose multiple options" style={{ width: '100%' }} mode="multiple">
                  {Markets.map((type: { name: string; value: string }) => (
                    <Select.Option key={type.value} value={type.value}>
                      <span className="text-capitalize">{humanizeText(type.name)}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="attachment-list-section">
                <h2 className="form-title">Upload File</h2>
                <Form.Item name="uploadFile" style={{ width: '100%' }}>
                  <UploadAttachmentButton
                    categories={AttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="content-wrap">
              <h2 className="form-title">Administrative Contact</h2>
              <Form.Item
                label="Administrator Username"
                name="adminUsername"
                rules={[
                  {
                    required: true,
                    validator: (_: any, value: string) => {
                      if (value === '') {
                        return Promise.reject(new Error('The field is required.'));
                      }

                      if (EmailRegex.test(value)) {
                        return Promise.reject(new Error('The field cannot be an email address'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Type here" disabled={!!partnerId} />
              </Form.Item>
              <Form.Item
                label="Administrative Email"
                name="adminEmail"
                rules={[
                  { type: 'email', message: 'Invalid Email' },
                  { required: true, message: 'This field is required' },
                ]}
              >
                <Input type="email" placeholder="Type here" autoComplete="off" disabled={!!partnerId} />
              </Form.Item>
              <Form.Item
                label="Administrative Contact Number"
                name="adminContactNumber"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <PhoneNumberInput disabled={!!partnerId} />
              </Form.Item>
            </div>
            <div className="content-wrap">
              <h2 className="form-title">Location</h2>
              {/* <Form.Item label="Search Address" name="legalName">
                <Input placeholder="Type here" />
              </Form.Item> */}
              <Form.Item
                label="Address Line 1"
                name="addressLine1"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item label="Address Line 2" name="addressLine2">
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item label="Address Line 3" name="addressLine3">
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item label="Country" name="country" rules={[{ required: true, message: 'This field is required' }]}>
                <Select placeholder="Select a country" showSearch optionFilterProp="label" options={countryOptions} />
              </Form.Item>
              <Form.Item label="Region" name="region">
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item label="State/Province" name="state">
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item
                label="Postal/Zip Code"
                name="postalCode"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type here" />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PartnerForm;
