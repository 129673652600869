import React from 'react';

const UpdateStatusIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="radio_button_checked">
        <path
          id="Vector"
          d="M10.0013 1.66406C5.4013 1.66406 1.66797 5.3974 1.66797 9.9974C1.66797 14.5974 5.4013 18.3307 10.0013 18.3307C14.6013 18.3307 18.3346 14.5974 18.3346 9.9974C18.3346 5.3974 14.6013 1.66406 10.0013 1.66406ZM10.0013 16.6641C6.31797 16.6641 3.33464 13.6807 3.33464 9.9974C3.33464 6.31406 6.31797 3.33073 10.0013 3.33073C13.6846 3.33073 16.668 6.31406 16.668 9.9974C16.668 13.6807 13.6846 16.6641 10.0013 16.6641Z"
          fill="#224A13"
        />
        <path
          id="Vector_2"
          d="M9.9987 14.1693C12.2999 14.1693 14.1654 12.3038 14.1654 10.0026C14.1654 7.70142 12.2999 5.83594 9.9987 5.83594C7.69751 5.83594 5.83203 7.70142 5.83203 10.0026C5.83203 12.3038 7.69751 14.1693 9.9987 14.1693Z"
          fill="#224A13"
        />
      </g>
    </svg>
  );
};

export default UpdateStatusIcon;
