import { Checkbox } from 'antd';
import React, { Fragment } from 'react';
import ActionSection from './ActionSection';
import { RoleI } from '../../interfaces/role';
import Text from '../Text';
import { CurrentUserI } from '../../interfaces/auth';
import { useOutletContext } from 'react-router-dom';
import { handleAccessControl } from '../../utils/role';

type Props = {
  role?: RoleI;
};

const RoleGeneralCollapseDetail: React.FC<Props> = ({ role }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'roles');
  const marketOption = [
    { label: 'AMER', value: 'AMER' },
    { label: 'APAC', value: 'APAC' },
    { label: 'EMEA', value: 'EMEA' },
  ];

  return (
    <Fragment>
      <Text variant="tableMd" className="tw-text-grey-700 tw-p-3 tw-w-full tw-divide-y-2 subtitle-collapse">
        MARKETS
      </Text>
      <Checkbox.Group className="market-checkbox" options={marketOption} value={role?.colPlatform_viewable_markets} />
      {access.isCanReadPlatform && (
        <>
          <Text variant="tableMd" className="tw-text-grey-700 tw-p-3 tw-w-full subtitle-collapse">
            CONTROL ACCESS
          </Text>
          <ActionSection
            className="general-collapse"
            label="Impersonate Partner"
            content={<Checkbox.Group options={[{ label: '', value: 'C' }]} value={role?.colPlatform_impersonation} />}
          />
        </>
      )}
    </Fragment>
  );
};

export default RoleGeneralCollapseDetail;
