import React from 'react';
import { Button, Dropdown, Pagination } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AccessControlI } from '../../interfaces';
import Text from '../Text';
import { IconName } from '../Icon/iconName';
import Icon from '../Icon';
import { EmailList } from '../../interfaces/emailList';
import Divider from '../Divider';
import { EmailListStatus } from '../../pages/EmailLists/EmailListStatus';
import MoreActionIcon from '../../svgs/MoreActionIcon';

interface Props {
  access: AccessControlI;
  data: EmailList[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const EmailListsCardList: React.FC<Props> = ({ access, data, pagination, onPaginationChange }) => {
  const navigate = useNavigate();

  const renderField = (props: { icon: IconName; value: string; className?: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-start tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-600 text-xs tw-flex-none tw-min-w-4" size={12} />
        <Text variant="labelSm" className={`flex-1 !tw-font-normal ${props.className}`}>
          {props.value}
        </Text>
      </div>
    );
  };

  const handleCardClick = (item: EmailList) => {
    if (access.isCanRead) navigate(`/email-lists/${item.id}`);
  };

  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="card-list">
        {data.map((item) => {
          return (
            <button key={item.id} className="card-item" onClick={() => handleCardClick(item)}>
              <a
                className="tw-flex tw-w-full tw-justify-between tw-p-0"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Text variant="inputLabelSm" className="tw-text-grey-900">
                  Email List
                </Text>
                <Dropdown
                  menu={{
                    items: [
                      access.isCanRead
                        ? {
                            key: '1',
                            label: (
                              <Link to={`/email-lists/${item.id}`}>
                                <Button className="btn-table-action-primary" type="link">
                                  View
                                </Button>
                              </Link>
                            ),
                          }
                        : null,
                      access.isCanUpdate
                        ? {
                            key: '2',
                            label: (
                              <Link to={`/email-lists/${item.id}/edit`}>
                                <Button className="btn-table-action-primary" type="link">
                                  Edit Email List
                                </Button>
                              </Link>
                            ),
                          }
                        : null,
                    ],
                  }}
                >
                  <div>
                    <MoreActionIcon />
                  </div>
                </Dropdown>
              </a>
              <div className="tw-flex tw-justify-between tw-flex-col tw-w-full tw-h-full tw-gap-4">
                <div className="tw-flex tw-flex-col tw-gap-4">
                  <div className="tw-flex tw-flex-col tw-gap">
                    <Text variant="bodyLgB" className="tw-text-primary-800">
                      {item.name}
                    </Text>
                  </div>
                  {renderField({
                    icon: 'icon-person',
                    value: `${item?._count?.emailListUsers ?? 0} receipient(s)`,
                    className: 'truncate-2-lines',
                  })}
                </div>
                <div>
                  <Divider />
                  <div className="tw-pt-3">
                    <EmailListStatus status={item.status} />
                  </div>
                </div>
              </div>
            </button>
          );
        })}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        showSizeChanger
      />
    </section>
  );
};

export default EmailListsCardList;
