import React from 'react';
import { Link } from 'react-router-dom';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import './SuccessSubmittedItem.scss';
import Text from '../Text';

type Props = {
  url?: string;
  title: string;
  description: string[];
  onClick?: () => void;
};

const SuccessSubmittedItem: React.FC<Props> = ({ url, description, title, onClick }) => {
  return url ? (
    <Link to={url} className="shared-success-submitted-item">
      <div className="wrap">
        <div className="tw-flex tw-flex-col">
          <Text variant="buttonMd" className="tw-mt-0 tw-mb-1 tw-text-primary-900">
            {title}
          </Text>
          {description.map((item: string, index: number) => (
            <Text key={index} variant="capt1" component="p">
              {item}
            </Text>
          ))}
        </div>
        <div className="tw-h-[20px]">
          <RightArrowIcon color="#323232" />
        </div>
      </div>
    </Link>
  ) : (
    <button onClick={onClick} className="shared-success-submitted-item">
      <div className="wrap">
        <div className="tw-flex tw-flex-col">
          <Text variant="buttonMd" className="tw-mt-0 tw-mb-1 tw-text-primary-900">
            {title}
          </Text>
          {description.map((item: string, index: number) => (
            <Text key={index} variant="capt1" component="p">
              {item}
            </Text>
          ))}
        </div>
        <div className="tw-h-[20px]">
          <RightArrowIcon color="#323232" />
        </div>
      </div>
    </button>
  );
};

export default SuccessSubmittedItem;
