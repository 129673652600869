import { Button } from 'antd';
import React from 'react';
import './IconButton.scss';

type Props = {
  icon?: any;
  htmlType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  style?: any;
  color?: 'primary-color' | 'secondary-color';
  className?: string;
  target?: string;
  href?: string;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  onClick?: (_: any) => void;
};

const IconButton: React.FC<Props> = ({
  icon,
  htmlType,
  loading,
  style,
  color,
  className,
  target,
  href,
  disabled,
  size,
  onClick,
  ...props
}) => {
  return (
    <Button
      type="primary"
      className={`btn-primary-icon ${color} ${className} ${size} !tw-mb-0`}
      htmlType={htmlType}
      loading={loading}
      style={style}
      onClick={onClick}
      target={target}
      href={href}
      disabled={disabled}
      {...props}
    >
      {icon}
    </Button>
  );
};

export default IconButton;
