import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { SiteI, SiteStatusI } from '../../interfaces/site';
import { handleDisplayAddress } from '../../utils/global';
import { PartnerStatus } from '../../pages/Partners/PartnerStatus';
import { PartnerI } from '../../interfaces/partner';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const SitesTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'SITE NAME',
      width: 150,
      dataIndex: 'displayName',
      ellipsis: true,
      render: (displayName: string) => {
        return <span>{displayName}</span>;
      },
    },
    {
      title: 'PARTNER ENTITY',
      width: 150,
      dataIndex: 'partner',
      ellipsis: true,
      render: (partner: PartnerI) => {
        return <span>{partner?.legalName}</span>;
      },
    },
    {
      title: 'market',
      dataIndex: 'market',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Address',
      width: 300,
      ellipsis: true,
      render: (record: SiteI) => {
        return <span>{handleDisplayAddress(record)}</span>;
      },
    },
    {
      title: 'Type Site',
      dataIndex: 'type',
      width: 100,
      ellipsis: true,
      render: (type: string) => {
        return <span className="text-capitalize">{type}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      ellipsis: true,
      render: (status: SiteStatusI) => {
        return <PartnerStatus status={status} />;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default SitesTable;
