import { Button, Dropdown } from 'antd';
import React from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import { handleFilterParams } from '../../utils/global';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import SizeBox from '../../components/SizeBox';
import EmptyData from '../../components/EmptyData';
import { ConfigReportSearchParamsI, ConfigReportsI } from '../../interfaces/configuration';
import ReportFilterForm from '../../components/forms/configuration/ReportFilter';
import ConfigReportCardList from '../../components/card-list/ConfigReports';
import ConfigReportsTable from '../../components/tables/ConfigReports';

type Props = {
  queryParams: ConfigReportSearchParamsI & { viewMode?: string };
  reports?: ConfigReportsI[];
  total?: number;
  isLoading: boolean;
  refetchData: () => void;
};

const AppliedConfigsTab: React.FC<Props> = ({ queryParams, reports, total, isLoading, refetchData }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'configurations');
  const navigate = useNavigate();

  const currentPage = queryParams?.page;

  const pagination: any = {
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    total: total,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const setQueryParams = (params: Partial<ConfigReportSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: ConfigReportSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/configurations/reports/${record?.code}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanUpdate
                ? {
                    key: '2',
                    label: (
                      <Link to={`/configurations/reports/${record?.code}/edit`}>
                        <Button className="btn-table-action-primary" type="link">
                          Edit
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <ReportFilterForm queryParams={queryParams} onChange={handleFilterChange} refetchData={refetchData} />
      {!isLoading && reports?.length === 0 && (
        <EmptyData
          title="No Product Group"
          message="All product group will be displayed here."
          button={{ title: 'Create Group', url: '/product-groups/new' }}
        />
      )}
      {!isLoading && reports?.length !== 0 && (
        <div>
          <DisplayLastUpdateApp />
          <SizeBox height={8} />
          {!queryParams.viewMode ? (
            <ConfigReportCardList
              access={access}
              data={reports ?? []}
              pagination={pagination}
              onPaginationChange={handleFilter}
            />
          ) : (
            <ConfigReportsTable
              rowKey="id"
              loading={isLoading}
              pagination={pagination}
              dataSource={reports}
              withAction={withAction}
              onChange2={handleFilter}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AppliedConfigsTab;
