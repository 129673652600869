import React from 'react';
import { HardwareI } from '../../interfaces/hardware';
import EcoPlantIcon from '../../svgs/hardwareTypes/EcoPlantIcon';
import HardwareTypeComponent from '../HardwareType';
import { CommonStatus } from '../CommonStatus';
import Text from '../Text';
import Icon from '../Icon';
import { DateFormat } from '../../utils/global';

type Props = {
  item?: HardwareI;
};

const EcoPlantCardItem: React.FC<Props> = ({ item }) => {
  return (
    <div className="scan-screen-data">
      <p className="label">1 ecoPLANT scanned:</p>
      <div className="card-item">
        <HardwareTypeComponent icon={<EcoPlantIcon width={16} height={16} />} text="ecoPLANT" />
        <Text variant="bodyMdB" className="tw-text-primary-900 tw-mb-3 tw-mt-1">{`${item?.serialCode}`}</Text>
        <Text variant="capt1" className="tw-flex tw-items-center tw-gap-[5px] tw-mb-3">
          <Icon name="icon-location_on" size={12} className=" tw-text-grey-700" />
          <span>{item?.site?.legalName}</span>
        </Text>
        <Text variant="capt1" className="tw-flex tw-items-center tw-gap-[5px] tw-mb-3">
          <Icon name="icon-date_range" size={12} className=" tw-text-grey-700" />
          <span>{item?.ecoPlant?.lastFilledDate ? DateFormat(item?.ecoPlant?.lastFilledDate) : ''}</span>
        </Text>
        <p className="status-section">
          <CommonStatus status={item?.status ?? ''} />
          <CommonStatus status={item?.condition ?? ''} />
        </p>
      </div>
    </div>
  );
};

export default EcoPlantCardItem;
