import { Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProduct } from '../../apis/products';
import ProductInformation from '../../components/Product/Information';
import ProductTopSection from '../../components/Product/TopSection';
import ProductResources from '../../components/Product/Resources';

const ProductDetailsPage: React.FC = () => {
  const { sku } = useParams<{ sku?: string }>();
  const { data: product, isLoading, error } = useGetProduct(sku);
  const navigate = useNavigate();

  if (error === 'Product not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <ProductTopSection product={product} />
      <ProductInformation product={product} />
      <ProductResources data={product} id={sku} />
    </Spin>
  );
};

export default ProductDetailsPage;
