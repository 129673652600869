import React from 'react';
type Props = {
  width?: number;
  height?: number;
};

const StickIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 16}px`}
      viewBox="0 0 16 16"
      width={`${width ?? 16}px`}
      fill="none"
    >
      <g id="check">
        <path
          id="Vector"
          d="M3.33301 8.66602L5.99967 11.3327L12.6663 4.66602"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default StickIcon;
