import { Form, message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import PartnerForm from '../../components/forms/partner/Form';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';
import { getPartnerProfile, updatePartnerProfile } from '../../apis/profile';

const PartnerUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useQuery(['partner-profile', 'detail', {}], () => getPartnerProfile());

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(updatePartnerProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partner-profile', 'detail'],
      });
      message.info('Update Partner profile successfully!');
      navigate(-1);
    },
    onError: () => {
      message.error('Update partner profile failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading || isPageLoading}>
      <div className="partner-profile-container">
        <CreateUpdateTopSection label="Edit Partner Profile" labelButton="Update" form={form} />
        <PartnerForm form={form} partner={data} partnerId={data?.id} handleSubmit={handleSubmit} />
      </div>
    </Spin>
  );
};

export default PartnerUpdatePage;
