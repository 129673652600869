import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const EcoPlantIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 24}px`}
      viewBox="0 0 24 24"
      width={`${width ?? 24}px`}
      fill="none"
    >
      <g id="Hardwares type">
        <g id="Vector">
          <path
            d="M3.6875 5.25C3.44588 5.25 3.25 5.44775 3.25 5.69168V18.0583C3.25 18.3022 3.44588 18.5 3.6875 18.5H20.3125C20.5541 18.5 20.75 18.3022 20.75 18.0583V5.69168C20.75 5.44775 20.5541 5.25 20.3125 5.25H3.6875ZM9.59375 9.8875H14.4062C14.5271 9.8875 14.625 9.98637 14.625 10.1083V16.7333H9.375V10.1083C9.375 9.98637 9.47295 9.8875 9.59375 9.8875ZM5 7.01667H5.875V16.7333H5V7.01667ZM8.5 7.01667V16.7333H7.625V7.01667H8.5ZM15.5 7.01667H16.375V16.7333H15.5V7.01667ZM19 7.01667V16.7333H18.125V7.01667H19Z"
            fill={color ?? 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
};
export default EcoPlantIcon;
