import React from 'react';

type Props = {
  width?: number | string;
  height?: number | string;
  color?: string;
};

const LotNumberIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      width={width ?? '12'}
      height={height ?? '12'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.68533 0L8.98133 3.97364e-08C9.34136 0.000113448 9.69354 0.106227 9.99364 0.305104C10.2937 0.503953 10.5286 0.786738 10.669 1.11819C11.3316 2.68123 11.6667 4.25403 11.6667 5.83333C11.6667 7.41264 11.3316 8.98544 10.669 10.5485C10.669 10.5485 10.669 10.5484 10.669 10.5485C10.5286 10.8799 10.2937 11.1627 9.99364 11.3616C9.69354 11.5604 9.34151 11.6666 8.98149 11.6667H2.68533C2.32531 11.6666 1.97313 11.5604 1.67302 11.3616C1.37295 11.1627 1.13807 10.8799 0.997651 10.5485C0.997632 10.5484 0.997671 10.5485 0.997651 10.5485C0.335091 8.98544 0 7.41264 0 5.83333C0 4.25408 0.335071 2.68132 0.997592 1.11833C1.13801 0.786878 1.37295 0.503953 1.67302 0.305104C1.97313 0.106227 2.32531 0.000113368 2.68533 0ZM8.98118 1H2.68549C2.68544 1 2.68554 1 2.68549 1C2.5219 1.00008 2.36179 1.04831 2.22542 1.13868C2.08901 1.22908 1.98223 1.35764 1.91841 1.50833C1.30363 2.95863 1 4.3993 1 5.83333C1 7.26736 1.30358 8.7079 1.91835 10.1582C1.98217 10.3089 2.08901 10.4376 2.22542 10.528C2.36179 10.6184 2.52174 10.6666 2.68533 10.6667M8.98118 1C9.14477 1.00008 9.30488 1.04831 9.44125 1.13868C9.57766 1.22908 9.68444 1.35764 9.74826 1.50833C10.363 2.95863 10.6667 4.3993 10.6667 5.83333C10.6667 7.26736 10.3631 8.7079 9.74832 10.1582C9.6845 10.3089 9.57766 10.4376 9.44125 10.528C9.30488 10.6184 9.14493 10.6666 8.98133 10.6667C8.98128 10.6667 8.98139 10.6667 8.98133 10.6667H2.68533"
          fill={color ?? 'currentColor'}
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.62196 0.0150578C4.88985 0.0820557 5.05271 0.353537 4.98571 0.621429C4.8151 1.30362 4.67807 1.98536 4.57453 2.66679H7.09344C6.9899 1.98536 6.85287 1.30362 6.68226 0.621429C6.61526 0.353537 6.77812 0.0820557 7.04601 0.0150578C7.3139 -0.0519402 7.58538 0.110916 7.65238 0.378808C7.84304 1.14118 7.99373 1.90383 8.1044 2.66679H10.834C11.1101 2.66679 11.334 2.89064 11.334 3.16679C11.334 3.44293 11.1101 3.66679 10.834 3.66679H8.22654C8.29816 4.38871 8.33398 5.11092 8.33398 5.83345C8.33398 6.55599 8.29816 7.2782 8.22654 8.00012H10.834C11.1101 8.00012 11.334 8.22398 11.334 8.50012C11.334 8.77626 11.1101 9.00012 10.834 9.00012H8.1044C7.99373 9.76308 7.84304 10.5257 7.65238 11.2881C7.58538 11.556 7.3139 11.7188 7.04601 11.6518C6.77812 11.5848 6.61526 11.3134 6.68226 11.0455C6.85287 10.3633 6.9899 9.68155 7.09344 9.00012H4.57453C4.67807 9.68155 4.8151 10.3633 4.98571 11.0455C5.05271 11.3134 4.88985 11.5848 4.62196 11.6518C4.35407 11.7188 4.08259 11.556 4.01559 11.2881C3.82493 10.5257 3.67424 9.76308 3.56357 9.00012H0.833984C0.557842 9.00012 0.333984 8.77626 0.333984 8.50012C0.333984 8.22398 0.557842 8.00012 0.833984 8.00012H3.44143C3.36981 7.2782 3.33398 6.55599 3.33398 5.83345C3.33398 5.11092 3.36981 4.38871 3.44143 3.66679H0.833984C0.557842 3.66679 0.333984 3.44293 0.333984 3.16679C0.333984 2.89064 0.557842 2.66679 0.833984 2.66679H3.56357C3.67424 1.90383 3.82493 1.14118 4.01559 0.378808C4.08259 0.110916 4.35407 -0.0519402 4.62196 0.0150578ZM4.44657 3.66679C4.37147 4.38923 4.33398 5.1114 4.33398 5.83345C4.33398 6.5555 4.37147 7.27767 4.44657 8.00012H7.2214C7.29649 7.27767 7.33398 6.5555 7.33398 5.83345C7.33398 5.1114 7.29649 4.38923 7.2214 3.66679H4.44657Z"
          fill={color ?? 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default LotNumberIcon;
