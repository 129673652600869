import React, { ReactNode } from 'react';
import './ItemCard.scss';
import { CommonStatus } from '../CommonStatus';
import DangerButton from '../buttons/DangerButton';
import TrashIcon from '../../svgs/TrashIcon';
import { ProductGroupVerifyItemI } from '../../interfaces/product';
import Text from '../Text';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';
import { getProductCategory } from '../../utils/global';

type Props = {
  item: ProductGroupVerifyItemI;
  canDelete?: boolean;
  handleDeleteItem?: (_: any) => void;
};

const ProductGroupItemCard: React.FC<Props> = ({ item, canDelete = true, handleDeleteItem }) => {
  const onDeleteClick = () => {
    handleDeleteItem?.(item);
  };

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <div className="component-product-group-item-card tw-min-h-[200px]">
      <div className="tw-flex tw-flex-col tw-justify-between tw-h-full tw-py-4 tw-px-4">
        {item.error ? (
          <div className="tw-h-full tw-justify-center tw-flex-col tw-flex">
            <Text variant="bodyLgB" className="tw-text-primary-800 tw-mb-3">
              {item?.sku}
            </Text>
            <CommonStatus status="inactive" text={item.error ?? 'Invalid'} />
          </div>
        ) : (
          <>
            <Text variant="inputLabelMd">{item?.currentData.brandName}</Text>
            <div className="tw-flex tw-flex-col tw-w-full">
              <Text variant="bodyLgB" className="tw-text-primary-800 tw-mb-3 tw-mt-1">
                {item?.currentData.name}
              </Text>
              <div className="tw-flex tw-flex-col tw-gap-y-2 tw-w-full">
                {renderField({ icon: 'icon-invert_colors', value: `${item?.currentData.abv ?? 0}% (ABV)` })}
                {renderField({ icon: 'icon-receipt', value: item?.currentData.sku ?? '' })}
                {renderField({
                  icon: 'icon-wine_bar',
                  value: (
                    <span className="text-capitalize">
                      {getProductCategory(item?.currentData?.category ?? undefined)}
                    </span>
                  ),
                })}
              </div>
            </div>
            <span className="tw-mt-3">
              {item?.currentData && <CommonStatus status={item?.currentData?.status ?? ''} />}
            </span>
          </>
        )}
      </div>
      <div className="report-delete-section">
        <div></div>
        {canDelete && <DangerButton icon={<TrashIcon width={12} height={12} />} onOk={onDeleteClick} />}
      </div>
    </div>
  );
};

export default ProductGroupItemCard;
