import React from 'react';

const DashboardPage: React.FC = () => {
  return (
    <div id="page-dashboard-container">
      <div className="content-wrap">You can see this page in full action the upcoming version.</div>
    </div>
  );
};

export default DashboardPage;
