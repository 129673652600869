import React, { useState } from 'react';
import useImpersonation from './useImpersonation';
import Text from '../Text';
import WarningIcon from '../../svgs/WarningIcon';
import { Button, Modal } from 'antd';
import Icon from '../Icon';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

export default function ImpersontaionStatus() {
  const { isImpersonating, signOut, isLoading } = useImpersonation();
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  if (!isImpersonating) return null;

  return (
    <>
      <div className="tw-w-full tw-px-8 tw-py-4 tw-flex tw-flex-row tw-bg-warning-200 tw-items-center tw-gap-4 tw-sticky tw-top-0 tw-z-50">
        <WarningIcon />
        <Text variant="bodyLg">You’re in Impersonation Mode.</Text>
        <Button
          icon={<Icon name="icon-exit_to_app" />}
          className="tw-flex tw-items-center"
          onClick={() => setIsConfirm(true)}
        >
          Exit
        </Button>
      </div>

      <Modal confirmLoading={isLoading} open={isConfirm} onCancel={() => setIsConfirm(false)} footer={null} width={412}>
        <div className="tw-flex tw-flex-col tw-p-4 tw-gap-8">
          <Icon name="icon-people_alt" className="tw-text-primary-800 tw-m-auto" />
          <Text variant="h3" className="tw-text-primary-800">
            Are you sure you want to exit Impersonation Mode and switch back to your main account?
          </Text>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <PrimaryButton
              className="tw-w-full tw-mb-3"
              label="Confirm"
              onClick={() => {
                signOut();
              }}
            />
            <SecondaryButton className="tw-w-full" label="Cancel" onClick={() => setIsConfirm(false)} />
          </div>
        </div>
      </Modal>
    </>
  );
}
