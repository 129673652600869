import React, { useState } from 'react';
import {
  HardwareItemI,
  HardwareScannedStateI,
  HardwareTypeI,
  HardwareVerifyCollectionI,
  HardwareVerifyTypeI,
} from '../../interfaces/hardware';
import './New.scss';
import AllocationScanStep from '../../components/HardwareAllocation/ScanStep';
import HardwareAllocationVerifyStep from '../../components/HardwareAllocation/AllocationVerifyStep';
import HardwareAllocationReviewStep from '../../components/HardwareAllocation/AllocationReviewStep';
import { doVerifyHardware } from '../../apis/hardwares';
import HardwareVerifyFilterForm from '../../components/forms/hardware/VerifyFilter';
import { HardwareProgressStepListI } from '../../interfaces';
import SharedHardwareProgressBar from '../../components/shared/Hardware/ProgressBar';
import useNavigateAway from '../../hooks/useNavigateAway';
import HardwareW2WGroupingPage from '../../components/shared/Hardware/W2WGrouping';
import { handleRemoveVerifiedData } from '../../utils/hardware';
import HardwareW2WDepletePage from '../../components/shared/Hardware/W2WDeplete';
import { useNavigate } from 'react-router-dom';
import { SiteI } from '../../interfaces/site';
import HardwareChooseSiteStep from '../../components/shared/Hardware/ChooseSiteStep';
import { useAppNotification } from '../../hooks/useAppNotification';
import Text from '../../components/Text';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'Site',
  },
  {
    step: 2,
    label: 'Scan',
  },
  {
    step: 3,
    label: 'Verify',
  },
  {
    step: 4,
    label: 'Review',
  },
];

const HardwareAllocationReceivePage: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [scannedData, setScannedData] = useState<HardwareScannedStateI[]>([]);
  const [registerVerifiedData, setRegisterVerifiedData] = useState<HardwareVerifyCollectionI>();
  const [filtered, setFiltered] = useState<HardwareVerifyCollectionI>();
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [chosenType, setChosenType] = useState<HardwareTypeI>();
  const [successData, setSuccessData] = useState();
  const [finalScannedData, setFinalScannedData] = useState<HardwareScannedStateI[]>([]);
  const [site, setSite] = useState<SiteI | undefined>();
  const { openNotification } = useAppNotification();

  useNavigateAway({
    when: finalScannedData.length > 0 || scannedData.length > 0,
    handleProceed() {
      setScannedData([]);
      setFinalScannedData([]);
      setStopDecodingScanner(true);
      setSite(undefined);
    },
  });

  const handleVerify = async () => {
    try {
      const finalData = [...finalScannedData, ...scannedData];
      const params = {
        hardwareList: finalData,
        verifyType: 'allocationReceive',
        siteId: site?.id,
      } as {
        hardwareList: HardwareScannedStateI[];
        verifyType: HardwareVerifyTypeI;
        siteId: number;
      };
      setFinalScannedData(finalData);
      setScannedData([]);
      const data = await doVerifyHardware(params);
      if (data) {
        openNotification({
          type: data?.numberOfInvalid ? 'warning' : 'success',
          title: (
            <div>
              <span>You have scanned {} Code(s) that includes:</span>
              <ul className="tw-my-1 tw-px-6 tw-pl-[25px]">
                <li>{data.numberOfHardwareScanned} hardware</li>
                {data?.numberOfInvalid ? <li>{data.numberOfInvalid} invalid code(s)</li> : ''}
              </ul>
            </div>
          ),
          content: data?.numberOfInvalid ? (
            <Text variant="bodyMd">Please remove invalid code(s) before proceeding to next step!</Text>
          ) : (
            ''
          ),
        });
      }
      setRegisterVerifiedData(data);
      setCurrentStep(3);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyFilter = (values: { serialCode: HardwareScannedStateI['serialCode'] }) => {
    if (values?.serialCode) {
      const hardwareList = registerVerifiedData?.hardwareList?.filter(
        (item: HardwareScannedStateI) => item.serialCode === values.serialCode,
      );
      const newData: any = { ...registerVerifiedData };
      newData.hardwareList = hardwareList;
      setFiltered(newData);
    } else {
      setFiltered(undefined);
    }
  };

  const handleDeleteVerifiedData = (item: HardwareItemI) => {
    const result = handleRemoveVerifiedData(registerVerifiedData, item.serialCode);
    setRegisterVerifiedData(result);
    setFinalScannedData((prevItems) => {
      const index = finalScannedData.findIndex((data) => data.serialCode === item.serialCode);
      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }
      return prevItems;
    });
  };

  const handleScanmoreButton = () => {
    setCurrentStep(2);
    setStopDecodingScanner(false);
  };

  const handleDiscard = () => {
    setCurrentStep(1);
    setScannedData([]);
    setFinalScannedData?.([]);
    setSite(undefined);
    setStopDecodingScanner(false);
  };

  const backFunction = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
    if (currentStep === 1) navigate(-1);
  };

  return (
    <div id="pages-hardware-allocations-new-container">
      {currentStep !== 6 && currentStep !== 5 && (
        <SharedHardwareProgressBar
          currentStep={currentStep}
          title="Receive Allocation Report"
          stepList={progressStepList}
          backFunction={backFunction}
        />
      )}
      {currentStep === 3 && (
        <HardwareVerifyFilterForm
          handleVerifyFilter={handleVerifyFilter}
          numberOfHardwareScanned={registerVerifiedData?.numberOfHardwareScanned}
          numberOfInvalid={registerVerifiedData?.numberOfInvalid}
        />
      )}
      {currentStep === 1 && (
        <HardwareChooseSiteStep setCurrentStep={setCurrentStep} nextStep={2} setSite={setSite} siteId={site?.id} />
      )}
      {currentStep === 2 && (
        <AllocationScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setScannedData}
          scannedData={scannedData}
          handleVerify={handleVerify}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          finalScannedData={finalScannedData}
          handleDiscard={handleDiscard}
          pageName="Allocation Receive"
        />
      )}
      {currentStep === 3 && (
        <HardwareAllocationVerifyStep
          setCurrentStep={setCurrentStep}
          registerVerifiedData={filtered ?? registerVerifiedData}
          handleDeleteVerifiedData={handleDeleteVerifiedData}
          handleScanmoreButton={handleScanmoreButton}
          site={site}
        />
      )}
      {currentStep === 4 && (
        <HardwareAllocationReviewStep
          setCurrentStep={setCurrentStep}
          registerVerifiedData={registerVerifiedData}
          allocationType="receive"
          setScannedData={setScannedData}
          setChosenType={setChosenType}
          setSuccessData={setSuccessData}
          successData={successData}
          setFinalScannedData={setFinalScannedData}
          site={site}
        />
      )}
      {currentStep === 5 && (
        <HardwareW2WGroupingPage
          siteId={site?.id}
          registerType={chosenType}
          successData={successData}
          backTo="/hardware-allocations"
          site={site}
        />
      )}
      {currentStep === 6 && (
        <HardwareW2WDepletePage site={site} successData={successData} backTo="/hardware-allocations" />
      )}
    </div>
  );
};

export default HardwareAllocationReceivePage;
