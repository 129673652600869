import { Checkbox, Form } from 'antd';
import React, { useEffect } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import Text from '../../Text';
import { ConfigurationSearchParamsI } from '../../../interfaces/configuration';
import SelectAllCheckbox from '../../SelectAllCheckbox';
import { convertToHardwareType } from '../../../utils/hardware';
import { ConfigurationUnits } from '../../../constants/configuration';

type FormParams = {
  hardwareType: string[];
  displayUnit: string[];
};

type Props = {
  queryParams: ConfigurationSearchParamsI;
  onChange(newParams: Partial<ConfigurationSearchParamsI>): void;
};

const PresetFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();
  const typeOptions = ['ecoplant', 'ecotote', 'smartpour'];

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: { hardwareType: values?.hardwareType, displayUnit: values?.displayUnit },
    });
  };

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  useEffect(() => {
    form.setFieldsValue({
      hardwareType: queryParams.filter?.hardwareType,
      displayUnit: queryParams.filter?.displayUnit,
    });
  }, [queryParams]);

  return (
    <div className="partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Form.Item name="hardwareType">
              <SelectAllCheckbox
                form={form}
                plainOptions={typeOptions}
                name="hardwareType"
                sectionTitle="Hardware Type"
              >
                {typeOptions.map((item) => (
                  <Checkbox key={item} value={item}>
                    {convertToHardwareType(item)}
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
            <Form.Item name="displayUnit">
              <SelectAllCheckbox form={form} plainOptions={typeOptions} name="displayUnit" sectionTitle="Display Unit">
                {ConfigurationUnits.map((item: { name: string; value: string }) => (
                  <Checkbox key={item.value} value={item.value}>
                    {item.name}
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PresetFilterContent;
