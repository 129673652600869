import { BaseResponse } from '../interfaces';
import { HardwareDepletionCollectionI, HardwareDepletionI } from '../interfaces/hardwareDepletion';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetDepletionHistory = (uuid?: string) => {
  const { data: response, error, isLoading } = useFetch<{ data: HardwareDepletionI }>(`depletion-histories/${uuid}`);
  const data = response?.data;
  return { data, error, isLoading };
};

export const getDepletionHistoriesList = async (params?: any) => {
  const { data: response } = await api.get<BaseResponse<HardwareDepletionCollectionI>>(
    `depletion-histories?${handleFilterParams(params)}`,
  );
  return response;
};
