import { Modal } from 'antd';
import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  isVisible: boolean;
  width?: number;
  handleCancel: () => void;
}

const NonClosableModal: React.FC<Props> = ({ children, isVisible, width = 412, handleCancel }) => {
  return (
    <Modal
      open={isVisible}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}
      width={width}
      maskClosable={false}
      closeIcon={null}
      closable={false}
      keyboard={false}
    >
      {children}
    </Modal>
  );
};

export default NonClosableModal;
