import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../utils/global';
import { ConfigurationI } from '../../interfaces/configuration';
import Text from '../Text';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';
import Icon from '../Icon';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const PresetsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Configuration',
      width: 150,
      ellipsis: true,
      render: (record: ConfigurationI) => {
        return (
          <span className="tw-flex tw-items-center tw-gap-x-2">
            <span>{`${record?.name}`}</span>
            {record?.remarks !== null && <Icon name="icon-text_snippet" size={16} />}
          </span>
        );
      },
    },
    {
      title: 'config id',
      dataIndex: 'code',
      width: 150,
      ellipsis: true,
      render: (code: string) => {
        return <span>{code}</span>;
      },
    },
    {
      title: 'hardware Type',
      width: 150,
      ellipsis: true,
      render: (item: ConfigurationI) => {
        return (
          <span className="tw-flex tw-items-center tw-gap-0.5">
            {handleDisplayIconByTypeFromApi(item?.hardwareType, { width: 16, height: 16, color: '#191919' })}
            <Text variant="labelMd" className="flex-1">
              {getTypeFromApi(item)}
            </Text>
          </span>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default PresetsTable;
