import { Form } from 'antd';
import React from 'react';
import EInputNumber from '../../inputs/EInputNumber';

type Props = {
  isAmericanOunce?: boolean;
};

const AmericanOuncesUnitForm: React.FC<Props> = ({ isAmericanOunce = false }) => {
  const validateDecimal = (value: string) => {
    const regex = /^\d+(\.0|\.25|\.5|\.75)?$/;
    if (value && !regex.test(value.toString())) {
      return Promise.reject(new Error('The decimal value must be either 0, .25, .5 or .75'));
    }
    return Promise.resolve();
  };
  return (
    <>
      <div className="flex-row">
        <Form.Item
          label="Button 1"
          name={[isAmericanOunce ? 'ozUS' : 'ozUK', 'button1']}
          extra={`Value range: 0.50 - 3.25`}
          rules={[{ validator: (_: any, value: string) => validateDecimal(value) }]}
        >
          <EInputNumber min={0.5} max={3.25} step={0.25} suffix={isAmericanOunce ? 'Fl oz' : 'oz'} />
        </Form.Item>
        <Form.Item
          label="Button 2"
          name={[isAmericanOunce ? 'ozUS' : 'ozUK', 'button2']}
          extra={`Value range: 0.50 - 3.25`}
          rules={[{ validator: (_: any, value: string) => validateDecimal(value) }]}
        >
          <EInputNumber min={0.5} max={3.25} step={0.25} suffix={isAmericanOunce ? 'Fl oz' : 'oz'} />
        </Form.Item>
      </div>
      <div className="flex-row">
        <Form.Item
          label="Button 3"
          name={[isAmericanOunce ? 'ozUS' : 'ozUK', 'button3']}
          extra={`Value range: 0.50 - 3.25`}
          rules={[{ validator: (_: any, value: string) => validateDecimal(value) }]}
        >
          <EInputNumber min={0.5} max={3.25} step={0.25} suffix={isAmericanOunce ? 'Fl oz' : 'oz'} />
        </Form.Item>
        <div></div>
      </div>
    </>
  );
};

export default AmericanOuncesUnitForm;
