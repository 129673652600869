import React from 'react';
import './HardwareAndAttachment.scss';
import { Tabs, TabsProps } from 'antd';
import AllInboxIcon from '../../svgs/AllInboxIcon';
import PaperClipIcon from '../../svgs/PaperClipIcon';
import { DepletionHardwareScannedComponent } from './HardwareScanned';
import { HardwareDepletionI } from '../../interfaces/hardwareDepletion';
import DepletionHistoryAttachments from './Attachments';

type Props = {
  data?: HardwareDepletionI;
};

const DepletionHardwareAndAttachmentComponent: React.FC<Props> = ({ data }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span className="tab-title">
          <AllInboxIcon width={20} height={20} /> <span>Hardware</span>
        </span>
      ),
      children: <DepletionHardwareScannedComponent data={data} />,
    },
    {
      key: '2',
      label: (
        <span className="tab-title">
          <PaperClipIcon /> <span>Attachment</span>
        </span>
      ),
      children: <DepletionHistoryAttachments attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="registraton-hardware-attachment-container details-container tw-mt-6" style={{ marginBottom: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default DepletionHardwareAndAttachmentComponent;
