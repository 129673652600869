import React from 'react';
import Icon from '../Icon';
import Text from '../Text';

type Props = {
  status: string | null;
};

const NetworkStatus: React.FC<Props> = ({ status = null }) => {
  return (
    <span
      className={`tw-flex tw-items-center tw-gap-1 ${status === 'online' ? 'tw-text-success-500' : 'tw-text-grey-700'}`}
    >
      <Icon name="icon-wifi" size={16} />
      <Text variant="bodyMdB" className="tw-capitalize">
        {' '}
        {status !== null ? status : 'Not Available'}
      </Text>
    </span>
  );
};

export default NetworkStatus;
