import { Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductInformation from '../../../components/Product/Information';
import { useGetPlatformProduct } from '../../../apis/platforms/products';
import PlatformProductTopSection from '../../../components/Platforms/Product/TopSection';
import ProductResources from '../../../components/Product/Resources';

const PlatformProductDetailsPage: React.FC = () => {
  const { sku } = useParams<{ sku: string }>();
  const { data: product, isLoading, error } = useGetPlatformProduct(sku);
  const navigate = useNavigate();

  if (error === 'Product not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <PlatformProductTopSection product={product} />
      <ProductInformation product={product} />
      <ProductResources data={product} id={sku} isPlatform={true} />
    </Spin>
  );
};

export default PlatformProductDetailsPage;
