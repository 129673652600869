import React, { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  text: string;
};

const HardwareTypeComponent: React.FC<Props> = ({ icon, text }) => {
  return (
    <p id="hardware-type-component">
      {icon} <span>{text}</span>
    </p>
  );
};

export default HardwareTypeComponent;
