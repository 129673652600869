import { Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSite } from '../../apis/sites';
import SiteInformation from '../../components/Site/Information';
import SiteName from '../../components/Site/Name';
import SiteResources from '../../components/Site/Resources';

const SiteDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: site, isLoading, error } = useGetSite(Number(id));
  const navigate = useNavigate();

  if (error === 'Partner not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <div id="site-details-container">
        <SiteName site={site} />
        <SiteInformation site={site} />
        <SiteResources data={site} />
      </div>
    </Spin>
  );
};

export default SiteDetailsPage;
