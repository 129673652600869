import React from 'react';

const EditIcon: React.FC = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="edit">
        <path
          id="Icon"
          d="M9.41536 3.33064H6.2487C5.31528 3.33064 4.84857 3.33064 4.49205 3.5123C4.17844 3.67208 3.92348 3.92705 3.76369 4.24066C3.58203 4.59718 3.58203 5.06389 3.58203 5.99731V13.9973C3.58203 14.9307 3.58203 15.3974 3.76369 15.754C3.92348 16.0676 4.17844 16.3225 4.49205 16.4823C4.84857 16.664 5.31528 16.664 6.2487 16.664H14.2487C15.1821 16.664 15.6488 16.664 16.0053 16.4823C16.319 16.3225 16.5739 16.0676 16.7337 15.754C16.9154 15.3974 16.9154 14.9307 16.9154 13.9973V10.414M13.1654 4.58064L15.5224 6.93766M9.21765 8.52835L14.7578 2.98816C15.4087 2.33728 16.464 2.33728 17.1149 2.98815C17.7657 3.63903 17.7657 4.6943 17.1149 5.34518L11.3966 11.0635C10.7619 11.6982 10.4445 12.0155 10.083 12.2679C9.76218 12.4919 9.41614 12.6775 9.05201 12.8208C8.64178 12.9823 8.20185 13.0711 7.32197 13.2486L6.91537 13.3306L6.95491 13.0538C7.09483 12.0744 7.1648 11.5846 7.32394 11.1274C7.46515 10.7217 7.65807 10.3358 7.89792 9.97944C8.16821 9.57779 8.51802 9.22798 9.21765 8.52835Z"
          stroke="#224A13"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default EditIcon;
