import { BaseResponse } from '../../interfaces';
import { SiteCollectionI, SiteI, SiteSearchParamsI } from '../../interfaces/site';
import { handleFilterParams } from '../../utils/global';
import { api } from '../api';
import { useFetch } from '../reactQuery';

export const useGetPlatformSite = (id: number) => {
  const { data: response, error, isLoading } = useFetch<any>(`platform/sites/${id}`);
  const data = response?.data;
  return { data, error, isLoading };
};

export const getPlatformSitesList = async (params?: SiteSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<SiteCollectionI>>(
    `platform/sites?${handleFilterParams(params)}`,
  );
  return response;
};

export const createPlatformSite = async (values: SiteI) => {
  const { data: response }: any = await api.post('platform/sites', values);
  return response.data;
};

export const updatePlatformSite = async ({ id, ...values }: { id: string; values: SiteI }) => {
  const { data: response }: any = await api.patch(`platform/sites/${id}`, values);
  return response.data;
};

export const deletePlatformSite = async (id: number) => {
  const { data: response }: any = await api.delete(`platform/sites/${id}`);
  return response.data;
};

export const updatePlatformSiteStatus = async (params: { id: number; status: string }) => {
  const { data: response }: any = await api.patch(`platform/sites/${params.id}/status`, { status: params.status });
  return response.data;
};
