import { Spin } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetHardware } from '../../apis/hardwares';
import TrackingInformation from '../../components/Tracking/Information';
import TrackingTopInfo from '../../components/Tracking/TopInfo';
import TrackingResources from '../../components/Tracking/Resources';

const TrackingDetailsPage: React.FC = () => {
  const { serialCode } = useParams<{ serialCode: string }>();
  const { data: hardware, isLoading, error } = useGetHardware(serialCode);
  const [currentTab, setCurrentTab] = useState<string>('1');
  const navigate = useNavigate();

  if (error === 'Hardware not found!' || error === 'Unregistered') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <TrackingTopInfo data={hardware} setCurrentTab={setCurrentTab} />
      <TrackingInformation data={hardware} setCurrentTab={setCurrentTab} />
      <TrackingResources data={hardware} id={serialCode} currentTab={currentTab} setCurrentTab={setCurrentTab} />
    </Spin>
  );
};

export default TrackingDetailsPage;
