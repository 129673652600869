import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import { useQuery } from 'react-query';
import { handleFilterParams } from '../../utils/global';
import { Pagination, Spin } from 'antd';
import { UserHistoryI, UserHistorySearchParams } from '../../interfaces/user';
import { getUserHistory } from '../../apis/users';
import UserHistoryFilter from './HistoryFilter';
import UserHistoryItem from './HistoryItem';
import SizeBox from '../SizeBox';
import DisplayLastUpdateApp from '../DisplayLastUpdate';

type Props = {
  id?: string;
};

const UserHistory: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const category = urlSearch.get('filter[category]');
    const user = urlSearch.get('filter[user]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search: search,
      filter: { user, category },
    } as UserHistorySearchParams & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { userHistories = [], total = 0 } = {},
    isLoading,
    refetch: refetchData,
  } = useQuery(
    ['user-histories', `${id}`, queryParams],
    () => getUserHistory({ id: Number(id), params: queryParams }),
    {
      select({ data: { userHistories, total } }) {
        return { userHistories, total };
      },
    },
  );

  const currentPage = queryParams?.page ?? 1;

  const setQueryParams = (params: Partial<UserHistorySearchParams>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: UserHistorySearchParams) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  return (
    <div className="partner-history-container">
      <Spin spinning={isLoading} className="partner-history-container">
        <UserHistoryFilter queryParams={queryParams} onChange={handleFilterChange} refetchData={refetchData} />
        <SizeBox height={16} />
        <DisplayLastUpdateApp />
        <SizeBox height={8} />
        {userHistories.map((item: UserHistoryI) => {
          return <UserHistoryItem item={item} key={item?.id} />;
        })}
        <div className="pagination-container tw-mt-[44px]">
          <Pagination
            total={total}
            pageSize={queryParams.limit ?? PAGE_SIZE}
            showTotal={(total) => `${total} Items`}
            current={currentPage}
            onChange={(page, pageSize) => handleFilter({ page, per_page: pageSize })}
            showSizeChanger
          />
        </div>
      </Spin>
    </div>
  );
};

export default UserHistory;
