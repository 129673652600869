import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const AddMoreIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 16}px`}
      viewBox="0 0 16 16"
      width={`${width ?? 16}px`}
      fill={color ?? 'currentColor'}
    >
      <g id="add_box">
        <path
          id="Vector"
          d="M12.6667 2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM12.6667 12.6667H3.33333V3.33333H12.6667V12.6667ZM7.33333 11.3333H8.66667V8.66667H11.3333V7.33333H8.66667V4.66667H7.33333V7.33333H4.66667V8.66667H7.33333V11.3333Z"
          fill={color ?? 'currentColor'}
        />
      </g>
    </svg>
  );
};
export default AddMoreIcon;
