import React from 'react';
import './CommonStatus.scss';

interface Props {
  status: string;
  text?: string;
}

const replacedLabel = (label: string) => {
  if (label?.toLowerCase() === 'request') {
    return 'Request in Review';
  }
  return label;
};

export const CommonStatus: React.FC<Props> = ({ status, text }) => {
  return <span className={`common-status tw-w-fit ${status}`}>{replacedLabel(text ?? status)}</span>;
};
