import { Button, Dropdown } from 'antd';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../../constants';
import SitesFilterForm from '../../../components/forms/site/Filter';
import SitesTable from '../../../components/tables/Sites';
import { SiteSearchParamsI } from '../../../interfaces/site';
import { handleFilterParams } from '../../../utils/global';
import { CurrentUserI } from '../../../interfaces/auth';
import { handleAccessControl } from '../../../utils/role';
import Text from '../../../components/Text';
import { getPlatformSitesList } from '../../../apis/platforms/sites';
import PlatformSitesCardList from '../../../components/card-list/platforms/Sites';
import DisplayLastUpdateApp from '../../../components/DisplayLastUpdate';
import SizeBox from '../../../components/SizeBox';
import EmptyData from '../../../components/EmptyData';

const PlatformSitesPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'platform/sites');
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const searchName = urlSearch.get('search[name]');
    const status = urlSearch.get('filter[status]')?.split(',') ?? [];
    const market = urlSearch.get('filter[market]')?.split(',') ?? [];
    const type = urlSearch.get('filter[type]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search: { name: searchName },
      filter: { status, market, type },
    } as SiteSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { sites = [], total = 0 } = {},
    isLoading,
    refetch: refetchData,
  } = useQuery(['platform-sites', 'list', queryParams], () => getPlatformSitesList(queryParams), {
    select({ data: { sites, total } }) {
      return { sites, total };
    },
  });

  const currentPage = queryParams?.page ?? 1;

  const pagination: any = {
    total,
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: Partial<SiteSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const handleFilterChange = (values: SiteSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/platform/sites/${record?.id}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanUpdate
                ? {
                    key: '2',
                    label: (
                      <Link to={`/platform/sites/${record?.id}/edit`}>
                        <Button className="btn-table-action-primary" type="link">
                          Edit Profile
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <div className="sites-container tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
        <Text component="h3" variant="h3">
          Site List
        </Text>
      </div>
      <SitesFilterForm queryParams={queryParams} onChange={handleFilterChange} refetchData={refetchData} />
      {!isLoading && sites.length === 0 && (
        <EmptyData
          title="No Site"
          message="Sites within all partner entities across platform will be displayed here."
        />
      )}
      {!isLoading && sites.length !== 0 && (
        <>
          <DisplayLastUpdateApp />
          <SizeBox height={8} />
          {!queryParams.viewMode ? (
            <PlatformSitesCardList
              data={sites}
              pagination={pagination}
              onPaginationChange={handleFilter}
              access={access}
            />
          ) : (
            <SitesTable
              rowKey="id"
              loading={isLoading}
              pagination={pagination}
              dataSource={sites}
              withAction={withAction}
              onChange2={handleFilter}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PlatformSitesPage;
