import { Form, message, Modal, Spin, Upload } from 'antd';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { allAttachmentTypes, getBase64Promise } from '../../utils/image';
import DeleteSVG from '../../svgs/delete_forever.svg';
import BackupIcon from '../../svgs/BackupIcon';
import './AttachmentUploader.scss';

type Props = {
  name: string;
  multiple?: boolean;
  documents: any[];
  setDocuments(_: any): void;
  isRequired?: boolean;
  isShowUploadList?: boolean;
  maxCount?: number;
  isDisabled?: boolean;
  acceptType?: any;
};

const AttachmentUploader: React.FC<Props> = ({
  name,
  multiple,
  documents,
  maxCount,
  setDocuments,
  isRequired,
  isShowUploadList,
  isDisabled,
  acceptType = allAttachmentTypes,
}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const handleFailed = (file: any) => message.error(`${file.name} file upload failed.`);
  const handleRemoved = (file: any) => message.success(`${file.name} file removed successful`);
  const handleUploaded = (file: any) => message.success(`${file.name} file uploaded successful.`);
  let counterFile = 0;
  const disable = isDisabled ?? false;
  const defaultMaxCount = maxCount ?? 1;

  const filesData: any[] = [];
  const handleChangeFile = async (file: any) => {
    if (file.status === undefined) {
      setIsUploading(true);
      file.data = await getBase64Promise(file);
      const result = file.data.split('base64,');

      let newFile: any = {};
      newFile = {
        data: result[1],
        name: file.name,
        type: file.type,
        url: file.data,
        thumbUrl: file.data,
        size: file.size,
      };
      filesData.push(newFile);

      if (newFile) {
        if (multiple) {
          setTimeout(() => {
            const files = [...documents];
            files.push(...filesData);
            setDocuments(files);
          }, 0);
        } else {
          setDocuments([newFile]);
        }
        setIsUploading(false);
        handleUploaded(file);
      } else {
        setIsUploading(false);
        handleFailed(file);
      }
      counterFile = 0;
    }

    if (file.status === 'removed') {
      const files = [...documents];
      if (multiple) {
        const indexFile = documents.findIndex((f: any) => f.id === file.id);
        files.splice(indexFile, 1);
        setDocuments(files);
      } else {
        setDocuments([]);
      }
      handleRemoved(file);
    }

    if (file.status === 'error') {
      handleFailed(file);
    }
  };

  const handleCancel = () => {
    setPreviewImage('');
    setPreviewVisible(false);
    setPreviewTitle('');
  };

  const handlePreview = async (file: any) => {
    if (file?.type?.includes('image') || file?.type?.includes('video')) {
      setPreviewImage(file.url || file.s3Url);
      setPreviewVisible(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    } else {
      window.open(file.s3Url || file.url, '_blank');
    }
  };

  const allowedFileTypes = Object.keys(acceptType);
  const allowedExtensions = allowedFileTypes.map((type) => acceptType[type]).join(', ');

  const uploadProps = {
    name,
    multiple: !!multiple,
    fileList: documents,
    accept:
      allowedExtensions !== '' ? allowedExtensions : 'image/png, image/jpeg, .pdf, .docx, .csv, .xlsx, .svg, .ico',
    beforeUpload: (file: any) => {
      ++counterFile;
      if (documents.length + counterFile > defaultMaxCount) {
        message.error(`Cannot upload more than ${defaultMaxCount} files`);
        return true;
      }

      if (!allowedFileTypes.includes(file.type) || file.type === '') {
        message.error(`${file.name} is not a valid file type`);
        return Upload.LIST_IGNORE;
      }

      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        message.error('Image must smaller than 50MB!');
        return true;
      }
      return false;
    },
    async onChange({ file }: any) {
      handleChangeFile(file);
    },
    showUploadList: isShowUploadList && {
      showRemoveIcon: true,
      removeIcon: <ReactSVG src={DeleteSVG} />,
    },
    onPreview(file: any) {
      handlePreview(file);
    },
  };

  return (
    <Spin spinning={isUploading}>
      <Form.Item
        name={name}
        className="attachment-uploader-field"
        rules={[
          { required: isRequired, message: 'This field is required' },
          {
            validator: (_, value) => {
              if ((!isRequired && !value) || value.fileList.length > 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('This field is required'));
            },
          },
        ]}
      >
        <Upload.Dragger
          className="attachment-uploader-container"
          {...uploadProps}
          disabled={disable}
          listType="picture"
        >
          <p className="ant-upload-drag-icon">
            <BackupIcon width={60} height={60} />
          </p>
          <p className="ant-upload-text">Drop files here or click to upload</p>
          <p className="ant-upload-hint">Maximum file size is 10MB</p>
          <p className="ant-upload-hint">{`Supported formats are ${allowedExtensions !== '' ? allowedExtensions : '.png, .jpeg, .ico, .svg, .pdf, .docx, .csv, .xlsx'}`}</p>
        </Upload.Dragger>
      </Form.Item>
      <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Spin>
  );
};

export default AttachmentUploader;
