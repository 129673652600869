import React from 'react';
import PartnerFilterFormComponent from './FilterForm';
import PartnerHistoryTable from '../tables/PartnerHistory';

const PartnerHistory: React.FC = () => {
  const handleFilterSubmit = (values: { search: string }) => {
    console.log('values', values);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <PartnerFilterFormComponent handleFilter={handleFilterSubmit} />
      <PartnerHistoryTable rowKey="id" pagination={false} dataSource={[]} />
    </div>
  );
};

export default PartnerHistory;
