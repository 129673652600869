import React from 'react';
import { Tabs, TabsProps } from 'antd';
import Text from '../Text';
import Icon from '../Icon';
import UserHistory from './History';
import UserAttachments from './Attachments';
import { UserI } from '../../interfaces/user';

type Props = {
  data?: UserI;
  id?: string;
};

const UserResources: React.FC<Props> = ({ data, id }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-history" size={20} /> <span>History</span>
        </Text>
      ),
      children: <UserHistory id={id} />,
    },
    {
      key: '2',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-attach_file" size={20} /> <span>Attachments</span>
        </Text>
      ),
      children: <UserAttachments attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="details-container" style={{ marginTop: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default UserResources;
