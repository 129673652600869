import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PhoneNumberInput } from './PhoneNumberInput/PhoneNumberInput';
import { CurrentUserI } from '../interfaces/auth';
import { AttachmentCategories, ContactUsReportCategories } from '../constants';
import UploadAttachmentButton from './buttons/UploadAttachmentButton';
import { useMutation } from 'react-query';
import { sendContactUsReport } from '../apis/platforms/contactUs';
import { convertToApiFile, GetPlatformInfo } from '../utils/global';
import { useAppNotification } from '../hooks/useAppNotification';
import Text from './Text';
import { AttachmentI } from '../interfaces/attachments';
import { ContactUsCategoryT } from '../interfaces';

type FormValuesT = {
  username: string;
  email: string;
  contactNumber: string;
  title: string;
  category: ContactUsCategoryT;
  description: string;
  attachments: AttachmentI[];
  metadata: Record<string, string>;
};

const PlatformIssueReportingModal = ({
  open,
  setOpen,
  currentUser,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  currentUser: CurrentUserI;
}) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const { openNotification } = useAppNotification();
  const { mutate } = useMutation(sendContactUsReport, {
    onSuccess: () => {
      form.resetFields();
      setFiles([]);
      setOpen(false);
      openNotification({
        type: 'success',
        title: 'Your feedback has been sent',
        content: (
          <Text variant="bodyMd">Thank for your feedback, we will carefully review it and get back to you soon.</Text>
        ),
      });
    },
    onError: () => {
      openNotification({
        type: 'error',
        title: "Your feedback hasn't been sent",
        content: <Text variant="bodyMd">Please try it again!</Text>,
      });
    },
  });

  const handleOnFinish = (values: FormValuesT) => {
    const newValues: any = values;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    const userAgent = GetPlatformInfo(navigator.userAgent);
    newValues.metadata = userAgent;
    mutate(newValues);
  };

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        username: currentUser?.username,
        email: currentUser?.email,
        contactNumber: currentUser?.contactNumber,
      });
    }
  }, [currentUser, open]);

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Text variant="h2" className="tw-p-6 tw-pb-0 tw-my-0">
          Contact Us
        </Text>
      }
      footer={null}
      width={600}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true, category: ContactUsReportCategories?.[0]?.value }}
        requiredMark
      >
        <div className="form-container">
          <div className="form-content !tw-mt-0">
            <Form.Item label="Username" name="username" rules={[{ required: true, message: 'This field is required' }]}>
              <Input placeholder="Username" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { type: 'email', message: 'Invalid Email' },
                { required: true, message: 'This field is required' },
              ]}
            >
              <Input type="email" placeholder="Type your email" autoComplete="off" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="contactNumber"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <PhoneNumberInput />
            </Form.Item>
            <Form.Item label="Category" name="category" rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder="Choose a Category">
                {ContactUsReportCategories.map(({ name, value }) => {
                  return (
                    <Select.Option key={value} value={value}>
                      {name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Title" name="title" rules={[{ required: true, message: 'This field is required' }]}>
              <Input placeholder="Type title here" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input.TextArea placeholder="Type description here" rows={3} />
            </Form.Item>
            <Form.Item name="attachments" label="Attachments">
              <UploadAttachmentButton
                categories={AttachmentCategories}
                form={form}
                setFiles={setFiles}
                files={files}
                style={{ width: '96%' }}
                multiple
              />
            </Form.Item>
          </div>
        </div>
        <Button className="tw-w-full" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default PlatformIssueReportingModal;
