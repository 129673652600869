import React from 'react';
import { Tabs, TabsProps } from 'antd';
import Icon from '../Icon';
import { ConfigReportsI } from '../../interfaces/configuration';
import { ConfigurationHardwareComponent } from '../Configuration/Hardware';
import Attachments from '../Attachments';

type Props = {
  data?: ConfigReportsI;
};

const ConfigReportResources: React.FC<Props> = ({ data }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span className="tab-title tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-all_inbox" size={20} /> <span>Hardware</span>
        </span>
      ),
      children: <ConfigurationHardwareComponent data={data?.hardwareList ?? []} />,
    },
    {
      key: '2',
      label: (
        <span className="tab-title tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-attach_file" size={20} /> <span>Attachments</span>
        </span>
      ),
      children: <Attachments attachments={data?.attachments} isDisplayTitle={false} />,
    },
  ];

  return (
    <div className="details-container" style={{ marginTop: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default ConfigReportResources;
