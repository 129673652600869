import React from 'react';
import './CameraNotReady.scss';
import Camera from '../images/cameraNotAllow.png';
import SecondaryButton from './buttons/SecondaryButton';

type Props = {
  onCheckPermission: () => void;
};

const CameraNotReady: React.FC<Props> = ({ onCheckPermission }) => {
  return (
    <div className="component-hardware-camera-not-ready-container">
      <img src={Camera} alt="Camera access required" />
      <h2>Camera access required</h2>
      <p>In order to scan by QR/Bar Code, you must allow access to your camera on the browser.</p>
      <div>
        <SecondaryButton label="Allow Camera Access" onClick={onCheckPermission} />
      </div>
    </div>
  );
};

export default CameraNotReady;
