import React, { useState } from 'react';
import { HardwareI, HardwareUpdateStatusI } from '../../../interfaces/hardware';
import Text from '../../Text';
import { Modal, message } from 'antd';
import { CommonStatus } from '../../CommonStatus';
import UpdateStatusForm from '../../forms/UpdateStatus';
import { useMutation, useQueryClient } from 'react-query';
import BackLink from '../../buttons/BackLink';
import { getTrackingTypeFromApi, handleDisplayIconByTypeFromApi } from '../../../utils/hardware';
import IconText from '../../IconText';
import { updatePlatformHardwareStatus } from '../../../apis/platforms/hardware';

type Props = {
  data: HardwareI;
};

const PlatformHardwareTopInfo: React.FC<Props> = ({ data }) => {
  const [isUpdateStatusVisible, setIsUpdateStatusVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleCancel = () => {
    setIsUpdateStatusVisible(false);
  };

  const { mutate: updateStatus } = useMutation(updatePlatformHardwareStatus, {
    onSuccess: async () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const onUpdateStatusSubmit = async (values: HardwareUpdateStatusI) => {
    const newParams = { id: data.id, params: values };
    updateStatus(newParams);
    setIsUpdateStatusVisible(false);
  };

  return (
    <section className="tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-flex tw-flex-col tw-flex-1 tw-mb-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <BackLink />
      </div>
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-auto">
          <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-2 tw-items-center">
            <IconText
              icon={handleDisplayIconByTypeFromApi(data?.type, { color: '#191919', width: 20, height: 20 })}
              text={getTrackingTypeFromApi(data)}
            />
            <span className=" tw-text-grey-700">{` | `}</span>
            <Text variant="h2" className="tw-truncate xs:tw-max-w-[195px] tw-text-grey-800">
              {data?.serialCode}
            </Text>
          </div>
          <div className="tw-flex tw-gap-x-0.5">
            <CommonStatus status={data?.status} />
            <CommonStatus status={data?.condition} />
          </div>
        </div>
      </div>
      <Modal open={isUpdateStatusVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={412}>
        <UpdateStatusForm onCancel={handleCancel} handleSubmit={onUpdateStatusSubmit} currentStatus={data?.status} />
      </Modal>
    </section>
  );
};

export default PlatformHardwareTopInfo;
