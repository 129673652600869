import { Form, Input, Radio, RadioChangeEvent, Space } from 'antd';
import React, { useState } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import Text from '../Text';
import './UpdateStatus.scss';
import { ReasonForInactiveHardware } from '../../constants';

type Props = {
  currentStatus?: string;
  handleSubmit: (_: any) => void;
  onCancel: () => void;
};

const UpdateStatusForm: React.FC<Props> = ({ currentStatus, onCancel, handleSubmit }) => {
  const [isInactiveSeleted, setIsInactiveSeleted] = useState<boolean>(false);
  const [isInactiveRemarkVisible, setIsInactiveRemarkVisible] = useState<boolean>(false);

  const onGroupChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    if (value === 'inactive') {
      setIsInactiveSeleted(true);
    } else {
      setIsInactiveSeleted(false);
    }
  };

  const onInactiveGroupChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    if (value === 'others') {
      setIsInactiveRemarkVisible(true);
    } else {
      setIsInactiveRemarkVisible(false);
    }
  };

  return (
    <div className="update-status-form-container">
      <Form
        scrollToFirstError
        layout="vertical"
        requiredMark={false}
        colon={false}
        onFinish={handleSubmit}
        initialValues={{ status: currentStatus }}
      >
        <Text variant="h3" className="tw-mb-6">
          Update Status
        </Text>
        <div className="FormContent">
          <Form.Item name="status">
            <Radio.Group onChange={onGroupChange}>
              <Space direction="vertical" className="tw-w-full">
                <Radio value={'active'} className="tw-items-center tw-flex">
                  <Text className="status active">Active</Text>
                </Radio>
                <Radio value={'inactive'} className="tw-items-center tw-flex">
                  <Text className="status inactive">Inactive</Text>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {isInactiveSeleted && (
            <Form.Item name="inactiveCause" label="Reason for inactive">
              <Radio.Group onChange={onInactiveGroupChange}>
                <Space direction="vertical" className="tw-w-full">
                  {ReasonForInactiveHardware.map((item) => (
                    <Radio value={item.value} key={item.value}>
                      <Text>{item.name}</Text>
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
          {isInactiveRemarkVisible && (
            <Form.Item name="inactiveRemark" label="Remarks">
              <Input.TextArea rows={3} placeholder="Type here" />
            </Form.Item>
          )}
        </div>
        <PrimaryButton className="tw-w-full tw-mb-3" label="Update" htmlType="submit" />
        <SecondaryButton className="tw-w-full" label="Cancel" onClick={onCancel} />
      </Form>
    </div>
  );
};

export default UpdateStatusForm;
