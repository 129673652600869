import React from 'react';
import { HardwareItemI } from '../../../interfaces/hardware';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../../utils/hardware';
import Text from '../../Text';
import { CommonStatus } from '../../CommonStatus';
import Icon from '../../Icon';
import NetworkStatus from '../NetworkStatus';
import { DateFormat } from '../../../utils/global';
import Divider from '../../Divider';
import DangerButton from '../../buttons/DangerButton';
import TrashIcon from '../../../svgs/TrashIcon';
import { Link } from 'react-router-dom';

type Props = {
  item: HardwareItemI;
  canDelete?: boolean;
  isDisplayDelete?: boolean;
  handleDelete?: (id: number) => void;
};

const HardwareGroupItemCard: React.FC<Props> = ({ item, canDelete = true, isDisplayDelete = true, handleDelete }) => {
  const onDeleteClick = () => {
    handleDelete?.(item?.id);
  };

  const renderDeleteContent = () => {
    return (
      <>
        <Text variant="h3">Remove Hardware</Text>
        <Text variant="bodyLg" component="p">
          Are you sure you want to remove <span className=" tw-font-bold">{`${item.serialCode}`}</span> from this
          hardware group?
        </Text>
      </>
    );
  };

  const renderInfoByType = () => {
    return (
      <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
        {item.type === 'ecotote' && (
          <>
            <Icon name="icon-water-drop" size={12} className=" tw-text-grey-700" />
            <span>
              {item?.ecoTote?.lastKnownVolume !== null
                ? (Number(item?.ecoTote?.lastKnownVolume) / 1000).toFixed(2)
                : (0).toFixed(2)}
              L
            </span>
          </>
        )}
        {item.type === 'ecoplant' && (
          <>
            <Icon name="icon-date_range" size={12} className=" tw-text-grey-700" />
            <span>{item?.ecoPlant?.lastFilledDate ? DateFormat(item?.ecoPlant?.lastFilledDate) : ''}</span>
          </>
        )}
        {item.type === 'smartpour' && <NetworkStatus status={null} />}
      </Text>
    );
  };
  return (
    <Link to={`/tracking/${item.serialCode}`} className="tw-text-grey-900 hover:tw-opacity-100 hover:tw-text-grey-900">
      <div className="tw-bg-white tw-rounded-lg tw-shadow-simple tw-flex tw-flex-col tw-min-w-[200px]">
        <div className="tw-flex tw-flex-col tw-justify-between tw-h-full tw-py-4 tw-px-4">
          <div>
            <Text variant="capt1" className=" tw-flex tw-items-center tw-gap-0.5">
              {handleDisplayIconByTypeFromApi(item?.type, { color: '#191919', width: 16, height: 16 })}
              <span>{getTypeFromApi(item)}</span>
            </Text>
            <Text variant="inputLabelLg" className="tw-mt-1 tw-text-primary-900">
              {`${item.serialCode}`}
            </Text>
            {item.type === 'ecotote' && (
              <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
                <>
                  <Icon name="icon-location_on" size={12} className=" tw-text-grey-700" />
                  <span>{!item.site ? 'Unassigned Site' : item?.site?.legalName}</span>
                </>
              </Text>
            )}
            {renderInfoByType()}
          </div>
          {item?.error ? (
            <Text>
              <CommonStatus status={'inactive'} text={item.error} />
            </Text>
          ) : (
            <span className="tw-flex tw-items-center tw-gap-0.5">
              {item?.status && <CommonStatus status={item?.status ?? ''} />}
              {item?.condition && <CommonStatus status={item?.condition ?? ''} />}
            </span>
          )}
        </div>
        {isDisplayDelete && (
          <>
            <Divider />
            <div className="tw-flex tw-justify-between tw-py-2 tw-px-3">
              {!item.error ? (
                <a href="" className="tw-flex tw-items-center tw-gap-1">
                  <Icon name="icon-flag" size={12} className="tw-text-primary-800" />
                  <Text variant="buttonSm">Report</Text>
                </a>
              ) : (
                <span className="tw-leading-5 tw-mt-3"></span>
              )}
              {!item.error && canDelete && (
                <DangerButton
                  icon={<TrashIcon width={12} height={12} />}
                  onOk={onDeleteClick}
                  content={renderDeleteContent()}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default HardwareGroupItemCard;
