import React from 'react';
import { Tabs, TabsProps } from 'antd';
import { PartnerI } from '../../interfaces/partner';
import PartnerAttachments from './Attachments';
import PartnerHistory from './History';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  data?: PartnerI;
};

const PartnerHistoryAndAttachment: React.FC<Props> = ({ data }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-history" size={20} /> <span>History</span>
        </Text>
      ),
      children: <PartnerHistory />,
    },
    {
      key: '2',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-attach_file" size={20} /> <span>Attachments</span>
        </Text>
      ),
      children: <PartnerAttachments attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="details-container" style={{ marginTop: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default PartnerHistoryAndAttachment;
