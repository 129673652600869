import React from 'react';
import { ReactSVG } from 'react-svg';
import LoginSVG from '../../svgs/logo.svg';
import './Logo.scss';

type Props = {
  className?: string;
};

const LogoSection: React.FC<Props> = ({ className, ...props }) => {
  return <ReactSVG src={LoginSVG} className={`logo-wrap ${className}`} {...props} />;
};

export default LogoSection;
