import React from 'react';
import './AllocationType.scss';
import Icon from './Icon';
import Text from './Text';
import { IconName } from './Icon/iconName';

interface Props {
  type: string;
}

export const AllocationTypeComponent: React.FC<Props> = ({ type }) => {
  const icon = type === 'send' ? 'icon-outgoing_mail' : 'icon-incoming_mail';
  const label = type === 'send' ? 'Sent' : 'Received';
  const color = type === 'send' ? 'tw-text-success-500' : 'tw-text-info-500';
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
      <Icon name={icon as IconName} className={`text-xs ${color}`} size={16} />
      <Text variant="inputLabelSm" className={`flex-1 ${color}`}>
        {label}
      </Text>
    </div>
  );
};
