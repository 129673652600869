import { Checkbox, Form } from 'antd';
import React, { Fragment, useMemo } from 'react';
import { ModuleI } from '../../../interfaces/module';
import { CheckboxOptionI } from '../../../interfaces';
import ActionSection from '../../Role/ActionSection';
import { handleGetPermissionFromModule } from '../../../utils/role';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const PartnerCollapse: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  const partnerCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Partner'),
    [modules],
  );
  const userCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'User'),
    [modules],
  );
  const roleCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Role'),
    [modules],
  );
  const siteCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Site'),
    [modules],
  );

  return (
    <Fragment>
      <ActionSection
        label="Profile"
        content={
          <Form.Item name="colPartner">
            <Checkbox.Group options={partnerCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colPartner')} />
          </Form.Item>
        }
      />
      <ActionSection
        label="Users"
        content={
          <Form.Item name="colUser">
            <Checkbox.Group options={userCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colUser')} />
          </Form.Item>
        }
      />
      <ActionSection
        label="Roles"
        content={
          <Form.Item name="colRole">
            <Checkbox.Group options={roleCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colRole')} />
          </Form.Item>
        }
      />
      <ActionSection
        label="Sites"
        content={
          <Form.Item name="colSite">
            <Checkbox.Group options={siteCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colSite')} />
          </Form.Item>
        }
      />
    </Fragment>
  );
};

export default PartnerCollapse;
