import React, { Fragment, useState } from 'react';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import { Modal, Tabs, TabsProps } from 'antd';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import { FillingStartScanningScreen } from './StartScanningScreen';
import { Link } from 'react-router-dom';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import './ScanStep.scss';
import ConfirmButton from '../buttons/ConfirmButton';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import { FillingParamsI } from '../../interfaces/hardwareFilling';
import FillingEcoToteScanQRCode from './EcoToteScanQRCode';
import FillingManualEcoTote from './ManualEcoTote';

const DiscardConfirmContent = () => {
  return (
    <div>
      <h2>Are you sure you want to close this Scanning session?</h2>
      <p>All your currently scanned Hardware will be lost.</p>
    </div>
  );
};

const RenderDescriptionWarningNotice = () => {
  return (
    <Fragment>
      <p>
        Ensure that the ecoTOTEs have been registered before scanning. If not, please proceed to register the
        ecoTOTE(s).
      </p>
      <Link to={`/hardware-registrations/new`}>
        <span>Register ecoTOTEs</span>
        <RightArrowIcon width={16} height={16} />{' '}
      </Link>
    </Fragment>
  );
};

const RenderDescriptionWarningNotice2 = () => {
  return (
    <Fragment>
      <p>
        Ensure that the ecoTOTEs are empty and have been marked as depleted before filling. If not, please proceed to
        mark ecoTOTEs as depleted.
      </p>
      <Link to={`/hardware-depletions/new`}>
        <span>Mark as Depleted</span>
        <RightArrowIcon width={16} height={16} />{' '}
      </Link>
    </Fragment>
  );
};

const ScanNoticeMessage = 'QR/Bar Code can be found on the outer casing of the ecoTOTE.';

type Props = {
  scannedData?: FillingParamsI;
  toteScanned: { serialCode: string }[];
  setCurrentStep: (_: number) => void;
  setScannedData: (_: FillingParamsI) => void;
  setToteScanned: (_: any) => void;
  handleVerify: () => void;
  stopDecodingScanner: boolean;
  setStopDecodingScanner: (_: any) => void;
  currentStep: number;
  pageName?: string;
};

const FillingEcoToteScanStep: React.FC<Props> = ({
  toteScanned,
  scannedData,
  stopDecodingScanner,
  currentStep,
  pageName,
  setScannedData,
  setCurrentStep,
  setToteScanned,
  handleVerify,
  setStopDecodingScanner,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [screen, setScreen] = useState<'start' | 'scan' | 'scanned'>('start');

  const validateScanResult = async (value: string) => {
    let status = 'success';
    if (toteScanned?.length > 0) {
      if (toteScanned?.find((data: { serialCode: string }) => data.serialCode === value)) {
        status = 'error';
      }
    }

    setTimeout(() => {
      if (status === 'success') {
        const successModal = modal.success({
          type: 'success',
          title: 'Successfully Scanned!',
          content: 'Code detected and added to scanned list!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <CheckCircleIcon width={16} height={16} color="#5FB670" />,
          width: 345,
        });
        const dataScanned = [...toteScanned, { serialCode: value }];
        setToteScanned(dataScanned);
        setScannedData({ ...scannedData, hardwareList: dataScanned });

        setTimeout(() => {
          successModal.destroy();
        }, 1000);
      }

      if (status === 'error') {
        const errorModal = modal.error({
          type: 'error',
          title: 'Failed Scanning!',
          content: 'We detected a duplicated scan. Please check again!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <ErrorModalIcon color="#B13939" />,
          width: 345,
        });

        setTimeout(() => {
          errorModal.destroy();
        }, 1000);
      }
    }, 300);
  };

  const handleScanOrManualSubmit = (result: any) => {
    validateScanResult(result.text);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <FillingEcoToteScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanNoticeMessage={ScanNoticeMessage}
          toteScanned={toteScanned}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          pageName={pageName}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <FillingManualEcoTote
          toteScanned={toteScanned}
          scanNoticeMessage={ScanNoticeMessage}
          handleScanOrManualSubmit={handleScanOrManualSubmit}
        />
      ),
    },
  ];

  const handleDiscard = () => {
    setToteScanned([]);
    setScannedData({ ...scannedData, hardwareList: [] });
  };

  const onVerifyClick = () => {
    handleVerify();
    setStopDecodingScanner(true);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  const onStartScreenClick = () => {
    setStopDecodingScanner(false);
    setScreen('scan');
  };

  return (
    <div className="component-hardware-filling-scan-step">
      <div className="wrapper">
        {screen === 'start' && (
          <FillingStartScanningScreen
            warningDescription={<RenderDescriptionWarningNotice />}
            warningDescription2={<RenderDescriptionWarningNotice2 />}
            setScreen={setScreen}
            setStopDecodingScanner={setStopDecodingScanner}
            currentStep={currentStep}
            content={ScanNoticeMessage}
          />
        )}
        {screen === 'scan' && <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label={'Previous Step'} onClick={() => setCurrentStep(3)} />
          {screen === 'start' && <PrimaryButton label={'Scan ecoTOTEs'} onClick={onStartScreenClick} />}
          {screen !== 'start' && (
            <>
              <ConfirmButton label="Discard" onOk={handleDiscard} content={<DiscardConfirmContent />} type="error" />
              <PrimaryButton label={'Continue'} onClick={onVerifyClick} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FillingEcoToteScanStep;
