import { Spin } from 'antd';
import React from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import DetailsTopSection from '../../components/shared/DetailTopSection';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import TransferInformation from '../../components/Transfer/Information';
import TransferResources from '../../components/Transfer/Resources';
import { useGetTransfer } from '../../apis/transfers';

const TransferDetailsPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const { data: transfer, isLoading, error } = useGetTransfer(code);
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'transfers');
  const navigate = useNavigate();

  if (error === 'OwnershipTransferReport not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <DetailsTopSection label={`Transfer Report - ${transfer?.code}`} access={access} isDisplayManageButton={false} />
      <TransferInformation data={transfer} />
      <TransferResources data={transfer} />
    </Spin>
  );
};

export default TransferDetailsPage;
