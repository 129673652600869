import React, { ReactNode } from 'react';
import Icon from './Icon';
import { Dropdown, MenuProps } from 'antd';
import Text from './Text';
import './Dropdown.scss';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

type Props = {
  menu: { items: MenuProps['items']; onClick?: MenuClickEventHandler };
  buttonLabel?: string | ReactNode;
  trigger?: 'contextMenu' | 'click' | 'hover';
  size?: 'small' | 'medium' | 'large';
  leftIcon?: ReactNode;
  icon?: ReactNode;
  type: 'primary' | 'secondary';
  className?: string;
  placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'top' | 'bottom';
  disabled?: boolean;
  style?: React.CSSProperties;
};

const DropdownApp: React.FC<Props> = ({
  menu,
  trigger = 'click',
  buttonLabel,
  placement = 'bottomLeft',
  size = 'large',
  leftIcon = null,
  icon,
  type = 'primary',
  className,
  disabled,
  style = {},
}) => {
  return (
    <>
      {!!menu.items?.some((item) => !!item) && (
        <Dropdown
          menu={menu}
          trigger={[trigger]}
          overlayClassName={`dropdown-app-container ${size} ${type}`}
          placement={placement}
          disabled={disabled}
        >
          <div
            style={style}
            className={`app-dropdown-container ${type} tw-flex tw-items-center tw-p-[8px] xs:tw-px-[8px] tw-rounded-lg tw-cursor-pointer tw-leading-[1px] ${className} ${disabled ? 'disabled' : ''}`}
          >
            {leftIcon}
            {buttonLabel && (
              <Text variant="buttonMd" className={`button-label xs:tw-text-[14px] xs:tw-leading-5`}>
                {buttonLabel}
              </Text>
            )}
            {icon ?? <Icon name="icon-keyboard_arrow_down" size={20} />}
          </div>
        </Dropdown>
      )}
    </>
  );
};

export default DropdownApp;
