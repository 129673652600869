import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { AttachmentI } from '../../interfaces/attachments';
import FileDefault from '../../images/file-default.png';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: AttachmentI[];
};

const AttachmentsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: ' ',
      width: 80,
      render: (record: any) => {
        return (
          <div className="table-image">
            {record.fileType.includes('image') ? (
              <img src={record?.url ? record.url : FileDefault} alt="attachment" />
            ) : (
              <img src={FileDefault} alt="attachment" />
            )}
          </div>
        );
      },
    },
    {
      title: 'File Name',
      width: 150,
      dataIndex: 'fileName',
      ellipsis: true,
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      width: 150,
      ellipsis: true,
      render: (categoryName: string) => <span className="text-capitalize">{categoryName}</span>,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      title: 'Action',
      key: 'action',
      width: 120,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default AttachmentsTable;
