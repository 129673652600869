export const ConfigurationUnits = [
  { name: 'Millilitres -  mL', value: 'ml' },
  { name: 'US Ounces -  Fl oz', value: 'ozUS' },
  { name: 'Imperial Ounces -   oz', value: 'ozUK' },
];

export const ConfigurationModes = [
  { name: 'Full Verification', value: 'full' },
  { name: 'Partial Verification', value: 'partial' },
  { name: 'Unrestricted', value: 'unrestricted' },
];

export const OunceOptions = [0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25];
