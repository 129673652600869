import React from 'react';
import { Form } from 'antd';
import EInputNumber from '../../inputs/EInputNumber';

const MillilitresUnitForm = () => {
  const validateDecimal = (value: string) => {
    const regex = /^\d+(\.0|\.5)?$/;
    if (value && !regex.test(value.toString())) {
      return Promise.reject(new Error('The decimal value must be either 0 or 5'));
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className="flex-row xs:tw-gap-0">
        <Form.Item
          label="Button 1"
          name={['ml', 'button1']}
          extra={`Value range: 5.0 - 4500.0`}
          rules={[{ validator: (_: any, value: string) => validateDecimal(value) }]}
        >
          <EInputNumber min={10} max={4500} step={0.5} suffix="mL" />
        </Form.Item>
        <Form.Item
          label="Button 2"
          name={['ml', 'button2']}
          extra={`Value range: 5.0 - 4500.0`}
          rules={[{ validator: (_: any, value: string) => validateDecimal(value) }]}
        >
          <EInputNumber min={10} max={4500} step={0.5} suffix="mL" />
        </Form.Item>
      </div>
      <div className="flex-row">
        <Form.Item
          label="Button 3"
          name={['ml', 'button3']}
          extra={`Value range: 5.0 - 4500.0`}
          rules={[{ validator: (_: any, value: string) => validateDecimal(value) }]}
        >
          <EInputNumber min={10} max={4500} step={0.5} suffix="mL" />
        </Form.Item>
        <div></div>
      </div>
    </>
  );
};

export default MillilitresUnitForm;
