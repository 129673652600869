import { Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetConfigReport } from '../../apis/configurations';
import ConfigReportTopSection from '../../components/ConfigReport/TopSection';
import ConfigReportInformation from '../../components/ConfigReport/Information';
import ConfigReportResources from '../../components/ConfigReport/Resources';

const ConfigReportDetailsPage: React.FC = () => {
  const { code } = useParams<{ code?: string }>();
  const { data: report, isLoading, error } = useGetConfigReport(code);
  const navigate = useNavigate();

  if (error === 'Report not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <ConfigReportTopSection data={report} />
      <ConfigReportInformation data={report} />
      <ConfigReportResources data={report} />
    </Spin>
  );
};

export default ConfigReportDetailsPage;
