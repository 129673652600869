import { Form, message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import SiteForm from '../../../components/forms/site/Form';
import { CurrentUserI } from '../../../interfaces/auth';
import { CreateUpdateTopSection } from '../../../components/shared/CreateUpdateTopSection';
import { updatePlatformSite, useGetPlatformSite } from '../../../apis/platforms/sites';

const PlatformSiteUpdatePage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetPlatformSite(Number(id));

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(updatePlatformSite, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['platform-sites', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`platform-sites/${id}`, undefined],
      });
      message.info('Update site successfully!');
      navigate(-1);
    },
    onError: () => {
      message.error('Update site failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading || isPageLoading}>
      <div className="platform-sites-container">
        <CreateUpdateTopSection label="Edit Site" labelButton="Update" form={form} />
        <SiteForm form={form} site={data} siteId={data?.id} handleSubmit={handleSubmit} currentUser={currentUser} />
      </div>
    </Spin>
  );
};

export default PlatformSiteUpdatePage;
