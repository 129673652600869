import React, { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import Logo from '../../images/qrcode-logo.png';
import { Modal } from 'antd';
import SecondaryButton from '../buttons/SecondaryButton';
import Icon from '../Icon';

type Props = {
  data: string;
  downloadName?: string;
};

export const DisplayQRCode: React.FC<Props> = ({ data, downloadName }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleDownload = () => {
    const canvas: any = document.getElementById('qrcode-download');
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${downloadName}`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      <div className="tw-w-auto tw-border tw-border-solid tw-border-grey-300 tw-rounded tw-p-3 tw-inline-block hover:tw-shadow-simple hover:tw-cursor-pointer">
        <button
          className="tw-shadow-none tw-bg-white tw-border-none tw-p-0  hover:tw-cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          <QRCode
            value={data}
            size={120}
            style={{ borderRadius: 4 }}
            logoImage={Logo}
            logoWidth={28}
            id="qrcode-download"
            quietZone={0}
          />
        </button>
        <SecondaryButton
          label="Download"
          icon={<Icon name="icon-download" size={16} />}
          className="tw-py-0.5 tw-mt-2 tw-rounded"
          onClick={handleDownload}
        />
      </div>
      <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={642}>
        <div className="tw-text-center tw-my-6">
          <QRCode
            value={data}
            size={480}
            logoImage={Logo}
            logoWidth={100}
            id="qrcode-download"
            style={{ border: '1px solid #DAD9D9', borderRadius: 4, padding: 24, width: '80%', height: 'auto' }}
          />
          <SecondaryButton
            label="Download as .PNG"
            icon={<Icon name="icon-download" size={16} />}
            className="tw-py-0.5 tw-rounded tw-inline-flex tw-mt-6"
            onClick={handleDownload}
          />
        </div>
      </Modal>
    </>
  );
};

export default DisplayQRCode;
