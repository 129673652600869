import React from 'react';
import { HardwareI } from '../../interfaces/hardware';
import HardwareGroupCardItem from '../card-list/HardwareGroupCardItem';
import { CurrentUserI } from '../../interfaces/auth';
import { useOutletContext } from 'react-router-dom';
import { handleAccessControl } from '../../utils/role';
import EmptyData from '../EmptyData';

type Props = {
  data: HardwareI;
};

const TrackingDetailGroups: React.FC<Props> = ({ data }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'hardware-groups');

  return (
    <div className="card-list">
      {data?.group ? <HardwareGroupCardItem item={data.group} access={access} /> : <EmptyData title="No Data" />}
    </div>
  );
};

export default TrackingDetailGroups;
