import React from 'react';
type Props = {
  width?: number;
  height?: number;
};

const FilterIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 20}px`}
      viewBox="0 0 20 20"
      width={`${width ?? 20}px`}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3395 1.66797C18.6729 1.66797 18.9229 1.83464 19.0895 2.16797C19.2562 2.41797 19.1729 2.7513 19.0062 3.0013L12.5062 10.668V17.5013C12.5062 17.8346 12.3395 18.0846 12.0895 18.2513C12.0062 18.3346 11.8395 18.3346 11.6729 18.3346C11.5062 18.3346 11.4229 18.3346 11.3395 18.2513L8.00621 16.5846C7.67288 16.418 7.50621 16.168 7.50621 15.8346V10.668L1.00621 3.0013C0.839545 2.7513 0.756212 2.41797 0.922879 2.16797C1.08955 1.83464 1.33955 1.66797 1.67288 1.66797H18.3395ZM10.8395 10.418C10.8395 10.168 10.9229 10.0013 11.0062 9.83464L16.5895 3.33464H3.50621L9.00621 9.91797C9.08955 10.0013 9.17288 10.2513 9.17288 10.418V15.3346L10.8395 16.168V10.418Z"
        fill="currentColor"
      />
      <mask id="mask0_13225_20022" maskUnits="userSpaceOnUse" x="0" y="1" width="20" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3395 1.66797C18.6729 1.66797 18.9229 1.83464 19.0895 2.16797C19.2562 2.41797 19.1729 2.7513 19.0062 3.0013L12.5062 10.668V17.5013C12.5062 17.8346 12.3395 18.0846 12.0895 18.2513C12.0062 18.3346 11.8395 18.3346 11.6729 18.3346C11.5062 18.3346 11.4229 18.3346 11.3395 18.2513L8.00621 16.5846C7.67288 16.418 7.50621 16.168 7.50621 15.8346V10.668L1.00621 3.0013C0.839545 2.7513 0.756212 2.41797 0.922879 2.16797C1.08955 1.83464 1.33955 1.66797 1.67288 1.66797H18.3395ZM10.8395 10.418C10.8395 10.168 10.9229 10.0013 11.0062 9.83464L16.5895 3.33464H3.50621L9.00621 9.91797C9.08955 10.0013 9.17288 10.2513 9.17288 10.418V15.3346L10.8395 16.168V10.418Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_13225_20022)"></g>
    </svg>
  );
};
export default FilterIcon;
