import { Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SiteInformation from '../../../components/Site/Information';
import SiteName from '../../../components/Site/Name';
import { useGetPlatformSite } from '../../../apis/platforms/sites';
import PlatformSiteResources from './Resources';

const PlatformSiteDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: site, isLoading, error } = useGetPlatformSite(Number(id));
  const navigate = useNavigate();

  if (error === 'Partner not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <SiteName site={site} isDisplayActions={false} />
      <SiteInformation site={site} />
      <PlatformSiteResources data={site} />
    </Spin>
  );
};

export default PlatformSiteDetailsPage;
