import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Text from '../Text';
import Icon from '../Icon';
import { MenuProps, Modal, message } from 'antd';
import DropdownApp from '../Dropdown';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import BackLink from '../buttons/BackLink';
import { useMutation, useQueryClient } from 'react-query';
import AppUpdateStatusForm from '../forms/AppUpdateStatus';
import { EmailListStatusList } from '../../constants';
import { EmailList } from '../../interfaces/emailList';
import { updateEmailListStatus } from '../../apis/emailLists';

type Props = {
  data: EmailList;
};

const EmailListTopInfo: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'email-lists');
  const [isUpdateStatusVisible, setIsUpdateStatusVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleCancel = () => {
    setIsUpdateStatusVisible(false);
  };

  const { mutate: updateStatus } = useMutation(updateEmailListStatus, {
    onSuccess: async () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const onUpdateStatusSubmit = async (values: { status: string }) => {
    updateStatus({ id: Number(data?.id), status: values.status });
    setIsUpdateStatusVisible(false);
  };

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'edit') navigate(`/email-lists/${data?.id}/edit`);
    if (e.key === 'update-status') setIsUpdateStatusVisible(true);
  };

  const items: MenuProps['items'] = [
    access.isCanUpdate
      ? {
          key: 'edit',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-edit" size={16} />
              <span>Edit Email List</span>
            </Text>
          ),
        }
      : null,
    access.isCanUpdateStatus
      ? {
          key: 'update-status',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-radio_button_checked" size={16} />
              <span>Update Status</span>
            </Text>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <section className="tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-flex tw-flex-col tw-flex-1 tw-mb-6">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <BackLink />
        <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
      </div>
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-auto">
          <Text variant="h3" className="tw-mb-2 tw-truncate xs:tw-max-w-[195px]">
            Email List: Weekly Report
          </Text>
        </div>
      </div>
      <Modal open={isUpdateStatusVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={412}>
        <AppUpdateStatusForm
          data={data}
          onCancel={handleCancel}
          handleSubmit={onUpdateStatusSubmit}
          statusList={EmailListStatusList}
        />
      </Modal>
    </section>
  );
};

export default EmailListTopInfo;
