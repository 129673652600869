import { Button, message, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import './Information.scss';
import { PartnerI } from '../../interfaces/partner';
import { DateTimeFormat, getCountryFromCode } from '../../utils/global';
import { UserI } from '../../interfaces/user';
import DetailsView from '../DetailsView';
import { formatIntlPhoneNumber } from '../PhoneNumberInput/PhoneNumberInput';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../Icon';
import Text from '../Text';

type Props = {
  partner: Partial<PartnerI>;
};

const PartnerInformation: React.FC<Props> = ({ partner }) => {
  const [adminInfo, setAdminInfo] = useState<UserI>();

  const onCopyClick = () => {
    message.info('Coppied');
  };

  useEffect(() => {
    if (partner) {
      const user = partner.users?.filter((user: UserI) => user.type === 'partnerAdmin');
      setAdminInfo(user?.[0] ?? partner.users?.[0]);
    }
  }, [partner]);

  return (
    <div className="tw-flex tw-gap-8 md:tw-flex-row tw-flex-wrap">
      <DetailsView title="Partner Information" className="tw-min-w-[290px] tw-flex-1">
        <DetailsView.Field
          label="Partner Reference Code"
          value={
            <span className="tw-flex tw-items-center tw-gap-2">
              <Text variant="bodyLgB">{partner?.referenceCode}</Text>
              <CopyToClipboard text={partner?.referenceCode ?? ''} onCopy={onCopyClick}>
                <Button className="tw-border-none tw-p-0">
                  <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
                </Button>
              </CopyToClipboard>
            </span>
          }
        />
        <DetailsView.Field label="Business Legal Name" value={partner?.legalName} />
        <DetailsView.Field label="Business Name" value={partner?.displayName} />
        <DetailsView.Field label="Business Contact Number" value={formatIntlPhoneNumber(partner?.contactNumber)} />
        <DetailsView.Field label="Business Contact Email" value={partner?.email} />
        <DetailsView.Field
          label="Market"
          value={
            partner?.market && partner.market.length > 0
              ? partner.market?.map((m: string) => <Tag key={m}>{m}</Tag>)
              : 'Not Applicable'
          }
        />
        <DetailsView.Field label="Onboarded On" value={DateTimeFormat(partner?.createdAt)} />
      </DetailsView>

      {adminInfo && (
        <DetailsView title="Administrative Contact" className="tw-min-w-[290px] tw-flex-1">
          <DetailsView.Field label="Administrator Username" value={adminInfo?.username} />
          <DetailsView.Field label="Administrative Email" value={adminInfo?.email} />
          <DetailsView.Field
            label="Administrative Contact Number"
            value={formatIntlPhoneNumber(adminInfo?.contactNumber)}
          />
        </DetailsView>
      )}
      <DetailsView title="Location" className="tw-min-w-[290px] tw-flex-1">
        <DetailsView.Field label="Address Line 1" value={partner?.addressLine1} />
        <DetailsView.Field label="Address Line 2" value={partner?.addressLine2} />
        <DetailsView.Field label="Address Line 3" value={partner?.addressLine3} />
        <DetailsView.Field label="Country" value={getCountryFromCode(partner?.country ?? '')} />
        <DetailsView.Field label="Region" value={partner?.region} />
        <DetailsView.Field label="State / Province" value={partner?.state} />
        <DetailsView.Field label="Postal / Zip Code" value={partner?.postalCode} />
      </DetailsView>
    </div>
  );
};

export default PartnerInformation;
