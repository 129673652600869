import React from 'react';

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

const PlusIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 20}px`}
      viewBox="0 0 20 20"
      width={`${width ?? 20}px`}
      fill={color ?? 'currentColor'}
    >
      <path d="M5 10H15M10 5V15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default PlusIcon;
