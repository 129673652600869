import React from 'react';
import { Tabs, TabsProps } from 'antd';
import { RegistrationHistoryI } from '../../interfaces/registrationHistory';
import { RegistrationHardwareScanned } from './HardwareScanned';
import RegistrationHistoryAttachments from './Attachments';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  data?: RegistrationHistoryI;
};

const RegistrationResources: React.FC<Props> = ({ data }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-x-2">
          <Icon name="icon-all_inbox" size={20} /> <span>Hardware</span>
        </Text>
      ),
      children: <RegistrationHardwareScanned data={data} />,
    },
    {
      key: '2',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-x-2">
          <Icon name="icon-attach_file" size={20} /> <span>Attachments</span>
        </Text>
      ),
      children: <RegistrationHistoryAttachments attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="registraton-hardware-attachment-container details-container tw-mt-6" style={{ marginBottom: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default RegistrationResources;
