import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Text from '../Text';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import BackLink from '../buttons/BackLink';
import PrimaryButton from '../buttons/PrimaryButton';
import { HardwareFaultReportI } from '../../interfaces/hardwareFaultReports';
import FaultyResolveModal from '../FaultyResolveModal';

type Props = {
  data?: HardwareFaultReportI;
};

const FaultReportTopInfo: React.FC<Props> = ({ data }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'tracking');
  const [isFaultResolveVisible, setIsFaultResolveVisible] = useState<boolean>(false);

  return (
    <section className="tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-flex tw-flex-col tw-flex-1 tw-mb-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <BackLink />
        {access.isCanUpdateStatus &&
          data?.status === 'unresolved' &&
          (currentUser.partnerId === data?.partnerId || currentUser.partnerId === data?.ownerId) && (
            <PrimaryButton label="Resolve Fault" onClick={() => setIsFaultResolveVisible(true)} />
          )}
      </div>
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-auto">
          <Text variant="h2" className="tw-truncate xs:tw-max-w-[195px] tw-text-grey-800">
            Fault Report - {data?.code}
          </Text>
        </div>
      </div>
      <FaultyResolveModal
        data={data}
        setIsModalVisible={setIsFaultResolveVisible}
        isModalVisible={isFaultResolveVisible}
      />
    </section>
  );
};

export default FaultReportTopInfo;
