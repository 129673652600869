import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const RightArrowIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 20 20"
      height={`${height ?? 20}px`}
      viewBox="0 0 20 20"
      width={`${width ?? 20}px`}
      fill="none"
    >
      <g id="chevron_right">
        <path
          id="Vector"
          d="M8.3332 5L7.1582 6.175L10.9749 10L7.1582 13.825L8.3332 15L13.3332 10L8.3332 5Z"
          fill={color ?? 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default RightArrowIcon;
