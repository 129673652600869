import React, { Fragment } from 'react';
import './CollapseForm.scss';
import { Collapse, CollapseProps, Tooltip } from 'antd';
import DashboardCollapse from './DashboardCollapse';
import PartnerCollapse from './PartnerCollapse';
import HardwareCollapse from './HardwareCollapse';
import ProductCollapse from './ProductCollapse';
import ActivateIcon from '../../../svgs/role/ActivateIcon';
import CreateIcon from '../../../svgs/role/CreateIcon';
import EditIcon from '../../../svgs/role/EditIcon';
import UpdateStatusIcon from '../../../svgs/role/UpdateStatusIcon';
import { ModuleI } from '../../../interfaces/module';
import ActionSection from '../../Role/ActionSection';
import GeneralCollapse from './GeneralCollapse';
import PlatformCollapse from './PlatformCollapse';
import { CurrentUserI } from '../../../interfaces/auth';
import { useOutletContext } from 'react-router-dom';
import { handleAccessControl } from '../../../utils/role';
import NotificationCollapse from './NotificationCollapse';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const RoleCollapseForm: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'roles');

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'DASHBOARD MANAGEMENT',
      children: <DashboardCollapse modules={modules} onCheckboxChange={onCheckboxChange} />,
    },
    ...(access.isCanReadPlatform
      ? [
          {
            key: '2',
            label: 'PLATFORM MANAGEMENT',
            children: <PlatformCollapse modules={modules} onCheckboxChange={onCheckboxChange} />,
          },
        ]
      : []),
    {
      key: '3',
      label: 'PARTNER MANAGEMENT',
      children: <PartnerCollapse modules={modules} onCheckboxChange={onCheckboxChange} />,
    },
    {
      key: '4',
      label: 'HARDWARE MANAGEMENT',
      children: <HardwareCollapse modules={modules} onCheckboxChange={onCheckboxChange} />,
    },
    {
      key: '5',
      label: 'PRODUCT MANAGEMENT',
      children: <ProductCollapse modules={modules} onCheckboxChange={onCheckboxChange} />,
    },
    {
      key: '6',
      label: 'NOTIFICATION MANAGEMENT',
      children: <NotificationCollapse modules={modules} onCheckboxChange={onCheckboxChange} />,
    },
  ];

  const generalItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'GENERAL',
      children: <GeneralCollapse onCheckboxChange={onCheckboxChange} />,
    },
  ];

  return (
    <div className="role-collapse-form-container">
      <div className="tw-mb-4">
        <Collapse items={generalItems} defaultActiveKey={['1']} expandIconPosition="end" />
      </div>
      <ActionSection
        label={<span className="grey-color">Action</span>}
        content={
          <Fragment>
            <Tooltip title="View" className="action-item">
              <ActivateIcon />
              <span className="tooltip-text">View</span>
            </Tooltip>
            <Tooltip title="Create" className="action-item">
              <CreateIcon />
              <span className="tooltip-text">Create</span>
            </Tooltip>
            <Tooltip title="Edit" className="action-item">
              <EditIcon />
              <span className="tooltip-text">Edit</span>
            </Tooltip>
            <Tooltip title="Update Status" className="action-item">
              <UpdateStatusIcon />
              <span className="tooltip-text">Update Status</span>
            </Tooltip>
          </Fragment>
        }
      />
      <Collapse items={items} defaultActiveKey={['1', '2', '3', '4', '5', '6']} expandIconPosition="end" />
    </div>
  );
};

export default RoleCollapseForm;
