import { Badge, Dropdown, Form, Input, MenuProps } from 'antd';
import React from 'react';
import IconButton from '../../buttons/IconButton';
import SearchInputIcon from '../../../svgs/SearchInputIcon';
import FilterIcon from '../../../svgs/FilterIcon';
import ViewColumn from '../../../svgs/ViewColumn';
import DropdownApp from '../../Dropdown';
import Icon from '../../Icon';
import { renderSortList } from '../../../utils/sort';
import RefreshButton from '../../buttons/RefreshButton';
import { ConfigReportSearchParamsI } from '../../../interfaces/configuration';
import ReportFilterContent from './ReportFilterContent';

type Props = {
  queryParams: ConfigReportSearchParamsI & { viewMode?: string };
  onChange(newParams: ConfigReportSearchParamsI & { viewMode?: string }): void;
  refetchData: () => void;
};

const ReportFilterForm: React.FC<Props> = ({ queryParams, onChange, refetchData }) => {
  const [form] = Form.useForm();

  const handleOnChange = (params: Partial<ConfigReportSearchParamsI & { viewMode?: string }>) => {
    onChange({ ...queryParams, ...params });
  };

  // const count = useMemo(() => {
  //   if (!queryParams.filter) return 0;
  //   let count = 0;
  //   Object.values(queryParams.filter).forEach((val: any) => {
  //     if (val !== null) {
  //       count += 1;
  //     }
  //   });
  //   return count;
  // }, [queryParams]);

  const handleDropdownItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleOnChange({
      orderBy: orderBy as ConfigReportSearchParamsI['orderBy'],
      orderType: type as ConfigReportSearchParamsI['orderType'],
    });
  };

  const items: MenuProps['items'] = renderSortList(queryParams, 'name');

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="filter-form-container">
      <div className="form-wrap">
        <Form
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={({ search }) => handleOnChange({ search })}
          scrollToFirstError
          className="tw-w-full"
        >
          <div className="form-container">
            <div className="form-content tw-flex-auto">
              <Form.Item name="search" className="search-box tw-w-full">
                <Input placeholder="Search" prefix={<SearchInputIcon />} allowClear />
              </Form.Item>
            </div>
            <div className="tw-flex tw-gap-3 xs:tw-gap-1 xs:tw-w-full xs:tw-justify-end">
              <DropdownApp
                icon={<Icon name="icon-sort" size={22} />}
                menu={menuDropdownProps}
                size="small"
                placement="bottomRight"
                type="secondary"
              />
              <Dropdown
                className="filter-dropdown-button"
                dropdownRender={() => <ReportFilterContent queryParams={queryParams} />}
                trigger={['click']}
                overlayClassName="filter-drowdown"
              >
                <Badge count={0}>
                  <IconButton icon={<FilterIcon height={20} />} disabled={true} />
                </Badge>
              </Dropdown>
              <IconButton
                className={
                  queryParams.viewMode === 'list'
                    ? 'tw-bg-primary-800 tw-text-white hover:!tw-bg-primary-800 hover:!tw-text-white'
                    : 'tw-bg-white hover:!tw-bg-primary-800 hover:!tw-text-white'
                }
                icon={<ViewColumn />}
                onClick={() => handleOnChange({ viewMode: queryParams.viewMode ? undefined : 'list' })}
              />
              <RefreshButton doRefetch={refetchData} />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ReportFilterForm;
