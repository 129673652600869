import React from 'react';
type Props = {
  width?: number;
  height?: number;
};

const WarningIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      height={`${height ?? 16}px`}
      viewBox="0 0 16 16"
      width={`${width ?? 16}px`}
      fill="none"
    >
      <g id="warning-solid">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.28406 1.9756C7.06071 0.68001 8.93819 0.68001 9.71485 1.9756L15.7073 11.972C16.5064 13.3051 15.5462 15.0003 13.9919 15.0003H2.00699C0.452754 15.0003 -0.507518 13.3051 0.291598 11.972L6.28406 1.9756ZM7.99945 5.00037C8.41366 5.00037 8.74945 5.33615 8.74945 5.75037V8.75037C8.74945 9.16458 8.41366 9.50037 7.99945 9.50037C7.58524 9.50037 7.24945 9.16458 7.24945 8.75037V5.75037C7.24945 5.33615 7.58524 5.00037 7.99945 5.00037ZM8.99945 11.5004C8.99945 12.0527 8.55173 12.5004 7.99945 12.5004C7.44717 12.5004 6.99945 12.0527 6.99945 11.5004C6.99945 10.9481 7.44717 10.5004 7.99945 10.5004C8.55173 10.5004 8.99945 10.9481 8.99945 11.5004Z"
          fill="#F29D41"
        />
      </g>
    </svg>
  );
};

export default WarningIcon;
