import { CurrentUserI } from './../interfaces/auth';
import { PageIgnoreCheckAuth } from '../constants';
import { AccessControlI } from '../interfaces';
import { RoleModuleI } from '../interfaces/role';
import { ModuleI } from '../interfaces/module';
import { getModulePermissions } from './global';

const mergeAndUniqueTwoArray = (arr1: string[], arr2: string[]) => {
  const merged = [...arr1];

  for (const item of arr2) {
    if (!merged.some((existingItem) => existingItem === item)) {
      merged.push(item);
    }
  }
  return merged;
};

const getModuleNameByPathname = (moduleName: string): RoleModuleI => {
  switch (moduleName) {
    case 'partners':
      return 'colPartner';
    case 'partner-profile':
      return 'colPartner';
    case 'dashboard':
      return 'colDashboard';
    case 'users':
      return 'colUser';
    case 'sites':
      return 'colSite';
    case 'roles':
      return 'colRole';
    case 'hardwares':
      return 'colHardware';
    case 'products':
      return 'colProduct';
    case 'hardware-registrations':
      return 'colRegistration';
    case 'registration-histories':
      return 'colRegistration';
    case 'hardware-allocations':
      return 'colAllocation';
    case 'allocation-histories':
      return 'colAllocation';
    case 'hardware-depletions':
      return 'colDepletion';
    case 'tracking':
      return 'colHardware';
    case 'hardware-filling':
      return 'colFilling';
    case 'filling-histories':
      return 'colFilling';
    case 'platform/partners':
      return 'colPlatform_partner';
    case 'platform/sites':
      return 'colPlatform_site';
    case 'platform/hardware':
      return 'colPlatform_hardware';
    case 'platform/products':
      return 'colPlatform_product';
    case 'platform/impersonation':
      return 'colPlatform_impersonation';
    case 'hardware-groups':
      return 'colHardware_grouping';
    case 'email-lists':
      return 'colEmail';
    case 'product-groups':
      return 'colProduct_grouping';
    case 'transfers':
      return 'colTransfer';
    case 'fault-reports':
      return 'colHardware';
    case 'configurations':
      return 'colConfiguration';
    default:
      return '';
  }
};

const ignoreEcospiritUser = ['platform/partners', 'platform/sites', 'platform/hardware', 'platform/products'];

export const getAccessControl = (currentUser: CurrentUserI, pathname: string): string[] => {
  if (currentUser.type === 'partnerAdmin' && currentUser.partner.isEcoSpirit === true) return ['C', 'R', 'U', 'D', 'A'];
  if (
    currentUser.type === 'partnerAdmin' &&
    currentUser.partner.isEcoSpirit === false &&
    !ignoreEcospiritUser.includes(pathname)
  )
    return ['C', 'R', 'U', 'D', 'A'];
  if (currentUser.roles && Object.keys(currentUser.roles).length === 0) return [];
  const moduleName = getModuleNameByPathname(pathname);

  let modules: string[] = [];
  if (!moduleName) return [];

  for (const item of currentUser.roles) {
    if (
      currentUser.type === 'partner' &&
      currentUser.partner.isEcoSpirit === false &&
      ignoreEcospiritUser.includes(pathname)
    )
      return [];
    modules = mergeAndUniqueTwoArray(modules, item[moduleName]);
  }
  return modules;
};

export const handleAccessControl = (currentUser: CurrentUserI, module: string): AccessControlI => {
  if (currentUser.type === 'partnerAdmin' && currentUser.partner.isEcoSpirit === true) {
    return {
      isCanCreate: true,
      isCanUpdate: true,
      isCanRead: true,
      isCanUpdateStatus: true,
      isCanDelete: true,
      isCanReadPlatform: true,
    };
  }

  if (currentUser.type === 'partnerAdmin' && currentUser.partner.isEcoSpirit === false) {
    if (ignoreEcospiritUser.includes(module)) {
      return {
        isCanCreate: false,
        isCanUpdate: false,
        isCanRead: false,
        isCanUpdateStatus: false,
        isCanDelete: false,
        isCanReadPlatform: false,
      };
    } else {
      return {
        isCanCreate: true,
        isCanUpdate: true,
        isCanRead: true,
        isCanUpdateStatus: true,
        isCanDelete: true,
        isCanReadPlatform: false,
      };
    }
  }

  if (currentUser.roles && Object.keys(currentUser.roles).length === 0) {
    return {
      isCanCreate: false,
      isCanUpdate: false,
      isCanRead: false,
      isCanUpdateStatus: false,
      isCanDelete: false,
      isCanReadPlatform: false,
    };
  }
  const access = getAccessControl(currentUser, module);

  return {
    isCanCreate: access.includes('C'),
    isCanUpdate: access.includes('U'),
    isCanRead: access.includes('R'),
    isCanUpdateStatus: access.includes('A'),
    isCanDelete: access.includes('D'),
    isCanReadPlatform: currentUser.type === 'partner' && currentUser.partner.isEcoSpirit,
  };
};

export const handleCheckAuthorize = (currentUser: CurrentUserI, pathname: string): boolean => {
  const pathnameSplit: string[] = pathname.split('/');
  let accessList: string[] = [];
  if (pathnameSplit[1] === 'platform') {
    accessList = getAccessControl(currentUser, `${pathnameSplit[1]}/${pathnameSplit[2]}`);
    if (pathnameSplit[3] === 'new' || pathnameSplit[3] === 'send' || pathnameSplit[3] === 'receive') {
      if (accessList.includes('C')) return true;
      return false;
    }

    if (pathnameSplit[3] === 'edit') {
      if (accessList.includes('U')) return true;
      return false;
    }

    if (accessList.includes('R')) {
      return true;
    }

    return false;
  }

  accessList = getAccessControl(currentUser, pathnameSplit[1]);

  if (pathnameSplit[1] && PageIgnoreCheckAuth.includes(pathnameSplit[1])) return true;
  if (pathnameSplit[2] === 'new' || pathnameSplit[2] === 'send' || pathnameSplit[2] === 'receive') {
    if (accessList.includes('C')) return true;
    return false;
  }

  if (pathnameSplit[3] === 'edit') {
    if (accessList.includes('U')) return true;
    return false;
  }

  if (accessList.includes('R')) {
    return true;
  }

  return false;
};

const getPathNameBaseOnModule = (module: string) => {
  switch (module) {
    case 'colPartner':
      return 'partner-profile';
    case 'colDashboard':
      return 'dashboard';
    case 'colUser':
      return 'users';
    case 'colSite':
      return 'sites';
    case 'colRole':
      return 'roles';
    case 'colHardware':
      return 'tracking';
    case 'colProduct':
      return 'products';
    case 'colRegistration':
      return 'hardware-registrations';
    case 'colAllocation':
      return 'hardware-allocations';
    case 'colDepletion':
      return 'hardware-depletions';
    case 'colFilling':
      return 'hardware-filling';
    case 'colPlatform_partner':
      return 'platform/partners';
    case 'colPlatform_site':
      return 'platform/sites';
    case 'colPlatform_hardware':
      return 'platform/hardware';
    case 'colPlatform_product':
      return 'platform/products';
    case 'colHardware_grouping':
      return 'tracking/groups';
    case 'colProduct_grouping':
      return 'products/groups';
    case 'colTransfer':
      return 'transfers';
    case 'colConfiguration':
      return 'configurations';
    default:
      return '';
  }
};

export const handleGetNextPath = (currentUser: CurrentUserI): string => {
  if (currentUser.type === 'partnerAdmin' && currentUser.partner.isEcoSpirit === true) {
    return 'platform/partners';
  } else if (currentUser.type === 'partnerAdmin' && currentUser.partner.isEcoSpirit === false) {
    return 'partner-profile';
  }

  const roles = currentUser.roles;
  let firstKeyWithArrayValue: string = '';

  for (const obj of roles) {
    if (Object.prototype.hasOwnProperty.call(obj, 'colDashboard') && obj['colDashboard'].length > 0) {
      // firstKeyWithArrayValue = 'colDashboard';
      firstKeyWithArrayValue = '';
      break;
    }
  }

  if (firstKeyWithArrayValue === '') {
    for (const obj of roles) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && Array.isArray(obj[key]) && obj[key].length > 0) {
          if (key !== 'colDashboard') {
            firstKeyWithArrayValue = key;
            break;
          }
        }
      }
      if (firstKeyWithArrayValue) {
        break;
      }
    }
  }

  const pathName = getPathNameBaseOnModule(firstKeyWithArrayValue);
  return pathName ?? '/';
};

export const handleGetPermissionFromModule = (modules?: ModuleI[], name?: string) => {
  const permissions = modules?.find((module: ModuleI) => module.name === name)?.permissions ?? [];
  return getModulePermissions(permissions);
};
