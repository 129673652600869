import { Form, Radio, Space } from 'antd';
import React, { useEffect } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import Text from '../Text';
import './UpdateStatus.scss';
import { CommonStatus } from '../CommonStatus';

type Props = {
  data?: any;
  statusList: { value: string; name: string }[];
  handleSubmit: (_: any) => void;
  onCancel: () => void;
};

const AppUpdateStatusForm: React.FC<Props> = ({ data, statusList, onCancel, handleSubmit }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        status: data.status,
      });
    }
  }, [data]);

  return (
    <div className="update-status-form-container">
      <Form form={form} scrollToFirstError layout="vertical" requiredMark={false} colon={false} onFinish={handleSubmit}>
        <Text variant="h3" className="tw-mb-6">
          Update Status
        </Text>
        <div className="FormContent">
          <Form.Item name="status">
            <Radio.Group value={data?.status}>
              <Space direction="vertical" className="tw-w-full">
                {statusList.map((item: { value: string; name: string }) => (
                  <Radio key={item.value} value={item.value}>
                    <CommonStatus status={item.value} text={item.name} />
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
        <PrimaryButton className="tw-w-full tw-mb-3" label="Update" htmlType="submit" />
        <SecondaryButton className="tw-w-full" label="Cancel" onClick={onCancel} />
      </Form>
    </div>
  );
};

export default AppUpdateStatusForm;
