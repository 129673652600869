import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import './ScanTabsContent.scss';
import { HardwareScannedStateI, HardwareTypeI } from '../../../interfaces/hardware';
import { renderNoticeContentByType } from '../../../utils/hardware';
import EmptyData from '../../EmptyData';
import Text from '../../Text';
import Icon from '../../Icon';

type Props = {
  registerType?: HardwareTypeI;
  registerData: HardwareScannedStateI[];
};

const ScanTabsContent: React.FC<Props> = ({ registerType, registerData }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="shared-hardware-scan-tabs-content">
      <p className="notice-section">{renderNoticeContentByType(registerType)}</p>
      <div className="scanned-btn">
        <Button type="primary" onClick={showDrawer}>
          <Icon name="icon-all_inbox" size={24} />
          {registerData.length} Code(s) Scanned
          <Icon name="icon-expand_more" size={24} />
        </Button>
      </div>
      <Drawer
        title={
          <div className="tw-flex tw-gap-2 tw-items-center">
            <Icon name="icon-all_inbox" size={24} />
            {registerData.length} Code(s) Scanned
          </div>
        }
        onClose={onDrawerClose}
        open={open}
        rootClassName="hardware-scan-qrcode-drawer"
      >
        <div className="hardware-scanned-list-drawer">
          {registerData?.length > 0 ? (
            registerData.map((data: HardwareScannedStateI) => (
              <div key={data.serialCode} className="item">
                <Text variant="bodyLgB" className="tw-text-primary-900 !tw-block">{`${data.serialCode}`}</Text>
              </div>
            ))
          ) : (
            <EmptyData message="No hardware scanned yet" />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default ScanTabsContent;
