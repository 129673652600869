import React from 'react';
import LoginForm from '../../components/forms/LoginForm';
import './Login.scss';
import { doLogin } from '../../apis/auth';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LoginParamsI } from '../../interfaces/auth';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../../constants';
import EAnalytics from '../../analytics';

const LoginPage: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleSubmit = async (values: LoginParamsI) => {
    EAnalytics.trackLogin(values.emailUsername);

    try {
      const { data }: AxiosResponse = await doLogin(values);
      const authData = JSON.stringify(data?.data);
      Cookies.set(TOKEN_NAME, authData);
      queryClient.invalidateQueries();
      if (data?.data?.authToken) navigate('/');
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="auth-container">
      <LoginForm handleSubmit={handleSubmit} />
    </div>
  );
};

export default LoginPage;
