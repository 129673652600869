import { Checkbox, Spin } from 'antd';
import React, { useMemo } from 'react';
import ActionSection from './ActionSection';
import { ModuleI } from '../../interfaces/module';
import { RoleI } from '../../interfaces/role';
import { CheckboxOptionI } from '../../interfaces';
import { getModulePermissions } from '../../utils/global';

type Props = {
  modules?: ModuleI[];
  role?: RoleI;
};

const RolePartnerCollapseDetail: React.FC<Props> = ({ modules, role }) => {
  const partnerCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Partner')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);
  const userCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'User')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const roleCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Role')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const siteCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Site')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  return (
    <Spin spinning={!role}>
      <ActionSection
        label="Profile"
        content={<Checkbox.Group options={partnerCheckboxOptions} value={role?.colPartner} />}
      />
      <ActionSection label="Users" content={<Checkbox.Group options={userCheckboxOptions} value={role?.colUser} />} />
      <ActionSection label="Roles" content={<Checkbox.Group options={roleCheckboxOptions} value={role?.colRole} />} />
      <ActionSection label="Sites" content={<Checkbox.Group options={siteCheckboxOptions} value={role?.colSite} />} />
    </Spin>
  );
};

export default RolePartnerCollapseDetail;
