import { BaseResponse } from '../../interfaces';
import { ProductI, ProductSearchParamsI, ProductsCollectionI } from '../../interfaces/product';
import { handleFilterParams } from '../../utils/global';
import { api } from '../api';
import { useFetch } from '../reactQuery';

export const useGetPlatformProduct = (sku?: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`platform/products/${sku}`);
  const data: ProductI = response?.data;
  return { data, error, isLoading };
};

export const getPlatformProductDetails = async (sku?: string) => {
  const { data: response } = await api.get<BaseResponse<ProductI>>(`platform/products/${sku}`);
  return response?.data;
};

export const getPlatformProductsList = async (params?: ProductSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<ProductsCollectionI>>(
    `platform/products?${handleFilterParams(params)}`,
  );
  return response?.data;
};
