import React from 'react';
import { Button, Dropdown, Pagination } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../svgs/MoreActionIcon';
import Divider from '../Divider';
import { RoleI } from '../../interfaces/role';
import { CommonStatus } from '../CommonStatus';
import { AccessControlI } from '../../interfaces';
import Icon from '../Icon';
import Text from '../Text';

interface Props {
  access: AccessControlI;
  data: RoleI[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const RolesCardList: React.FC<Props> = ({ access, data, pagination, onPaginationChange }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: RoleI) => {
    if (access.isCanRead) navitate(`/roles/${item.id}`);
  };

  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="card-list">
        {data.map((item) => {
          return (
            <div
              key={item.id}
              className="card-item"
              onClick={() => handleCardClick(item)}
              onKeyDown={() => handleCardClick(item)}
            >
              <div
                className="tw-flex tw-w-full tw-justify-between tw-p-0"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Text variant="inputLabelSm" className="tw-text-primary-800">
                  {item.partner.displayName}
                </Text>
                <Dropdown
                  menu={{
                    items: [
                      access.isCanRead
                        ? {
                            key: '1',
                            label: (
                              <Link to={`/roles/${item.id}`}>
                                <Button className="btn-table-action-primary" type="link">
                                  View
                                </Button>
                              </Link>
                            ),
                          }
                        : null,
                      access.isCanUpdate
                        ? {
                            key: '2',
                            label: (
                              <Link to={`/roles/${item.id}/edit`}>
                                <Button className="btn-table-action-primary" type="link">
                                  Edit Profile
                                </Button>
                              </Link>
                            ),
                          }
                        : null,
                    ],
                  }}
                >
                  <div>
                    <MoreActionIcon />
                  </div>
                </Dropdown>
              </div>
              <div className="tw-flex tw-justify-between tw-flex-col tw-w-full tw-h-full">
                <div className="tw-flex tw-flex-col">
                  <Text variant="bodyMdB" className="tw-font-bold tw-mb-4">
                    {item.name}
                  </Text>
                  <div className="tw-mb-3 tw-flex tw-items-center tw-gap-1">
                    <Icon name="icon-person_outline" size={16} className="tw-text-grey-700 tw-min-w-4" />
                    <Text variant="inputLabelSm">{item?.users?.length ?? 'No of User(s)'}</Text>
                  </div>
                </div>
              </div>
              <div className="tw-w-full">
                <Divider />
                <div className="tw-pt-3">
                  <CommonStatus status={item.status} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        pageSizeOptions={[5, 10, 20, 50]}
        showSizeChanger
      />
    </section>
  );
};

export default RolesCardList;
