import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const ReceiveIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg width={width ?? 16} height={height ?? 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#226;&#173;&#144;&#239;&#184;&#143; Icon">
        <g id="Vector">
          <path
            d="M12.333 7.3335C12.4463 7.3335 12.5597 7.34016 12.6663 7.3535V4.58016C12.6663 3.8935 12.1063 3.3335 11.4197 3.3335H2.57967C1.89301 3.3335 1.33301 3.8935 1.33301 4.58016V11.4202C1.33301 12.1068 1.89301 12.6668 2.57967 12.6668H9.06634C8.81301 12.1668 8.66634 11.6002 8.66634 11.0002C8.66634 8.9735 10.3063 7.3335 12.333 7.3335ZM6.93301 8.66683L2.66634 6.12683V4.66683H2.81967L6.93967 7.12016L11.1597 4.66683H11.333V6.10683L6.93301 8.66683Z"
            fill={color ?? 'currentColor'}
          />
          <path
            d="M12.6663 8.66683L13.6063 9.60683L12.553 10.6668H15.333V12.0002H12.553L13.6063 13.0602L12.6663 14.0002L9.99967 11.3335L12.6663 8.66683Z"
            fill={color ?? 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
};

export default ReceiveIcon;
