import React from 'react';
import { Checkbox, Form } from 'antd';
import ActionSection from '../../Role/ActionSection';
import Text from '../../Text';
import { CurrentUserI } from '../../../interfaces/auth';
import { useOutletContext } from 'react-router-dom';
import { handleAccessControl } from '../../../utils/role';

type Props = {
  onCheckboxChange: (values: string[], colName: string) => void;
};

const GeneralCollapse: React.FC<Props> = ({ onCheckboxChange }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'roles');
  const marketOption = [
    { label: 'AMER', value: 'AMER' },
    { label: 'APAC', value: 'APAC' },
    { label: 'EMEA', value: 'EMEA' },
  ];

  return (
    <>
      <Text variant="tableMd" className="tw-text-grey-700 tw-p-3 tw-w-full tw-divide-y-2 subtitle-collapse">
        MARKETS
      </Text>
      <Form.Item name="colPlatform_viewable_markets">
        <Checkbox.Group
          className="market-checkbox"
          options={marketOption}
          onChange={(e) => onCheckboxChange(e, 'colPlatform_viewable_markets')}
        />
      </Form.Item>
      {access.isCanReadPlatform && (
        <>
          <Text variant="tableMd" className="tw-text-grey-700 tw-p-3 tw-w-full subtitle-collapse">
            CONTROL ACCESS
          </Text>
          <ActionSection
            className="general-collapse"
            label="Impersonate Partner"
            content={
              <Form.Item name="colPlatform_impersonation">
                <Checkbox.Group
                  options={[{ label: '', value: 'C' }]}
                  onChange={(e) => onCheckboxChange(e, 'colPlatform_impersonation')}
                />
              </Form.Item>
            }
          />
        </>
      )}
    </>
  );
};

export default GeneralCollapse;
