import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Navigate, useLocation } from 'react-router-dom';
import { TOKEN_NAME } from './constants';
import AdminLayout from './pages/AdminLayout';
import { useGetProfile } from './apis/auth';
import { Spin } from 'antd';
import { CurrentUserI } from './interfaces/auth';
import { handleCheckAuthorize, handleGetNextPath } from './utils/role';
import EAnalytics from './analytics';

const RequireAuth: React.FC = () => {
  const location = useLocation();
  const token = Cookies.get(TOKEN_NAME);
  const profile = useGetProfile(!!token);

  const currentUserId = profile.data?.data?.id;

  useEffect(() => {
    if (currentUserId) {
      EAnalytics.setUserId(currentUserId);
    }
  }, [currentUserId]);

  const renderDashboard = (currentUser: CurrentUserI) => {
    const pathName = handleGetNextPath(currentUser);
    return <Navigate to={pathName} state={{ from: location }} replace />;
  };

  if (token) {
    if (profile && profile.isSuccess === true) {
      const userInfo: CurrentUserI = profile.data.data;
      if (location.pathname === '/') {
        return renderDashboard(userInfo);
      } else {
        const isAuthorize = handleCheckAuthorize(userInfo, location.pathname);
        if (isAuthorize) {
          return <AdminLayout currentUser={userInfo} />;
        } else return <Navigate to="/unauthorized" state={{ from: location }} replace />;
      }
    } else if (profile.isError) {
      Cookies.remove(TOKEN_NAME);
      return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
      return (
        <div className="loading-screen">
          <Spin spinning={true} />
        </div>
      );
    }
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
