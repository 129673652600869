import { Form, MenuProps, Modal, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Icon from '../Icon';
import Text from '../Text';
import DropdownApp from '../Dropdown';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import { useMutation, useQueryClient } from 'react-query';
import AppUpdateStatusForm from '../forms/AppUpdateStatus';
import { ProductAttachmentCategories, ProductStatusList } from '../../constants';
import { CommonStatus } from '../CommonStatus';
import BackLink from '../buttons/BackLink';
import { ProductI } from '../../interfaces/product';
import { updateProduct, updateProductStatus } from '../../apis/products';
import AddAttachmentForm from '../forms/AddAttachmentForm';

type Props = {
  product?: Partial<ProductI>;
};

const ProductTopSection: React.FC<Props> = ({ product }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'products');
  const navigate = useNavigate();
  const [isUpdateStatusVisible, setIsUpdateStatusVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsUpdateStatusVisible(false);
  };

  const { mutate: updateStatus } = useMutation(updateProductStatus, {
    onSuccess: async () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const onUpdateStatusSubmit = async (values: { status: string }) => {
    updateStatus({ sku: product?.sku, status: values.status });
    setIsUpdateStatusVisible(false);
  };

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'update-status') setIsUpdateStatusVisible(true);
    if (e.key === 'edit') navigate(`/products/${product?.sku}/edit`);
    if (e.key === 'update-attachments') setIsVisibleModalUpload(true);
  };

  const { mutate: uploadAttachment } = useMutation(updateProduct, {
    onSuccess: async () => {
      message.info('Uploaded attachment!');
      queryClient.invalidateQueries({
        queryKey: [`products/${product?.sku}`],
      });
    },
    onError: () => {
      message.error('Adding attachment failed! Please try again');
    },
  });

  function handleCancelUploadAttachment() {
    setIsVisibleModalUpload(false);
    form.resetFields(['categoryName', 'file']);
  }

  const items: MenuProps['items'] = [
    access.isCanUpdate
      ? {
          key: 'edit',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-edit" size={16} />
              <span>Edit Product</span>
            </Text>
          ),
        }
      : null,
    access.isCanUpdateStatus
      ? {
          key: 'update-status',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-radio_button_checked" size={16} />
              <span>Update Status</span>
            </Text>
          ),
        }
      : null,
    access?.isCanUpdate
      ? {
          key: 'update-attachments',
          label: (
            <>
              <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
                <Icon name="icon-upload_file" size={16} />
                <span>Upload Attachments</span>
              </Text>
            </>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="product-name-container tw-mb-6">
      <div className="content-wrap">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <BackLink />
          <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
        </div>
        <div className="wrapper tw-flex tw-items-center tw-flex-row">
          <div className="name-section">
            <Text variant="h2">{product?.name}</Text>
            <div className="status tw-mt-2">{<CommonStatus status={product?.status as ProductI['status']} />}</div>
          </div>
        </div>
      </div>
      <Modal open={isUpdateStatusVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={412}>
        <AppUpdateStatusForm
          data={product}
          onCancel={handleCancel}
          handleSubmit={onUpdateStatusSubmit}
          statusList={ProductStatusList}
        />
      </Modal>
      <Modal
        open={isVisibleModalUpload}
        onOk={handleCancelUploadAttachment}
        onCancel={handleCancelUploadAttachment}
        footer={null}
        width={560}
      >
        <AddAttachmentForm
          form={form}
          handleCancel={handleCancelUploadAttachment}
          multiple
          categories={ProductAttachmentCategories}
          update={uploadAttachment}
        />
      </Modal>
    </div>
  );
};

export default ProductTopSection;
