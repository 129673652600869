import { Form, FormInstance, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { HardwareGroupParamsI } from '../../interfaces/hardware';
import SecondaryButton from '../buttons/SecondaryButton';
import Icon from '../Icon';
import ScanUpdateProductGroupCode from './ScanUpdateGroupCode';

type Props = {
  form: FormInstance;
  groupId?: string;
  group?: any;
  isCodeDuplicate: boolean;
  handleSubmit(_: any): void;
};

const ProductGroupForm: React.FC<Props> = ({ group, groupId, form, isCodeDuplicate, handleSubmit }) => {
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState('');

  const handleOnFinish = (values: Partial<HardwareGroupParamsI>) => {
    handleSubmit(values);
  };

  const handleCancel = () => {
    setTimeout(() => setStopDecodingScanner(true), 900);
    setTimeout(() => setIsModalVisible(false), 1000);
  };

  const onUpdateCodeClick = () => {
    setIsModalVisible(true);
    setStopDecodingScanner(false);
  };

  const handleUpdateCodeSubmit = async (code: string) => {
    form.setFieldsValue({ code });
    setStopDecodingScanner(true);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (group && group.code === groupId) {
      form.setFieldsValue({
        ...group,
      });
    }
  }, [group]);

  useEffect(() => {
    if (isCodeDuplicate) {
      setWarningMessage('Duplicate Product Group Code. Please scan a new unique group code');
    }
  }, [isCodeDuplicate]);

  return (
    <div className="group-form-container">
      <Form form={form} onFinish={handleOnFinish} scrollToFirstError autoComplete="off" layout="vertical">
        <div className="form-container">
          <div className="form-content">
            <div className="content-wrap">
              <h2 className="form-title">Product Group Details</h2>
              <Form.Item
                label="Product Group Name"
                name="title"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type Product group title" />
              </Form.Item>
              <div className="tw-flex tw-gap-2">
                <Form.Item
                  label="Product Group Code"
                  name="code"
                  rules={[{ required: true, message: 'This field is required' }]}
                  className="tw-flex-1"
                  help={warningMessage || ''}
                  validateStatus={warningMessage ? 'error' : undefined}
                >
                  <Input placeholder="Type Product group title" disabled />
                </Form.Item>
                <SecondaryButton
                  label="Update Code"
                  icon={<Icon name="icon-camera_alt" size={16} />}
                  className="tw-self-end tw-mb-[16px] tw-max-h-[40px]"
                  onClick={onUpdateCodeClick}
                />
              </div>
              <Form.Item label="Remarks" name="remarks">
                <Input.TextArea placeholder="Type remarks" rows={5} />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        open={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        width={680}
        rootClassName="ScanUpdateGroupCodeModal"
      >
        <ScanUpdateProductGroupCode
          setStopDecodingScanner={setStopDecodingScanner}
          stopDecodingScanner={stopDecodingScanner}
          handleScanned={handleUpdateCodeSubmit}
        />
      </Modal>
    </div>
  );
};

export default ProductGroupForm;
