import { Image, MenuProps, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import DefaultSVG from '../../svgs/supervised_user_circle.svg';
import { PartnerI } from '../../interfaces/partner';
import Text from '../Text';
import { PartnerStatus } from '../../pages/Partners/PartnerStatus';
import { useMutation, useQueryClient } from 'react-query';
import { PartnerStatusList } from '../../constants';
import { CurrentUserI } from '../../interfaces/auth';
import { useOutletContext } from 'react-router';
import { handleAccessControl } from '../../utils/role';
import Icon from '../Icon';
import DropdownApp from '../Dropdown';
import AppUpdateStatusForm from '../forms/AppUpdateStatus';
import BackLink from '../buttons/BackLink';
import { useNavigate } from 'react-router-dom';
import useImpersonation from '../Impersonation/useImpersonation';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { UserI } from '../../interfaces/user';
import { updatePartnerProfileStatus } from '../../apis/profile';

type Props = {
  partner: Partial<PartnerI>;
};

const PartnerProfileName: React.FC<Props> = ({ partner }) => {
  const { impersonate, isLoading, isImpersonating } = useImpersonation();

  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'partners');

  const impersonationAccess = handleAccessControl(currentUser, 'platform/impersonation');
  const navigate = useNavigate();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [isUpdateStatusVisible, setIsUpdateStatusVisible] = useState<boolean>(false);
  const [isConfirmImpersonate, setIsConfirmImpersonate] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const isMyProfile = partner?.id === currentUser?.partnerId;
  const amIEcoSpirit = currentUser.partner.isEcoSpirit;
  const canImpersonate = impersonationAccess.isCanCreate && amIEcoSpirit && !isMyProfile;

  const handleCancel = () => {
    setIsUpdateStatusVisible(false);
  };

  const { mutate: updateStatus } = useMutation(updatePartnerProfileStatus, {
    onSuccess: async () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const onUpdateStatusSubmit = async (values: { status: string }) => {
    updateStatus({ id: Number(partner?.id), status: values.status });
    setIsUpdateStatusVisible(false);
  };

  useEffect(() => {
    if (partner) {
      const attachments = partner?.attachments;
      const logos = attachments?.filter((attachment: any) => (attachment.categoryName = 'logo'));
      setLogoUrl(logos ? logos[0]?.url : DefaultSVG);
    }
  }, [partner]);

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'update-status') setIsUpdateStatusVisible(true);
    if (e.key === 'edit') navigate(`/partner-profile/edit`);
    if (e.key === 'impersonate') setIsConfirmImpersonate(true);
  };

  const items: MenuProps['items'] = [
    access.isCanUpdate
      ? {
          key: 'edit',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-edit" size={16} />
              <span>Edit Partner Profile</span>
            </Text>
          ),
        }
      : null,
    access.isCanUpdateStatus
      ? {
          key: 'update-status',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-radio_button_checked" size={16} />
              <span>Update Status</span>
            </Text>
          ),
        }
      : null,

    canImpersonate
      ? {
          key: 'impersonate',
          disabled: isImpersonating,
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-people_alt" size={16} />
              <span>Impersonate</span>
            </Text>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="partner-name-container">
      <div className="content-wrap">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <BackLink />
          <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
        </div>
        <div className="wrapper">
          <div className="logo-section">
            <Image src={logoUrl} alt="Partner Logo" preview={false} fallback={DefaultSVG} />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-2">
            <Text variant="h4" component="h4">
              {partner?.legalName}
            </Text>
            <div>{partner && <PartnerStatus status={partner.status as PartnerI['status']} />}</div>
          </div>
        </div>
      </div>
      <Modal open={isUpdateStatusVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={412}>
        <AppUpdateStatusForm
          data={partner}
          onCancel={handleCancel}
          handleSubmit={onUpdateStatusSubmit}
          statusList={PartnerStatusList}
        />
      </Modal>

      <Modal
        confirmLoading={isLoading}
        open={isConfirmImpersonate}
        onCancel={() => setIsConfirmImpersonate(false)}
        footer={null}
        width={412}
      >
        <div className="tw-flex tw-flex-col tw-p-4 tw-gap-8">
          <Icon name="icon-people_alt" className="tw-text-primary-800 tw-m-auto" />
          <Text variant="h3" className="tw-text-primary-800">
            Are you sure you want to impersonate this Partner’s Admin?
          </Text>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <PrimaryButton
              className="tw-w-full tw-mb-3"
              label="Confirm"
              onClick={() => {
                const admin = partner.users?.filter((user: UserI) => user.type === 'partnerAdmin')[0];
                if (admin) {
                  impersonate(admin.email);
                }
              }}
            />
            <SecondaryButton className="tw-w-full" label="Cancel" onClick={() => setIsConfirmImpersonate(false)} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PartnerProfileName;
