import React, { ReactNode } from 'react';
import '../shared/Hardware/StartScanningScreen.scss';
import { Notice } from '../Notice';
import WarningIcon from '../../svgs/WarningIcon';
import SizeBox from '../SizeBox';
import EcoPlantIllustration from '../../svgs/illustrations/EcoPlantIllustration';
import LotNumberIllustration from '../../svgs/illustrations/LotNumberIllustration';
import ProductIllustration from '../../svgs/illustrations/ProductIllustration';
import EcoToteIllustration from '../../svgs/illustrations/EcoToteIllustration';

interface Props {
  warningDescription?: ReactNode;
  warningDescription2?: ReactNode;
  content?: ReactNode;
  currentStep?: number;
  setScreen: (screen: 'start' | 'scan' | 'scanned') => void;
  setStopDecodingScanner: (_: any) => void;
}

export const FillingStartScanningScreen: React.FC<Props> = ({
  warningDescription2,
  warningDescription,
  content,
  currentStep,
}) => {
  return (
    <div className="start-scanning-screen-component">
      <div className="tw-max-w-[450px] tw-max-h-[300px] tw-my-0 tw-mx-auto tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-pl-3">
        {currentStep === 1 && (
          <div className="tw-pt-[22px]">
            <EcoPlantIllustration />
          </div>
        )}
        {currentStep === 2 && (
          <div className="tw-pb-4 tw-text-center tw-pt-[22px] ">
            <ProductIllustration />
          </div>
        )}
        {currentStep === 3 && (
          <div className="tw-pb-4 tw-text-center tw-pt-[22px] ">
            <LotNumberIllustration />
          </div>
        )}
        {currentStep === 4 && (
          <div className="tw-text-center tw-pt-2">
            <EcoToteIllustration />
          </div>
        )}
      </div>
      <div className="tw-max-w-[450px] tw-my-0 tw-mx-auto">
        {content && (
          <>
            <SizeBox height={24} />
            {content}
          </>
        )}
        {warningDescription && (
          <>
            <SizeBox height={24} />
            <Notice description={warningDescription} type="warning" icon={<WarningIcon />} closable={false} />
          </>
        )}
        {warningDescription2 && (
          <>
            <SizeBox height={24} />
            <Notice description={warningDescription2} type="warning" icon={<WarningIcon />} closable={false} />
          </>
        )}
      </div>
    </div>
  );
};
