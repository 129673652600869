import React, { useState } from 'react';
import { ProductI } from '../../../interfaces/product';
import ProductGroupDetailItemCard from '../../ProductGroup/DetailItemCard';
import { PAGE_SIZE } from '../../../constants';
import { Pagination } from 'antd';
import EmptyData from '../../EmptyData';

type Props = {
  isHasFilter?: boolean;
  products?: ProductI[];
};

const ProductList: React.FC<Props> = ({ products, isHasFilter = false }) => {
  console.log('isHasFilter', isHasFilter);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;

  const handlePaginationChange = (page: number, per_page: number) => {
    setCurrentPage(page);
    setPageSize(per_page);
  };

  return (
    <div className="tw-py-4 tw-px-4">
      {products && products.length > 0 ? (
        <>
          <div className="tw-flex tw-gap-4 tw-flex-wrap">
            {products?.slice(start, end)?.map((item: ProductI) => {
              return <ProductGroupDetailItemCard item={item} key={item.id} canDelete={false} />;
            })}
          </div>
          <div className="pagination-container">
            <Pagination
              total={products?.length ?? 0}
              pageSize={pageSize}
              showTotal={(total) => `${total} Items`}
              current={currentPage}
              onChange={handlePaginationChange}
              showSizeChanger
              pageSizeOptions={[5, 10, 20, 50]}
            />
          </div>
        </>
      ) : (
        <EmptyData title="No data" message="All products will be displayed here." />
      )}
    </div>
  );
};

export default ProductList;
