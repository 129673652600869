import { Checkbox, CheckboxProps, Form, FormInstance, Input, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import RoleCollapseForm from './CollapseForm';
import { RoleParamsI } from '../../../interfaces/role';
import { useQuery } from 'react-query';
import { CurrentUserI } from '../../../interfaces/auth';
import { getModulesList } from '../../../apis/modules';
import ErrorModalIcon from '../../../svgs/ErrorModalIcon';
import { ModuleI } from '../../../interfaces/module';
import Text from '../../Text';

type Props = {
  form: FormInstance;
  role?: any;
  currentUser: CurrentUserI;
  handleSubmit(_: any): void;
};

const getAllPermissions = (modules: ModuleI[]) => {
  let merged: any = {};
  modules?.forEach((module: ModuleI) => {
    merged[`col${module.name}`] = module.permissions;
  });
  return merged;
};

const countLength = (data: any) => {
  let counted = 0;
  Object.values(data).forEach((element: any) => {
    counted += element.length;
  });
  return counted;
};

const RoleForm: React.FC<Props> = ({ form, role, handleSubmit }) => {
  const [modal, contextHolder] = Modal.useModal();
  const [checkedList, setCheckedList] = useState({});
  const [plainOptions, setPlainOptions] = useState({});
  const [plainOptionsLength, setPlainOptionsLength] = useState<number>(0);
  const [checkedListLength, setCheckedListLength] = useState<number>(0);

  const checkAll = plainOptionsLength === checkedListLength;
  const indeterminate = checkedListLength > 0 && checkedListLength < plainOptionsLength;

  const { data: moduleData, isLoading: isModuleLoading } = useQuery(['modules', 'list', {}], () => getModulesList());
  const checkPermissionsEmpty = (values: RoleParamsI) => {
    let isEmpty = true;
    for (const i of Object.values(values)) {
      if (Array.isArray(i) && i.length > 0) {
        isEmpty = false;
      }
    }
    return isEmpty;
  };

  const handleOnFinish = (values: RoleParamsI) => {
    const isEmpty = checkPermissionsEmpty(values);

    if (isEmpty) {
      const errorModal = modal.error({
        type: 'error',
        title: 'Unable to Submit',
        content: 'Roles need to have at least 1 permission',
        className: 'context-modal-custom-container',
        closable: true,
        centered: true,
        footer: null,
        icon: <ErrorModalIcon color="#B13939" />,
        width: 345,
      });

      setTimeout(() => {
        errorModal.destroy();
      }, 5 * 1000);
    } else {
      handleSubmit(values);
    }
  };

  const onCheckboxChange = (values: string[], colName: any) => {
    let newList: any = { ...checkedList };
    newList[colName] = values;
    setCheckedList({ ...checkedList, ...newList });

    if (
      !values.includes('R') &&
      colName !== 'colPlatform_impersonation' &&
      colName !== 'colPlatform_viewable_markets'
    ) {
      const newValue = [...values, 'R'];
      form.setFieldValue(colName, newValue);
    }

    if (Object.keys(values).length === 0) {
      form.setFieldValue(colName, []);
    }

    if (colName === 'colAllocation') {
      if (values.includes('C') || values.includes('U')) {
        form.setFieldValue('colSite', ['R']);
      }
    }
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setCheckedList(e.target.checked ? plainOptions : {});
    if (e.target.checked) {
      form.setFieldsValue(plainOptions);
    } else {
      const newList: any = { ...checkedList };
      Object.keys(checkedList).forEach((item) => {
        newList[item] = [];
      });
      form.setFieldsValue({ ...newList });
    }
  };

  useEffect(() => {
    if (role) {
      form.setFieldsValue({
        ...role,
      });
    }
  }, [role]);

  useEffect(() => {
    if (checkedList) {
      setCheckedListLength(countLength(checkedList));
    }
  }, [checkedList]);

  useEffect(() => {
    if (moduleData) {
      const modules = getAllPermissions(moduleData?.modules ?? []);
      setPlainOptionsLength(countLength(modules));
      setPlainOptions(modules);
    }
  }, [moduleData]);

  return (
    <div className="role-form-container">
      {contextHolder}
      <Form form={form} onFinish={handleOnFinish} scrollToFirstError autoComplete="off">
        <div className="form-container">
          <div className="form-content">
            <div className="content-wrap">
              <h2 className="form-title">Role Information</h2>
              <Form.Item label="Role Name" name="name" rules={[{ required: true, message: 'This field is required' }]}>
                <Input placeholder="Type your role name" />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea placeholder="Type a description to describe this role" />
              </Form.Item>
            </div>
            <div className="content-wrap" style={{ marginTop: 16 }}>
              <h2 className="form-title tw-flex tw-justify-between">
                <span>Access</span>
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                  <Text variant="bodySm" className="tw-flex">
                    Select All
                  </Text>
                </Checkbox>
              </h2>
              <Spin spinning={isModuleLoading}>
                <RoleCollapseForm modules={moduleData?.modules} onCheckboxChange={onCheckboxChange} />
              </Spin>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RoleForm;
