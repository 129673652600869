import React from 'react';
import { DateFormat } from '../../utils/global';
import DetailsView from '../DetailsView';
import { TransferI } from '../../interfaces/transfer';
import SizeBox from '../SizeBox';
import { handleGetUniqType } from '../../utils/hardware';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  data?: TransferI;
};

const TransferInformation: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <DetailsView title="New Hardware Owner’s Information">
        <div className="flex-row">
          <DetailsView.Field label="Transferred to Partner:" value={data?.newOwner?.displayName} />
          <DetailsView.Field label="Date Transferred" value={DateFormat(data?.createdAt)} />
        </div>
      </DetailsView>
      <SizeBox height={16} />
      <DetailsView title="Ownership Transfer Details">
        <div className="flex-row">
          <DetailsView.Field label="Number of Hardware" value={data?.hardwareList.length} />
          <DetailsView.Field
            label="Hardware Type"
            value={handleGetUniqType(data?.hardwareList ?? []).map((item: { type: string; count: number }) => (
              <>
                {item.type.includes('ecoTOTE') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="labelMd">{item.count}</Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
                {item.type.includes('ecoPLANT') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="labelMd">{item.count}</Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
                {item.type.includes('SmartPour') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="labelMd">{item.count}</Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
              </>
            ))}
          />
        </div>
        {/* <Divider />
        <DetailsView.Field label="Remarks" value={data?.remarks} /> */}
      </DetailsView>
    </div>
  );
};

export default TransferInformation;
