import { Drawer } from 'antd';
import React, { ReactNode, useState } from 'react';
import './ViewItemsOfGroup.scss';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import GroupRemoveHardwareDrawer from '../Tracking/GroupRemoveHardwareDrawer';
import GroupRemoveProductDrawer from '../ProductGroup/GroupRemoveProductDrawer';

type Props = {
  label?: string;
  data: any;
  drawerTitle: ReactNode;
};

const ViewItemOfGroupButton: React.FC<Props> = ({ label, data, drawerTitle }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  return (
    <>
      <button
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        className="tw-inline-flex tw-items-center tw-border-solid tw-border tw-border-primary-800 tw-py-1 tw-px-3 tw-bg-white tw-text-primary-800"
      >
        <span>{label}</span>
        <RightArrowIcon width={16} height={16} />{' '}
      </button>
      <Drawer
        title={drawerTitle}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        rootClassName="history-drawer"
        width={884}
      >
        {data?.hardwares?.length ? (
          <GroupRemoveHardwareDrawer data={data} isDisplayDelete={false} isDisplayFilter={false} />
        ) : (
          <GroupRemoveProductDrawer data={data} isDisplayDelete={false} isDisplayFilter={false} />
        )}
      </Drawer>
    </>
  );
};

export default ViewItemOfGroupButton;
