import { Button, Modal, message } from 'antd';
import React, { useState } from 'react';
import './IconButton.scss';
import { ReactSVG } from 'react-svg';
import UploadFileSvg from '../../svgs/upload_file.svg';
import { AttachmentI } from '../../interfaces/attachments';
import UploadAttachmentForm from '../forms/UploadAttachmentForm';
import FileDefault from '../../images/file-default.png';
import TrashSVG from '../../svgs/trash.svg';
import DangerButton from './DangerButton';
import './UploadAttachmentButton.scss';
import { useMutation, useQueryClient } from 'react-query';
import { deleteAttachment } from '../../apis/attachments';
import { UploadAttachmentCategoryI } from '../../interfaces';

type Props = {
  form: any;
  files: AttachmentI[];
  setFiles: any;
  multiple?: boolean;
  isRequired?: boolean;
  categories?: { value: UploadAttachmentCategoryI; name: string }[];
  className?: string;
  style?: React.CSSProperties;
};

const UploadAttachmentButton: React.FC<Props> = ({
  files,
  form,
  setFiles,
  isRequired,
  multiple,
  categories,
  className,
  style,
}) => {
  const queryClient = useQueryClient();
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  function handleCancel() {
    setIsVisibleModal(false);
    form.resetFields(['categoryName', 'file']);
  }

  function onUploadClick() {
    setIsVisibleModal(true);
  }

  const { mutateAsync: mutationDelete } = useMutation(deleteAttachment, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries();
    },
  });

  function handleDelete(document: AttachmentI, index: number) {
    if (document?.data) {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);
    } else {
      mutationDelete(Number(document.id));
    }
  }

  return (
    <div className={`upload-attachment-button-container ${className}`} style={{ ...style }}>
      <Button type="primary" className={`btn-upload-attachment`} onClick={onUploadClick}>
        <ReactSVG src={UploadFileSvg} />
        Upload Attachment
      </Button>
      <div className="file-list-wrap">
        {files?.map((file: any, index: number) => (
          <div className="document-item" key={file.id || file.uid || index}>
            <div className="document-info">
              <div className="image">
                {file.type.includes('image') ? (
                  <img src={file?.url ? file.url : FileDefault} alt="attachment" />
                ) : (
                  <img src={FileDefault} alt="attachment" />
                )}
              </div>
              <div className="content">
                <p className="file-name">{file?.name}</p>
                <p className="category-name text-capitalize">{`Category: ${file?.categoryName ?? ''}`}</p>
              </div>
            </div>
            <DangerButton onOk={() => handleDelete(file, index)} icon={<ReactSVG src={TrashSVG} />} />
          </div>
        ))}
      </div>
      <Modal open={isVisibleModal} onOk={handleCancel} onCancel={handleCancel} footer={null} width={560}>
        <UploadAttachmentForm
          files={files}
          setFiles={setFiles}
          handleCancel={handleCancel}
          isRequired={isRequired}
          multiple={multiple}
          categories={categories}
        />
      </Modal>
    </div>
  );
};

export default UploadAttachmentButton;
