import React, { ReactNode } from 'react';
import { Button, Divider, Dropdown, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../svgs/MoreActionIcon';
import './HardwareCardItem.scss';
import { DateFormat } from '../../utils/global';
import Text from '../Text';
import { IconName } from '../Icon/iconName';
import Icon from '../Icon';
import { HardwareFaultReportI } from '../../interfaces/hardwareFaultReports';
import { CommonStatus } from '../CommonStatus';

type Props = {
  item: HardwareFaultReportI;
};
const FaultReportCardItem: React.FC<Props> = ({ item }) => {
  const navitate = useNavigate();
  const items = [
    {
      key: '1',
      label: (
        <Link to={`/fault-reports/${item.code}`}>
          <Button className="btn-table-action-primary" type="link">
            View
          </Button>
        </Link>
      ),
    },
  ];

  const handleCardClick = (item: any) => {
    navitate(`/fault-reports/${item.code}`);
  };

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button className="hardware-card-item card-item tw-border-none" onClick={() => handleCardClick(item)}>
      <a className="btn-more-action" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
        <Dropdown menu={{ items }}>
          <Space>
            <MoreActionIcon />
          </Space>
        </Dropdown>
      </a>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <Text variant="inputLabelSm" className="tw-text-grey-700">
          Fault Report
        </Text>
        <Text variant="bodyLgB" className="tw-text-primary-800">
          {item.code}
        </Text>
        <div className="tw-flex tw-flex-col tw-gap-2">
          {renderField({
            icon: 'icon-receipt_long',
            value: `${item?.type === 'hardwareFault' ? 'Hardware Fault' : 'Product Fault'}`,
          })}
          <CommonStatus status={item?.status} />
        </div>
        <Divider className="tw-m-0" />
        {renderField({
          icon: 'icon-calendar_today',
          value: `${item?.dateResolved ? DateFormat(item?.dateResolved) : DateFormat(item?.createdAt)}`,
        })}
      </div>
    </button>
  );
};

export default FaultReportCardItem;
