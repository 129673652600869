import React from 'react';
type Props = {
  width?: number;
  height?: number;
};

const MoreActionIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 20 20"
      height={`${height ?? 20}px`}
      viewBox="0 0 20 20"
      width={`${width ?? 20}px`}
      fill="currentColor"
    >
      <g id="more_horiz">
        <path
          id="Vector"
          d="M4.9987 8.33203C4.08203 8.33203 3.33203 9.08203 3.33203 9.9987C3.33203 10.9154 4.08203 11.6654 4.9987 11.6654C5.91536 11.6654 6.66536 10.9154 6.66536 9.9987C6.66536 9.08203 5.91536 8.33203 4.9987 8.33203ZM14.9987 8.33203C14.082 8.33203 13.332 9.08203 13.332 9.9987C13.332 10.9154 14.082 11.6654 14.9987 11.6654C15.9154 11.6654 16.6654 10.9154 16.6654 9.9987C16.6654 9.08203 15.9154 8.33203 14.9987 8.33203ZM9.9987 8.33203C9.08203 8.33203 8.33203 9.08203 8.33203 9.9987C8.33203 10.9154 9.08203 11.6654 9.9987 11.6654C10.9154 11.6654 11.6654 10.9154 11.6654 9.9987C11.6654 9.08203 10.9154 8.33203 9.9987 8.33203Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MoreActionIcon;
