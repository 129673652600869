import { AttachmentI } from './../interfaces/attachments';
import * as qs from 'qs';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  APP_VERSION,
  DATETIME_FORMAT,
  DATE_FORMAT_FRONTEND,
  RoleCheckboxOptions,
  TIME_FORMAT_FRONTEND,
} from '../constants';
import countries from 'react-select-country-list';
import { CheckboxOptionI } from '../interfaces';
import { PermissionsI } from '../interfaces/module';
import { ProductCategoryI } from '../interfaces/productCategory';
import { allAttachmentTypes, logoType } from './image';
import { ConfigurationUnits } from '../constants/configuration';

dayjs.extend(customParseFormat);

export const humanizeText = (text: string) => {
  return text?.replace(/_/g, ' ');
};

export const handleFilterParams = (params: any) => {
  return qs.stringify(params, { encode: false, skipNulls: true, arrayFormat: 'comma' });
};

export const convertDayJSToISOString = (dayjsString: Dayjs) => {
  return dayjs(dayjsString);
};

export const DateFormat = (date: any) => {
  if (date === null) {
    return;
  }

  return dayjs(date).format(DATE_FORMAT_FRONTEND);
};

export const TimeFormat = (date: any) => {
  if (date === null) {
    return;
  }

  return dayjs(date).format(TIME_FORMAT_FRONTEND);
};

export const DateTimeFormat = (datetime: any, format?: string) => {
  if (datetime === null) return;
  return dayjs(datetime).format(format ?? DATETIME_FORMAT);
};

export const convertDateTimeToNumber = (datetime: Dayjs) => {
  return datetime ? Number(dayjs(datetime).valueOf()) : null;
};

export const handleDisplayFilterFields = (searchString: string) => {
  const newSearch = searchString.replace('?', '');
  const oldSearch: any = qs.parse(newSearch);
  return oldSearch?.filter || undefined;
};

export const getCountryFromCode = (code: string) => {
  if (!code) return '';
  return countries().getLabel(code) ?? '';
};

export const handleDisplayAddress = (record: any) => {
  if (!record) return '';
  return `${record?.addressLine1} 
  ${record?.addressLine2 ? record.addressLine2 : ''} 
  ${record?.addressLine3 ? record.addressLine3 : ''}, 
  ${record?.region ? record.region : ''}, 
  ${record?.state ? record.state : ''}, 
  ${record?.country ? getCountryFromCode(record.country) : ''}, 
  ${record?.postalCode}`;
};

export const convertToApiFile = (file: any): AttachmentI => {
  delete file?.url;
  delete file?.thumbUrl;
  delete file?.uid;

  return {
    fileName: file.name,
    contentType: file.type,
    categoryName: file.categoryName ?? '',
    url: file.url,
    data: file.data,
  };
};

export const getModulePermissions = (modulePermission: PermissionsI[]) => {
  if (!modulePermission || (modulePermission && Object.keys(modulePermission).length === 0)) return [];
  return RoleCheckboxOptions.map((item: CheckboxOptionI) => {
    const value = item?.value;
    if (!modulePermission?.includes(value)) {
      return {
        ...item,
        disabled: true,
      };
    }
    return item;
  });
};

export const getUploadFileType = {
  certificate: allAttachmentTypes,
  logo: logoType,
  fault: allAttachmentTypes,
  brandPlate: allAttachmentTypes,
  others: allAttachmentTypes,
};

export const hasDuplicateBottleCodeValues = (array: { value: string }[]) => {
  let uniqueValues = new Set();

  for (let obj of array) {
    if (uniqueValues.has(obj.value)) {
      return true;
    } else {
      uniqueValues.add(obj.value);
    }
  }

  return false;
};

export const getProductCategory = (category: ProductCategoryI | undefined) => {
  if (!category) return '';
  if (category?.type === 'Beverage Sub-Classification') {
    return category.parent?.name;
  } else {
    return category?.name;
  }
};

export const AmericanOzToMili = (amount: number) => Math.round(amount * 29.5735);
export const MiliToAmericanOz = (amount: number) => Math.round(amount / 29.5735);
export const BritishOzToMili = (amount: number) => Math.round(amount * 28.4131);
export const MiliToBritishOz = (amount: number) => Math.round(amount / 28.4131);

export const GetConfigUnitText = (value: string) => {
  return ConfigurationUnits.find((item: { value: string; name: string }) => item.value === value)?.name;
};

export const GetConfigUnit = (value: string) => {
  if (value === 'ozUS') return 'Fl oz';
  if (value === 'ozUK') return 'oz';
  return 'mL';
};

export const GetPlatformInfo = (agent: string) => {
  let browserInfo;
  if (agent.includes('Chrome')) {
    browserInfo = {
      platform: 'Google Chrome',
      appVersion: APP_VERSION,
    };
  } else if (agent.includes('Firefox')) {
    browserInfo = {
      platform: 'Mozilla Firefox',
      appVersion: APP_VERSION,
    };
  } else if (agent.includes('Safari') && !agent.includes('Chrome')) {
    browserInfo = {
      platform: 'Safari',
      appVersion: APP_VERSION,
    };
  } else if (agent.includes('Edg')) {
    browserInfo = {
      platform: 'Microsoft Edge',
      appVersion: APP_VERSION,
    };
  } else {
    browserInfo = 'Unknown browser';
  }
  return browserInfo;
};
