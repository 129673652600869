import React, { ReactNode, useState } from 'react';
import { Button, Drawer } from 'antd';
import './ScanTabsContent.scss';
import EmptyData from '../EmptyData';
import NoticeIcon from '../../svgs/NoticeIcon';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  toteScanned?: { serialCode: string }[];
  scanNoticeMessage: string | ReactNode;
};

const FillingScanTabsContent: React.FC<Props> = ({ toteScanned, scanNoticeMessage }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="component-allocation-scan-tabs-content">
      <div className="notice-section">
        <NoticeIcon />
        <div>
          <span>{scanNoticeMessage}</span>
        </div>
      </div>
      <div className="scanned-btn">
        <Button type="primary" onClick={showDrawer}>
          <Icon name="icon-all_inbox" size={24} />
          {toteScanned?.length} Code(s) Scanned
          <Icon name="icon-expand_more" size={24} />
        </Button>
      </div>
      <Drawer
        title={<span>{`${toteScanned?.length} Code(s) Scanned`}</span>}
        onClose={onDrawerClose}
        open={open}
        rootClassName="hardware-scan-qrcode-drawer"
      >
        <div className="hardware-scanned-list-drawer">
          {toteScanned && toteScanned?.length > 0 ? (
            toteScanned.map((data: { serialCode: string }) => (
              <div key={data.serialCode} className="item">
                <Text variant="bodyLgB" className="tw-text-primary-900 !tw-block">{`${data.serialCode}`}</Text>
              </div>
            ))
          ) : (
            <EmptyData message="No hardware scanned yet" />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default FillingScanTabsContent;
