import { BaseResponse } from '../interfaces';
import { SiteCollectionI, SiteI, SiteSearchParamsI } from '../interfaces/site';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetSite = (id: number) => {
  const { data: response, error, isLoading } = useFetch<any>(`sites/${id}`);
  const data = response?.data;
  return { data, error, isLoading };
};

export const getSitesList = async (params?: SiteSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<SiteCollectionI>>(`sites?${handleFilterParams(params)}`);
  return response;
};

export const createSite = async (values: SiteI) => {
  const { data: response }: any = await api.post('sites', values);
  return response.data;
};

export const updateSite = async ({ id, ...values }: { id: string; values: SiteI }) => {
  const { data: response }: any = await api.patch(`sites/${id}`, values);
  return response.data;
};

export const deleteSite = async (id: number) => {
  const { data: response }: any = await api.delete(`sites/${id}`);
  return response.data;
};

export const updateSiteStatus = async (params: { id: number; status: string }) => {
  const { data: response }: any = await api.patch(`sites/${params.id}/status`, { status: params.status });
  return response.data;
};
