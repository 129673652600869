import React from 'react';
import { RegistrationHistoryI } from '../../interfaces/registrationHistory';
import Divider from '../Divider';
import { Tag } from 'antd';
import DetailsView from '../DetailsView';
import { DateTimeFormat } from '../../utils/global';
import { Link } from 'react-router-dom';

type Props = {
  registrationHistory?: RegistrationHistoryI;
};

const RegistrationHistoryInformation: React.FC<Props> = ({ registrationHistory }) => {
  return (
    <DetailsView title="Registration Details">
      <DetailsView.Field label="Owner" value={registrationHistory?.partner?.legalName} />
      <DetailsView.Field label="Allocatee" value={registrationHistory?.partner?.legalName} />
      <Divider />
      <DetailsView.Field label="Date Registered" value={DateTimeFormat(registrationHistory?.createdAt)} />
      <DetailsView.Field label="Number of Hardware" value={registrationHistory?.successHardwareCount} />
      <Divider />
      {registrationHistory?.site && (
        <DetailsView.Field
          label="Site"
          value={
            <Tag>
              <Link to={`/sites/${registrationHistory?.site?.id}`}>{registrationHistory?.site?.displayName}</Link>
            </Tag>
          }
        />
      )}
      {/* <Divider />
      <DetailsView.Field label="Remarks" value={registrationHistory?.remarks} /> */}
    </DetailsView>
  );
};

export default RegistrationHistoryInformation;
