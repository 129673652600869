import { Checkbox, Form } from 'antd';
import React, { useEffect } from 'react';
import { HardwareFaultReportSearchParams } from '../../../interfaces/hardwareFaultReports';
import Text from '../../Text';
import SelectAllCheckbox from '../../SelectAllCheckbox';
import Divider from '../../Divider';
import SizeBox from '../../SizeBox';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { CommonStatus } from '../../CommonStatus';

type FormParams = {
  type: 'hardwareFault' | 'productFault';
  status: 'unresolved' | 'resolved';
};

type Props = {
  queryParams: HardwareFaultReportSearchParams;
  onChange(newParams: Partial<HardwareFaultReportSearchParams>): void;
};

const FaultReportFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: { type: values?.type, status: values?.status },
    });
  };

  const typeOptions = ['hardwareFault', 'productFault'];
  const statusOptions = ['resolved', 'unresolved'];

  useEffect(() => {
    if (queryParams.filter) {
      const type = queryParams.filter?.type ? queryParams.filter?.type.split(',') : [];
      const status = queryParams.filter?.status ? queryParams.filter?.status.split(',') : [];
      form.setFieldsValue({
        type,
        status,
      });
    }
  }, [queryParams]);

  return (
    <div className="component-hardware-filter-content-container partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content tw-max-h-[500px] tw-overflow-y-auto">
            <Form.Item name="type">
              <SelectAllCheckbox form={form} plainOptions={typeOptions} name="type" sectionTitle="Type">
                {typeOptions.map((item: string) => (
                  <Checkbox key={item} value={item}>
                    <span className="text-capitalize">
                      {item === 'hardwareFault' ? 'Hardware Fault' : 'Product Fault'}
                    </span>
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
            <Divider />
            <SizeBox height={12} />
            <Form.Item name="status">
              <SelectAllCheckbox form={form} plainOptions={statusOptions} name="status" sectionTitle="Status">
                {statusOptions.map((item: string) => (
                  <Checkbox key={item} value={item}>
                    <CommonStatus status={item} />
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FaultReportFilterContent;
