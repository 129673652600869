import { Form, Input, message } from 'antd';
import React, { useMemo } from 'react';
import { PartnerI } from '../../interfaces/partner';
import { UserI } from '../../interfaces/user';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import Text from '../Text';
import { useMutation, useQueryClient } from 'react-query';
import { doPartnerCreationReject } from '../../apis/partners';
import { useAppNotification } from '../../hooks/useAppNotification';
import { useNavigate } from 'react-router-dom';

type Props = {
  partner: Partial<PartnerI>;
  handleCancel: () => void;
};

const PartnerRejectContent: React.FC<Props> = ({ partner, handleCancel }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { openNotification } = useAppNotification();
  const navigate = useNavigate();

  const administrator = useMemo(() => {
    return partner?.users?.find((user: UserI) => user.type === 'partnerAdmin');
  }, [partner]);

  const { mutate: rejectRequest } = useMutation(doPartnerCreationReject, {
    onSuccess: async () => {
      openNotification({
        type: 'error',
        title: 'Partner Creation Request Rejected and Deleted! ',
        content: (
          <Text variant="bodyMd">An email notification will be sent to the Requestor and the intended Partner.</Text>
        ),
      });
      queryClient.invalidateQueries({
        queryKey: ['platform/partners', 'list'],
      });
      navigate('/platform/partners', { replace: true });
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const handleRejectSubmit = (values: { reason: string }) => {
    rejectRequest({ id: Number(partner?.id), body: values });
  };

  return (
    <div>
      <Text variant="h3" className="tw-text-error-500">
        Are you sure you want to reject this Partner Creation Request?
      </Text>
      <Text variant="bodyLg">{`Once confirmed, an email notification will be sent to the Requestor - ${partner?.createdBy?.email}, as well as intended Partner - ${administrator?.username} - ${administrator?.email}. `}</Text>
      <Text variant="bodyLg">This request will also be automatically deleted.</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleRejectSubmit}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container">
          <div className="form-content tw-mt-4">
            <Form.Item
              label="Reasons for rejection"
              name="reason"
              rules={[{ required: true, message: 'This field is required.' }]}
            >
              <Input.TextArea
                placeholder="Please provide reasons for rejection here."
                rows={5}
                className="tw-border-grey-500"
              />
            </Form.Item>
          </div>
        </div>
        <div className="tw-flex tw-gap-3 tw-mt-9">
          <SecondaryButton
            label="Cancel"
            onClick={handleCancel}
            className="tw-flex-1 !tw-border-grey-500 tw-text-grey-900 hover:!tw-border-grey-500 hover:!tw-text-grey-900"
          />
          <PrimaryButton
            label="Reject"
            htmlType="submit"
            className="tw-flex-1 tw-bg-error-500 tw-text-white tw-border-error-500 hover:!tw-bg-error-500 hover:tw-text-white hover:tw-border-error-500"
          />
        </div>
      </Form>
    </div>
  );
};

export default PartnerRejectContent;
