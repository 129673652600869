import React from 'react';
import { InputNumber } from 'antd';
import Icon from '../Icon';
import './EInputNumber.scss';

type Props = {
  min?: number;
  max?: number;
  step?: number;
  suffix?: string;
};

const EInputNumber: React.FC<Props> = ({ min = 5, max = 4500, step = 1, suffix, ...props }) => {
  return (
    <>
      <InputNumber
        {...props}
        min={min}
        max={max}
        className="e-input-number-container tw-w-full"
        controls={{
          upIcon: <Icon name="icon-add_circle_outline" size={28} />,
          downIcon: <Icon name="icon-remove_circle_outline" size={28} />,
        }}
        step={step}
        suffix={suffix ?? ''}
      />
    </>
  );
};

export default EInputNumber;
