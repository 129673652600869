import React, { ReactNode, useEffect, useState } from 'react';
import { Divider, Input } from 'antd';
import EmptyData from '../EmptyData';
import { useQuery } from 'react-query';
import { getConfigurationsList } from '../../apis/configurations';
import { ConfigurationI } from '../../interfaces/configuration';
import Text from '../Text';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';
import { DateFormat } from '../../utils/global';
import PrimaryButton from '../buttons/PrimaryButton';
import SearchInputIcon from '../../svgs/SearchInputIcon';

type Props = {
  setCurrentStep: (value: number) => void;
  setConfiguration: (data: ConfigurationI) => void;
  configuration?: ConfigurationI;
  configCode?: string;
};

export const ChoosePreset: React.FC<Props> = ({ configuration, configCode, setCurrentStep, setConfiguration }) => {
  const [filteredData, setFilteredData] = useState<ConfigurationI[]>([]);

  const { data: { hardwareConfigs = [] } = {}, isLoading } = useQuery(['configuration', 'list', {}], () =>
    getConfigurationsList(),
  );

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  const onFilterChange = (event: any) => {
    const { value } = event.target;
    if (value) {
      const data = hardwareConfigs.filter(
        (item: ConfigurationI) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.code.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredData(data);
    } else {
      setFilteredData(hardwareConfigs);
    }
  };

  useEffect(() => {
    if (hardwareConfigs.length) {
      setFilteredData(hardwareConfigs);
    }
  }, [hardwareConfigs]);

  useEffect(() => {
    if (configCode) {
      const result = hardwareConfigs?.find((config: ConfigurationI) => config.code === configCode);
      if (result) setConfiguration(result);
    }
  }, [configCode, hardwareConfigs]);

  return (
    <div className="tw-py-4 tw-px-4">
      <div>
        <div className="tw-w-[500px] tw-my-0 tw-mx-[auto]">
          <Text variant="bodyLg" className="tw-w-full tw-text-center tw-block tw-mt-4 tw-mb-3">
            {`Choose a configuration preset`}
          </Text>
          <Input placeholder="Search for a site or address" prefix={<SearchInputIcon />} onChange={onFilterChange} />
        </div>
        <div className="card-list tw-mt-5">
          {!isLoading && filteredData?.length === 0 && <EmptyData title="No Data" />}
          {filteredData?.map((item: ConfigurationI) => {
            return (
              <button
                className={`${configuration?.id === item.id ? 'active !tw-bg-primary-50' : 'non-active'} card-item hardware-card-item tw-bg-white tw-rounded-lg tw-border-none tw-w-full tw-mb-3 tw-flex tw-flex-col tw-py-4 tw-px-4 tw-gap-1 hover:tw-bg-primary-50`}
                key={item.id}
                onClick={() => setConfiguration(item)}
              >
                <div className="item">
                  <Text variant="inputLabelSm" className="tw-text-grey-700">
                    Configuration Preset
                  </Text>
                  <div className="tw-flex tw-flex-col tw-w-full tw-gap-4">
                    <Text variant="bodyLgB" className="tw-text-primary-800 tw-mt-2">
                      {item.name}
                    </Text>
                    <div className="tw-flex tw-flex-col tw-w-full tw-gap-2">
                      {renderField({ icon: 'icon-build_circle', value: item?.code })}
                      <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
                        {handleDisplayIconByTypeFromApi(item?.hardwareType, {
                          width: 16,
                          height: 16,
                        })}
                        <Text variant="inputLabelSm" className="flex-1">
                          {getTypeFromApi(item)}
                        </Text>
                      </div>
                    </div>
                    <Divider className="tw-m-0" />
                    {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
      <div className="hardware-footer-section">
        <div>
          <PrimaryButton label="Continue" onClick={() => setCurrentStep(2)} disabled={!configuration} />
        </div>
      </div>
    </div>
  );
};
