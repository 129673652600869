import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { UserI } from '../../interfaces/user';
import { Tag } from 'antd';
import { CommonStatus } from '../CommonStatus';
import { SiteI } from '../../interfaces/site';
import { Avatar } from '../User/Avatar';
import { Link } from 'react-router-dom';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: UserI[];
};

const RoleUsersTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: ' ',
      width: 60,
      dataIndex: 'id',
      render: (id: UserI['id']) => {
        return <Avatar id={id ?? 0} className="!tw-w-10 !tw-h-10 tw-rounded-[50%]" />;
      },
    },
    {
      title: 'User Name',
      width: 150,
      ellipsis: true,
      render: (user: UserI) => {
        return <Link to={`/users/${user?.id}`}>{user?.username}</Link>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'MOBILE',
      dataIndex: 'contactNumber',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Sites',
      dataIndex: 'sites',
      width: 150,
      ellipsis: true,
      render: (sites: SiteI[]) => {
        return sites?.map((site: SiteI) => <Tag key={site.id}>{site.displayName}</Tag>);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      ellipsis: true,
      render: (status: string) => {
        return <CommonStatus status={status} />;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      title: 'Action',
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default RoleUsersTable;
