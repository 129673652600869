import { Form, FormInstance, Input, Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { ConfigurationI, ConfigurationUnitsI } from '../../../interfaces/configuration';
import Text from '../../Text';
import ConfigInstructionImage from '../../../images/configInstruction.png';
import InfoIcon from '../../../svgs/InfoIcon';
import { ConfigurationModes, ConfigurationUnits } from '../../../constants/configuration';
import MillilitresUnitForm from './MillilitresUnitForm';
import AmericanOuncesUnitForm from './AmericanOuncesUnitForm';
import EInputNumber from '../../inputs/EInputNumber';
import { AmericanOzToMili, BritishOzToMili, MiliToAmericanOz, MiliToBritishOz } from '../../../utils/global';
import { useOutletContext } from 'react-router-dom';
import { CurrentUserI } from '../../../interfaces/auth';
import { Notice } from '../../Notice';
import SizeBox from '../../SizeBox';

type Props = {
  form: FormInstance;
  configuration?: any;
  type: string;
  isApplied?: boolean;
  handleSubmit(_: any): void;
};

const ConfigurationForm: React.FC<Props> = ({ configuration, type, form, isApplied, handleSubmit }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const [unit, setUnit] = useState<ConfigurationUnitsI>('ml');
  const [showExtraText, setShowExtraText] = useState(true);

  const handleOnFinish = (valuesForm: Partial<ConfigurationI>) => {
    const newValues: any = valuesForm;
    newValues.hardwareType = type;

    let values: {
      button1?: number;
      button2?: number;
      button3?: number;
      button4?: number;
      button5?: number;
    } = {};

    if (valuesForm?.displayUnit === 'ozUS') {
      values.button1 = AmericanOzToMili(newValues?.ozUS.button1 ?? 0);
      values.button2 = AmericanOzToMili(newValues?.ozUS.button2 ?? 0);
      values.button3 = AmericanOzToMili(newValues?.ozUS.button3 ?? 0);
    }

    if (valuesForm?.displayUnit === 'ozUK') {
      values.button1 = BritishOzToMili(newValues?.ozUK.button1 ?? 0);
      values.button2 = BritishOzToMili(newValues?.ozUK.button2 ?? 0);
      values.button3 = BritishOzToMili(newValues?.ozUK.button3 ?? 0);
    }

    if (valuesForm?.displayUnit === 'ml') {
      values.button1 = newValues?.ml.button1;
      values.button2 = newValues?.ml.button2;
      values.button3 = newValues?.ml.button3;
    }

    newValues.values = {
      ...values,
      button4: newValues?.button4 ?? 0,
      button5: newValues?.button5 ?? 0,
    };
    delete newValues.ozUS;
    delete newValues.ozUK;
    delete newValues.ml;
    delete newValues.button4;
    delete newValues.button5;
    handleSubmit(newValues);
  };

  const onUnitChange = (e: RadioChangeEvent) => {
    setUnit(e.target.value);
  };

  useEffect(() => {
    form.setFieldsValue({
      displayUnit: unit,
      button4: 10,
      button5: 10,
      ml: {
        button1: 10,
        button2: 10,
        button3: 10,
      },
      ozUS: {
        button1: 0.5,
        button2: 0.5,
        button3: 0.5,
      },
      ozUK: {
        button1: 0.5,
        button2: 0.5,
        button3: 0.5,
      },
    });
  }, []);

  useEffect(() => {
    if (configuration) {
      setUnit(configuration?.displayUnit);

      form.setFieldsValue({
        ...configuration,
        button4: configuration?.values.button4,
        button5: configuration?.values.button5,
      });
      if (configuration.displayUnit === 'ml') {
        const values = {
          button1: configuration?.values.button1,
          button2: configuration?.values.button2,
          button3: configuration?.values.button3,
        };
        form.setFieldsValue({
          ml: values,
        });
      }
      if (configuration.displayUnit === 'ozUS') {
        const values = {
          button1: MiliToAmericanOz(configuration?.values.button1),
          button2: MiliToAmericanOz(configuration?.values.button2),
          button3: MiliToAmericanOz(configuration?.values.button3),
        };
        form.setFieldsValue({
          ozUS: values,
        });
      }
      if (configuration.displayUnit === 'ozUK') {
        const values = {
          button1: MiliToBritishOz(configuration?.values.button1),
          button2: MiliToBritishOz(configuration?.values.button2),
          button3: MiliToBritishOz(configuration?.values.button3),
        };
        form.setFieldsValue({
          ozUK: values,
        });
      }
    }
  }, [configuration]);

  return (
    <div>
      <Form
        form={form}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        layout="vertical"
        initialValues={{
          ecoToteMode: 'full',
          vsrMode: 'full',
          gradientKPlus: 0.056,
          gradientKMinus: 0.04,
          productGroupCode: '000000',
        }}
      >
        {!configuration && (
          <div className="form-container">
            <div className="form-content">
              <div className="content-wrap">
                <h2 className="form-title">SmartPour Configuration Details</h2>
                <Form.Item
                  label="Configuration Name"
                  name="name"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Input placeholder="Type Configuration Name" />
                </Form.Item>
                <Form.Item label="Remarks" name="remarks">
                  <Input.TextArea placeholder="Type Remarks" />
                </Form.Item>
              </div>
            </div>
          </div>
        )}
        {isApplied && (
          <>
            <SizeBox height={24} />
            <Notice
              type="warning"
              message={
                <span>
                  If you choose to manually edit this hardware configuration, this hardware will automatically be
                  removed from the current applied preset{' '}
                  <span className="tw-font-bold">
                    {configuration?.name} - {configuration?.code}
                  </span>{' '}
                  .
                </span>
              }
              closable={false}
            />
          </>
        )}
        <div className="form-container">
          <div className="form-content">
            <div className="content-wrap">
              <h2 className="form-title">Configuration Settings</h2>
              <div className="tw-flex">
                <div className="tw-flex tw-flex-1 tw-gap-3">
                  <span className="tw-mt-0.5">
                    <InfoIcon color="#4CB4E7" size={16} />
                  </span>
                  <div className="tw-max-w-[458px]">
                    <Text variant="bodyMdB" className="tw-w-full">
                      Configure default volume for SmartPour’s buttons
                    </Text>
                    <Text variant="bodyMd" className="tw-w-full tw-mt-3">
                      You can set up different volume for buttons on the SmartPour’s interface. Volume Range:
                    </Text>
                    <ul className="tw-m-0 tw-pl-6">
                      <li>
                        <Text variant="bodyMd" className="tw-inline-block tw-min-w-[136px]">
                          Millilitres - mL
                        </Text>
                        <Text variant="bodyMdB">5.0 - 4500.0</Text>
                      </li>
                      <li>
                        <Text variant="bodyMd" className="tw-inline-block tw-min-w-[136px]">
                          US Ounces - Fl oz
                        </Text>
                        <Text variant="bodyMdB">0.50 - 3.25</Text>
                      </li>
                      <li>
                        <Text variant="bodyMd" className="tw-inline-block tw-min-w-[136px]">
                          Imperial Ounces - oz
                        </Text>
                        <Text variant="bodyMdB">0.50 - 3.25</Text>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tw-flex-1">
                  <img src={ConfigInstructionImage} alt="Configuration Instruction Image" className="tw-w-full" />
                </div>
              </div>
              <div className="tw-bg-grey-100 tw-p-[20px] tw-rounded-lg tw-border tw-border-solid tw-border-grey-500 tw-mt-6">
                <div className="tw-mb-6">
                  <Text variant="inputLabelMd" className="tw-block tw-w-full tw-mb-0.25">
                    Display Unit
                  </Text>
                  <Form.Item name="displayUnit">
                    <Radio.Group onChange={onUnitChange} className="tw-gap-2.5 tw-flex sm:tw-flex-col">
                      {ConfigurationUnits.map((item: { name: string; value: string }) => (
                        <Radio value={item.value} key={item.value} className="tw-font-bold">
                          {item.name}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
                {unit === 'ml' && <MillilitresUnitForm />}
                {unit === 'ozUS' && <AmericanOuncesUnitForm isAmericanOunce={true} />}
                {unit === 'ozUK' && <AmericanOuncesUnitForm />}
              </div>
              <div className="flex-row tw-mt-6">
                <Form.Item
                  label="Button 4 (mL)"
                  name={'button4'}
                  extra={`Value range: 5.0 - 4500.0`}
                  rules={[
                    {
                      validator(_, value) {
                        const regex = /^\d+(\.0|\.5)?$/;
                        if (value && !regex.test(value.toString())) {
                          return Promise.reject(new Error('The decimal value must be either 0 or 5'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <EInputNumber min={10} max={4500} step={0.5} suffix="mL" />
                </Form.Item>
                <Form.Item
                  label="Button 5 (mL)"
                  name={'button5'}
                  extra={`Value range: 5.0 - 4500.0`}
                  rules={[
                    {
                      validator(_, value) {
                        const regex = /^\d+(\.0|\.5)?$/;
                        if (value && !regex.test(value.toString())) {
                          return Promise.reject(new Error('The decimal value must be either 0 or 5'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <EInputNumber min={10} max={4500} step={0.5} suffix="mL" />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        {currentUser.partner.isEcoSpirit && (
          <div className="form-container">
            <div className="form-content">
              <div className="content-wrap">
                <h2 className="form-title">Extended Configuration Settings</h2>
                <Form.Item
                  label="Product Group Code"
                  name="productGroupCode"
                  extra={showExtraText ? '6 alphanumeric upper case characters' : ' '}
                  rules={[
                    {
                      validator(_: any, value) {
                        const alphanumericUppercaseRegex = /^[A-Z0-9]{6}$/;
                        if (!alphanumericUppercaseRegex.test(value)) {
                          setShowExtraText(false);
                          return Promise.reject(
                            new Error('6 alphanumeric upper case characters. Please type another valid code.'),
                          );
                        }
                        setShowExtraText(true);
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="Type Product Group Code" />
                </Form.Item>
                <div className="tw-bg-grey-100 tw-p-[20px] tw-rounded-lg tw-border tw-border-solid tw-border-grey-500 tw-mb-6">
                  <div className="tw-mb-6 tw-mt-6">
                    <Text variant="inputLabelMd" className="tw-block tw-w-full tw-mb-0.25">
                      ecoTOTE Mode
                    </Text>
                    <Form.Item name="ecoToteMode">
                      <Radio.Group className="tw-gap-2.5 tw-flex">
                        {ConfigurationModes.map((item: { name: string; value: string }) => (
                          <Radio value={item.value} key={item.value}>
                            {item.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="tw-mb-6 tw-mt-6">
                    <Text variant="inputLabelMd" className="tw-block tw-w-full tw-mb-0.25">
                      VSR Mode
                    </Text>
                    <Form.Item name="vsrMode">
                      <Radio.Group className="tw-gap-2.5 tw-flex">
                        {ConfigurationModes.map((item: { name: string; value: string }) => (
                          <Radio value={item.value} key={item.value}>
                            {item.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
                <div className="flex-row">
                  <Form.Item label="Gradient K Plus" name="gradientKPlus" extra={`Value Range: 0.000 - 1`}>
                    <EInputNumber min={0} max={1} step={0.001} />
                  </Form.Item>
                  <Form.Item label="Gradient K Minus" name="gradientKMinus" extra={`Value Range: 0.000 - 1`}>
                    <EInputNumber min={0} max={1} step={0.001} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ConfigurationForm;
