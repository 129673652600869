import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const SmartPOURIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 24}px`}
      viewBox="0 0 24 24"
      width={`${width ?? 24}px`}
      fill={color ?? 'currentColor'}
    >
      <g id="Hardwares type">
        <g id="Vector">
          <path
            d="M8.17405 4.16013L8.8737 4.88945C10.6123 3.07708 13.4319 3.07708 15.1706 4.88945L15.8702 4.16013C13.7468 1.94662 10.301 1.94662 8.17405 4.16013ZM9.57335 5.61878L10.273 6.3481C11.2385 5.34163 12.8058 5.34163 13.7713 6.3481L14.4709 5.61878C13.1206 4.21118 10.9272 4.21118 9.57335 5.61878Z"
            fill={color ?? 'currentColor'}
          />
          <path
            d="M6.25 7.55073C6.25 7.28505 6.46535 7.0697 6.73103 7.0697H17.3133C17.5789 7.0697 17.7943 7.28505 17.7943 7.55073V21.0191C17.7943 21.2847 17.5789 21.5001 17.3133 21.5001H6.73103C6.46535 21.5001 6.25 21.2847 6.25 21.0191V7.55073ZM9.13608 12.1204V18.3735C9.13608 18.5063 9.24375 18.614 9.37658 18.614H14.6677C14.8005 18.614 14.9082 18.5063 14.9082 18.3735V12.1204C14.9082 11.9875 14.8005 11.8798 14.6677 11.8798H9.37658C9.24375 11.8798 9.13608 11.9875 9.13608 12.1204ZM9.13608 9.95578C9.6674 9.95578 10.0981 9.52508 10.0981 8.99375C10.0981 8.46245 9.6674 8.03173 9.13608 8.03173C8.60477 8.03173 8.17405 8.46245 8.17405 8.99375C8.17405 9.52508 8.60477 9.95578 9.13608 9.95578ZM12.9842 8.99375C12.9842 8.46245 12.5535 8.03173 12.0222 8.03173C11.4908 8.03173 11.0601 8.46245 11.0601 8.99375C11.0601 9.52508 11.4908 9.95578 12.0222 9.95578C12.5535 9.95578 12.9842 9.52508 12.9842 8.99375ZM14.9082 9.95578C15.4396 9.95578 15.8702 9.52508 15.8702 8.99375C15.8702 8.46245 15.4396 8.03173 14.9082 8.03173C14.3769 8.03173 13.9462 8.46245 13.9462 8.99375C13.9462 9.52508 14.3769 9.95578 14.9082 9.95578Z"
            fill={color ?? 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
};
export default SmartPOURIcon;
