import { Form, Input } from 'antd';
import React from 'react';
import SearchInputIcon from '../../svgs/SearchInputIcon';
import IconButton from '../buttons/IconButton';
import SortIcon from '../../svgs/sort.svg';
import { ReactSVG } from 'react-svg';

type Props = {
  handleFilter(_: any): void;
};

const SiteFilterFormComponent: React.FC<Props> = ({ handleFilter }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: any) => {
    handleFilter(values);
  };

  return (
    <div className="filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container tw-mb-4">
          <div className="form-content">
            <Form.Item name="search">
              <Input placeholder="Search" prefix={<SearchInputIcon />} />
            </Form.Item>
          </div>
          <div className="form-footer">
            <IconButton icon={<ReactSVG src={SortIcon} className="icon-wrap" style={{ height: 20 }} />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SiteFilterFormComponent;
