import { ForgetPasswordParamsI, ForgetPasswordResponseI } from '../interfaces/auth';
import { api } from './api';
import { useFetch } from './reactQuery';

export const doLogin = (params: any) => api.post<{ data: any }>('login', params);

export const useGetProfile = (enabled = true) => {
  return useFetch<any>(`me`, {}, { enabled });
};

export const doActiveUser = async (params: { newPassword: string; token: string | undefined }) => {
  const { data: response }: any = await api.post(`active/${params.token}`, params);
  return response.data;
};

export const doResetPassword = async (params: { newPassword: string; token: string | undefined }) => {
  const { data: response }: any = await api.post(`reset-password/${params.token}`, params);
  return response.data;
};

export const doForgotPassword = async (params: ForgetPasswordParamsI) => {
  const { data: response } = await api.post<ForgetPasswordResponseI>('forgot-password', params);
  return response.status;
};
