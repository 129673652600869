import { BaseResponse } from '../interfaces';
import { AttachmentI } from '../interfaces/attachments';
import { HardwareSearchParamsI } from '../interfaces/hardware';
import {
  HardwareAllocationCollectionI,
  HardwareAllocationReceiveParamsI,
  HardwareAllocationSendParamsI,
} from '../interfaces/hardwareAllocation';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetHardwareAllocation = (id: number) => {
  const { data: response, error, isLoading } = useFetch<any>(`allocation-histories/${id}`);
  const data = response?.data;
  return { data, error, isLoading };
};

export const getHardwareAllocationsList = async (params?: HardwareSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<HardwareAllocationCollectionI>>(
    `allocation-histories?${handleFilterParams(params)}`,
  );
  return response;
};

export const doAllocationSend = async (values: HardwareAllocationSendParamsI) => {
  const { data: response }: any = await api.post('hardwares/allocation-send', values);
  return response.data;
};

export const doAllocationReceive = async (values: HardwareAllocationReceiveParamsI) => {
  const { data: response }: any = await api.post('hardwares/allocation-receive', values);
  return response.data;
};

export const updateAllocationHistories = async ({
  id,
  values,
}: {
  id: string;
  values: Partial<{ attachments: AttachmentI }>;
}) => {
  const { data: response }: any = await api.patch(`allocation-histories/${id}`, values);
  return response.data;
};
