import React, { PropsWithChildren, memo } from 'react';

export type ETextVariant =
  | 'display1'
  | 'display2'
  | 'display3'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h6'
  | 'bodyXl'
  | 'bodyLg'
  | 'bodyLgB'
  | 'bodyMd'
  | 'bodyMdB'
  | 'bodySm'
  | 'bodySmB'
  | 'capt1'
  | 'capt2'
  | 'buttonLg'
  | 'buttonMd'
  | 'buttonSm'
  | 'labelLg'
  | 'labelMd'
  | 'labelSm'
  | 'inputLabelLg'
  | 'inputLabelMd'
  | 'inputLabelSm'
  | 'tableMd'
  | 'tableXs'
  | 'labelXm';

interface Props extends PropsWithChildren {
  variant?: ETextVariant;
  className?: string;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';
}

const variantsClassMap: Record<ETextVariant, string> = {
  display1: 'tw-font-poppins tw-font-medium tw-text-[64px] tw-leading-[72px]',
  display2: 'tw-font-poppins tw-font-medium tw-text-[50px] tw-leading-[64px]',
  display3: 'tw-font-poppins tw-font-medium tw-text-[42px] tw-leading-[56px]',
  h1: 'tw-font-poppins tw-font-medium tw-text-[34px] tw-leading-[42px]',
  h2: 'tw-font-poppins tw-font-medium tw-text-[24px] tw-leading-[32px]',
  h3: 'tw-font-poppins tw-font-medium tw-text-[20px] tw-leading-[28px]',
  h4: 'tw-font-poppins tw-font-medium tw-text-[16px] tw-leading-[24px]',
  h6: 'tw-font-poppins tw-font-medium tw-text-[14px] tw-leading-[23px]',
  bodyXl: 'tw-font-oxygen tw-text-[20px] tw-leading-[32px]',
  bodyLg: 'tw-font-oxygen tw-text-[16px] tw-leading-[24px]',
  bodyLgB: 'tw-font-oxygen tw-font-bold tw-text-[16px] tw-leading-[24px]',
  bodyMd: 'tw-font-oxygen tw-text-[14px] tw-leading-[20px]',
  bodyMdB: 'tw-font-oxygen tw-font-bold tw-text-[14px] tw-leading-[20px]',
  bodySm: 'tw-font-oxygen tw-text-[12px] tw-leading-[18px]',
  bodySmB: 'tw-font-oxygen tw-font-bold tw-text-[12px] tw-leading-[18px]',
  capt1: 'tw-font-poppins tw-text-[10px] tw-leading-[14px]',
  capt2: 'tw-font-poppins tw-text-[12px] tw-leading-[18px]',
  buttonLg: 'tw-font-poppins tw-font-medium tw-text-[16px] tw-leading-[24px]',
  buttonMd: 'tw-font-poppins tw-font-medium tw-text-[14px] tw-leading-[20px]',
  buttonSm: 'tw-font-poppins tw-font-medium tw-text-[12px] tw-leading-[16px]',
  labelLg: 'tw-font-poppins tw-font-semibold tw-text-[16px] tw-leading-[24px]',
  labelMd: 'tw-font-poppins tw-font-semibold tw-text-[14px] tw-leading-[20px]',
  labelSm: 'tw-font-poppins tw-font-semibold tw-text-[12px] tw-leading-[16px]',
  labelXm: 'tw-font-poppins tw-font-semibold tw-text-[10px] tw-leading-[14px]',
  inputLabelLg: 'tw-font-poppins tw-text-[16px] tw-leading-[24px]',
  inputLabelMd: 'tw-font-poppins tw-text-[14px] tw-leading-[20px]',
  inputLabelSm: 'tw-font-poppins tw-text-[12px] tw-leading-[16px]',
  tableMd: 'tw-font-poppins tw-font-semibold tw-text-[12px] tw-leading-[16px]',
  tableXs: 'tw-font-poppins tw-font-semibold tw-text-[6px] tw-leading-[4px]',
};

const Text: React.FC<Props> = memo(({ children, variant = 'bodyMd', component = 'span', className = '', ...props }) => {
  const finalClassName = `tw-inline-block tw-m-0 tw-overflow-hidden tw-text-ellipsis ${variantsClassMap[variant]} ${className}`;

  const Component = component as keyof JSX.IntrinsicElements;

  return (
    <Component {...props} className={finalClassName}>
      {children}
    </Component>
  );
});

export default Text;
