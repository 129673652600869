import React from 'react';
import './EmailListStatus.scss';
import Text from '../../components/Text';
import { EmailListStatusI } from '../../interfaces/emailList';

interface Props {
  status: EmailListStatusI;
}

export const EmailListStatus: React.FC<Props> = ({ status }) => {
  return (
    <Text className={`partner-status ${status}`} variant="labelSm">
      {status}
    </Text>
  );
};
