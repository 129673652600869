import { Spin } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TrackingInformation from '../../../components/Tracking/Information';
import { useGetPlatformHardware } from '../../../apis/platforms/hardware';
import PlatformHardwareTopInfo from '../../../components/Platforms/Hardware/TopInfo';
import PlatformHardwareResources from '../../../components/Platforms/Hardware/Resources';

const PlatformHardwareDetailsPage: React.FC = () => {
  const { serialCode } = useParams<{ serialCode: string }>();
  const { data: hardware, isLoading, error } = useGetPlatformHardware(serialCode);
  const [currentTab, setCurrentTab] = useState<string>('1');
  const navigate = useNavigate();

  if (error === 'Hardware not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <PlatformHardwareTopInfo data={hardware} />
      <TrackingInformation data={hardware} setCurrentTab={setCurrentTab} isPlatForm={true} />
      <PlatformHardwareResources
        data={hardware}
        id={serialCode}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    </Spin>
  );
};

export default PlatformHardwareDetailsPage;
