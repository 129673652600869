import React from 'react';
import { HardwareScannedStateI } from '../../interfaces/hardware';
import './AllocationScanQRCode.scss';
import AllocationScanTabsContent from './AllocationScanTabsContent';
import { ScanCode } from '../shared/Scanner';
import usePageTimeTracker from '../../hooks/usePageTimeTracker';

type Props = {
  scannedData: HardwareScannedStateI[];
  stopDecodingScanner: boolean;
  pageName?: string;
  handleScanOrManualSubmit: (_: any) => void;
  setStopDecodingScanner: (_: any) => void;
};

const AllocationScanQRCode: React.FC<Props> = ({
  scannedData,
  stopDecodingScanner,
  pageName,
  setStopDecodingScanner,
  handleScanOrManualSubmit,
}) => {
  usePageTimeTracker(pageName ?? '');

  return (
    <div className="component-hardware-register-scan-qrcode">
      <h2>Scan by QR/Bar Code</h2>
      <AllocationScanTabsContent scannedData={scannedData} />
      <ScanCode
        stopDecodingScanner={stopDecodingScanner}
        setStopDecodingScanner={setStopDecodingScanner}
        handleScanOrManualSubmit={handleScanOrManualSubmit}
      />
    </div>
  );
};

export default AllocationScanQRCode;
