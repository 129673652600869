import { Form, FormInstance, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { UserI, UserParamsI } from '../../../interfaces/user';
import { CurrentUserI } from '../../../interfaces/auth';
import './Form.scss';
import UploadAttachmentButton from '../../buttons/UploadAttachmentButton';
import { convertToApiFile, humanizeText } from '../../../utils/global';
import { AttachmentI } from '../../../interfaces/attachments';
import { useQuery } from 'react-query';
import { getSitesList } from '../../../apis/sites';
import { SiteI } from '../../../interfaces/site';
import { getRolesList } from '../../../apis/roles';
import { RoleI } from '../../../interfaces/role';
import { AttachmentCategories, EmailRegex } from '../../../constants';
import { PhoneNumberInput } from '../../PhoneNumberInput/PhoneNumberInput';

type Props = {
  form: FormInstance;
  userId?: number;
  user?: UserI;
  currentUser: CurrentUserI;
  handleSubmit(_: any): void;
};

const UserForm: React.FC<Props> = ({ form, user, userId, currentUser, handleSubmit }) => {
  const [files, setFiles] = useState<any[]>([]);
  const { data: siteData, isLoading: isSiteLoading } = useQuery(['sites', 'list', {}], () => getSitesList());
  const { data: roleData, isLoading: isRoleLoading } = useQuery(['roles', 'list', {}], () => getRolesList());

  const handleOnFinish = (values: UserParamsI) => {
    const newValues: any = values;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (user && user.id === userId) {
      let newAttachments;
      if (user?.attachments && Object.values(user.attachments).length > 0) {
        newAttachments = user?.attachments.map((attachment: AttachmentI) => {
          return {
            categoryName: attachment.categoryName,
            id: attachment.id,
            name: attachment.fileName,
            type: attachment.fileType,
            url: attachment.url,
          };
        });
      }

      form.setFieldsValue({
        ...user,
        roleIds: user?.roles && user?.type !== 'partnerAdmin' ? user.roles.map((role: RoleI) => role?.id) : [],
        siteIds: user?.sites && user?.type !== 'partnerAdmin' ? user.sites.map((role: SiteI) => role?.id) : [],
      });
      setFiles(newAttachments ?? []);
    }
  }, [user]);

  return (
    <div className="user-form-container">
      <Form
        form={form}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
            <div className="content-wrap">
              <div className="partner-entity-wrap">
                <p className="label">Partner Entity</p>
                <p className="value">{currentUser?.partner.displayName}</p>
              </div>
              <h2 className="form-title">User Information</h2>
              <Form.Item
                label="User Name"
                name="username"
                rules={[
                  {
                    required: true,
                    validator: (_: any, value: string) => {
                      if (value === '') {
                        return Promise.reject(new Error('The field is required.'));
                      }

                      if (EmailRegex.test(value)) {
                        return Promise.reject(new Error('The field cannot be an email address'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Type your username" />
              </Form.Item>
              <Form.Item label="Roles" name="roleIds">
                <Select
                  disabled={user?.type === 'partnerAdmin'}
                  placeholder={user?.type === 'partnerAdmin' ? 'Partner Administrator' : 'Multichoice Select'}
                  style={{ width: '100%' }}
                  mode="multiple"
                  loading={isRoleLoading}
                >
                  {roleData?.roles?.map((role: RoleI) => (
                    <Select.Option key={role.id} value={role.id}>
                      {humanizeText(role.name)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Sites" name="siteIds">
                <Select
                  disabled={user?.type === 'partnerAdmin'}
                  placeholder={user?.type === 'partnerAdmin' ? 'All Sites' : 'Multichoice Select'}
                  style={{ width: '100%' }}
                  mode="multiple"
                  loading={isSiteLoading}
                >
                  {siteData?.data?.sites?.map((site: SiteI) => (
                    <Select.Option key={site.id} value={site.id}>
                      {site.legalName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="contactNumber"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <PhoneNumberInput />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { type: 'email', message: 'Invalid Email' },
                  { required: true, message: 'This field is required' },
                ]}
              >
                <Input type="email" placeholder="Type your email" autoComplete="off" disabled={!!userId} />
              </Form.Item>
              <div className="attachment-list-section">
                <h2 className="form-title">Upload File</h2>
                <Form.Item name="uploadFile" label="Upload Attachment" style={{ width: '100%' }}>
                  <UploadAttachmentButton
                    categories={AttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UserForm;
