import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import { useQuery } from 'react-query';
import { getHardwareFaultReports } from '../../apis/hardwares';
import { handleFilterParams } from '../../utils/global';
import { Button, Dropdown, Spin } from 'antd';
import HardwareFaultReportFilter from '../shared/Hardware/FaultReportHistoryFilter';
import { HardwareFaultReportI, HardwareFaultReportSearchParams } from '../../interfaces/hardwareFaultReports';
import SizeBox from '../SizeBox';
import FaultReports from '../card-list/FaultReports';
import FaultReportsTable from '../tables/FaultReports';
import EmptyData from '../EmptyData';

type Props = {
  id?: string;
};

const TrackingFaultReports: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const typeFilter = urlSearch.get('filter[type]');
    const statusFilter = urlSearch.get('filter[status]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search: search,
      filter: { status: statusFilter, type: typeFilter },
    } as HardwareFaultReportSearchParams & { viewMode?: string };
  }, [urlSearch]);

  const { data: { faultReports = [], total = 0 } = {}, isLoading } = useQuery(
    [`hardwares/${id}/fault-reports`, `${id}`, queryParams],
    () => getHardwareFaultReports({ serialCode: id ?? '', params: queryParams }),
    {
      select({ data: { faultReports, total } }) {
        return { faultReports, total };
      },
    },
  );

  const currentPage = queryParams?.page ?? 1;
  const pagination: any = {
    total,
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: Partial<HardwareFaultReportSearchParams>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: HardwareFaultReportSearchParams) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const withAction = (record: HardwareFaultReportI) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/fault-reports/${record?.code}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <HardwareFaultReportFilter queryParams={queryParams} onChange={handleFilterChange} />
      <SizeBox height={32} />
      {!isLoading && faultReports.length === 0 && <EmptyData title="No Data" />}
      {!queryParams.viewMode ? (
        <FaultReports
          faultReports={faultReports ?? []}
          total={total}
          handleFilter={handleFilter}
          currentPage={currentPage}
          queryParams={queryParams}
        />
      ) : (
        <FaultReportsTable
          rowKey="id"
          loading={isLoading}
          pagination={pagination}
          dataSource={faultReports}
          withAction={withAction}
          onChange2={handleFilter}
        />
      )}
    </Spin>
  );
};

export default TrackingFaultReports;
