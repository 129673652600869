import React, { useState } from 'react';
import { ProductGroupI } from '../../interfaces/product';
import EmptyData from '../EmptyData';
import { Pagination } from 'antd';
import { useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import ProductGroupCardItem from '../card-list/ProductGroupCardItem';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';

type Props = {
  groups?: ProductGroupI[];
  isPlatform?: boolean;
};

const ProductGroupList: React.FC<Props> = ({ groups, isPlatform }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'product-groups');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;

  const handlePaginationChange = (page: number, per_page: number) => {
    setCurrentPage(page);
    setPageSize(per_page);
  };

  return (
    <div>
      <div className="tw-flex tw-gap-4 tw-flex-wrap">
        {groups && groups.length > 0 ? (
          groups
            .slice(start, end)
            ?.map((item) => <ProductGroupCardItem item={item} access={access} key={item.id} isPlatform={isPlatform} />)
        ) : (
          <EmptyData title="No Data" />
        )}
      </div>
      <div className="pagination-container tw-mt-4">
        <Pagination
          total={groups?.length ?? 0}
          pageSize={pageSize}
          showTotal={(total) => `${total} Items`}
          current={currentPage}
          onChange={handlePaginationChange}
          showSizeChanger
          pageSizeOptions={[5, 10, 20, 50]}
        />
      </div>
    </div>
  );
};

export default ProductGroupList;
