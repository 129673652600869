import React from 'react';
import Text from '../Text';
import { Radio, Space } from 'antd';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

type Props = {
  type: 'ecotote' | 'all';
  onChange: (_: any) => void;
  onConfirmClick: () => void;
  onBackClick: () => void;
};

const HardwareChooseType: React.FC<Props> = ({ type, onChange, onBackClick, onConfirmClick }) => {
  const renderLabel = (icon: IconName, label: string, value: string) => {
    return (
      <Radio value={value} className="tw-p-3 tw-flex tw-items-center">
        <span className="tw-flex tw-items-center tw-m-0 tw-gap-[2px]">
          <Icon name={icon} size={20} />
          <Text variant="labelMd">{label}</Text>
        </span>
      </Radio>
    );
  };

  return (
    <div>
      <Text variant="h3" className="tw-mb-2">
        Choose a Hardware Type
      </Text>
      <Text variant="bodyMd" className="tw-mb-6">
        Hardware Grouping only allow the same Hardware Type to be grouped. Please choose 1 Hardware Type for Grouping.
      </Text>
      <Radio.Group onChange={onChange}>
        <Space direction="vertical">
          {renderLabel('icon-eco-tote', 'ecoTOTE 4.5L', 'ecotote4.5l')}
          {renderLabel('icon-eco-tote', 'ecoTOTE 1.75L', 'ecotote1.75l')}
          {type === 'all' && (
            <>
              {renderLabel('icon-smart-pour', 'SmartPour', 'smartpour')}
              {renderLabel('icon-eco-plant', 'ecoPLANT', 'ecoplant')}
            </>
          )}
        </Space>
      </Radio.Group>
      <PrimaryButton label="Confirm" className="tw-w-full tw-mb-3 tw-mt-6" onClick={onConfirmClick} />
      <SecondaryButton label="Back" className="tw-w-full" onClick={onBackClick} />
    </div>
  );
};

export default HardwareChooseType;
