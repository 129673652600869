import React from 'react';
import './ProgressBar.scss';
import { HardwareProgressStepListI } from '../../../interfaces';
import StickIcon from '../../../svgs/StickIcon';
import BackLink from '../../buttons/BackLink';
import Text from '../../Text';

type Props = {
  currentStep: number;
  title: string;
  stepList: HardwareProgressStepListI[];
  backTo?: string;
  stepName?: string;
  backFunction?: () => void;
};

const SharedHardwareProgressBar: React.FC<Props> = ({ currentStep, title, stepList, stepName, backFunction }) => {
  return (
    <div className="shared-hardware-progress-bar">
      <BackLink backFunction={backFunction} />
      <h2>{title}</h2>
      <div className="status-bar">
        <ul>
          {stepList.map((item: HardwareProgressStepListI) => (
            <li
              key={item.step}
              className={`step ${item.disabled ? 'disabled' : ''} ${currentStep === item.step ? 'active' : 'inactive'} ${currentStep > item.step ? 'completed' : ''} for${stepList.length}step`}
            >
              <span className="number">{currentStep > item.step ? <StickIcon /> : item.step}</span>
              <span className="text">{item.label}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="tw-text-center tw-mt-3">
        <Text variant="inputLabelLg">{stepName}</Text>
      </div>
    </div>
  );
};

export default SharedHardwareProgressBar;
