import React from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import './VerifyStep.scss';
import { ProductGroupVerifyCollectionI, ProductGroupVerifyItemI } from '../../interfaces/product';
import ProductGroupItemCard from './ItemCard';

type Props = {
  setCurrentStep: (step: number) => void;
  verifiedData?: ProductGroupVerifyCollectionI;
  handleDeleteVerifiedData?: (_: any) => void;
  handleScanMoreButton: () => void;
};

const ProductGroupVerifyStep: React.FC<Props> = ({
  verifiedData,
  setCurrentStep,
  handleDeleteVerifiedData,
  handleScanMoreButton,
}) => {
  return (
    <div className="component-product-group-verify-step">
      <div className="wrapper">
        {verifiedData?.productList?.map((item: ProductGroupVerifyItemI, index: number) => {
          return <ProductGroupItemCard item={item} key={index} handleDeleteItem={handleDeleteVerifiedData} />;
        })}
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Scan more" onClick={handleScanMoreButton} />
          <PrimaryButton
            label="Continue"
            onClick={() => setCurrentStep(3)}
            disabled={verifiedData?.productList?.length === 0}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductGroupVerifyStep;
