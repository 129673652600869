import { Form, Input } from 'antd';
import React, { useState } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import './LoginForm.scss';
import './ResetPassword.scss';
import { ResetPasswordParamsI } from '../../interfaces/auth';
import passwordValidator from 'password-validator';
import CheckIcon from '../../svgs/CheckIcon';
import CloseIcon from '../../svgs/CloseIcon';

const schema = new passwordValidator();

schema.is().min(9).has().uppercase(1).has().lowercase(1).has().digits(1).has().symbols(1).not(/[#]/);

type RequirementType = {
  text: string;
  fulfilled: boolean;
  value: string;
};

type Props = {
  handleSubmit: (_: any) => void;
};

const ResetPasswordForm: React.FC<Props> = ({ handleSubmit }) => {
  const [form] = Form.useForm();
  const [password, setPassword] = useState<any>({});
  const [confirmPassword, setConfirmPassword] = useState<any>({});
  const [validateResult, setValidateResult] = useState<string>();

  const [requirements, setRequirements] = useState<RequirementType[]>([
    { text: 'At least 9 characters.', fulfilled: false, value: 'min' },
    { text: '1 Uppercase', fulfilled: false, value: 'uppercase' },
    { text: '1 Lowercase', fulfilled: false, value: 'lowercase' },
    { text: '1 Digit', fulfilled: false, value: 'digits' },
    { text: `1 Special Character (!$%^&*()_+|~-=\`{}[]:";'<>?,/.)`, fulfilled: false, value: 'symbols' },
  ]);

  const validatePassword = (_: any, value: string) => {
    if (!value) {
      setRequirements(requirements.map((req) => ({ ...req, fulfilled: false })));
      return Promise.reject(new Error('Please enter your password'));
    }

    const isValid: any = schema.validate(value, { list: true });

    if (isValid) {
      const validRequirement: any = requirements.map((item: RequirementType) => {
        if (isValid.includes('not')) {
          const invalidSymbols = requirements.findIndex((idx: RequirementType) => idx.value === 'symbols');
          requirements[invalidSymbols].fulfilled = false;
        }

        if (!isValid.includes(item.value) && !isValid.includes('not')) {
          return {
            ...item,
            fulfilled: true,
          };
        }

        return item;
      });
      setRequirements(validRequirement);
    }

    if (Object.values(isValid).length >= 1) {
      setValidateResult('weak');
      return Promise.reject(new Error());
    }

    setValidateResult('strong');
    setPassword(value);

    if (confirmPassword && Object.keys(confirmPassword).length > 0 && confirmPassword.value !== '') {
      if (value === confirmPassword.value) {
        setConfirmPassword({
          ...confirmPassword,
          validateStatus: 'success',
          errorMsg: null,
        });
      } else {
        return Promise.reject(new Error('The confirmed password and the password do not match'));
      }
    }

    return Promise.resolve();
  };

  function validateConfirmPassword(value: string) {
    if (value === '') {
      return {
        validateStatus: 'error',
        errorMsg: 'Please confirm your password',
      };
    }

    if (value === password) {
      form.validateFields(['newPassword']);
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    if (password) {
      return {
        validateStatus: 'error',
        errorMsg: 'The confirmed password and the password do not match',
      };
    }
  }

  const onConfirmPasswordChange = (event: any) => {
    const { value } = event.target;
    setConfirmPassword({ ...validateConfirmPassword(value), value });
  };

  const onFinish = (values: ResetPasswordParamsI) => {
    const newValues = values;
    const passwordValue = values.newPassword;
    const conformPasswordValue = values.confirmPassword;

    if (conformPasswordValue === passwordValue) {
      handleSubmit(newValues);
    }
  };

  return (
    <Form form={form} scrollToFirstError layout="vertical" requiredMark={false} colon={false} onFinish={onFinish}>
      <div className="FormContent">
        <Form.Item
          name="newPassword"
          label="New Password"
          className="password-input"
          rules={[{ validator: validatePassword }]}
        >
          <Input.Password size="large" placeholder="Required" />
        </Form.Item>
        <div className="password-requirement-section">
          {validateResult && (
            <p>
              Password Strength:{' '}
              <span className={`text-capitalize ${validateResult === 'strong' ? 'strong' : 'weak'}`}>
                {validateResult}
              </span>
            </p>
          )}
          <p>Password Requirements: </p>
          {requirements.map((req) => (
            <div className={`requirement-item ${req.fulfilled ? 'fulfilled' : 'none-fulfilled'}`} key={req.text}>
              {req.fulfilled ? <CheckIcon /> : <CloseIcon />} <span>{`${req.text}`}</span>
            </div>
          ))}
        </div>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          validateStatus={confirmPassword.validateStatus}
          help={confirmPassword.errorMsg}
          className="password-input"
          dependencies={['newPassword']}
        >
          <Input.Password size="large" placeholder="Required" onChange={onConfirmPasswordChange} />
        </Form.Item>
      </div>
      <div className="FormFooter">
        <PrimaryButton htmlType="submit" label="Set Password" style={{ width: '100%' }} />
      </div>
    </Form>
  );
};

export default ResetPasswordForm;
