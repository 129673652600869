import React from 'react';
type Props = {
  size?: number;
  color?: string;
};

const LocationOnIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${size ?? 32}px`}
      viewBox="0 0 32 32"
      width={`${size ?? 32}px`}
      fill={color ?? 'currentColor'}
    >
      <g id="location_on" clipPath="url(#clip0_15282_30118)">
        <path
          id="Vector"
          d="M16.0013 2.66406C10.8413 2.66406 6.66797 6.8374 6.66797 11.9974C6.66797 18.9974 16.0013 29.3307 16.0013 29.3307C16.0013 29.3307 25.3346 18.9974 25.3346 11.9974C25.3346 6.8374 21.1613 2.66406 16.0013 2.66406ZM16.0013 15.3307C14.1613 15.3307 12.668 13.8374 12.668 11.9974C12.668 10.1574 14.1613 8.66406 16.0013 8.66406C17.8413 8.66406 19.3346 10.1574 19.3346 11.9974C19.3346 13.8374 17.8413 15.3307 16.0013 15.3307Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_15282_30118">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LocationOnIcon;
