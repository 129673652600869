import { Form, Select, message } from 'antd';
import React, { useState } from 'react';
import { getUploadFileType, humanizeText } from '../../utils/global';
import { AttachmentI } from '../../interfaces/attachments';
import AttachmentUploader from '../uploads/AttachmentUploader';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import './UploadAttachmentForm.scss';
import { UploadAttachmentCategoryI } from '../../interfaces';
import { validateAttachmentCategory } from '../../utils/image';

type Props = {
  setFiles: any;
  files: AttachmentI[];
  isRequired?: boolean;
  multiple?: boolean;
  categories?: { value: UploadAttachmentCategoryI; name: string }[];
  handleCancel: () => void;
};

const UploadAttachmentForm: React.FC<Props> = ({ isRequired, files, setFiles, multiple, categories, handleCancel }) => {
  const [form] = Form.useForm();
  const [documents, setDocuments] = useState([]);
  const [fileType, setFileType] = useState<any>();

  const onCancel = () => {
    setDocuments([]);
    handleCancel();
    form.resetFields();
  };

  const handleSubmit = (values: { categoryName: string; file: any }) => {
    if (multiple) {
      const newDocuments = documents?.map((item: any) => {
        return {
          ...item,
          categoryName: values.categoryName,
        };
      });
      setFiles([...newDocuments, ...files]);
    } else {
      const newDocuments: AttachmentI = documents[0];
      newDocuments.categoryName = values.categoryName;
      setFiles([newDocuments, ...files]);
    }
    setDocuments([]);
    handleCancel();
    form.resetFields();
  };

  const onCategoryChange = (value: UploadAttachmentCategoryI) => {
    setFileType(getUploadFileType[value]);
    if (documents && documents.length > 0) {
      const isValid = validateAttachmentCategory(documents, value);
      if (!isValid) {
        form.resetFields(['categoryName']);
        message.error('Please select category correctly!');
      }
    }
  };

  return (
    <div className="upload-attachment-form-container form-container">
      <h2 className="form-title">Upload Attachment</h2>
      <Form
        form={form}
        onFinish={handleSubmit}
        scrollToFirstError
        autoComplete="off"
        layout={'vertical'}
        initialValues={{ categoryName: categories?.[0]?.value }}
      >
        {categories && (
          <div className="attachment-category-wrap">
            <p>Category*</p>
            <Form.Item name="categoryName" rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder="Select a Category" style={{ width: '100%' }} onChange={onCategoryChange}>
                {categories?.map((type: { name: string; value: string }) => (
                  <Select.Option key={type.value} value={type.value}>
                    <span>{humanizeText(type.name)}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}
        <AttachmentUploader
          name="file"
          documents={documents}
          setDocuments={setDocuments}
          multiple={multiple ?? false}
          maxCount={!multiple ? 2 : 100}
          acceptType={fileType}
          isRequired={isRequired}
        />
        <div className="actions">
          <SecondaryButton label="Cancel" onClick={onCancel} />
          <PrimaryButton label="Upload" htmlType="submit" />
        </div>
      </Form>
    </div>
  );
};

export default UploadAttachmentForm;
