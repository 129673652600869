import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import '../HardwareCardItem.scss';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../../utils/hardware';
import { AccessControlI } from '../../../interfaces';
import { HardwareItemI } from '../../../interfaces/hardware';
import { CommonStatus } from '../../CommonStatus';
import Text from '../../Text';
import Icon from '../../Icon';
import { IconName } from '../../Icon/iconName';
import { DateFormat } from '../../../utils/global';
import NetworkStatus from '../../shared/NetworkStatus';
import FaultyReportButton from '../../buttons/FaultyReportButton';
import Divider from '../../Divider';

type Props = {
  item: HardwareItemI;
  access: AccessControlI;
};
const HardwareCardItem: React.FC<Props> = ({ item }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: HardwareItemI) => {
    navitate(`/platform/hardware/${item.serialCode}`);
  };

  const renderInfoByType = () => {
    return (
      <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
        {item.type === 'ecotote' && (
          <>
            <Icon name="icon-water-drop" size={12} className=" tw-text-grey-700 tw-min-w-4" />
            <span>
              {item?.ecoTote?.lastKnownVolume !== null
                ? (Number(item?.ecoTote?.lastKnownVolume) / 1000).toFixed(2)
                : (0).toFixed(2)}
              L
            </span>
          </>
        )}
        {item.type === 'ecoplant' && (
          <>
            <Icon name="icon-date_range" size={12} className=" tw-text-grey-700 tw-min-w-4" />
            <span>{item?.ecoPlant?.lastFilledDate ? DateFormat(item?.ecoPlant?.lastFilledDate) : ''}</span>
          </>
        )}
        {item.type === 'smartpour' && <NetworkStatus status={item?.smartPour?.network ?? null} />}
      </Text>
    );
  };

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-mb-3">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4 tw-min-h-4" size={12} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button className="hardware-card-item card-item" onClick={() => handleCardClick(item)}>
      {item?.remarks !== null && <Icon name="icon-text_snippet" className="btn-more-action" size={16} />}
      <div className="card-info tw-pb-3">
        <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-1">
          {handleDisplayIconByTypeFromApi(item.type, { color: '#191919', width: 16, height: 16 })}
          <span>{getTypeFromApi(item)}</span>
        </Text>
        <Text variant="inputLabelLg" className="tw-mt-0.5 tw-mb-3 tw-text-primary-900">{`${item.serialCode}`}</Text>
        {renderField({ icon: 'icon-person', value: item?.owner?.legalName })}
        {!item?.site ? (
          <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
            <Icon name="icon-location_on" className="tw-text-grey-700 tw-min-w-4 tw-min-h-4" size={12} />
            <span>Unassigned Site</span>
          </Text>
        ) : (
          <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
            <Icon name="icon-location_on" size={12} className="tw-text-grey-700 tw-min-w-4 tw-min-h-4" />
            {item?.site?.legalName}
          </Text>
        )}
        {renderInfoByType()}
        <p className="status-section">
          {item?.status && <CommonStatus status={item?.status} />}
          {item?.condition && <CommonStatus status={item?.condition} />}
        </p>
      </div>
      <Divider />
      <div className="tw-flex tw-justify-between tw-py-2 tw-px-3 tw-min-h-[26px]">
        {!item.error ? (
          <FaultyReportButton data={item} hardwareType={item.type} />
        ) : (
          <span className="tw-leading-5 tw-mt-3"></span>
        )}
      </div>
    </button>
  );
};

export default HardwareCardItem;
