import React from 'react';
import { Button, message, Modal } from 'antd';
import DeleteForeverIcon from '../../svgs/DeleteForeverIcon';
import { deleteAttachment } from '../../apis/attachments';
import { useMutation, useQueryClient } from 'react-query';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import { useOutletContext } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const DeleteAttachmentButton = ({
  id,
  apiPath,
  accessModule,
}: {
  id: number;
  apiPath: string;
  accessModule: string;
}) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(deleteAttachment, {
    onSuccess: async () => {
      message.info('Deleted attachment!');
      queryClient.invalidateQueries({
        queryKey: [apiPath],
      });
    },
    onError: () => {
      message.error('An error has occurred while attempting to delete the attachment');
    },
  });
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, accessModule!);

  if (!access.isCanUpdate) return null;
  const { confirm } = Modal;
  function showConfirm() {
    confirm({
      title: <span className="tw-ml-2">Delete attachment</span>,
      icon: <ExclamationCircleOutlined />,
      content: 'Do you really want to delete this attachment?',
      onOk() {
        mutate(+id!);
      },
    });
  }
  return (
    <Button className="btn-table-action-warn has-icon" type="link" style={{ color: '#b13939' }} onClick={showConfirm}>
      <DeleteForeverIcon />
    </Button>
  );
};

export default DeleteAttachmentButton;
