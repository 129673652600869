import React from 'react';
import { Pagination } from 'antd';
import { HardwareFaultReportI, HardwareFaultReportSearchParams } from '../../interfaces/hardwareFaultReports';
import FaultReportCardItem from './FaultReportCardItem';
import { PAGE_SIZE } from '../../constants';

type Props = {
  faultReports: HardwareFaultReportI[];
  total: number;
  queryParams: HardwareFaultReportSearchParams & { viewMode?: string };
  currentPage: number;
  handleFilter: (_: any) => void;
};
const FaultReports: React.FC<Props> = ({ faultReports, total, queryParams, currentPage, handleFilter }) => {
  return (
    <>
      <div className="card-list">
        {faultReports.map((item: HardwareFaultReportI) => {
          return <FaultReportCardItem item={item} key={item?.id} />;
        })}
      </div>
      <div className="pagination-container tw-mt-[44px]">
        <Pagination
          total={total}
          pageSize={queryParams.limit ?? PAGE_SIZE}
          showTotal={(total) => `${total} Items`}
          current={currentPage}
          onChange={(page, pageSize) => handleFilter({ page, per_page: pageSize })}
          showSizeChanger
        />
      </div>
    </>
  );
};

export default FaultReports;
