import React from 'react';
import { Tabs, TabsProps } from 'antd';
import Icon from '../Icon';
import Text from '../Text';
import AttachmentsInDetail from '../AttachmentsInDetail';
import ProductList from '../shared/Products/ProductList';
import { ProductGroupI } from '../../interfaces/product';

type Props = {
  data?: ProductGroupI;
};

const ProductGroupResources: React.FC<Props> = ({ data }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-x-2">
          <Icon name="icon-all_inbox" size={20} /> <span>Products</span>
        </Text>
      ),
      children: <ProductList products={data?.products} />,
    },
    {
      key: '2',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-x-2">
          <Icon name="icon-attach_file" /> <span>Attachments</span>
        </Text>
      ),
      children: <AttachmentsInDetail attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="tw-mb-4">
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="app-tabs" />
      </div>
    </div>
  );
};

export default ProductGroupResources;
