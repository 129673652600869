import React, { ReactNode } from 'react';
import BackLink from '../buttons/BackLink';
import Text from '../Text';
import PrimaryButton from '../buttons/PrimaryButton';
import { FormInstance } from 'antd';

type Props = {
  label: ReactNode;
  labelButton: ReactNode;
  form: FormInstance;
  override?: ReactNode;
  confirmFunction?: () => void;
};

export const CreateUpdateTopSection: React.FC<Props> = ({ label, labelButton, form, override, confirmFunction }) => {
  const handleClick = () => {
    if (confirmFunction) {
      confirmFunction();
    } else form.submit();
  };

  return (
    <div className="content-wrap">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4 ">
        <BackLink />
        {override ? (
          override
        ) : (
          <PrimaryButton label={labelButton} onClick={handleClick} className="!tw-min-w-[150px]" />
        )}
      </div>
      <div className="wrapper tw-flex tw-items-center tw-flex-row">
        <div className="name-section">
          <Text variant="h2">{label}</Text>
        </div>
      </div>
    </div>
  );
};
