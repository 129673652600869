import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductHistoryI, ProductHistorySearchParams } from '../../interfaces/product';
import { PAGE_SIZE } from '../../constants';
import { useQuery } from 'react-query';
import { getProductHistory } from '../../apis/products';
import { handleFilterParams } from '../../utils/global';
import { Pagination, Spin } from 'antd';
import ProductHistoryItem from './HistoryItem';
import ProductHistoryFilter from './HistoryFilter';
import DisplayLastUpdateApp from '../DisplayLastUpdate';
import SizeBox from '../SizeBox';

type Props = {
  id?: string;
  isPlatform?: boolean;
};

const ProductHistory: React.FC<Props> = ({ id, isPlatform }) => {
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const category = urlSearch.get('filter[category]');
    const user = urlSearch.get('filter[user]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search: search,
      filter: { user, category },
    } as ProductHistorySearchParams & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { productHistories = [], total = 0 } = {},
    isLoading,
    refetch: refetchData,
  } = useQuery(['product-histories', `${id}`, queryParams], () => getProductHistory({ sku: id, params: queryParams }), {
    select({ data: { productHistories, total } }) {
      return { productHistories, total };
    },
  });

  const currentPage = queryParams?.page ?? 1;

  const setQueryParams = (params: Partial<ProductHistorySearchParams>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: ProductHistorySearchParams) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  return (
    <div className="partner-history-container">
      <Spin spinning={isLoading} className="partner-history-container">
        <ProductHistoryFilter queryParams={queryParams} onChange={handleFilterChange} refetchData={refetchData} />
        <SizeBox height={16} />
        <DisplayLastUpdateApp />
        <SizeBox height={8} />
        {productHistories.map((item: ProductHistoryI) => {
          if (!['recallReportUpdateStatus', 'recallReport'].includes(item.eventName)) {
            return <ProductHistoryItem item={item} key={item?.sku} isPlatform={isPlatform} />;
          }
        })}
        <div className="pagination-container tw-mt-[44px]">
          <Pagination
            total={total}
            pageSize={queryParams.limit ?? PAGE_SIZE}
            showTotal={(total) => `${total} Items`}
            current={currentPage}
            onChange={(page, pageSize) => handleFilter({ page, per_page: pageSize })}
            showSizeChanger
          />
        </div>
      </Spin>
    </div>
  );
};

export default ProductHistory;
