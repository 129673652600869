import React, { ReactNode } from 'react';
import './StartScanningScreen.scss';
import { Notice } from '../../Notice';
import SizeBox from '../../SizeBox';
import PrimaryButton from '../../buttons/PrimaryButton';
import WarningIcon from '../../../svgs/WarningIcon';
import InformationIcon from '../../../svgs/InformationIcon';

interface Props {
  warningDescription?: string | ReactNode;
  infoDescription?: string | ReactNode;
  warningDescription2?: string | ReactNode;
  setIsStartScanning: (_: boolean) => void;
}

export const StartScanningScreen: React.FC<Props> = ({
  infoDescription,
  warningDescription,
  warningDescription2,
  setIsStartScanning,
}) => {
  return (
    <div className="start-scanning-screen-component">
      {warningDescription && (
        <Notice description={warningDescription} type="warning" icon={<WarningIcon />} closable={false} />
      )}
      {warningDescription2 && (
        <>
          <SizeBox height={24} />
          <Notice description={warningDescription2} type="warning" icon={<WarningIcon />} closable={false} />
        </>
      )}
      {infoDescription && (
        <>
          <SizeBox height={24} />
          <Notice description={infoDescription} type="info" icon={<InformationIcon />} closable={false} />
        </>
      )}
      <SizeBox height={24} />
      <div className="button-acton">
        <PrimaryButton label="Start Scanning" onClick={() => setIsStartScanning(true)} />
      </div>
    </div>
  );
};
