import { BaseResponse } from '../interfaces';
import { ModuleCollectionI, ModuleI } from '../interfaces/module';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetModule = (id: ModuleI['id']) => {
  const { data: response, error, isLoading } = useFetch<any>(`modules/${id}`);
  const data = response?.data;
  return { data, error, isLoading };
};

export const getModulesList = async () => {
  const { data: response } = await api.get<BaseResponse<ModuleCollectionI>>(`modules`);
  return response?.data;
};
