import React from 'react';
import { useMutation } from 'react-query';
import { message, notification } from 'antd';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { ResetPasswordParamsI } from '../../interfaces/auth';
import { doResetPassword } from '../../apis/auth';
import ResetPasswordForm from '../../components/forms/ResetPassword';
import './Login.scss';
import { ReactSVG } from 'react-svg';
import LoginSVG from '../../svgs/logo.svg';

const ResetPasswordPage: React.FC = () => {
  const { pathname } = useLocation();
  const token = pathname.split('/')[2] || undefined;

  const navigate = useNavigate();

  const { mutate: createMutate } = useMutation(doResetPassword, {
    onSuccess: async () => {
      message.info('Your password has updated. Try to login again with new password.');
      setTimeout(() => {
        navigate(`/login`);
      }, 3000);
    },
    onError: () => {
      notification.error({
        message: 'The password reset link has expired. Please request a new one.',
        placement: 'top',
      });
    },
  });

  const handleSubmit = async (values: ResetPasswordParamsI) => {
    const newValues: ResetPasswordParamsI = values;
    newValues.token = token;
    createMutate(newValues);
  };

  return (
    <div className="auth-container">
      <div className="login-form-container reset-password-container">
        <div className="form-container">
          <div className="logo-section">
            <Link to="/">
              <ReactSVG src={LoginSVG} />
            </Link>
          </div>
          <h2 className="title">Reset Password</h2>
          <ResetPasswordForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
