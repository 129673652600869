import { Drawer } from 'antd';
import React from 'react';
import LogoSection from '../../components/layouts/Logo';
import MenuLayout from './Menu';
import { CurrentUserI } from '../../interfaces/auth';
import './MenuDrawer.scss';
import Icon from '../Icon';
import CompanyInfo from './CompanyInfo';

type Props = {
  currentUser: CurrentUserI;
  open: boolean;
  setOpen: (_: boolean) => void;
};

const MenuDrawer: React.FC<Props> = ({ currentUser, open, setOpen }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      placement="left"
      closable={true}
      onClose={onClose}
      open={open}
      rootClassName="menu-drawer-container"
      width={220}
      closeIcon={<Icon name="icon-cancel" />}
    >
      <div>
        <LogoSection className="logo-section" />
        <MenuLayout currentUser={currentUser} />
      </div>
      <CompanyInfo />
    </Drawer>
  );
};

export default MenuDrawer;
