import React from 'react';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneNumberInput.scss';

interface Props {
  value?: string;
  onChange?(phone?: string): void;
  disabled?: boolean;
}

export const PhoneNumberInput: React.FC<Props> = ({ value, onChange, ...rest }) => {
  return (
    <PhoneInput
      international
      className="PhoneInput tw-w-full"
      countryCallingCodeEditable={false}
      initialValueFormat="national"
      defaultCountry="SG"
      placeholder="Enter phone number"
      value={!value || value.includes('+') ? value : `+${value}`}
      onChange={(v) => onChange?.(v?.toString())}
      {...rest}
    />
  );
};

export function formatIntlPhoneNumber(phone?: string) {
  if (!phone) return '';
  const internationalPhone = phone.includes('+') ? phone : `+${phone}`;
  const formatedPhone = formatPhoneNumberIntl(internationalPhone);
  return formatedPhone || internationalPhone;
}
