import { Tabs, TabsProps } from 'antd';
import React from 'react';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import SharedScanQRCode from '../shared/Hardware/ScanQRCodeScreen';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import SharedManualScreen from '../shared/Hardware/ManualScreen';
import '../../components/Tracking/ScanUpdateGroupCode.scss';
import { useAppNotification } from '../../hooks/useAppNotification';
import Text from '../Text';

type Props = {
  stopDecodingScanner: boolean;
  setStopDecodingScanner: (_: any) => void;
  handleScanned: (code: string) => void;
};

const REGEX = /^[A-Z0-9]{6}$/;
const errorMessage = 'Please make sure the group code format only includes 6 letters, numbers, and in uppercase';

const ScanUpdateProductGroupCode: React.FC<Props> = ({
  stopDecodingScanner,
  setStopDecodingScanner,
  handleScanned,
}) => {
  const { openNotification } = useAppNotification();
  let count = 1;

  const validateScanResult = (value: string) => {
    let status = 'success';
    if (!REGEX.test(value)) {
      status = 'error';
    }

    setTimeout(() => {
      if (status === 'success') {
        handleScanned(value);
      }
      if (status === 'error') {
        openNotification({
          type: 'error',
          title: 'Incorrect Group Code Format!',
          content: <Text variant="bodyMd">{errorMessage}</Text>,
        });
      }
      count = 1;
    }, 300);
  };

  const handleScanOrManualSubmit = (result: any) => {
    if (count === 1) {
      validateScanResult(result.text);
      count += 1;
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <div className="tw-flex tw-items-center tw-flex-col tw-flex-1">
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </div>
      ),
      children: (
        <SharedScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <div className="tw-flex tw-items-center tw-flex-col tw-flex-1">
          <KeyboardIcon /> <span>Manual</span>
        </div>
      ),
      children: (
        <SharedManualScreen
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          formLabel="Enter Product Group Code"
          placeHolder="Type Product Group Code"
          title="Manual Input Group Code"
          errorMessage={errorMessage}
          validateRegex={REGEX}
        />
      ),
    },
  ];

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  return <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} className="scanUpdateGroupCodeTabs" />;
};

export default ScanUpdateProductGroupCode;
