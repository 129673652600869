import React, { useMemo } from 'react';
import { Checkbox, Form } from 'antd';
import { ModuleI } from '../../../interfaces/module';
import { CheckboxOptionI } from '../../../interfaces';
import ActionSection from '../../Role/ActionSection';
import { handleGetPermissionFromModule } from '../../../utils/role';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const DashboardCollapse: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  const dashboardCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Dashboard'),
    [modules],
  );

  return (
    <ActionSection
      label="Dashboard"
      content={
        <Form.Item name="colDashboard">
          <Checkbox.Group options={dashboardCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colDashboard')} />
        </Form.Item>
      }
    />
  );
};

export default DashboardCollapse;
