import React from 'react';
import { Tabs, TabsProps } from 'antd';
import PaperClipIcon from '../../svgs/PaperClipIcon';
import Attachments from './Attachments';
import SiteUsersTable from '../tables/SiteUsers';
import { SiteI } from '../../interfaces/site';
import Icon from '../Icon';

type Props = {
  data?: SiteI;
};

const SiteResources: React.FC<Props> = ({ data }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span className="tab-title">
          <Icon name="icon-person" size={20} /> <span>Users</span>
        </span>
      ),
      children: <SiteUsersTable dataSource={data?.users} />,
    },
    {
      key: '2',
      label: (
        <span className="tab-title">
          <PaperClipIcon /> <span>Attachment</span>
        </span>
      ),
      children: <Attachments attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="product-history-attachment-container details-container" style={{ marginTop: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default SiteResources;
