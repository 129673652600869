import { BaseResponse } from '../interfaces';
import { PartnerI } from '../interfaces/partner';
import { UserI } from '../interfaces/user';
import { api } from './api';

export const updateProfile = async ({ ...values }: { values: UserI }) => {
  const { data: response }: any = await api.patch(`me`, values);
  return response.data;
};

export const getPartnerProfile = async () => {
  const { data: response } = await api.get<BaseResponse<any>>(`partner`);
  return response?.data;
};

export const updatePartnerProfile = async ({ ...values }: { values: PartnerI }) => {
  const { data: response }: any = await api.patch(`partner`, values);
  return response.data;
};

export const updatePartnerProfileStatus = async (params: { id: number; status: string }) => {
  const { data: response }: any = await api.patch(`partner/status`, { status: params.status });
  return response.data;
};
