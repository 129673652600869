import React, { Fragment, useState } from 'react';
import { AllocationHistoryI } from '../../interfaces/allocationHistory';
import { Notice } from '../Notice';
import { Link } from 'react-router-dom';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import WarningIcon from '../../svgs/WarningIcon';
import { Pagination } from 'antd';
import { PAGE_SIZE } from '../../constants';
import SizeBox from '../SizeBox';
import HardwareDetialsCardItem from '../shared/Hardware/HardwareDetailsCardItem';

const RenderDescriptionNotice = () => {
  return (
    <Fragment>
      <p>
        The hardware might be invalid due to not being registered yet. You can try go through Registration first and try
        to allocate them again.
      </p>
      <Link to={`/hardware-registrations/new`}>
        <span>Go to Registration</span>
        <RightArrowIcon width={16} height={16} />{' '}
      </Link>
    </Fragment>
  );
};

type Props = {
  data?: AllocationHistoryI;
};

export const AllocationHardwareComponent: React.FC<Props> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;

  const handlePaginationChange = (page: number, per_page: number) => {
    setCurrentPage(page);
    setPageSize(per_page);
  };

  return (
    <div className="tw-py-4 tw-px-4">
      {data?.invalidScanList && data?.invalidScanList?.length > 0 && (
        <>
          <Notice description={<RenderDescriptionNotice />} type="warning" icon={<WarningIcon />} closable={false} />
          <SizeBox height={32} />
        </>
      )}
      <div className="tw-flex tw-gap-4 tw-flex-wrap">
        {data?.hardwareList?.slice(start, end)?.map((item: any) => {
          return <HardwareDetialsCardItem item={item} key={item.serialCode} />;
        })}
      </div>
      <div className="pagination-container">
        <Pagination
          total={data?.hardwareList.length ?? 0}
          pageSize={pageSize}
          showTotal={(total) => `${total} Items`}
          current={currentPage}
          onChange={handlePaginationChange}
          showSizeChanger
          pageSizeOptions={[5, 10, 20, 50]}
        />
      </div>
    </div>
  );
};
