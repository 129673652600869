import React, { useState } from 'react';
import { IQrScannerProps, QrScanner } from '@yudiel/react-qr-scanner';
import CameraNotReady from '../CameraNotReady';
import { Modal } from 'antd';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import './Scanner.scss';

type Props = {
  stopDecodingScanner?: boolean;
  setStopDecodingScanner: (_: any) => void;
  handleScanOrManualSubmit: (result: any) => void;
} & { args?: IQrScannerProps };

export const ScanCode: React.FC<Props> = ({ args, stopDecodingScanner, handleScanOrManualSubmit }) => {
  const [isCamereReady, setIsCamereReady] = useState(true);
  const [modal, contextHolder] = Modal.useModal();

  const handleScannerError = (error: any) => {
    if (error) {
      setIsCamereReady(false);
    }
  };

  const onCheckPermission = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ video: true, audio: false });
    permissions
      .then(() => {
        setIsCamereReady(true);
      })
      .catch((err) => {
        setIsCamereReady(false);
        const errorModal = modal.error({
          type: 'error',
          title: 'Permission denied!',
          content: "Please unblock the camera in your browser's settings.",
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <ErrorModalIcon color="#B13939" />,
          width: 345,
        });

        setTimeout(() => {
          errorModal.destroy();
        }, 5 * 1000);
        console.log(`${err.name} : ${err.message}`);
      });
  };
  return (
    <div className="qr-scanner-component">
      {contextHolder}
      {isCamereReady ? (
        <QrScanner
          {...args}
          stopDecoding={stopDecodingScanner}
          onResult={(result) => handleScanOrManualSubmit(result)}
          onError={(error) => handleScannerError(error)}
        />
      ) : (
        <CameraNotReady onCheckPermission={onCheckPermission} />
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ScanCode.args = {
  scanDelay: 500,
  tracker: true,
  hideCount: false,
  constraints: { facingMode: 'environment' },
  deviceId: '',
};
