import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { UserI } from '../../interfaces/user';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const PartnerHistoryTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'User',
      width: 150,
      ellipsis: true,
      render: (user: UserI) => {
        return <span>{user?.username}</span>;
      },
    },
    {
      title: 'EVENT NAME',
      dataIndex: 'event',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      width: 150,
      ellipsis: true,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default PartnerHistoryTable;
