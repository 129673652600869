import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import './ScanTabsContent.scss';
import EmptyData from '../EmptyData';
import NoticeIcon from '../../svgs/NoticeIcon';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import Text from '../Text';
import { ProductGroupScannedStateI } from '../../interfaces/product';
import Icon from '../Icon';

type Props = {
  scannedData: ProductGroupScannedStateI[];
};

const ProductGroupScanTabsContent: React.FC<Props> = ({ scannedData }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="component-scan-tabs-content">
      <div className="notice-section">
        <NoticeIcon />
        <span>
          QR/Barcode for Product can be found on the Brand Plate near the handle of ecoTOTE. Please note that it’s not
          to be mistaken with the QR/Barcode of the ecoTOTE itself on the outer casing.
        </span>
      </div>
      <div className="scanned-btn">
        <Button type="primary" onClick={showDrawer}>
          <span>
            <Icon name="icon-wine_bar" size={20} className="tw-text-grey-700" />
            <span>{`${scannedData.length} Product Scanned`}</span>
            <RightArrowIcon />
          </span>
        </Button>
      </div>
      <Drawer
        title={
          <span className="tw-flex tw-items-center tw-gap-2">
            <Icon name="icon-wine_bar" size={20} className="tw-text-grey-700" />{' '}
            {`${scannedData.length} Product Scanned`}
          </span>
        }
        onClose={onDrawerClose}
        open={open}
        rootClassName="hardware-scan-qrcode-drawer"
      >
        <div className="hardware-scanned-list-drawer">
          {scannedData?.length > 0 ? (
            scannedData.map((data: ProductGroupScannedStateI) => (
              <div key={data.sku} className="item">
                <Text variant="bodyLgB" className="tw-text-primary-900 !tw-block">{`${data.sku}`}</Text>
              </div>
            ))
          ) : (
            <EmptyData message="No hardware scanned yet" />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default ProductGroupScanTabsContent;
