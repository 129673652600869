import React, { ReactNode } from 'react';
import { notification } from 'antd';
import ErrorIcon from '../svgs/ErrorIcon';
import type { NotificationArgsProps } from 'antd';
type NotificationPlacement = NotificationArgsProps['placement'];

type Props = {
  message?: ReactNode;
  placement?: NotificationPlacement;
};

const AppErrorNofication: React.FC<Props> = ({ message, placement = 'topRight' }) => {
  notification.error({
    message,
    placement,
    icon: <ErrorIcon color="#B13939" />,
    className: 'AppNotification',
  });

  return null;
};

export default AppErrorNofication;
