import { BaseResponse } from '../../interfaces';
import {
  HardwareCollectionI,
  HardwareHistoriesCollectionI,
  HardwareI,
  HardwareSearchParamsI,
  HardwareUpdateStatusI,
} from '../../interfaces/hardware';
import { HardwareFaultReportCollectionI, HardwareFaultReportSearchParams } from '../../interfaces/hardwareFaultReports';
import { HardwareHistorySearchParams } from '../../interfaces/hardwareHistory';
import { handleFilterParams } from '../../utils/global';
import { api } from '../api';
import { useFetch } from '../reactQuery';

export const useGetPlatformHardware = (serialCode?: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`platform/hardwares/${serialCode}`);
  const data = response?.data;
  return { data, error, isLoading };
};

export const getPlatformHardwareDetails = async (id: number | string) => {
  const { data: response } = await api.get<BaseResponse<HardwareI>>(`platform/hardwares/${id}`);
  return response.data;
};

export const getPlatformHardwareList = async (params?: HardwareSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<HardwareCollectionI>>(
    `platform/hardwares?${handleFilterParams(params)}`,
  );
  return response;
};

export const updatePlatformHardwares = async ({ id, ...values }: { id: string; values: HardwareI }) => {
  const { data: response }: any = await api.patch(`platform/hardwares/${id}`, values);
  return response.data;
};

export const getPlatformProductHistoriesList = async (id?: HardwareI['id']) => {
  const { data: response } = await api.get<BaseResponse<HardwareHistoriesCollectionI>>(
    `platform/hardwares/${id}/histories`,
  );
  return response?.data;
};

export const updatePlatformHardwareStatus = async (newParams: { id: number; params: HardwareUpdateStatusI }) => {
  const { data: response }: any = await api.patch(`platform/hardwares/${newParams?.id}/status`, {
    ...newParams.params,
  });
  return response.data;
};

export const getPlatformHardwareHistory = async (params: { id: string; params: HardwareHistorySearchParams }) => {
  const { data: response } = await api.get<BaseResponse<HardwareHistoriesCollectionI>>(
    `platform/hardwares/${params?.id}/histories?${handleFilterParams(params?.params)}`,
  );
  return response;
};

export const getPlatformHardwareFaultReports = async (params: {
  serialCode: string;
  params: HardwareFaultReportSearchParams;
}) => {
  const { data: response } = await api.get<BaseResponse<HardwareFaultReportCollectionI>>(
    `platform/hardwares/${params.serialCode}/fault-reports?${handleFilterParams(params?.params)}`,
  );
  return response;
};
