import React, { useState } from 'react';
import { Pagination } from 'antd';
import { PAGE_SIZE } from '../../constants';
import { HardwareDepletionI } from '../../interfaces/hardwareDepletion';
import HardwareDetialsCardItem from '../shared/Hardware/HardwareDetailsCardItem';

type Props = {
  data?: HardwareDepletionI;
};

export const DepletionHardwareScannedComponent: React.FC<Props> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;

  const handlePaginationChange = (page: number, per_page: number) => {
    setCurrentPage(page);
    setPageSize(per_page);
  };

  return (
    <div className="tw-py-4 tw-px-4">
      <div className="tw-flex tw-gap-4 tw-flex-wrap">
        {data?.hardwareList?.slice(start, end)?.map((item: any) => {
          return <HardwareDetialsCardItem item={item} key={item.serialCode} />;
        })}
      </div>
      <div className="pagination-container">
        <Pagination
          total={data?.hardwareList.length ?? 0}
          pageSize={pageSize}
          showTotal={(total) => `${total} Items`}
          current={currentPage}
          onChange={handlePaginationChange}
          showSizeChanger
          pageSizeOptions={[5, 10, 20, 50]}
        />
      </div>
    </div>
  );
};
