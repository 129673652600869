import React, { ReactNode } from 'react';
import './Notice.scss';
import { Alert } from 'antd';
import InformationIcon from '../svgs/InformationIcon';
import ErrorIcon from '../svgs/ErrorIcon';
import WarningIcon from '../svgs/WarningIcon';

interface Props {
  message?: string | ReactNode;
  description?: string | ReactNode;
  type: 'info' | 'warning' | 'error' | 'success';
  icon?: ReactNode;
  showIcon?: boolean;
  closable?: boolean;
  onClose?: () => void;
}

export const Notice: React.FC<Props> = ({ message, description, type, icon, showIcon, closable, onClose }) => {
  const renderIcon = (type: string) => {
    switch (type) {
      case 'error':
        return <ErrorIcon />;
      case 'info':
        return <InformationIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'success':
        return <InformationIcon />;
      default:
        return <InformationIcon />;
    }
  };

  return (
    <Alert
      message={message ?? message}
      description={description}
      type={type ?? 'info'}
      showIcon={showIcon ?? true}
      icon={icon ?? renderIcon(type)}
      className={`notice-custom ${type}`}
      closable={closable ?? true}
      onClose={onClose ?? onClose}
    />
  );
};
