import React from 'react';

const ActivateIcon: React.FC = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="circle_tick">
        <path
          id="Vector"
          d="M10.2513 1.66406C5.66797 1.66406 1.91797 5.41406 1.91797 9.9974C1.91797 14.5807 5.66797 18.3307 10.2513 18.3307C14.8346 18.3307 18.5846 14.5807 18.5846 9.9974C18.5846 5.41406 14.8346 1.66406 10.2513 1.66406ZM10.2513 16.6641C6.5763 16.6641 3.58464 13.6724 3.58464 9.9974C3.58464 6.3224 6.5763 3.33073 10.2513 3.33073C13.9263 3.33073 16.918 6.3224 16.918 9.9974C16.918 13.6724 13.9263 16.6641 10.2513 16.6641ZM14.0763 6.31406L8.58464 11.8057L6.4263 9.65573L5.2513 10.8307L8.58464 14.1641L15.2513 7.4974L14.0763 6.31406Z"
          fill="#224A13"
        />
      </g>
    </svg>
  );
};

export default ActivateIcon;
