import React from 'react';
import { Tabs, TabsProps } from 'antd';
import Icon from '../../Icon';
import Text from '../../Text';
import HardwareAttachments from './Attachments';
import PlatformHardwareHistory from './History';
import { HardwareI } from '../../../interfaces/hardware';
import PlatformHardwareFaultReports from './FaultReports';

type Props = {
  data: HardwareI;
  id?: string;
  currentTab: string;
  setCurrentTab?: (_: string) => void;
};

const PlatformHardwareResources: React.FC<Props> = ({ data, id, currentTab, setCurrentTab }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-history" size={20} /> <span>History</span>
        </Text>
      ),
      children: <PlatformHardwareHistory id={id} hardwareType={data?.type} />,
    },
    {
      key: '3',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-error_outline" /> <span>Fault Report</span>
        </Text>
      ),
      children: <PlatformHardwareFaultReports id={id} />,
    },
    {
      key: '4',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-attach_file" /> <span>Attachments</span>
        </Text>
      ),
      children: <HardwareAttachments attachments={data?.attachments} />,
    },
  ];

  const handleTabChange = (key: string) => {
    setCurrentTab?.(key);
  };

  return (
    <div className="hardware-attachment-container" id="platform-hardware-container" style={{ marginBottom: 16 }}>
      <div className="content-wrap">
        <Tabs
          defaultActiveKey={currentTab}
          items={items}
          rootClassName="app-tabs"
          onChange={handleTabChange}
          activeKey={currentTab}
        />
      </div>
    </div>
  );
};

export default PlatformHardwareResources;
