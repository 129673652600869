import { Badge, Dropdown, Form, Input, MenuProps } from 'antd';
import React, { useMemo } from 'react';
import IconButton from '../../buttons/IconButton';
import SearchInputIcon from '../../../svgs/SearchInputIcon';
import FilterContent from '../user/FilterContent';
import FilterIcon from '../../../svgs/FilterIcon';
import ViewColumn from '../../../svgs/ViewColumn';
import { UserSearchParamsI } from '../../../interfaces/user';
import DropdownApp from '../../Dropdown';
import Icon from '../../Icon';
import { renderSortList } from '../../../utils/sort';

type Props = {
  queryParams: UserSearchParamsI & { viewMode?: string };
  onChange(newParams: UserSearchParamsI & { viewMode?: string }): void;
  showView?: boolean;
  showFilter?: boolean;
};

const ExistingUserFilter: React.FC<Props> = ({ queryParams, onChange, showView, showFilter }) => {
  const [form] = Form.useForm();

  const handleOnChange = (params: Partial<UserSearchParamsI & { viewMode?: string }>) => {
    onChange({ ...queryParams, ...params });
  };

  const count = useMemo(() => {
    if (!queryParams.filter) return 0;
    let count = 0;

    Object.values(queryParams.filter).forEach((val: any) => {
      count += val?.length || 0;
    });
    return count;
  }, [queryParams]);

  const handleDropdownItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleOnChange({
      orderBy: orderBy as UserSearchParamsI['orderBy'],
      orderType: type as UserSearchParamsI['orderType'],
      page: queryParams.page,
      limit: queryParams.limit,
    });
  };

  const items: MenuProps['items'] = renderSortList(queryParams, 'username');

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="tw-flex tw-flex-row tw-gap-2 tw-w-full tw-my-8 tw-items-start">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={({ username }) => handleOnChange({ search: { username } })}
        scrollToFirstError
        className="tw-w-full"
      >
        <Form.Item
          name="username"
          className="search-box tw-w-full tw-mb-0"
          initialValue={typeof queryParams.search === 'string' ? queryParams.search : ''}
        >
          <Input placeholder="Search" prefix={<SearchInputIcon />} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
      <DropdownApp
        icon={<Icon name="icon-sort" size={22} />}
        menu={menuDropdownProps}
        size="small"
        placement="bottomRight"
        type="secondary"
      />
      {showFilter && (
        <Dropdown
          className="filter-dropdown-button"
          dropdownRender={() => (
            <FilterContent
              queryParams={queryParams}
              onChange={({ filter, search }) => handleOnChange({ filter, search })}
            />
          )}
          trigger={['click']}
          overlayClassName="filter-drowdown"
        >
          <Badge count={count}>
            <IconButton icon={<FilterIcon height={20} />} />
          </Badge>
        </Dropdown>
      )}
      {showView && (
        <IconButton
          className={
            queryParams.viewMode === 'list'
              ? '!tw-h-full tw-bg-primary-800 tw-text-white hover:!tw-bg-primary-800 hover:!tw-text-white'
              : '!tw-h-full tw-bg-white hover:!tw-bg-primary-800 hover:!tw-text-white'
          }
          icon={<ViewColumn />}
          onClick={() => handleOnChange({ viewMode: queryParams.viewMode ? undefined : 'list' })}
        />
      )}
    </div>
  );
};

export default ExistingUserFilter;
