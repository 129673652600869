import React from 'react';
import { Button } from 'antd';
import { ReactSVG } from 'react-svg';
import DownloadSVG from '../../svgs/download.svg';
import { AttachmentI } from '../../interfaces/attachments';
import { saveAs } from 'file-saver';
import AttachmentsTable from '../tables/Attachments';
import DeleteAttachmentButton from '../buttons/DeleteAttachmentButton';
import { useParams } from 'react-router-dom';

type Props = {
  attachments?: any[];
};

const FillingHistoryAttachments: React.FC<Props> = ({ attachments }) => {
  const { uuid: hardwareId } = useParams();

  const handleDownload = (record: AttachmentI) => {
    saveAs(record.url, record.fileName || 'allocation-history-attachment-file');
  };

  const withAction = (record: AttachmentI) => {
    return (
      <div className="table-action-icon tw-flex tw-gap-x-1">
        <Button className="btn-table-action-primary has-icon" type="link" onClick={() => handleDownload(record)}>
          <ReactSVG src={DownloadSVG} />
        </Button>
        <DeleteAttachmentButton
          id={+record.id!}
          apiPath={`filling-histories/${hardwareId}`}
          accessModule="filling-histories"
        />
      </div>
    );
  };
  return (
    <div className="partner-history-container">
      <div className="page-content-header">
        <h2 className="title-page">Attachments</h2>
      </div>
      <div className="content-wrap">
        <AttachmentsTable rowKey="id" pagination={false} dataSource={attachments} withAction={withAction} />
      </div>
    </div>
  );
};

export default FillingHistoryAttachments;
