import React from 'react';
import { HardwareVerifyCollectionI } from '../../../interfaces/hardware';
import SecondaryButton from '../../buttons/SecondaryButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import HardwareDetailsCard from './HardwareDetailsCard';
import { useAppNotification } from '../../../hooks/useAppNotification';

type Props = {
  registerVerifiedData?: HardwareVerifyCollectionI;
  setCurrentStep: (step: number) => void;
  handleDeleteVerifiedData?: (_: any) => void;
  handleScanmoreButton: () => void;
};

const HardwareGroupVerifyStep: React.FC<Props> = ({
  registerVerifiedData,
  setCurrentStep,
  handleDeleteVerifiedData,
  handleScanmoreButton,
}) => {
  const { openNotification } = useAppNotification();

  const continueClick = () => {
    if (registerVerifiedData?.hardwareList?.length === 0) {
      openNotification({
        type: 'error',
        title: 'No Valid Code Scanned!',
        content: 'Please scan more to make sure you have at least 1 valid scanned hardware before proceeding.',
      });
    } else if (registerVerifiedData?.numberOfInvalid) {
      openNotification({
        type: 'error',
        title: 'Invalid Code(s) Not Removed Yet',
        content: 'Please check and remove all invalid scans before proceeding to next step',
      });
    } else {
      setCurrentStep(5);
    }
  };

  return (
    <div className="tw-py-4 tw-px-4">
      <div className="tw-flex tw-gap-4 tw-flex-wrap">
        {registerVerifiedData?.hardwareList?.map((item: any) => {
          return <HardwareDetailsCard item={item} key={item?.serialCode} handleDelete={handleDeleteVerifiedData} />;
        })}
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Scan more" onClick={handleScanmoreButton} />
          <PrimaryButton label="Next" onClick={continueClick} />
        </div>
      </div>
    </div>
  );
};

export default HardwareGroupVerifyStep;
