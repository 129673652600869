import { Form, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import { ProductSearchParamsI } from '../../../interfaces/product';
import { useQuery } from 'react-query';
import countryList from 'react-select-country-list';
import { getProductCategoriesList, getProductCategory } from '../../../apis/productCategories';
import { ProductCategoryI } from '../../../interfaces/productCategory';
import SecondaryButton from '../../buttons/SecondaryButton';
import Text from '../../Text';

type FormParams = {
  name: string;
  sku: string;
  brand: string;
  categoryId: string;
  secondCategoryId: number;
  thirdCategoryId: number;
  productionOrigin: string;
};

type Props = {
  queryParams: ProductSearchParamsI;
  onChange(newParams: Partial<ProductSearchParamsI>): void;
};

const ProductFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [parentId, setParentId] = useState<number>();
  const [categoryChildren, setCategoryChildren] = useState<ProductCategoryI[]>([]);
  const [categoryThirdChildren, setCategoryThirdChildren] = useState<ProductCategoryI[]>([]);
  const [secondCategoryId, setSecondCategoryId] = useState<number>();
  const [thirdCategoryId, setThirdCategoryId] = useState<number>();
  const [isCategoryLoading, setIsCategoryLoading] = useState<boolean>(false);

  const { data: categoriesData, isLoading } = useQuery(['categories', 'list', {}], () => getProductCategoriesList());

  const handleOnFinish = (values: FormParams) => {
    const categoryId = values?.thirdCategoryId ?? values?.secondCategoryId ?? values?.categoryId;
    onChange({
      filter: { categoryId: categoryId?.toString(), productionOrigin: values?.productionOrigin },
    });
  };

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const onParentCategoryChange = async (value: number) => {
    if (value) {
      const result: any = await getProductCategory(value);
      if (result?.children) {
        setCategoryChildren(result?.children);
      } else {
        setCategoryChildren([]);
      }
      form.resetFields(['secondCategoryId', 'thirdCategoryId']);
      setCategoryThirdChildren([]);
    }
  };

  const onChildrenChange = (value: number) => {
    const secondChildren: ProductCategoryI | undefined = categoryChildren.find(
      (child: ProductCategoryI) => child.id === value,
    );
    if (secondChildren?.children) {
      setCategoryThirdChildren(secondChildren?.children);
    } else {
      setCategoryThirdChildren([]);
    }
    form.resetFields(['thirdCategoryId']);
  };

  const handleGetSecondCategory = async (secondId: number) => {
    const result: any = await getProductCategory(secondId);
    if (result?.children) {
      setCategoryChildren(result?.children);
    } else {
      setCategoryChildren([]);
    }
  };

  const handleGetCategory = async (id: number) => {
    try {
      setIsCategoryLoading(true);
      const result: any = await getProductCategory(id);

      if (!result) return undefined;
      if (result?.parentId === null) {
        setParentId(result?.id);
        setCategoryChildren(result?.children);
        setSecondCategoryId(id);
        setIsCategoryLoading(false);
      } else if (result?.parent && result?.parent?.parentId === null) {
        setParentId(result?.parent?.id);
        setSecondCategoryId(result?.id);
        setCategoryThirdChildren(result?.children);
        handleGetSecondCategory(result?.parent?.id);
        setIsCategoryLoading(false);
      } else {
        setThirdCategoryId(result.id);
        handleGetCategory(result?.parent.id);
        setIsCategoryLoading(false);
      }
    } catch (error: any) {
      console.log('error', error?.message);
    }
  };

  useEffect(() => {
    if (queryParams.filter?.categoryId) {
      handleGetCategory(Number(queryParams.filter?.categoryId));
    }
  }, []);

  useEffect(() => {
    if (parentId || secondCategoryId || thirdCategoryId) {
      form.setFieldsValue({
        categoryId: parentId,
        secondCategoryId: secondCategoryId ?? null,
        thirdCategoryId: thirdCategoryId ?? null,
      });
    }
  }, [parentId, secondCategoryId, thirdCategoryId]);

  useEffect(() => {
    form.setFieldsValue({
      categoryId: queryParams.filter?.categoryId,
      productionOrigin: queryParams.filter?.productionOrigin,
    });
  }, [queryParams]);

  return (
    <div className="partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Text variant="h4" className="tw-mb-3">
              Product Origin
            </Text>
            <Form.Item name="productionOrigin">
              <Select
                placeholder="Select a country"
                showSearch
                optionFilterProp="label"
                options={countryOptions}
                allowClear
              />
            </Form.Item>
            <Text variant="h4" className="tw-mb-3">
              Beverage Type
            </Text>
            <Form.Item label="Beverage Type" name="categoryId">
              <Select
                placeholder="Choose an option"
                style={{ width: '100%' }}
                onChange={onParentCategoryChange}
                loading={isLoading || isCategoryLoading}
                allowClear
              >
                {categoriesData?.categories?.map((category: ProductCategoryI) => (
                  <Select.Option key={category.id} value={category.id}>
                    <span>{category.name}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {categoryChildren?.length > 0 && (
              <Form.Item label="Beverage Classification" name="secondCategoryId">
                <Select placeholder="Choose an option" style={{ width: '100%' }} onChange={onChildrenChange} allowClear>
                  {categoryChildren?.map((category: ProductCategoryI) => (
                    <Select.Option key={category.id} value={category.id}>
                      <span>{category.name}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {categoryThirdChildren.length > 0 && (
              <Form.Item label="Beverage Sub-Classification" name="thirdCategoryId">
                <Select placeholder="Choose an option" style={{ width: '100%' }} allowClear>
                  {categoryThirdChildren.map((category: ProductCategoryI) => (
                    <Select.Option key={category.id} value={category.id}>
                      <span className="text-capitalize">{category.name}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProductFilterContent;
