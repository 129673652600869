import React from 'react';
import Text from '../Text';
import './CompanyInfo.scss';
import { Link } from 'react-router-dom';

const CompanyInfo: React.FC = () => {
  return (
    <div className="company-info-menu">
      <Text variant="labelXm" className="tw-text-grey-700 tw-font-oxygen tw-flex tw-mb-2 !tw-font-normal">
        Version 1.1.1 | &nbsp;
        <Link
          to="https://ecospirits.global/circularone-change-log/"
          target="_blank"
          className="tw-underline tw-text-grey-700 hover:tw-text-grey-700 hover:tw-underline tw-inline-block"
        >
          View Change Log
        </Link>
      </Text>
      <Text variant="labelSm" className="tw-font-semibold tw-text-grey-600 tw-mb-2 tw-flex tw-items-center tw-gap-1">
        © {`${new Date().getFullYear()} `}
        <Text variant="labelSm" component="span" className="tw-text-primary-900">
          ecoSPIRITS.
        </Text>
      </Text>
      <Text variant="capt1" className="tw-text-grey-700 tw-font-normal">
        All Right Reserved.
      </Text>
    </div>
  );
};

export default CompanyInfo;
