import { MenuProps, Modal, message } from 'antd';
import React, { useState } from 'react';
import Text from '../Text';
import { useMutation, useQueryClient } from 'react-query';
import { SiteStatusList } from '../../constants';
import { CurrentUserI } from '../../interfaces/auth';
import { useOutletContext } from 'react-router';
import { handleAccessControl } from '../../utils/role';
import Icon from '../Icon';
import DropdownApp from '../Dropdown';
import { CommonStatus } from '../CommonStatus';
import { SiteI } from '../../interfaces/site';
import { updateSiteStatus } from '../../apis/sites';
import AppUpdateStatusForm from '../forms/AppUpdateStatus';
import LocationOnIcon from '../../svgs/LocationOnIcon';
import BackLink from '../buttons/BackLink';
import { useNavigate } from 'react-router-dom';

type Props = {
  site: Partial<SiteI>;
  isDisplayActions?: boolean;
};

const SiteName: React.FC<Props> = ({ site, isDisplayActions = true }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'sites');
  const navigate = useNavigate();

  const [isUpdateStatusVisible, setIsUpdateStatusVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleCancel = () => {
    setIsUpdateStatusVisible(false);
  };

  const { mutate: updateStatus } = useMutation(updateSiteStatus, {
    onSuccess: async () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const onUpdateStatusSubmit = async (values: { status: string }) => {
    updateStatus({ id: Number(site?.id), status: values.status });
    setIsUpdateStatusVisible(false);
  };

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'update-status') setIsUpdateStatusVisible(true);
    if (e.key === 'edit') navigate(`/sites/${site?.id}/edit`);
  };

  const items: MenuProps['items'] = [
    access.isCanUpdate
      ? {
          key: 'edit',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-edit" size={16} />
              <span>Edit Site</span>
            </Text>
          ),
        }
      : null,
    access.isCanUpdateStatus
      ? {
          key: 'update-status',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-radio_button_checked" size={16} />
              <span>Update Status</span>
            </Text>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="partner-name-container tw-mb-6">
      <div className="content-wrap">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <BackLink />
          {isDisplayActions && (
            <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />
          )}
        </div>
        <div className="wrapper tw-flex tw-items-center tw-flex-row">
          <div className="logo-section tw-mr-2 tw-w-[64px] tw-h-[64px] xs:tw-w-[48px] xs:tw-h-[48px] tw-flex tw-items-center tw-justify-center tw-bg-grey-400 tw-rounded-lg">
            <LocationOnIcon size={32} color="#898989" />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-2">
            <Text variant="h4" component="h4">
              {site?.legalName}
            </Text>
            <div>{<CommonStatus status={site?.status as SiteI['status']} />}</div>
          </div>
        </div>
      </div>
      <Modal open={isUpdateStatusVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={412}>
        <AppUpdateStatusForm
          data={site}
          onCancel={handleCancel}
          handleSubmit={onUpdateStatusSubmit}
          statusList={SiteStatusList}
        />
      </Modal>
    </div>
  );
};

export default SiteName;
