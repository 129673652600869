import React from 'react';
import Text from '../../Text';
import { CommonStatus } from '../../CommonStatus';
import BackLink from '../../buttons/BackLink';
import { ProductI } from '../../../interfaces/product';

type Props = {
  product?: Partial<ProductI>;
};

const PlatformProductTopSection: React.FC<Props> = ({ product }) => {
  return (
    <div className="product-name-container tw-mb-6">
      <div className="content-wrap">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <BackLink />
        </div>
        <div className="wrapper tw-flex tw-items-center tw-flex-row">
          <div className="name-section">
            <Text variant="h2">{product?.name}</Text>
            <div className="status tw-mt-2">{<CommonStatus status={product?.status as ProductI['status']} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformProductTopSection;
