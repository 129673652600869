import { Form, message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { updatePartner, useGetPartner } from '../../../apis/partners';
import { CreateUpdateTopSection } from '../../../components/shared/CreateUpdateTopSection';
import PartnerForm from '../../../components/forms/partner/Form';

const PartnerUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetPartner(Number(id));

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(updatePartner, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['platform-partners', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`platform-partners/${id}`, undefined],
      });
      message.info('Update partner successfully!');
      navigate(-1);
    },
    onError: () => {
      message.error('Update partner failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading || isPageLoading}>
      <div className="partners-container">
        <CreateUpdateTopSection label="Edit Partner" labelButton="Update" form={form} />
        <PartnerForm form={form} partner={data} partnerId={data?.id} handleSubmit={handleSubmit} />
      </div>
    </Spin>
  );
};

export default PartnerUpdatePage;
