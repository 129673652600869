import React, { Fragment, useState } from 'react';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import FillingScanQRCode from './ScanQRCode';
import { Tabs, TabsProps } from 'antd';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import { FillingStartScanningScreen } from './StartScanningScreen';
import FillingManual from './Manual';
import './ScanStep.scss';
import { FillingParamsI } from '../../interfaces/hardwareFilling';
import LotNumberCardItem from './LotNumberCardItem';

const ScanNoticeMessage =
  'For the Lot Number, please refer to Barrel/IBC. If unavailable, please input the Date of Fill (DD/MM/YY).';
const ManualNoticeMessage =
  'For the Lot Number, please refer to Barrel/IBC. If unavailable, please input the Date of Fill (DD/MM/YY).';

const StartScreenContent = () => {
  return (
    <ul className="tw-my-0">
      <li>Lot Number can be found on top or on the side of the Barrel. Refer to Barrel/IBC. </li>
      <li>If unavailable, input the Date of Fill (DD/MM/YY)</li>
    </ul>
  );
};

type Props = {
  setCurrentStep: (_: number) => void;
  setScannedData: (_: FillingParamsI) => void;
  scannedData?: FillingParamsI;
  stopDecodingScanner: boolean;
  currentStep: number;
  pageName?: string;
  setStopDecodingScanner: (_: any) => void;
};

const FillingLotNumberScanStep: React.FC<Props> = ({
  scannedData,
  stopDecodingScanner,
  currentStep,
  pageName,
  setCurrentStep,
  setScannedData,
  setStopDecodingScanner,
}) => {
  const [screen, setScreen] = useState<'start' | 'scan' | 'scanned'>('start');
  const [dataChecked, setDataChecked] = useState<string>();

  const handleScanOrManualSubmit = (result: any) => {
    setTimeout(() => {
      setScreen('scanned');
      setDataChecked(result.text);
      setScannedData({ ...scannedData, lotNumber: result.text });
    }, 1000);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <FillingScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanNoticeMessage={ScanNoticeMessage}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          pageName={pageName}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <FillingManual
          label="Lot Number"
          scanNoticeMessage={ManualNoticeMessage}
          handleScanOrManualSubmit={handleScanOrManualSubmit}
        />
      ),
    },
  ];

  const onDiscard = () => {
    if (dataChecked) {
      setScreen('scan');
      setDataChecked(undefined);
    } else {
      setCurrentStep(2);
    }
  };

  const onContinue = () => {
    setCurrentStep(4);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  const onStartScreenClick = () => {
    setStopDecodingScanner(false);
    setScreen('scan');
  };

  return (
    <div className="component-hardware-filling-scan-step">
      <div className="wrapper">
        {screen === 'start' && (
          <FillingStartScanningScreen
            setScreen={setScreen}
            setStopDecodingScanner={setStopDecodingScanner}
            currentStep={currentStep}
            content={<StartScreenContent />}
          />
        )}
        {screen === 'scan' && <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />}
        {screen === 'scanned' && <LotNumberCardItem item={dataChecked} />}
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label={dataChecked ? 'Rescan' : 'Previous Step'} onClick={onDiscard} />
          {screen === 'start' && <PrimaryButton label={'Scan Lot Number'} onClick={onStartScreenClick} />}
          {screen !== 'start' && <PrimaryButton label={'Continue'} onClick={onContinue} disabled={!dataChecked} />}
        </div>
      </div>
    </div>
  );
};

export default FillingLotNumberScanStep;
