import { Checkbox, FormInstance } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import Text from './Text';

type Props = {
  plainOptions: any[];
  form: FormInstance;
  name: string;
  sectionTitle?: string;
} & PropsWithChildren;

const SelectAllCheckbox: React.FC<Props> = ({ plainOptions, children, form, name, sectionTitle }) => {
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const getOptionValue = (plainOptions: any) => (typeof plainOptions === 'string' ? plainOptions : plainOptions.value);

  useEffect(() => {
    const initialValues = form.getFieldValue(name);
    if (initialValues) {
      setCheckedList(initialValues);
      setIndeterminate(!!initialValues.length && initialValues.length < plainOptions.length);
      setCheckAll(initialValues.length === plainOptions.length);
    }
  }, [form, name, plainOptions]);

  const onChange = (list: any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    form.setFieldsValue({ [name]: list });
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? plainOptions.map(getOptionValue) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    form.setFieldsValue({ [name]: e.target.checked ? plainOptions.map(getOptionValue) : [] });
  };

  return (
    <div className="tw-flex tw-flex-col tw-w-full">
      <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
        {sectionTitle ? <Text variant="h4">{sectionTitle}</Text> : <div></div>}
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          className="tw-flex tw-flex-row-reverse"
        >
          <Text variant="bodySm" className="tw-text-grey-700 tw-flex">
            Select All
          </Text>
        </Checkbox>
      </div>
      <Checkbox.Group value={checkedList} onChange={onChange} style={{ gap: 8 }}>
        {children}
      </Checkbox.Group>
    </div>
  );
};

export default SelectAllCheckbox;
