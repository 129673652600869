import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../utils/global';
import { CommonStatus } from '../CommonStatus';
import { HardwareFaultReportI } from '../../interfaces/hardwareFaultReports';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: HardwareFaultReportI[];
};

const FaultReportsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Fault report',
      width: 200,
      ellipsis: true,
      dataIndex: 'code',
    },
    {
      title: 'FAULT TYPE',
      width: 150,
      ellipsis: true,
      dataIndex: 'type',
      render: (type: string) => {
        return <span>{type === 'hardwareFault' ? 'Hardware Fault' : 'Product Fault'}</span>;
      },
    },
    {
      title: 'Status',
      width: 180,
      dataIndex: 'status',
      render: (status: 'unresolved' | 'resolved') => {
        return <CommonStatus status={status} />;
      },
    },
    {
      title: 'date reported',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default FaultReportsTable;
