import { Form, message, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { updateFillingHistories, useGetFillingHistory } from '../../apis/hardwardFilling';
import FillingHistoryInformation from '../../components/FillingHistory/Information';
import DetailsTopSection from '../../components/shared/DetailTopSection';
import { useMutation, useQueryClient } from 'react-query';
import AddAttachmentForm from '../../components/forms/AddAttachmentForm';
import { ProductAttachmentCategories } from '../../constants';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import FillingResources from '../../components/FillingHistory/Resource';

const FillingDetailsPage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetFillingHistory(uuid);
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'filling-histories');
  const { mutate } = useMutation(updateFillingHistories, {
    onSuccess: async () => {
      message.info('Uploaded attachment!');
      queryClient.invalidateQueries({
        queryKey: [`filling-histories/${uuid}`],
      });
    },
    onError: () => {
      message.error('Adding attachment failed! Please try again');
    },
  });

  function handleCancelUploadAttachment() {
    setIsVisibleModalUpload(false);
    form.resetFields(['categoryName', 'file']);
  }
  if (error && error === 'FillingHistory not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <DetailsTopSection
        label={`Fill Report - ${data?.uuid}`}
        setIsVisibleModalUpload={setIsVisibleModalUpload}
        access={access}
      />
      <FillingHistoryInformation data={data} />
      <FillingResources data={data} />
      <Modal
        open={isVisibleModalUpload}
        onOk={handleCancelUploadAttachment}
        onCancel={handleCancelUploadAttachment}
        footer={null}
        width={560}
      >
        <AddAttachmentForm
          form={form}
          handleCancel={handleCancelUploadAttachment}
          multiple
          categories={ProductAttachmentCategories}
          update={mutate}
        />
      </Modal>
    </Spin>
  );
};

export default FillingDetailsPage;
