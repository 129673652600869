import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const NoticeIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 14}px`}
      viewBox="0 0 14 14"
      width={`${width ?? 14}px`}
      fill={color ?? 'currentColor'}
    >
      <path
        id="Vector"
        d="M6.33301 3.66732H7.66634V5.00065H6.33301V3.66732ZM6.33301 6.33398H7.66634V10.334H6.33301V6.33398ZM6.99967 0.333984C3.31967 0.333984 0.333008 3.32065 0.333008 7.00065C0.333008 10.6807 3.31967 13.6673 6.99967 13.6673C10.6797 13.6673 13.6663 10.6807 13.6663 7.00065C13.6663 3.32065 10.6797 0.333984 6.99967 0.333984ZM6.99967 12.334C4.05967 12.334 1.66634 9.94065 1.66634 7.00065C1.66634 4.06065 4.05967 1.66732 6.99967 1.66732C9.93967 1.66732 12.333 4.06065 12.333 7.00065C12.333 9.94065 9.93967 12.334 6.99967 12.334Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
export default NoticeIcon;
