import { Tag } from 'antd';
import React from 'react';
import './Information.scss';
import { UserI } from '../../interfaces/user';
import { RoleI } from '../../interfaces/role';
import { SiteI } from '../../interfaces/site';
import { formatIntlPhoneNumber } from '../PhoneNumberInput/PhoneNumberInput';
import { Link } from 'react-router-dom';

type Props = {
  user?: UserI;
};

const UserInformation: React.FC<Props> = ({ user }) => {
  return (
    <div className="user-information-container details-container">
      <div className="details-wrap">
        <div className="content-wrap">
          <h2 className="details-title">User Information</h2>
          <div className="details-content-wrap">
            <div className="item">
              <div className="label">Partner Entity</div>
              <div className="value">{user?.partner?.legalName ?? 'Not Applicable'}</div>
            </div>
            <div className="item">
              <div className="label">Username</div>
              <div className="value">{user?.username ?? 'Not Applicable'}</div>
            </div>
            <div className="item">
              <div className="label">Roles</div>
              <div className="value">
                {user?.type !== 'partnerAdmin' && user?.roles && user?.roles?.length > 0
                  ? user.roles.map((role: RoleI) => (
                      <Tag key={role?.id}>
                        <Link to={`/roles/${role?.id}`}>{role?.name}</Link>
                      </Tag>
                    ))
                  : user?.type === 'partnerAdmin'
                    ? 'Partner Administrator'
                    : 'Not Applicable'}
              </div>
            </div>
            <div className="item">
              <div className="label">Site</div>
              <div className="value">
                {user?.type !== 'partnerAdmin' && user?.sites && user?.sites.length > 0
                  ? user.sites.map((site: SiteI) => (
                      <Tag key={site?.id}>
                        <Link to={`/sites/${site?.id}`}>{site?.legalName}</Link>
                      </Tag>
                    ))
                  : user?.type === 'partnerAdmin'
                    ? 'All Sites'
                    : 'Not Applicable'}
              </div>
            </div>
          </div>
          <h2 className="details-title">User Contact</h2>
          <div className="details-content-wrap">
            <div className="item">
              <div className="label">Phone Number</div>
              <div className="value">
                {user?.contactNumber ? formatIntlPhoneNumber(user.contactNumber) : 'Not Applicable'}
              </div>
            </div>
            <div className="item">
              <div className="label">Email</div>
              <div className="value">{user?.email ?? 'Not Applicable'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
