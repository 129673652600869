import React from 'react';
type Props = {
  width?: number;
  height?: number;
};

const TrashIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 20}px`}
      viewBox="0 0 20 20"
      width={`${width ?? 20}px`}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5013 4.16536H14.168V3.33203C14.168 1.91536 13.0846 0.832031 11.668 0.832031H8.33464C6.91797 0.832031 5.83464 1.91536 5.83464 3.33203V4.16536H2.5013C2.0013 4.16536 1.66797 4.4987 1.66797 4.9987C1.66797 5.4987 2.0013 5.83203 2.5013 5.83203H3.33464V16.6654C3.33464 18.082 4.41797 19.1654 5.83464 19.1654H14.168C15.5846 19.1654 16.668 18.082 16.668 16.6654V5.83203H17.5013C18.0013 5.83203 18.3346 5.4987 18.3346 4.9987C18.3346 4.4987 18.0013 4.16536 17.5013 4.16536ZM7.5013 3.33203C7.5013 2.83203 7.83464 2.4987 8.33464 2.4987H11.668C12.168 2.4987 12.5013 2.83203 12.5013 3.33203V4.16536H7.5013V3.33203ZM14.168 17.4987C14.668 17.4987 15.0013 17.1654 15.0013 16.6654V5.83203H5.0013V16.6654C5.0013 17.1654 5.33464 17.4987 5.83464 17.4987H14.168ZM9.16797 9.16536V14.1654C9.16797 14.6654 8.83464 14.9987 8.33464 14.9987C7.83464 14.9987 7.5013 14.6654 7.5013 14.1654V9.16536C7.5013 8.66536 7.83464 8.33203 8.33464 8.33203C8.83464 8.33203 9.16797 8.66536 9.16797 9.16536ZM12.5013 14.1654V9.16536C12.5013 8.66536 12.168 8.33203 11.668 8.33203C11.168 8.33203 10.8346 8.66536 10.8346 9.16536V14.1654C10.8346 14.6654 11.168 14.9987 11.668 14.9987C12.168 14.9987 12.5013 14.6654 12.5013 14.1654Z"
        fill="#B13939"
      />
      <mask id="mask0_11962_8633" maskUnits="userSpaceOnUse" x="1" y="0" width="18" height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5013 4.16536H14.168V3.33203C14.168 1.91536 13.0846 0.832031 11.668 0.832031H8.33464C6.91797 0.832031 5.83464 1.91536 5.83464 3.33203V4.16536H2.5013C2.0013 4.16536 1.66797 4.4987 1.66797 4.9987C1.66797 5.4987 2.0013 5.83203 2.5013 5.83203H3.33464V16.6654C3.33464 18.082 4.41797 19.1654 5.83464 19.1654H14.168C15.5846 19.1654 16.668 18.082 16.668 16.6654V5.83203H17.5013C18.0013 5.83203 18.3346 5.4987 18.3346 4.9987C18.3346 4.4987 18.0013 4.16536 17.5013 4.16536ZM7.5013 3.33203C7.5013 2.83203 7.83464 2.4987 8.33464 2.4987H11.668C12.168 2.4987 12.5013 2.83203 12.5013 3.33203V4.16536H7.5013V3.33203ZM14.168 17.4987C14.668 17.4987 15.0013 17.1654 15.0013 16.6654V5.83203H5.0013V16.6654C5.0013 17.1654 5.33464 17.4987 5.83464 17.4987H14.168ZM9.16797 9.16536V14.1654C9.16797 14.6654 8.83464 14.9987 8.33464 14.9987C7.83464 14.9987 7.5013 14.6654 7.5013 14.1654V9.16536C7.5013 8.66536 7.83464 8.33203 8.33464 8.33203C8.83464 8.33203 9.16797 8.66536 9.16797 9.16536ZM12.5013 14.1654V9.16536C12.5013 8.66536 12.168 8.33203 11.668 8.33203C11.168 8.33203 10.8346 8.66536 10.8346 9.16536V14.1654C10.8346 14.6654 11.168 14.9987 11.668 14.9987C12.168 14.9987 12.5013 14.6654 12.5013 14.1654Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11962_8633)"></g>
    </svg>
  );
};
export default TrashIcon;
