import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../constants';
import { LoginResponseI, RenewTokenParamsI } from '../interfaces/auth';
import axiosInstance from '../axiosInstance';

const baseUri = process.env.REACT_APP_API_URI ?? '';

export const getAuthenToken = (): LoginResponseI => {
  const token: string = Cookies.get(TOKEN_NAME) ?? '';
  const tokenParsed = token ? JSON.parse(token) : undefined;
  return tokenParsed;
};

export const doRefreshToken = async (params: RenewTokenParamsI) => {
  const response = await axiosInstance.post(`${baseUri}/renew-token`, params, {
    headers: {
      Authorization: undefined,
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const getRenewToken = async () => {
  try {
    const { data }: any = await doRefreshToken({ renewToken: getAuthenToken().renewToken || '' });
    const authData = JSON.stringify(data?.data);
    Cookies.set(TOKEN_NAME, authData);
    return data?.data.authToken;
  } catch (error) {
    console.log('error', error);
  }
};
