import { Form, Input } from 'antd';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PrimaryButton from '../buttons/PrimaryButton';
import LoginSVG from '../../svgs/logo.svg';
import './LoginForm.scss';
import './ResetPassword.scss';
import { ForgetPasswordParamsI } from '../../interfaces/auth';

type Props = {
  isSuccess: boolean;
  handleSubmit: (_: any) => void;
};

const ForgotPasswordForm: React.FC<Props> = ({ isSuccess, handleSubmit }) => {
  const [email, setEmail] = useState<string>('');

  const onFinish = (values: ForgetPasswordParamsI) => {
    handleSubmit(values);
    setEmail(values.emailUsername);
  };

  return (
    <div className="login-form-container reset-password-container">
      <div className="form-container">
        <div className="logo-section">
          <Link to="/">
            <ReactSVG src={LoginSVG} />
          </Link>
        </div>
        {isSuccess ? (
          <div className="forgot-password-success-container">
            <p>
              We have sent you a reset link in your email: <span>{email}</span>
            </p>
            <p>If the email exists, we will send you the instruction for password reset.</p>
          </div>
        ) : (
          <Fragment>
            <h2 className="title">Forgot Password?</h2>
            <div className="forgot-password-container">
              <p>Please enter your email or username below.</p>
              <p>If the email exists, we will send you the instruction for password reset.</p>
              <Form scrollToFirstError layout="vertical" requiredMark={false} colon={false} onFinish={onFinish}>
                <Form.Item
                  label="Email or Username"
                  name="emailUsername"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Input size="large" placeholder="Email" />
                </Form.Item>
                <div className="FormFooter">
                  <PrimaryButton htmlType="submit" label="Reset Password" style={{ width: '100%' }} />
                </div>
              </Form>
            </div>
          </Fragment>
        )}
        <div className="back-to-button-wrap">
          <Link to="/login">Back to Sign In</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
