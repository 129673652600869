import { Form, Input } from 'antd';
import React from 'react';
import SearchInputIcon from '../../svgs/SearchInputIcon';
import Icon from '../Icon';
import IconButton from '../buttons/IconButton';

type Props = {
  handleFilter(_: any): void;
};

const DetailFilterForm: React.FC<Props> = ({ handleFilter }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: any) => {
    handleFilter(values);
  };

  return (
    <div className="filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="tw-flex tw-items-start tw-gap-3">
          <Form.Item name="search" className="tw-flex-auto tw-mb-4">
            <Input placeholder="Search" prefix={<SearchInputIcon />} />
          </Form.Item>
          <IconButton icon={<Icon name="icon-sort" size={20} />} />
        </div>
      </Form>
    </div>
  );
};

export default DetailFilterForm;
