import React from 'react';
import PartnerFilterFormComponent from './FilterForm';
import { Button } from 'antd';
import { ReactSVG } from 'react-svg';
import DownloadSVG from '../../svgs/download.svg';
import { AttachmentI } from '../../interfaces/attachments';
import { saveAs } from 'file-saver';
import AttachmentsTable from '../tables/Attachments';
import SizeBox from '../SizeBox';

type Props = {
  attachments?: any[];
};

const ProductAttachments: React.FC<Props> = ({ attachments }) => {
  const handleFilterSubmit = (values: { search: string }) => {
    console.log('values', values);
  };

  const handleDownload = (record: AttachmentI) => {
    saveAs(record.url, record.fileName || 'partner-attachment-file');
  };

  const withAction = (record: AttachmentI) => {
    return (
      <div className="table-action-icon" style={{ display: 'flex' }}>
        <Button className="btn-table-action-primary has-icon" type="link" onClick={() => handleDownload(record)}>
          <ReactSVG src={DownloadSVG} />
        </Button>
      </div>
    );
  };
  return (
    <div className="product-attachment-container">
      <PartnerFilterFormComponent handleFilter={handleFilterSubmit} />
      <SizeBox height={12} />
      <AttachmentsTable rowKey="id" pagination={false} dataSource={attachments} withAction={withAction} />
    </div>
  );
};

export default ProductAttachments;
