import React, { ReactNode } from 'react';
import Text, { ETextVariant } from './Text';

type Props = {
  icon: ReactNode;
  text: ReactNode;
  variant?: ETextVariant;
};

const IconText: React.FC<Props> = ({ icon, text, variant }) => {
  return (
    <div className="tw-flex tw-items-center tw-g-1">
      {icon}
      <Text variant={variant ?? 'labelMd'} component="span">
        {text}
      </Text>
    </div>
  );
};

export default IconText;
