import React from 'react';
import { Tag } from 'antd';
import { AllocationHistoryI } from '../../interfaces/allocationHistory';
import { DateFormat, getCountryFromCode } from '../../utils/global';
import Divider from '../Divider';
import { AllocationTypeComponent } from '../AllocationType';
import DetailsView from '../DetailsView';
import { Link } from 'react-router-dom';

type Props = {
  allocationHistory: AllocationHistoryI;
};

const AllocateeInformation: React.FC<Props> = ({ allocationHistory }) => {
  return (
    <div className="allocation-history-allocatee-information-container details-container" style={{ marginBottom: 16 }}>
      <DetailsView title="Allocatee’s Information">
        <DetailsView.Field label="Allocation Type" value={<AllocationTypeComponent type={allocationHistory?.type} />} />
        <div className="flex-row">
          <DetailsView.Field label="Date Created" value={DateFormat(allocationHistory?.createdAt)} />
        </div>
        <Divider />
        {allocationHistory?.siteId ? (
          <DetailsView.Field
            label="Site"
            value={
              <Tag>
                <Link to={`/sites/${allocationHistory?.site?.id}`}>{allocationHistory?.site?.displayName}</Link>
              </Tag>
            }
          />
        ) : (
          <>
            <div className="flex-row">
              <DetailsView.Field label="Address Line 1" value={allocationHistory?.addressLine1} />
              <DetailsView.Field label="Address Line 2" value={allocationHistory?.addressLine2} />
              <DetailsView.Field label="Address Line 3" value={allocationHistory?.addressLine3} />
            </div>
            <div className="flex-row">
              <DetailsView.Field label="Country" value={getCountryFromCode(allocationHistory?.country)} />
              <DetailsView.Field label="Region" value={allocationHistory?.region} />
              <DetailsView.Field label="State / Province" value={allocationHistory?.state} />
            </div>
            <DetailsView.Field label="Postal / Zip Code" value={allocationHistory?.postalCode} />
          </>
        )}
      </DetailsView>
    </div>
  );
};

export default AllocateeInformation;
