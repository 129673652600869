import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const PaperClipIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg width={width ?? 20} height={height ?? 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="attach_file" clipPath="url(#clip0_13773_87651)">
        <path
          id="Vector"
          d="M13.7487 5.00065V14.584C13.7487 16.4256 12.257 17.9173 10.4154 17.9173C8.5737 17.9173 7.08203 16.4256 7.08203 14.584V4.16732C7.08203 3.01732 8.01536 2.08398 9.16536 2.08398C10.3154 2.08398 11.2487 3.01732 11.2487 4.16732V12.9173C11.2487 13.3756 10.8737 13.7506 10.4154 13.7506C9.95703 13.7506 9.58203 13.3756 9.58203 12.9173V5.00065H8.33203V12.9173C8.33203 14.0673 9.26536 15.0006 10.4154 15.0006C11.5654 15.0006 12.4987 14.0673 12.4987 12.9173V4.16732C12.4987 2.32565 11.007 0.833984 9.16536 0.833984C7.3237 0.833984 5.83203 2.32565 5.83203 4.16732V14.584C5.83203 17.1173 7.88203 19.1673 10.4154 19.1673C12.9487 19.1673 14.9987 17.1173 14.9987 14.584V5.00065H13.7487Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13773_87651">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaperClipIcon;
