import { Form, FormInstance, Input, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { convertToApiFile, humanizeText } from '../../../utils/global';
import { AttachmentCategories, Markets, SiteStatuses, SiteTypes } from '../../../constants';
import countryList from 'react-select-country-list';
import './Form.scss';
import { PartnerI } from '../../../interfaces/partner';
import { AttachmentI } from '../../../interfaces/attachments';
import { SiteParamsI } from '../../../interfaces/site';
import { CurrentUserI } from '../../../interfaces/auth';
import { getPartnersList } from '../../../apis/partners';
import { useQuery } from 'react-query';
import UploadAttachmentButton from '../../buttons/UploadAttachmentButton';

type Props = {
  form: FormInstance;
  siteId?: number;
  site?: PartnerI;
  currentUser: CurrentUserI;
  handleSubmit(_: any): void;
};

const SiteForm: React.FC<Props> = ({ site, siteId, form, currentUser, handleSubmit }) => {
  const [files, setFiles] = useState<any[]>([]);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [siteStatus, setSiteStatus] = useState<string>('active');

  const { data: partners = [], isLoading } = useQuery(['partners', 'list', {}], () => getPartnersList(), {
    enabled: currentUser.type === 'ecoSpiritsAdmin',
    select({ data: { partners } }) {
      return partners;
    },
  });

  const handleOnFinish = (values: SiteParamsI) => {
    const newValues: any = values;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (site && site.id === siteId) {
      setSiteStatus(site.status);
      let newAttachments;
      if (site?.attachments && Object.values(site.attachments).length > 0) {
        newAttachments = site?.attachments.map((attachment: AttachmentI) => {
          return {
            categoryName: attachment.categoryName,
            id: attachment.id,
            name: attachment.fileName,
            type: attachment.fileType,
            url: attachment.url,
          };
        });
      }

      form.setFieldsValue({
        ...site,
      });
      setFiles(newAttachments ?? []);
    }
  }, [site]);

  return (
    <div className="site-form-container">
      <Form
        form={form}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        layout={'vertical'}
        initialValues={{
          attachments: [{ file: [] }],
          status: siteStatus,
        }}
      >
        <div className="form-container">
          <div className="form-content">
            <div className="content-wrap">
              <h2 className="form-title">Site Information</h2>
              <Form.Item
                label="Site Name"
                name="legalName"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type site name" />
              </Form.Item>
              <Form.Item
                label="Display Name"
                name="displayName"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type display name" />
              </Form.Item>
              {currentUser.type === 'ecoSpiritsAdmin' && (
                <Form.Item
                  label="Partner ID"
                  name="partnerId"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Select placeholder="Choose a partner" style={{ width: '100%' }} loading={isLoading}>
                    {partners.map((partner) => (
                      <Select.Option key={partner?.id} value={partner.id}>
                        <span>{humanizeText(partner?.legalName)}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label="Type of Site"
                name="type"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Select placeholder="Select an option" style={{ width: '100%' }}>
                  {SiteTypes.map((type: { name: string; value: string }) => (
                    <Select.Option key={type.value} value={type.value}>
                      <span className="text-capitalize">{humanizeText(type.name)}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Market" name="market" rules={[{ required: true, message: 'This field is required' }]}>
                <Select placeholder="Select an option" style={{ width: '100%' }}>
                  {Markets.map((type: { name: string; value: string }) => (
                    <Select.Option key={type.value} value={type.value}>
                      <span className="text-capitalize">{humanizeText(type.name)}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea placeholder="Type a description to describe this site" rows={5} />
              </Form.Item>
              <Form.Item label="Status" name="status">
                <Select placeholder="Choose a option" style={{ width: '100%' }}>
                  {SiteStatuses.map((type: { name: string; value: string }) => (
                    <Select.Option key={type.value} value={type.value}>
                      <span className="text-capitalize">{humanizeText(type.name)}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="content-wrap">
              <h2 className="form-title">Location</h2>
              <Form.Item
                label="Address Line 1"
                name="addressLine1"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item label="Address Line 2" name="addressLine2">
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item label="Address Line 3" name="addressLine3">
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item label="Country" name="country" rules={[{ required: true, message: 'This field is required' }]}>
                <Select placeholder="Select a country" showSearch optionFilterProp="label" options={countryOptions} />
              </Form.Item>
              <Form.Item label="Region" name="region">
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item label="State/Province" name="state">
                <Input placeholder="Type here" />
              </Form.Item>
              <Form.Item
                label="Postal/Zip Code"
                name="postalCode"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Type here" />
              </Form.Item>
            </div>
            <div className="content-wrap">
              <div className="attachment-list-section">
                <h2 className="form-title">Upload File</h2>
                <Form.Item name="uploadFile" style={{ width: '100%' }}>
                  <UploadAttachmentButton
                    categories={AttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SiteForm;
