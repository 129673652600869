import React from 'react';
import { Tabs, TabsProps } from 'antd';
import Icon from '../Icon';
import { ConfigurationHardwareComponent } from './Hardware';
import { ConfigurationI } from '../../interfaces/configuration';

type Props = {
  data?: ConfigurationI;
};

const ConfigurationResources: React.FC<Props> = ({ data }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span className="tab-title tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-all_inbox" size={20} /> <span>Hardware</span>
        </span>
      ),
      children: <ConfigurationHardwareComponent data={data?.hardware ?? []} />,
    },
  ];

  return (
    <div className="details-container" style={{ marginTop: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default ConfigurationResources;
