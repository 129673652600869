import React from 'react';
type Props = {
  size?: number;
};

const RefreshIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${size ?? 16}px`}
      viewBox="0 0 16 16"
      width={`${size ?? 16}px`}
      fill="none"
    >
      <path
        d="M7.99977 14.2502C6.86371 14.2514 5.74885 13.9426 4.77536 13.357C3.80188 12.7713 3.00668 11.9311 2.47547 10.9269C1.94427 9.92268 1.69721 8.79253 1.76091 7.65825C1.82461 6.52398 2.19667 5.42859 2.83698 4.49017L4.25047 5.61683C4.31529 5.66785 4.39766 5.69116 4.47961 5.68168C4.56155 5.6722 4.63642 5.63069 4.68788 5.56622C4.72648 5.51837 4.74978 5.46002 4.75475 5.39875L5.10968 1.22952C5.11319 1.18861 5.10861 1.14741 5.09619 1.10827C5.08376 1.06913 5.06375 1.03283 5.03728 1.00143C5.01082 0.970031 4.97843 0.944158 4.94195 0.925289C4.90548 0.90642 4.86565 0.894926 4.82473 0.891463C4.78382 0.888227 4.74266 0.892894 4.70351 0.90521L0.719873 2.18122C0.666126 2.19845 0.61805 2.22992 0.580751 2.27228C0.543452 2.31464 0.518325 2.36631 0.508037 2.4218C0.503858 2.44048 0.501556 2.45953 0.501163 2.47867C0.501211 2.52565 0.511855 2.57202 0.532303 2.61432C0.552751 2.65663 0.582476 2.69377 0.619266 2.723L1.85779 3.71031C1.07026 4.83314 0.606176 6.15066 0.516154 7.51918C0.426131 8.8877 0.713625 10.2547 1.34728 11.471C1.98094 12.6873 2.93642 13.7063 4.10952 14.4167C5.28262 15.1272 6.62829 15.5019 7.99977 15.5C8.1655 15.5 8.32444 15.4342 8.44163 15.317C8.55882 15.1998 8.62465 15.0408 8.62465 14.8751C8.62465 14.7094 8.55882 14.5504 8.44163 14.4332C8.32444 14.3161 8.1655 14.2502 7.99977 14.2502Z"
        fill="currentColor"
      />
      <path
        d="M7.375 1.12766C7.375 1.29339 7.44084 1.45233 7.55802 1.56952C7.67521 1.68671 7.83415 1.75255 7.99988 1.75255C9.13595 1.75132 10.2508 2.06017 11.2243 2.64581C12.1978 3.23144 12.993 4.07164 13.5242 5.07587C14.0554 6.08009 14.3024 7.21024 14.2387 8.34452C14.175 9.47879 13.803 10.5742 13.1627 11.5126L11.7492 10.3859C11.7049 10.3508 11.652 10.3284 11.596 10.3209C11.54 10.3135 11.4831 10.3214 11.4312 10.3438C11.3793 10.3662 11.3345 10.4022 11.3015 10.448C11.2685 10.4938 11.2485 10.5477 11.2437 10.604L10.8887 14.7732C10.8852 14.8142 10.8898 14.8554 10.9022 14.8945C10.9146 14.9336 10.9347 14.9699 10.9611 15.0013C10.9876 15.0327 11.02 15.0586 11.0564 15.0775C11.0929 15.0964 11.1328 15.1078 11.1737 15.1113C11.2146 15.1145 11.2557 15.1099 11.2949 15.0976L15.2798 13.8215C15.3335 13.8043 15.3816 13.7729 15.4189 13.7305C15.4562 13.6881 15.4813 13.6365 15.4916 13.581C15.4958 13.5623 15.4981 13.5432 15.4985 13.5241C15.4984 13.4771 15.4878 13.4307 15.4673 13.3884C15.4469 13.3461 15.4172 13.309 15.3804 13.2798L14.1419 12.2925C14.9294 11.1696 15.3935 9.85211 15.4835 8.48359C15.5735 7.11507 15.286 5.74811 14.6524 4.53179C14.0187 3.31548 13.0632 2.29651 11.8901 1.58604C10.717 0.875559 9.37136 0.50085 7.99988 0.502779C7.83415 0.502779 7.67521 0.568615 7.55802 0.685803C7.44084 0.802991 7.375 0.961933 7.375 1.12766Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RefreshIcon;
