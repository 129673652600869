import React from 'react';
import Text from './Text';
import { DateTimeFormat } from '../utils/global';

const DisplayLastUpdateApp = () => {
  return (
    <Text variant="bodyLg" className="tw-text-grey-700">
      Last Updated: <span className="tw-text-grey-800">{DateTimeFormat(new Date())}</span>
    </Text>
  );
};

export default DisplayLastUpdateApp;
