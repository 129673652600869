import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const QuestionMarkIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 18 18"
      height={`${height ?? 18}px`}
      viewBox="0 0 18 18"
      width={`${width ?? 18}px`}
      fill={color ?? 'currentColor'}
    >
      <path
        id="Vector"
        d="M8.16675 13.9993H9.83342V12.3327H8.16675V13.9993ZM9.00008 0.666016C4.40008 0.666016 0.666748 4.39935 0.666748 8.99935C0.666748 13.5993 4.40008 17.3327 9.00008 17.3327C13.6001 17.3327 17.3334 13.5993 17.3334 8.99935C17.3334 4.39935 13.6001 0.666016 9.00008 0.666016ZM9.00008 15.666C5.32508 15.666 2.33341 12.6743 2.33341 8.99935C2.33341 5.32435 5.32508 2.33268 9.00008 2.33268C12.6751 2.33268 15.6667 5.32435 15.6667 8.99935C15.6667 12.6743 12.6751 15.666 9.00008 15.666ZM9.00008 3.99935C7.15841 3.99935 5.66675 5.49102 5.66675 7.33268H7.33342C7.33342 6.41602 8.08342 5.66602 9.00008 5.66602C9.91675 5.66602 10.6667 6.41602 10.6667 7.33268C10.6667 8.99935 8.16675 8.79102 8.16675 11.4993H9.83342C9.83342 9.62435 12.3334 9.41602 12.3334 7.33268C12.3334 5.49102 10.8417 3.99935 9.00008 3.99935Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};

export default QuestionMarkIcon;
