import React, { Fragment, useState } from 'react';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import FillingScanQRCode from './ScanQRCode';
import { Modal, Tabs, TabsProps } from 'antd';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import { FillingStartScanningScreen } from './StartScanningScreen';
import { Link } from 'react-router-dom';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import FillingManual from './Manual';
import './ScanStep.scss';
import { ProductI } from '../../interfaces/product';
import ProductCardItem from './ProductCardItem';
import { FillingParamsI } from '../../interfaces/hardwareFilling';
import { getHardwareProductBySKU } from '../../apis/hardwares';

const RenderDescriptionWarningNotice = () => {
  return (
    <Fragment>
      <p>Ensure that the product has been created before scanning. If not, please proceed to create the product.</p>
      <Link to={`/products/new`}>
        <span>Create Product</span>
        <RightArrowIcon width={16} height={16} />{' '}
      </Link>
    </Fragment>
  );
};

const ScanNoticeMessage =
  'QR/Bar Code for Product can be found on the Brand Plate near the handle of ecoTOTE. Please note that it’s not to be mistaken with the QR/Bar code of the ecoTOTE itself on the outer casing.';

const StartScreenContent = () => {
  return (
    <ul className="tw-my-0">
      <li>QR/Barcode for Product can be found on the Brand Plate near the handle of ecoTOTE </li>
      <li>Please note that it’s not to be mistaken with the QR/Barcode of the ecoTOTE itself on the outer casing</li>
    </ul>
  );
};

type Props = {
  setCurrentStep: (_: number) => void;
  setScannedData: (_: FillingParamsI) => void;
  scannedData?: FillingParamsI;
  stopDecodingScanner: boolean;
  currentStep: number;
  pageName?: string;
  setStopDecodingScanner: (_: any) => void;
};

const FillingProductScanStep: React.FC<Props> = ({
  scannedData,
  stopDecodingScanner,
  currentStep,
  pageName,
  setScannedData,
  setCurrentStep,
  setStopDecodingScanner,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [screen, setScreen] = useState<'start' | 'scan' | 'scanned'>('start');
  const [dataChecked, setDataChecked] = useState<ProductI>();

  const validateScanResult = async (value: string) => {
    let status = 'success';
    try {
      const result = await getHardwareProductBySKU(value);
      setTimeout(() => {
        if (status === 'success') {
          setScreen('scanned');
          setDataChecked(result);
          setScannedData({ ...scannedData, productId: result?.id, product: result });
        }
      }, 1000);
    } catch (error: any) {
      const errorModal = modal.error({
        type: 'error',
        title: 'Failed Scanning!',
        content:
          'We cannot recognise this product’s serial code. Please make sure the Product is registered in database.',
        className: 'context-modal-custom-container',
        closable: true,
        centered: true,
        footer: null,
        icon: <ErrorModalIcon color="#B13939" />,
        width: 345,
      });

      setTimeout(() => {
        errorModal.destroy();
      }, 1000);
    }
  };

  const handleScanOrManualSubmit = (result: any) => {
    validateScanResult(result.text);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <FillingScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanNoticeMessage={ScanNoticeMessage}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          pageName={pageName}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <FillingManual scanNoticeMessage={ScanNoticeMessage} handleScanOrManualSubmit={handleScanOrManualSubmit} />
      ),
    },
  ];

  const onDiscard = () => {
    if (dataChecked) {
      setScreen('scan');
      setDataChecked(undefined);
    } else {
      setCurrentStep(1);
    }
  };

  const onContinue = () => {
    setCurrentStep(3);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  const onStartScreenClick = () => {
    setStopDecodingScanner(false);
    setScreen('scan');
  };

  return (
    <div className="component-hardware-filling-scan-step">
      <div className="wrapper">
        {screen === 'start' && (
          <FillingStartScanningScreen
            warningDescription2={<RenderDescriptionWarningNotice />}
            setScreen={setScreen}
            setStopDecodingScanner={setStopDecodingScanner}
            currentStep={currentStep}
            content={<StartScreenContent />}
          />
        )}
        {screen === 'scan' && <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />}
        {screen === 'scanned' && <ProductCardItem item={dataChecked} />}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label={dataChecked ? 'Rescan' : 'Previous Step'} onClick={onDiscard} />
          {screen === 'start' && <PrimaryButton label={'Scan Product Code'} onClick={onStartScreenClick} />}
          {screen !== 'start' && <PrimaryButton label={'Continue'} onClick={onContinue} disabled={!dataChecked} />}
        </div>
      </div>
    </div>
  );
};

export default FillingProductScanStep;
